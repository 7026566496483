import { useGetUserIntegrationsQuery } from "../../../../Redux/api/users.api";
import MDContainer from "components/MDContainer";
import Block from "layouts/pages/widgets/components/Block";
import { useParams } from "react-router-dom";
import IntegrationItem from "./blocks/integration-item";

interface IIntegrationsProps {}

const Integrations = ({}: IIntegrationsProps) => {
  const { id: userId } = useParams();
  const { data } = useGetUserIntegrationsQuery(userId);

  if (!data?.integrations) {
    return null;
  }
  return (
    <Block title="Интеграции" id="integrations" wrapperStyles={{ width: "100%" }}>
      <MDContainer variant="column" gap={0}>
        {data.integrations.map((integration: any) => (
          <IntegrationItem userId={userId} key={integration.integration_id} data={integration} />
        ))}
      </MDContainer>
    </Block>
  );
};

export default Integrations;
