/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext, useEffect, useState } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

import { Form, Formik, FormikErrors } from "formik";

import * as Yup from "yup";

import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

import CoverLayout from "layouts/authentication/components/CoverLayout";

import bgImage from "assets/images/bg-sign-in-cover.jpeg";

import { Login } from "api/auth/login";
import { SendPassword } from "api/auth/send-password";
import { IFormValues } from "./interfaces";
import { routes } from "../../../../constants";
import { UserContext } from "context/user";
import { Logout } from "api/auth/logout";

const initialValues: IFormValues = {
  email: "",
  password: "",
};

const emailSchema = Yup.object().shape({
  email: Yup.string()
    .min(2, "Минимальная длина 2 символа")
    .max(50, "Максимальная длина 50 символов")
    .email("Некоректный email")
    .required("Email является обязательным"),
});

const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, "Пароль слишком короткий!")
    .max(50, "Пароль слишком длинный!")
    .required("Пароль является обязательным"),
});

function Cover(): JSX.Element {
  const [isPassword, setIsPassword] = useState<boolean>(false);

  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  const getFormContent = (
    values: IFormValues,
    handleChange: any,
    errors: FormikErrors<IFormValues>
  ) => {
    if (isPassword) {
      return (
        <MDBox mb={2}>
          <MDInput
            type="password"
            label="Пароль"
            variant="standard"
            fullWidth
            placeholder="Вам на почту отправлен пароль"
            InputLabelProps={{ shrink: true }}
            value={values.password}
            onChange={handleChange("password")}
            error={errors["password"]}
            helperText={errors["password"]}
          />
        </MDBox>
      );
    }

    return (
      <MDBox mb={2}>
        <MDInput
          type="email"
          label="Email"
          variant="standard"
          fullWidth
          placeholder="Введите пожалуйста свой e-mail"
          InputLabelProps={{ shrink: true }}
          value={values.email}
          onChange={handleChange("email")}
          error={errors["email"]}
          helperText={errors["email"]}
        />
      </MDBox>
    );
  };

  const handleSubmit = async (values: IFormValues, config: any) => {
    if (!isPassword) {
      const { message } = await SendPassword(values.email);
      if (message) setIsPassword(true);
      return;
    }

    const { user } = await Login(values.email, values.password);

    if (user) {
      const { access_token, ...restUser } = user;
      setUser(restUser);
      localStorage.setItem("token", access_token.value);
      navigate(routes.USERS);
    }
  };

  useEffect(() => {
    (async () => {
      await logout();
    })();
  }, []);

  const logout = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      await Logout(false).then(() => localStorage.removeItem("token"));
    } else {
      return;
    }
  };

  return (
    <CoverLayout image={bgImage}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={isPassword ? passwordSchema : emailSchema}
      >
        {({ values, errors, handleChange, isSubmitting }) => (
          <Form>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={-3}
                p={3}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  ВХОД
                </MDTypography>
                <MDTypography display="block" variant="button" color="white" my={1}>
                  Введите логин и пароль указанный в поле
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox>
                  {getFormContent(values, handleChange, errors)}

                  <MDBox mt={4} mb={1}>
                    <MDButton
                      variant="gradient"
                      color="primary"
                      fullWidth={true}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      ГОТОВО
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Form>
        )}
      </Formik>
    </CoverLayout>
  );
}

export default Cover;
