import { useEffect, useMemo, useState } from "react";
import Select from "layouts/pages/widgets/components/Select";
import { IItem } from "layouts/pages/widgets/components/Select/interfaces";
import { useGetWarehousesQuery } from "../../../../../Redux/api/warehouses.api";

interface INomenclatureFieldProps {
  disabled?: boolean;
  error?: string;
  initialValueId?: number | string;
  onSelect?: (value: IItem) => void;
}

const formatToSelectItem = (units?: any[]): IItem[] => {
  if (!units) return [];
  return units.map((unit: any) => ({
    id: unit?.id,
    label: unit?.name,
    value: unit?.id,
    icon: "",
  }));
};

const WarehouseField = ({ disabled, onSelect, error, initialValueId }: INomenclatureFieldProps) => {
  const { data } = useGetWarehousesQuery("", { refetchOnMountOrArgChange: true });
  const [selectedOption, setSelectedOption] = useState<IItem | null>(null);
  const options = useMemo(() => formatToSelectItem(data?.warehouses), [data]);

  useEffect(() => {
    const option = options.find((item: any) => item.id == initialValueId);
    if (option) {
      setSelectedOption(option);
    }
  }, [options]);

  const onSelectItem = (value: IItem) => {
    setSelectedOption(value);
    onSelect(value);
  };

  return (
    <Select
      label="Склад"
      placeholder="Выберите из списка"
      noPointerEvents={disabled}
      error={error}
      popupIcon={disabled ? null : undefined}
      select={{
        value: selectedOption,
        items: options,
        onSelect: onSelectItem,
      }}
    />
  );
};

export default WarehouseField;
