import { IBranch, INewBranch } from "Redux/api/interfaces";

export const getInitialValues = (branch: { branch: IBranch }) => {
  if (branch?.branch) {
    return {
      address: branch.branch.address,
      code: branch.branch.code,
      email: branch.branch.email,
      head: branch.branch.head,
      id: branch.branch.id,
      inn: branch.branch.inn,
      kpp: branch.branch.kpp,
      legal_address: branch.branch.legal_address,
      name: branch.branch.name,
      phone_1: branch.branch.phone_1,
      phone_2: branch.branch.phone_2,
      title: branch.branch.title,
      responsible_user: branch.branch.responsible_user,
    } as IBranch & INewBranch;
  }
};
