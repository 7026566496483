import { api } from ".";

export interface ISalesChannelResponse {
  salesChannels: ISalesChannel[];
  salesChannelsCount: number;
}

export interface ISalesChannel {
  id: number;
  name: string;
  avatar: string;
  created_at: string;
}

export const salesChanelsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSalesChanels: builder.query<ISalesChannelResponse, void>({
      query: () => `sales_channels`,
      transformErrorResponse: (response: any) => response.data.message,
    }),
  }),
});

export const { useGetSalesChanelsQuery } = salesChanelsApi;
