import React, { Fragment, useState, useEffect } from "react";
// import "dayjs/locale/ru";
// Material Dashboard 2 PRO React components
import { CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
// import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import ru from "dayjs/locale/ru";
import Icon from "@mui/material/Icon";
import Filters from "components/MDFilters";
import MuiMenu from "@mui/material/Menu";
import { useTheme, styled, Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useStyles } from "./styles";
import { IProps } from "./interfaces";

import CalendarIcon from "assets/images/calendar-range.svg";
import pxToRem from "assets/theme/functions/pxToRem";
import ClearIcon from "@mui/icons-material/Clear";

const Menu = styled(MuiMenu)(({ theme }: { theme: Theme }) => {
  return {
    "& > .MuiPaper-root": {
      backgroundColor: `${theme.palette.translucent.main} !important`,
      backdropFilter: "blur(10px)",
      border: `1px solid ${theme.palette.translucent.main}`,
      [theme.breakpoints.down("lg")]: {
        width: `min(${pxToRem(330)}, 100%)`,
      },
    },
  };
});

function TopPanel(props: IProps) {
  const [resetKey, setResetKey] = useState(0);
  const { main, dates, filters, exportCSV, globalStyles } = props;

  const theme = useTheme();
  const styles = useStyles(theme);
  const [menu, setMenu] = useState(null);

  const openMenu = (event: any) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      {filters ? <Filters filters={filters} onClose={closeMenu} /> : <CircularProgress />}
    </Menu>
  );

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const IconCustom = () => <img src={CalendarIcon} />;

  const deleteDate = () => {
    dates?.from?.action(null);
    dates?.to?.action(null);
    setResetKey((prevKey) => prevKey + 1);
  };

  return (
    <MDBox sx={{ ...styles.wrapper, ...globalStyles }} key={`date-${resetKey}`}>
      <MDButton
        sx={styles.main}
        onClick={main.action}
        variant="gradient"
        color="info"
        style={{
          alignSelf: isMobile ? "center" : "flex-start",
          ...(isMobile ? { width: "100%" } : {}),
        }}
      >
        <Icon>add</Icon>
        {main.title}
      </MDButton>

      <MDBox
        sx={{
          ...styles.inner,
          ...(isMobile ? { flexDirection: "column", width: "100%" } : {}),
        }}
      >
        <MDBox sx={styles.dates}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"ru"}>
            {dates?.from && (
              <DatePicker
                sx={styles.date}
                label={dates.from.placeholder}
                format={dates.mask || "DD.MM.YYYY"}
                value={dates.from.value}
                onChange={dates.from.action}
                slots={{ openPickerIcon: IconCustom }}
              />
            )}

            {dates?.from && dates?.to && <span>-</span>}

            {dates?.to && (
              <DatePicker
                sx={styles.date}
                label={dates.to.placeholder}
                format={dates.mask || "DD.MM.YYYY"}
                value={dates.to.value}
                onChange={dates.to.action}
                slots={{ openPickerIcon: IconCustom }}
              />
            )}

            {dates?.from && dates?.to && (
              <MDButton
                onClick={deleteDate}
                size="small"
                border="none"
                iconOnly
                sx={{
                  backgroundColor: "transparent !important",
                  padding: "3px",
                  maxWidth: "16px",
                  maxHeight: "16px",
                  minWidth: "16px",
                  minHeight: "16px",
                  opacity: dates.to.value || dates.from.value ? 1 : 0,
                  pointerEvents: dates.to.value || dates.from.value ? "initial" : "none",
                }}
              >
                <ClearIcon />
              </MDButton>
            )}
          </LocalizationProvider>
        </MDBox>

        <MDBox sx={styles.buttons}>
          {filters && (
            <Fragment>
              <MDButton sx={styles.button} variant="outlined" color="dark" onClick={openMenu}>
                Фильтры
                <Icon>keyboard_arrow_down</Icon>
              </MDButton>
              {renderMenu}
            </Fragment>
          )}

          {exportCSV && (
            <MDButton sx={styles.button} variant="outlined" color="dark">
              <Icon>add</Icon>
              export csv
            </MDButton>
          )}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default TopPanel;
