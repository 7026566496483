import { Box } from "@mui/material";
import MDTypography from "components/MDTypography";
import { ReactNode } from "react";

interface IReadOnlyContent {
  avatar: string;
  title: ReactNode;
  description: ReactNode;
}

export const ReadOnlyContent = (props: IReadOnlyContent) => {
  const { avatar, title, description, ...other } = props;

  return (
    <Box display="flex" alignItems="center" gap="20px" {...other}>
      <Box display="flex" alignItems="center" gap="8px">
        <img src={avatar} alt="" style={{ width: 16, height: 16 }} />

        <MDTypography
          color="dark"
          fontSize={14}
          lineHeight="21px"
          fontWeight={400}
          letterSpacing="0.13px"
        >
          {title}
        </MDTypography>
      </Box>

      <MDTypography
        color="dark"
        fontSize={14}
        lineHeight="21px"
        fontWeight={400}
        letterSpacing="0.13px"
      >
        {description}
      </MDTypography>
    </Box>
  );
};
