import { api } from "./index";
import { IDepartment } from "./interfaces";

//types
export interface IDepartmentsResponse {
  departments: IDepartment[];
  departmentsCount: number;
}

export const departmentsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDepartments: builder.query<IDepartmentsResponse, void>({
      query: () => `departments`,
      keepUnusedDataFor: 0,
    }),
    // getDepartmentsById: builder.query({
    //   query: (idDepartments) => `departments/${idDepartments}`,
    // }),
    // createDepartments: builder.mutation({
    //   query: (initialPost) => ({
    //     url: `departments`,
    //     method: "POST",
    //     body: initialPost,
    //   }),
    // }),
    // editDepartments: builder.mutation({
    //   query: (initialPost) => ({
    //     url: `departments/${initialPost.idDepartments}`,
    //     method: "PUT",
    //     body: initialPost,
    //   }),
    // }),
    // deleteDepartments: builder.mutation({
    //   query: (idDepartments) => ({
    //     url: `departments/${idDepartments}`,
    //     method: "DELETE",
    //   }),
    // }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetDepartmentsQuery,
  // useGetDepartmentsByIdQuery,
  // useCreateDepartmentsMutation,
  // useEditDepartmentsMutation,
  // useDeleteDepartmentsMutation,
} = departmentsApi;
