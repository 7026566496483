import { api } from ".";

export interface ICountryResponse {
  countries: ICountry[];
  countriesCount: number;
}

export interface ICountry {
  id: number;
  name: string;
  avatar: string;
  created_at: string;
}

export const countryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCountries: builder.query<ICountryResponse, void>({
      query: () => `countries`,
      transformErrorResponse: (response: any) => response.data.message,
    }),
  }),
});

export const { useGetCountriesQuery } = countryApi;
