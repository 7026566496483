import {
  IAddCompanyCounterparty,
  IAddPhysicalCounterparty,
  ICounterpartyResponse,
} from "Redux/api/counterperties.api";

export const getMainCompanyInitialValues = (counterparty?: ICounterpartyResponse) => {
  if (counterparty?.counterparty && "name" in counterparty.counterparty) {
    const {
      id,
      type,
      name,
      inn,
      head,
      registered_at,
      phone_1,
      phone_2,
      email,
      legal_address,
      kpp,
    } = counterparty.counterparty;
    return {
      id,
      counterparty_type_id: type.id,
      name,
      inn,
      kpp,
      head,
      registered_at,
      phone_1,
      phone_2,
      email,
      legal_address,
      is_company: true,
    } as IAddCompanyCounterparty;
  }
};

export const getMainPhysicalInitialValues = (counterparty?: ICounterpartyResponse) => {
  if (counterparty?.counterparty && "first_name" in counterparty.counterparty) {
    const {
      id,
      type,
      first_name,
      last_name,
      middle_name,
      birthday,
      phone_1,
      phone_2,
      email,
      address,
    } = counterparty.counterparty;

    return {
      id,
      counterparty_type_id: type.id,
      first_name,
      last_name,
      middle_name,
      birthday,
      phone_1,
      phone_2,
      email,
      address,
      is_company: false,
    } as IAddPhysicalCounterparty;
  }
};
