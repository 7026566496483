import { BaseQueryFn, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../api/constants/baseUrl";
import { toast } from "react-hot-toast";

interface IMessage {
  message: string;
}

const hasErrorMessage = (obj: unknown): obj is IMessage => {
  return (obj as IMessage)?.message !== undefined && typeof (obj as IMessage).message === "string";
};

const baseQuery = fetchBaseQuery({
  baseUrl: baseURL,
  prepareHeaders: async (headers) => {
    const accessToken = localStorage.getItem("token");
    if (accessToken) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }
    headers.set("Accept", "application/json");
    headers.set("Content-Type", "application/json");
    return headers;
  },
});

export const baseQueryWithReauth: BaseQueryFn = async (
  args?: any,
  api?: any,
  extraOptions = {
    baseQuery,
  }
) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result?.error?.data) {
    let temp = result?.error?.data as any;
    toast.error(Object.values(temp.errors).join("\n"), {
      duration: 5000,
    });
  }

  if (result.error && hasErrorMessage(result.error.data)) {
    toast.error(`${result.error.status.toString()} ${result.error?.data?.message}`, {
      duration: 5000,
    });
  }

  if (
    result.error &&
    result.error?.status === 401 &&
    result.error?.data &&
    hasErrorMessage(result.error.data) &&
    result.error?.data?.message === "Unauthenticated."
  ) {
    sessionStorage.clear();
    localStorage.clear();
    window.location.replace("/authentication/sign-in/cover");
  }

  return result;
};

export const api = createApi({
  reducerPath: "api",
  tagTypes: [
    "counterparty",
    "counterparty_bank_requisites",
    "branch_bank_requisites",
    "counterparty_contacts",
    "branch",
    "applications",
    "branch_integrations",
    "category",
    "user",
    "user-integrations",
    "nomenclatures",
    "orders",
    "order_nomenclatures",
    "warehouse",
    "warehouse_integrations",
    "inventories",
    "shops",
    "shopHours",
    "shopHolidays",
    "shopExceptions",
  ],
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
