import { useEffect, useState } from "react";
import { IFilter } from "layouts/pages/widgets/components/TopPanel/interfaces";
import { ICity } from "../../../../Redux/api/warehouses.api";
import { useGetCitiesQuery } from "../../../../Redux/api/search-city.api";
import {
  INomenclatureType,
  useGetNomenclatureTypesQuery,
} from "../../../../Redux/api/nomenclature-types.api";

const ownedFilters: IFilter = {
  id: 1,
  title: "Иерархия",
  tag: "owned_by_us",
  url: "&owned_by_us=",
  isInput: true,
  list: [
    {
      id: 1,
      isInput: false,
      tag: "balance",
      name: "Свой",
      url: "1",
    },
    {
      id: 2,
      isInput: false,
      tag: "balance",
      name: "Чужой",
      url: "0",
    },
  ],
  exclusive: true,
};

const addFilers = (filters: IFilter[], cities: ICity[], types: INomenclatureType[]) => {
  const citiesFilters: IFilter = {
    id: 3,
    title: "Город",
    tag: "city",
    url: "&city_id[]=",
    isInput: true,
    list: cities?.map((city: ICity) => ({
      id: city.id,
      isInput: false,
      tag: "city",
      name: city.name,
      url: `${city.id}`,
    })),
    exclusive: true,
  };

  const typesFilters: IFilter = {
    id: 2,
    title: "Тип",
    tag: "type",
    url: "&type_id[]=",
    isInput: true,
    list: types.map((type: INomenclatureType) => ({
      id: type.id,
      isInput: false,
      tag: "type",
      name: type.name,
      url: `${type.id}`,
    })),
    exclusive: true,
  };

  return [...filters, ownedFilters, citiesFilters, typesFilters];
};

export const useWarehousesFilters = () => {
  const [filters, setFilters] = useState<IFilter[]>([]);
  const { data: cities, isFetching: isResponsibleCitiesFetching } = useGetCitiesQuery();
  const { data: types, isFetching: isResponsibleTypesFetching } = useGetNomenclatureTypesQuery();

  useEffect(() => {
    if (cities?.cities && types?.nomenclatureTypes) {
      setFilters((old) => {
        return addFilers(old, cities.cities, types.nomenclatureTypes);
      });
    }
  }, [cities, types]);

  if (isResponsibleCitiesFetching || isResponsibleTypesFetching) return null;

  return filters;
};
