import { Autocomplete, Box } from "@mui/material";
import { useEffect, useState } from "react";
import DebouncedInput from "layouts/pages/widgets/components/DebouncedInput";
import {
  ICounterparty,
  useGetCounterpartiesByQueryMutation,
  useGetCounterpartyByIdQuery,
} from "../../../Redux/api/counterperties.api";

interface ICounterpartyAutocompleteFieldProps {
  disabled?: boolean;
  onBlur?: () => void;
  onSelect?: (value: IItem) => void;
  error?: string;
  label?: string;
  placeholder?: string;
  initialId: number | string;
}

interface IItem {
  label: string;
  value: string | number;
  icon?: string;
}

const formatCounterparty = (counterparty: ICounterparty) => {
  return {
    label:
      "inn" in counterparty
        ? `${counterparty.name} ИНН ${counterparty.inn}`
        : `${counterparty.last_name} ${counterparty.first_name} ${counterparty.middle_name}`,
    value: counterparty.id,
    icon: counterparty?.avatar?.path,
  } as IItem;
};

const CounterpartyAutocompleteField = ({
  disabled,
  onBlur,
  onSelect,
  error,
  label = "Клиент",
  placeholder = "Начните ввод названия клиета",
  initialId,
}: ICounterpartyAutocompleteFieldProps) => {
  const [searchClient, serchResult] = useGetCounterpartiesByQueryMutation();
  const [selectedOption, setSelectedOption] = useState<IItem | null>(null);
  const [query, setQuery] = useState<string>("");
  const { data: initial, isSuccess } = useGetCounterpartyByIdQuery(initialId, { skip: !initialId });

  const addressOptions = serchResult?.data?.counterparties.map(formatCounterparty) || [];

  const onAddressSelect = (e: any, newValue: IItem) => {
    setSelectedOption(newValue);
    onSelect(newValue);
  };

  useEffect(() => {
    if (initialId && initial?.counterparty && isSuccess) {
      setSelectedOption(formatCounterparty(initial.counterparty));
    }
  }, [initial, initialId, isSuccess]);

  useEffect(() => {
    if (query.length > 2) {
      searchClient(query);
    }
  }, [query]);

  return (
    <Autocomplete
      style={{ width: "100%", pointerEvents: disabled ? "none" : "auto" }}
      isOptionEqualToValue={(op, val) => op?.value === val?.value}
      getOptionLabel={(option: IItem) => option.label}
      options={addressOptions}
      filterOptions={(x) => x}
      id="address"
      popupIcon={disabled ? null : undefined}
      noOptionsText="Нет результатов"
      onBlur={onBlur}
      onChange={onAddressSelect}
      value={selectedOption}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {option?.icon && <img width="20" style={{ marginRight: 10 }} src={`${option.icon}`} />}
          {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <DebouncedInput
          label={label}
          debounceTimeout={500}
          handleDebounce={setQuery}
          isLoading={serchResult.isLoading}
          input={{
            noPointerEvents: disabled,
            ...params,
            placeholder: placeholder,
            value: query,
            error: error,
          }}
        />
      )}
    />
  );
};

export default CounterpartyAutocompleteField;
