import { useDispatch, useSelector } from "react-redux";
import { ISelectedFilter, addItemToFilters, filtersSelector } from "../../Redux/filters/slice";
import MDBox from "components/MDBox";
import crossIcon from "assets/images/cross-icon.svg";
import MDTypography from "components/MDTypography";

function FilterTagList() {
  const filters = useSelector(filtersSelector);
  const dispatch = useDispatch();

  return (
    <MDBox
      sx={{
        display: "flex",
        gap: 1,
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      {filters.map((item: ISelectedFilter) => (
        <MDBox
          key={item.tag}
          sx={{
            width: "max-content",
            background: "#7B809A",
            py: 0.375,
            pl: 0.375,
            pr: 1.5,
            borderRadius: "50px",
          }}
        >
          <MDBox
            sx={{
              display: "flex",
              gap: 1,
              height: "100%",
              alignItems: "center",
            }}
          >
            <MDBox
              component="img"
              src={crossIcon}
              alt="crossIcon"
              sx={{ cursor: "pointer" }}
              width={15}
              heught={15}
              onClick={() => dispatch(addItemToFilters(item))}
            />
            <MDTypography
              variant="div"
              color="light"
              fontWeight="regular"
              fontSize={12}
              lineHeight="140%"
            >
              {item.name}
            </MDTypography>
          </MDBox>
        </MDBox>
      ))}
    </MDBox>
  );
}

export default FilterTagList;
