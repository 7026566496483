import { api } from ".";

export interface IVat {
  id: number;
  name: string;
  avatar: string;
  created_at: string;
}

interface IGetVatsResponse {
  vats: IVat[];
  vatsCount: number;
}

interface IGetVatsByIdRequest {
  vat: IVat;
}

export const vatsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getVats: builder.query<IGetVatsResponse, void>({
      query: () => `vats`,
    }),
    getVatsById: builder.query<IGetVatsByIdRequest, string | number>({
      query: (id) => `vats/${id}`,
    }),
  }),
});

export const { useGetVatsQuery, useGetVatsByIdQuery } = vatsApi;
