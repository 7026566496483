import Input from "layouts/pages/widgets/components/Input";
import { IWarehouse, useGetWarehousesQuery } from "../../../../../Redux/api/warehouses.api";
import { useEffect, useMemo, useState } from "react";
import { Autocomplete, Box } from "@mui/material";

interface IWarehousesMultiselectFieldProps {
  disabled?: boolean;
  error?: string | string[];
  initialValueIds?: (number | string)[];
  onSelect?: (value: IWarehouse[]) => void;
}

const WarehousesMultiselectField = ({
  disabled,
  error,
  initialValueIds,
  onSelect,
}: IWarehousesMultiselectFieldProps) => {
  const { data: warehouses } = useGetWarehousesQuery("", { refetchOnMountOrArgChange: true });
  const [selectedOptions, setSelectedOptions] = useState<IWarehouse[]>([]);

  const warehouseOptions = warehouses?.warehouses || [];

  useEffect(() => {
    const options = warehouseOptions.filter((item) => initialValueIds?.some((id) => id == item.id));
    if (options) {
      setSelectedOptions(options);
    }
  }, [warehouseOptions, initialValueIds, disabled]);

  const onSelectItem = (e: any, value: IWarehouse[]) => {
    setSelectedOptions(value);
    onSelect(value);
  };

  return (
    <Autocomplete
      style={{ width: "100%", pointerEvents: disabled ? "none" : "auto" }}
      multiple
      filterOptions={(x) => x}
      getOptionLabel={(opt) => `${opt.name}, ${opt.address}`}
      isOptionEqualToValue={(op, val) => op.id === val.id}
      options={warehouseOptions}
      onChange={onSelectItem}
      popupIcon={disabled ? null : undefined}
      value={selectedOptions}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {option?.avatar && (
            <img width="20" style={{ marginRight: 10 }} src={`${option.avatar?.path}`} />
          )}
          {option.name}, {option.address}
        </Box>
      )}
      renderInput={(params: any) => (
        <Input
          label="Склад"
          input={{
            noPointerEvents: disabled,
            placeholder: disabled ? "" : "Выберите из списка",
            error,
            ...params,
          }}
        />
      )}
    />
  );
};

export default WarehousesMultiselectField;
