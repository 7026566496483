import { useContext, useEffect, useRef, useState } from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import logoSlack from "assets/images/small-logos/logo-slack.svg";

interface IAccountIntegrationSimpleProps {
  isApiActive: boolean;
  onActiveChange: (value: boolean) => void;
  message?: string;
  token?: string;
  label: string;
}

const AccountIntegrationSimple = ({
  isApiActive,
  onActiveChange,
  message = "",
  token,
  label,
}: IAccountIntegrationSimpleProps) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [isActive, setIsActive] = useState(isApiActive);
  const timer = useRef(null);
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    if (!timer.current && isActive !== isApiActive) {
      timer.current = setTimeout(() => {
        onActiveChange(isActive);
      }, 500);
    }
    return () => {
      clearTimeout(timer.current);
      timer.current = null;
    };
  }, [isActive]);

  const handleIsActive = () => setIsActive((v) => !v);

  const onCopyClick = () => {
    navigator.clipboard.writeText(token);
  };

  return (
    <Accordion expanded={expanded} sx={{ "&:before": { content: "none" }, boxShadow: "none" }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <MDBox display="flex" alignItems="center">
          <MDAvatar src={logoSlack} alt="Slack logo" variant="rounded" />
          <MDBox ml={2}>
            <MDTypography variant="h5" fontWeight="medium">
              {label}
            </MDTypography>
            <MDBox
              display="flex"
              alignItems="flex-end"
              onClick={() => setExpanded((v) => !v)}
              style={{ cursor: "pointer" }}
            >
              <MDTypography variant="button" color="text">
                {expanded ? "Свернуть" : "Открыть"}
              </MDTypography>
              <MDTypography variant="button" color="text" sx={{ lineHeight: 0 }}>
                <Icon fontSize="small">{expanded ? "expand_less" : "expand_more"}</Icon>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          width={{ xs: "100%", sm: "auto" }}
          mt={{ xs: 1, sm: 0 }}
        >
          <MDBox lineHeight={0} mx={2}>
            <MDTypography variant="button" color="text">
              {isActive ? "Активно" : "Не активно"}
            </MDTypography>
          </MDBox>
          <MDBox mr={1}>
            <Switch checked={isActive} onChange={handleIsActive} />
          </MDBox>
        </MDBox>
      </MDBox>
      <AccordionDetails>
        <MDBox ml={{ xs: 0, sm: 2 }} pl={{ xs: 0, sm: 2, md: 6 }} pt={2} lineHeight={1}>
          <MDTypography variant="button" color="text">
            {message}
          </MDTypography>
          <MDBox
            bgColor={darkMode ? "grey-900" : "grey-100"}
            borderRadius="lg"
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
            my={3}
            py={1}
            pl={{ xs: 1, sm: 2 }}
            pr={1}
          >
            <MDTypography variant="button" fontWeight="medium" color="text">
              Токен
            </MDTypography>
            <MDBox
              display="flex"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <MDBox
                mr={{ xs: 0, sm: 2 }}
                mb={{ xs: 1, sm: 0 }}
                lineHeight={0}
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  maxWidth: "80%",
                }}
              >
                <MDTypography variant="button" fontWeight="medium">
                  {token}
                </MDTypography>
              </MDBox>
              <MDButton variant="gradient" color="dark" size="small" onClick={onCopyClick}>
                Скопировать
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccountIntegrationSimple;
