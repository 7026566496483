import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import { INewCategory } from "Redux/api/interfaces/ICategory";
import MDBox from "components/MDBox";
import MDContainer from "components/MDContainer";
import MDEditor from "components/MDEditor";
import MDTypography from "components/MDTypography";
import { useFormikContext } from "formik";
import ContentWrapper from "layouts/components/content-wrapper";
import CategoryParentField from "layouts/components/fields/parent-category-field";
import TagsMultiselectField from "layouts/components/fields/tags-multiselect-field";
import Block from "layouts/pages/widgets/components/Block";
import { CustomDropZone } from "layouts/pages/widgets/components/CustomDropZone";
import Input from "layouts/pages/widgets/components/Input";
import { ChangeEvent, useContext } from "react";
import { CategoryNameContext } from "../../context/category-name-context";

const AddFields = (props: any) => {
  const { setIsEdit } = props;

  const { setName, uploadPhotoLocalFile } = useContext(CategoryNameContext);
  const { values, errors, setFieldValue } = useFormikContext<INewCategory>();

  const handlerChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;

    setFieldValue(fieldName, fieldValue);

    if (fieldName === "name") {
      setName(fieldValue);
    }
  };

  const getFilesHandler = (files: File[]) => {
    const [file] = files;

    setIsEdit(true);

    uploadPhotoLocalFile(file);
    setFieldValue("photo_id", 1);
  };

  const handleChangeDesc = (value: any) => {
    if (values.description !== value && value?.replace(/<[^>]*>/g, "")?.length > 0) {
      setFieldValue("description", value);
    }
  };

  return (
    <MDContainer gap={3}>
      <ContentWrapper
        aside={
          <Card sx={{ p: 3 }}>
            <Input
              label="Код (необязательно)"
              input={{
                noPointerEvents: false,
                placeholder: "Введите код",
                value: values.code,
                name: "code",
                onChange: handlerChange,
                focus: () => setIsEdit(true),
                error: errors.code,
              }}
            />
          </Card>
        }
      >
        <Block title="Основная информация" wrapperStyles={{ width: "100%" }}>
          <MDContainer gap={3}>
            <Input
              label="Название"
              input={{
                noPointerEvents: false,
                placeholder: "Введите название категории",
                value: values.name,
                name: "name",
                onChange: handlerChange,
                error: errors.name,
              }}
            />
          </MDContainer>

          <MDContainer gap={3}>
            <Grid item xs={12} sm={12}>
              <MDBox mb={1} width="100%">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Описание
                </MDTypography>
              </MDBox>

              <MDEditor
                onChange={(value) => handleChangeDesc(value)}
                toolbarOptions={["fontFamily", "inline", "blockType", "fontSize", "list"]}
                isError={!!errors.description}
              />
            </Grid>
          </MDContainer>

          <MDContainer gap={3}>
            <CategoryParentField
              disabled={false}
              error={errors.parent_id}
              initialValueId={values.parent_id}
              onSelect={(value) => setFieldValue("parent_id", value?.id)}
            />
          </MDContainer>

          <MDContainer gap={3}>
            <TagsMultiselectField
              disabled={false}
              error={errors?.tag_id}
              initialValueIds={values.tag_id}
              onSelect={(value) =>
                setFieldValue(
                  "tag_id",
                  value.map((v) => v.id)
                )
              }
            />
          </MDContainer>
        </Block>

        <CustomDropZone
          getFilesHandler={getFilesHandler}
          loadedFiles={[]}
          isError={!!errors.photo_id}
        />
      </ContentWrapper>
    </MDContainer>
  );
};

export default AddFields;
