import { routes } from "../constants/route";
import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Settings from "layouts/pages/account/settings";
import PricingPage from "layouts/pages/pricing-page";
import Widgets from "layouts/pages/widgets";
import Charts from "layouts/pages/charts";
import Notifications from "layouts/pages/notifications";
import { NewUser, ViewUser, UserTable } from "layouts/pages/users";
import { NewECommerce, ViewECommerce, ECommerceTable } from "layouts/pages/e-commerce";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import SignInCover from "layouts/authentication/sign-in/cover";
import {
  CounterpartiesTable,
  ViewCounterparty,
  NewCounterparty,
} from "layouts/dictionary/counterparties";
import { NewBranch, ViewBranch, BranchesTable } from "layouts/dictionary/branches";
import { CategoriesTable, NewCategory, ViewCategory } from "layouts/dictionary/categories";
import {
  NewNomenclature,
  NomenclaturesTable,
  ViewNomenclature,
} from "layouts/dictionary/nomenclatures";
import OrdersTable from "layouts/ecommerce/orders/table/orders-table";
import NewOrder from "layouts/ecommerce/orders/new-order/new-order";
import ViewOrder from "layouts/ecommerce/orders/view-order/view-order";
import { NewWarehouse, ViewWarehouse, WarehousesTable } from "layouts/dictionary/warehouses";
import { Navigate } from "react-router-dom";
import { InventoriesTable, NewInventory, ViewInventory } from "layouts/dictionary/inventories";
import { NewShop, ShopsTable, ViewShop } from "layouts/dictionary/shops";

const routesMap = new Map<string, React.ReactElement>();

//Редирект
routesMap.set("*", <Navigate to={routes.PROFILE} />);

routesMap.set(routes.PROFILE, <ProfileOverview />);
routesMap.set(routes.SETTINGS, <Settings />);
routesMap.set(routes.LOGIN, <SignInCover />);

// Пользователи
routesMap.set(routes.USERS, <UserTable />);
routesMap.set(routes.CREATE_USER, <NewUser />);
routesMap.set(routes.VIEW_USER, <ViewUser />);

// Телефония
routesMap.set(routes.PRICING_PAGE, <PricingPage />);
routesMap.set(routes.WIGETS_PAGE, <Widgets />);
routesMap.set(routes.CHARTS_PAGE, <Charts />);
routesMap.set(routes.NOTIFIACTIONS_PAGE, <Notifications />);

// Заказы
routesMap.set(routes.KANBAN, <Kanban />);
routesMap.set(routes.WIZARD, <Wizard />);
routesMap.set(routes.DATA_TABLES, <DataTables />);
routesMap.set(routes.CALENDAR, <Calendar />);

// Задачи
routesMap.set(routes.NEW_PRODUCT, <NewProduct />);
routesMap.set(routes.EDIT_PRODUCT, <EditProduct />);
routesMap.set(routes.PRODUCT_PAGE, <ProductPage />);

// Заказы
routesMap.set(routes.ORDER_LIST, <OrdersTable />);
routesMap.set(routes.VIEW_ORDER, <ViewOrder />);
routesMap.set(routes.NEW_ORDER, <NewOrder />);

routesMap.set(routes.ANALITICS, <Analytics />);
routesMap.set(routes.SALES, <Sales />);

// E-торговля
routesMap.set(routes.ECOMMERCE, <ECommerceTable />);
routesMap.set(routes.NEW_ECOMMERCE, <NewECommerce />);
routesMap.set(routes.VIEW_ECOMMERCE, <ViewECommerce />);

// Категории
routesMap.set(routes.CATEGORIES, <CategoriesTable />);
routesMap.set(routes.CREATE_CATEGORY, <NewCategory />);
routesMap.set(routes.VIEW_CATEGORY, <ViewCategory />);

// Мои бизнесы
routesMap.set(routes.BRANCHES, <BranchesTable />);
routesMap.set(routes.CREATE_BRANCH, <NewBranch />);
routesMap.set(routes.VIEW_BRANCH, <ViewBranch />);

// COUNTERPARTY
routesMap.set(routes.COUNTERPARTY, <CounterpartiesTable />);
routesMap.set(routes.CREATE_COUNTERPARTY, <NewCounterparty />);
routesMap.set(routes.VIEW_COUNTERPARTY, <ViewCounterparty />);

// Услуги/Товары
routesMap.set(routes.NOMENCLATURES, <NomenclaturesTable />);
routesMap.set(routes.CREATE_NOMENCLATURES, <NewNomenclature />);
routesMap.set(routes.VIEW_NOMENCLATURES, <ViewNomenclature />);

// WAREHOUSES
routesMap.set(routes.WAREHOUSES, <WarehousesTable />);
routesMap.set(routes.CREATE_WAREHOUSE, <NewWarehouse />);
routesMap.set(routes.VIEW_WAREHOUSE, <ViewWarehouse />);

// Inventories from warehouses
routesMap.set(routes.WAREHOUSE_INVENTORIES, <InventoriesTable />);
routesMap.set(routes.CREATE_WAREHOUSE_INVENTORY, <NewInventory />);
routesMap.set(routes.VIEW_WAREHOUSE_INVENTORY, <ViewInventory />);

// Inventories
routesMap.set(routes.INVENTORIES, <InventoriesTable />);
routesMap.set(routes.CREATE_INVENTORY, <NewInventory />);
routesMap.set(routes.VIEW_INVENTORY, <ViewInventory />);

//Shops
routesMap.set(routes.SHOPS, <ShopsTable />);
routesMap.set(routes.CREATE_SHOP, <NewShop />);
routesMap.set(routes.VIEW_SHOP, <ViewShop />);

export default routesMap;
