import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { UserContext } from "context/user";
import { IAuthGuard, IUser, IUserContext } from "interfaces";
import { GetUser } from "api/auth/user";
import { routes } from "./constants";

const AuthGuard = (props: IAuthGuard) => {
  const { children } = props;

  const [user, setUser] = useState<IUser>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  const checkToken = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate(routes.LOGIN);
      setIsLoading(false);
      return;
    }

    const { user } = await GetUser();

    setIsLoading(false);

    if (!user) {
      navigate(routes.LOGIN);
      return;
    }

    setUser(user);
  };

  const userContextValue = useMemo(() => {
    const value: IUserContext = { user, setUser };

    return value;
  }, [user]);

  useEffect(() => {
    checkToken();
  }, []);

  return (
    !isLoading && <UserContext.Provider value={userContextValue}>{children}</UserContext.Provider>
  );
};

export default AuthGuard;
