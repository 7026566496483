import { Field, useFormikContext } from "formik";
import MDContainer from "components/MDContainer";
import Block from "layouts/pages/widgets/components/Block";
import { useContext, useEffect, useState } from "react";
import BranchField from "layouts/components/fields/branch-field";
import { ProductsContext } from "../context/products-context";
import { IAddNomenclature } from "../../../../Redux/api/nomenclatures.api";
import InputFormikField from "layouts/components/fields/input-formik-field";
import EditorField from "layouts/components/fields/editor-field";
import CategoryField from "layouts/components/fields/category-field";
import TagsMultiselectField from "layouts/components/fields/tags-multiselect-field";
import NomenclatureTypeField from "layouts/components/fields/nomenclature-type-field";
import NomenclatureKindField from "layouts/components/fields/nomenclature-kind-field";
import StorageUnitsField from "layouts/components/fields/storage-utnit-field";
import BrandField from "layouts/components/fields/brand-field";
import CountryField from "layouts/components/fields/country-field";
import SidePanel from "examples/SidePanel";

interface IMainFieldsetProps {
  disabled?: boolean;
  avatarId?: number | string;
}

const MainFieldset = ({ disabled, avatarId }: IMainFieldsetProps) => {
  const { setName } = useContext(ProductsContext);
  const { values, errors, setFieldValue, initialValues } = useFormikContext<IAddNomenclature>();

  const [brandOpen, setBrandOpen] = useState<any>(false);

  useEffect(() => {
    if (avatarId) {
      setFieldValue("avatar_id", avatarId);
    }
  }, [avatarId]);

  useEffect(() => {
    setName(disabled ? "" : values.name);
  }, [values.name, disabled]);

  return (
    <>
      <Block title={"Основная информация"} wrapperStyles={{ width: "100%" }}>
        <MDContainer gap={3}>
          <Field
            name="name"
            label="Название"
            placeholder="Введите название"
            disabled={disabled}
            component={InputFormikField}
          />
        </MDContainer>
        <MDContainer gap={3}>
          <EditorField
            value={values.description}
            onChange={(value) => setFieldValue("description", value)}
            label={"Описание (необязательно)"}
            disabled={disabled}
          />
        </MDContainer>
        <MDContainer gap={3}>
          <CategoryField
            disabled={disabled}
            error={errors.category_id}
            initialValueId={initialValues.category_id}
            onSelect={(value) => setFieldValue("category_id", value?.id)}
          />
        </MDContainer>
        <MDContainer gap={3}>
          <EditorField
            value={values.specification}
            onChange={(value) => setFieldValue("specification", value)}
            label={"Спецификация (необязательно)"}
            disabled={disabled}
          />
        </MDContainer>
        <MDContainer gap={3}>
          <NomenclatureTypeField
            disabled={disabled}
            error={errors.type_id}
            initialValueId={initialValues.type_id}
            onSelect={(value) => setFieldValue("type_id", value?.id)}
          />
          <CountryField
            disabled={disabled}
            error={errors.country_id}
            initialValueId={initialValues.country_id}
            onSelect={(value) => setFieldValue("country_id", value?.id)}
          />
        </MDContainer>
        <MDContainer gap={3}>
          <StorageUnitsField
            disabled={disabled}
            error={errors.storage_unit_id}
            initialValueId={initialValues.storage_unit_id}
            onSelect={(value) => setFieldValue("storage_unit_id", value?.id)}
          />
          <BrandField
            disabled={disabled || brandOpen}
            error={errors.brand_id}
            initialValueId={initialValues.brand_id}
            onSelect={(value) => setFieldValue("brand_id", value?.id)}
            onOpen={(value) => setBrandOpen(value)}
          />
        </MDContainer>

        <MDContainer gap={3}>
          <NomenclatureKindField
            disabled={disabled}
            error={errors.kind_id}
            initialValueId={initialValues.kind_id}
            onSelect={(value) => setFieldValue("kind_id", value?.id)}
          />
          <BranchField
            disabled={disabled}
            error={errors.branch_id}
            initialValueId={initialValues.branch_id}
            onSelect={(value) => setFieldValue("branch_id", value?.id)}
          />
        </MDContainer>
        <MDContainer gap={3}>
          <TagsMultiselectField
            disabled={disabled}
            error={errors?.tags}
            initialValueIds={values.tags}
            onSelect={(value) =>
              setFieldValue(
                "tags",
                value.map((v) => v.id)
              )
            }
          />
        </MDContainer>
        <MDContainer gap={3}></MDContainer>
      </Block>
      <SidePanel isOpen={brandOpen} setIsOpen={setBrandOpen} title={"торговой марки"} />
    </>
  );
};

export default MainFieldset;
