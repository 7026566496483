import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import InputMask from "react-input-mask";

import MDContainer from "components/MDContainer";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

import { IProps } from "./interfaces";

const Input = (props: IProps) => {
  const {
    label,
    outerRef,
    mask,
    input: { placeholder, blur, focus, defaultValue, noPointerEvents, ...rest },
  } = props;

  const [value, setValue] = useState<string>("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleBlur = () => {
    blur?.(value.replace(/[^0-9]+/g, ""));
  };

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue, noPointerEvents]);

  const getContent = () => {
    if (mask) {
      return (
        <InputMask
          mask={mask}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={focus as any}
          style={{ pointerEvents: noPointerEvents ? "none" : "auto" }}
        >
          {() => (
            <TextField
              variant="standard"
              type="tel"
              placeholder={placeholder}
              error={!!rest.error}
              helperText={rest.error || (rest.error && rest.errorMessage)}
              sx={{
                width: "100%",
              }}
            />
          )}
        </InputMask>
      );
    }

    return (
      <MDInput
        style={{ pointerEvents: noPointerEvents ? "none" : "auto" }}
        variant="standard"
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={focus}
        ref={outerRef}
        {...rest}
        sx={{ width: "100%" }}
      />
    );
  };

  return (
    <MDContainer variant="column" gap={1.4}>
      <MDTypography fontSize={14} fontWeight="regular" lineHeight="140%" color="text">
        {label}
      </MDTypography>

      {getContent()}
    </MDContainer>
  );
};

export default Input;
