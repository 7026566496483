import { INewBranch } from "Redux/api/interfaces";

export const initialValues: INewBranch = {
  title: "",
  inn: "",
  name: "",
  kpp: "",
  phone_1: "",
  phone_2: "",
  email: "",
  address: "",
  legal_address: "",
  code: "",
  head: "",
  responsible_user_id: null,
};
