import { useEffect, useState } from "react";
import { IFilter } from "layouts/pages/widgets/components/TopPanel/interfaces";
import {
  INomenclatureKind,
  useGetNomenclatureKindsQuery,
} from "../../../../Redux/api/nomenclature-kinds.api";
import {
  INomenclatureType,
  useGetNomenclatureTypesQuery,
} from "../../../../Redux/api/nomenclature-types.api";

const addFilers = (types: INomenclatureType[], kinds: INomenclatureKind[]) => {
  const importFilter: IFilter = {
    id: 1,
    title: "Импорт",
    tag: "import",
    url: "&is_import=",
    isInput: false,
    list: [
      {
        id: 10,
        isInput: false,
        tag: "import",
        name: "да",
        url: "1",
      },
      {
        id: 11,
        isInput: false,
        tag: "import",
        name: "нет",
        url: "0",
      },
    ],
    exclusive: true,
  };

  const typesFilter: IFilter = {
    id: 2,
    title: "Тип",
    tag: "type_id",
    url: "&type_id[]=",
    isInput: false,
    list: types.map((type) => ({
      id: type.id,
      isInput: false,
      tag: "type_id",
      name: type.name,
      url: `${type.id}`,
    })),
    exclusive: true,
  };

  const kindsFilter: IFilter = {
    id: 3,
    title: "Вид",
    tag: "kind_id",
    url: "&kind_id[]=",
    isInput: false,
    list: kinds.map((kind) => ({
      id: kind.id,
      isInput: false,
      tag: "kind_id",
      name: kind.name,
      url: `${kind.id}`,
    })),
    exclusive: true,
  };
  return [typesFilter, kindsFilter, importFilter];
};

export const useProductServiceFilters = () => {
  const [filters, setFilters] = useState<IFilter[]>([]);
  const { data: kinds, isFetching: isKindsFetching } = useGetNomenclatureKindsQuery();
  const { data: types, isFetching: isTypesFetching } = useGetNomenclatureTypesQuery();

  useEffect(() => {
    if (types && kinds) {
      setFilters(addFilers(types.nomenclatureTypes, kinds.nomenclatureKinds));
    }
  }, [types, kinds]);

  if (isTypesFetching || isKindsFetching) return null;

  return filters;
};
