import { InternalAxiosRequestConfig } from "axios";
import { routes } from "../../constants";

export const responseRejectInterceptor = (
  value: any
): InternalAxiosRequestConfig<any> | Promise<InternalAxiosRequestConfig<any>> => {
  let { response, config } = value;

  if (response?.status === 401 && !config?.isBefore) {
    localStorage.clear();
    window.location.href = routes.LOGIN;
  }

  return response;
};
