import { IAddOrder, IOrder } from "Redux/api/orders.api";

export const getInitialValues = (order: IOrder): IAddOrder => ({
  id: order.id,
  avatar_id: order.avatar?.id,
  counterparty_id: order.counterparty?.id,
  delivery_type_id: order.delivery_type?.id,
  address: order.address,
  shop_id: order.shop.id,
  branch_id: order.branch?.id,
  object_id: order.object?.id,
  application_id: order.application?.id,
  sales_channel_id: order.sales_channel?.id,
  manager_id: order.manager?.id,
  promotion_id: order.promotion?.id,
  contract_number: order.contract_number,
  contract_id: order.contract?.id,
  contract_start_date: order.contract_start_date,
  contract_end_date: order.contract_end_date,
  invoice_number: order.invoice_number,
  invoice_start_date: order.invoice_start_date,
  invoice_end_date: order.invoice_end_date,
});
