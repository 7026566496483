import { useCallback, useState, useEffect } from "react";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import { filterParamsSelector } from "../Redux//filters/slice";

const useFilterParams = (additionalParam?: string) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [query, setQuery] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const filterParams = useSelector(filterParamsSelector);

  const queryParams = useCallback(() => {
    let params = `page=${page}&per_page=${perPage}`;
    if (startDate) params += `&date_from=${moment(startDate?.$d).format("YYYY-MM-DD")}`;
    if (endDate) params += `&date_to=${moment(endDate?.$d).format("YYYY-MM-DD")}`;
    if (filterParams) {
      params += filterParams;
    }
    if (additionalParam) {
      params += additionalParam;
    }
    return query.length < 1 ? params : params.concat(`&query=${query}`);
  }, [query, perPage, page, startDate, endDate, filterParams]);

  return {
    queryParams,
    setQuery,
    setPage,
    setPerPage,
    setEndDate,
    setStartDate,
    page,
    perPage,
    query,
    startDate,
    endDate,
  };
};

export default useFilterParams;
