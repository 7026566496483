export const routes: Record<string, string> = {
  PROFILE: "/profile",
  SETTINGS: "/settings",
  LOGIN: "/sign-in",
  CALLS: "/calls",
  CONNECT_CALL: "/calls/connect",
  ORDERS: "/orders/list",
  CREATE_ORDER: "/orders/new",
  TASKS: "/tasks/list",
  CREATE_TASK: "/tasks/new",

  USERS: "/users/list-users",
  CREATE_USER: "/users/new-user",
  VIEW_USER: "/users/list-users/:id",

  COUNTERPARTY: "/counterparty/list-counterparty",
  CREATE_COUNTERPARTY: "/counterparty/new-counterparty",
  VIEW_COUNTERPARTY: "/counterparty/list-counterparty/:id",

  BRANCHES: "/branches/list-branches",
  CREATE_BRANCH: "/branches/new-branch",
  VIEW_BRANCH: "/branches/list-branches/:id",

  ECOMMERCE: "/ecommerce/list-ecommerce",
  NEW_ECOMMERCE: "/ecommerce/new-ecommerce",
  VIEW_ECOMMERCE: "/ecommerce/list-ecommerce/:id",

  CATEGORIES: "/categories/list-categories",
  CREATE_CATEGORY: "/categories/new-category",
  VIEW_CATEGORY: "/categories/list-categories/:id",

  WAREHOUSES: "/warehouses/list-warehouses",
  CREATE_WAREHOUSE: "/warehouses/new-warehouse",
  VIEW_WAREHOUSE: "/warehouses/list-warehouses/:id",

  WAREHOUSE_INVENTORIES: "/warehouses/:warehouseId/inventories/list-inventories",
  CREATE_WAREHOUSE_INVENTORY: "/warehouses/:warehouseId/inventories/new-inventory",
  VIEW_WAREHOUSE_INVENTORY: "/warehouses/:warehouseId/inventories/list-inventories/:id",

  INVENTORIES: "/inventories/list-inventories",
  CREATE_INVENTORY: "/inventories/new-inventory",
  VIEW_INVENTORY: "/inventories/list-inventories/:id",

  PRICING_PAGE: "/pages/pricing-page",
  WIGETS_PAGE: "/pages/widgets",
  CHARTS_PAGE: "/pages/charts",
  NOTIFIACTIONS_PAGE: "/pages/notifications",

  KANBAN: "/applications/kanban",
  WIZARD: "/applications/wizard",
  DATA_TABLES: "/applications/data-tables",
  CALENDAR: "/applications/calendar",

  NEW_PRODUCT: "/ecommerce/products/new-product",
  EDIT_PRODUCT: "/ecommerce/products/edit-product",
  PRODUCT_PAGE: "/ecommerce/products/product-page",

  NOMENCLATURES: "/nomenclatures/list-nomenclatures",
  CREATE_NOMENCLATURES: "/nomenclatures/new-nomenclature",
  VIEW_NOMENCLATURES: "/nomenclatures/list-nomenclatures/:id",

  SHOPS: "/shops/list-shops",
  CREATE_SHOP: "/shops/new-shop",
  VIEW_SHOP: "/shops/list-shops/:id",

  ORDER_LIST: "/ecommerce/orders/order-list",
  NEW_ORDER: "/ecommerce/orders/new-order",
  VIEW_ORDER: "/ecommerce/orders/order-list/:id",

  ANALITICS: "/dashboards/analytics",
  SALES: "/dashboards/sales",
};
