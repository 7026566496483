import { CreateAxiosDefaults } from "axios";

import { baseURL } from "./baseUrl";

export const config: CreateAxiosDefaults = {
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
};
