import { Stack, Switch } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import MDTypography from "components/MDTypography";
import PageLayout from "examples/CardPage";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Loader from "layouts/components/loader";
import ContentHeader from "layouts/pages/widgets/components/ContentHeader";
import Tabs from "layouts/pages/widgets/components/Tabs";
import { useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetWarehouseByidQuery,
  useGetWarehouseHistoryQuery,
} from "../../../../Redux/api/warehouses.api";
import History from "../../../pages/widgets/components/History";
import Main from "../components/main";
import { WarehouseNameContext } from "../context/warehouse-name-context";
import { getInitialValues } from "../consts";
import { useLoadFile } from "hooks/userLoadFile";
import { AVATAR } from "constants/file-types";

const ViewWarehouse = () => {
  const [isOwned, setIsOwned] = useState(false);
  const [name, setName] = useState("");

  const { id } = useParams<string>();
  const { data: warehouse, isFetching } = useGetWarehouseByidQuery(id);
  const { file, filePath, uploadLocalFile, loadFile, cancelUploadLocalFile } = useLoadFile(AVATAR);

  useLayoutEffect(() => {
    setIsOwned(warehouse?.warehouse?.owned_by_us || false);
  }, [warehouse]);

  if (isFetching && !warehouse?.warehouse) {
    return <Loader />;
  }

  return (
    <PageLayout header={<DashboardNavbar />}>
      <WarehouseNameContext.Provider value={{ setName, file }} key="edit">
        <ContentHeader
          info={{
            img: filePath || warehouse?.warehouse?.avatar?.path || "",
            uploadLocalFile,
            title: name || warehouse?.warehouse?.name,
          }}
          action={
            <Stack direction="row" spacing={0.5} alignItems="center">
              <MDTypography color="text" style={{ fontSize: pxToRem(14) }}>
                Свой / Чужой
              </MDTypography>
              <Switch checked={!isOwned} onChange={() => setIsOwned((v) => !v)} />
            </Stack>
          }
          error={!(file || warehouse?.warehouse?.avatar?.path)}
        />

        <Tabs
          defaultTab={1}
          items={{
            headers: ["Основные", "История"],
            components: [
              <Main
                key="main"
                edit={true}
                initialValues={getInitialValues(warehouse)}
                type="edit"
                isOwned={isOwned}
                loadFile={loadFile}
                cancelUploadLocalFile={cancelUploadLocalFile}
              />,
              <History key="history" request={useGetWarehouseHistoryQuery} />,
            ],
          }}
        />
      </WarehouseNameContext.Provider>
    </PageLayout>
  );
};

export default ViewWarehouse;
