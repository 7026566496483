import { api } from ".";

export interface ISalesStrategyResponse {
  salesStrategies: ISalesStrategy[];
  salesStrategiesCount: number;
}

export interface ISalesStrategy {
  id: number;
  name: string;
  avatar: string;
  created_at: string;
}

export const salesStrategiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSalesStrategies: builder.query<ISalesStrategyResponse, void>({
      query: () => `sales_strategies`,
      transformErrorResponse: (response: any) => response.data.message,
    }),
  }),
});

export const { useGetSalesStrategiesQuery } = salesStrategiesApi;
