import { Field, useFormikContext } from "formik";
import MDContainer from "components/MDContainer";
import Block from "layouts/pages/widgets/components/Block";
import { useEffect, useState } from "react";
import InputFormikField from "layouts/components/fields/input-formik-field";
import { IAddOrder } from "Redux/api/orders.api";
import dayjs, { Dayjs } from "dayjs";
import DatePicker from "layouts/pages/widgets/components/DatePicker";
import LoadInput from "layouts/pages/widgets/components/LoadInput";
import { NewFile } from "api/files/new";

interface IMainFieldsetProps {
  disabled?: boolean;
  avatarId?: number | string;
}

const DocumentsFieldset = ({ disabled, avatarId }: IMainFieldsetProps) => {
  const { values, errors, setFieldValue, setFieldError } = useFormikContext<IAddOrder>();
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    if (avatarId) {
      setFieldValue("avatar_id", avatarId);
    }
  }, [avatarId]);

  const loadFile = async (loadedFile: File) => {
    try {
      const { file } = await NewFile({
        file: loadedFile,
        fileTypeId: 3,
      });

      if (file) {
        setFileName(file.name);
        setFieldValue("contract_id", file.id);
      }
    } catch (e) {
      setFieldError("contract_id", "Ошибка при загрузке файла");
    }
  };

  return (
    <>
      <Block title={"Договор"} wrapperStyles={{ width: "100%" }}>
        <MDContainer gap={3}>
          <Field
            name="contract_number"
            label="Номер договора (необязательно)"
            placeholder="Введите номер договора"
            disabled={disabled}
            component={InputFormikField}
          />
          <LoadInput
            label="Договор (необязательно)"
            noPointerIvents={disabled}
            input={{
              value: fileName,
              placeholder: "Прикрепить договор",
              name: "contract_id",
              error: errors["contract_id"] as string,
            }}
            handler={loadFile}
          />
        </MDContainer>
        <MDContainer gap={3}>
          <DatePicker
            disabled={disabled}
            label="Дата начала (необязательно)"
            placeholder="Выберите дату"
            value={values.contract_start_date || ""}
            name="contract_start_date"
            error={errors["contract_start_date"]}
            onChange={([date]: Dayjs[]) => {
              setFieldValue("contract_start_date", dayjs(date).format("YYYY-MM-DD"));
            }}
          />
          <DatePicker
            disabled={disabled}
            label="Дата окончания договора (необязательно)"
            placeholder="Выберите дату"
            value={values.contract_end_date || ""}
            name="contract_end_date"
            error={errors["contract_end_date"]}
            onChange={([date]: Dayjs[]) => {
              setFieldValue("contract_end_date", dayjs(date).format("YYYY-MM-DD"));
            }}
          />
        </MDContainer>
      </Block>
    </>
  );
};

export default DocumentsFieldset;
