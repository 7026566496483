import {
  useGetBranchesQuery,
  useGetFilterResponsibleUsersQuery,
} from "../../../../Redux/api/branches.api";
import { IBranch, IResponsibleUsers } from "../../../../Redux/api/interfaces";

import { useEffect, useState } from "react";
import { IFilter } from "layouts/pages/widgets/components/TopPanel/interfaces";

const statusFilter: IFilter = {
  id: 1,
  title: "Баланс",
  tag: "balance",
  url: "",
  isInput: true,
  list: [
    {
      id: 11,
      isInput: true,
      tag: "balance",
      name: "Баланс >",
      url: "&balance_gt=",
    },
    {
      id: 12,
      isInput: true,
      tag: "balance",
      name: "Баланс <",
      url: "&balance_lt=",
    },
  ],
  exclusive: true,
};

const addFilers = (filters: IFilter[], responsibleUsers: IResponsibleUsers[]) => {
  const businessFilters: IFilter = {
    id: 2,
    title: "Ответственный",
    tag: "responsible_user_id",
    url: "&responsible_user_id[]=",
    isInput: false,
    list: responsibleUsers.map(({ id, name }) => ({
      id: id,
      isInput: false,
      tag: "branch",
      name: name,
      url: `${id}`,
    })),
    exclusive: false,
  };

  return [...filters, businessFilters, statusFilter];
};

export const useBranchesFilters = () => {
  const [filters, setFilters] = useState<IFilter[]>([]);
  const { data: responsibleUsers, isFetching: isResponsibleUsersFetching } =
    useGetFilterResponsibleUsersQuery();

  useEffect(() => {
    if (responsibleUsers) {
      setFilters((old) => {
        return addFilers(old, responsibleUsers.responsibleUsers);
      });
    }
  }, [responsibleUsers]);

  if (isResponsibleUsersFetching) return null;

  return filters;
};
