import { FC, forwardRef } from "react";

import { ContainerProps } from "@mui/material";

import MDContainerRoot from "components/MDContainer/MDContainerRoot";

type TAligment = "flex-start" | "flex-end" | "center" | "initial" | "space-between";
type TFlexWrap = "nowrap" | "wrap";

interface IProps extends ContainerProps {
  variant?: "row" | "column";
  verticalAligment?: TAligment;
  horizontalAligment?: TAligment;
  flexWrap?: TFlexWrap;
  [key: string]: any;
}

const MDContainer: FC<IProps> = forwardRef((props: IProps, ref) => {
  const { variant, verticalAligment, horizontalAligment, flexWrap, ...rest } = props;

  const isRow = variant === "row";

  return (
    <MDContainerRoot
      {...rest}
      ref={ref}
      container
      maxWidth="auto"
      flexWrap={flexWrap || "nowrap"}
      sx={{
        alignItems: isRow ? verticalAligment : horizontalAligment,
        justifyContent: isRow ? horizontalAligment : verticalAligment,
        flexDirection: {
          xs: "column",
          md: variant,
        },
      }}
    />
  );
});

MDContainer.defaultProps = {
  variant: "row",
  verticalAligment: "flex-start",
  horizontalAligment: "flex-start",
};

export default MDContainer;
