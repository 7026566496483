import * as Yup from "yup";

export const mainValidationScheme = Yup.object().shape({
  first_name: Yup.string().required("Заполните поле Имя"),
  last_name: Yup.string().required("Заполните поле Фамилия"),
  // middle_name: Yup.string().required("Заполните поле Отчество"),
  // birthday: Yup.date().required("Заполните поле Дата рождения"),
  // branch_id: Yup.number().required("Выберите Направление бизнеса"),
  // department_id: Yup.number().required("Выберите Отдел"),
  role_id: Yup.number().required("Выберите Роль"),
  login: Yup.string().email("Невалидный Логин/почта").required("Заполните поле Логин/почта"),
  phone: Yup.string().required("Заполните поле Телефон"),
  // employment_contract_id: Yup.number().required("Загрузите Трудовой договор"),
  // employment_date: Yup.date().required("Заполните поле Дата приема на работу "),
  // permissions: Yup.array()
  //   .of(Yup.string().required())
  //   .compact((v) => !v)
  //   .required("Выберите доступ"),
});
