import MDAlert from "components/MDAlert";
import PageLayout from "examples/CardPage";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import useFilterParams from "hooks/useFilterParams";
import { Table } from "layouts/pages/widgets/components/Table";
import TopPanel from "layouts/pages/widgets/components/TopPanel";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../constants";
import { useGetCounterpartiesTableQuery } from "../../../../Redux/api/counterperties.api";
import { useCounterpartyFilters } from "../hooks/useCounterpartiesFilters";
import { ITableField } from "interfaces";

const CounterpartiesTable = () => {
  const {
    queryParams,
    setQuery,
    setPage,
    setPerPage,
    setStartDate,
    setEndDate,
    query,
    page,
    perPage,
    startDate,
    endDate,
  } = useFilterParams();
  const { data, isFetching, isError, error, refetch, isLoading } = useGetCounterpartiesTableQuery(
    queryParams()
  );
  const filters = useCounterpartyFilters();

  useEffect(() => {
    refetch();
  }, [page, perPage, query, startDate, endDate]);

  const navigate = useNavigate();

  const optionsDots = [
    {
      id: 1,
      text: "Просмотр/редакт",
      url: routes.COUNTERPARTY,
      isId: false,
      action: (item: ITableField) => navigate(routes.COUNTERPARTY + "/" + item.value),
    },
  ];

  return (
    <PageLayout header={<DashboardNavbar />}>
      {isError && (
        <MDAlert color="error" dismissible mb={0}>
          {JSON.stringify(error)}
        </MDAlert>
      )}

      <TopPanel
        main={{
          title: "НОВЫЙ",
          action: () => {
            navigate(routes.CREATE_COUNTERPARTY);
          },
        }}
        dates={{
          from: {
            value: startDate,
            placeholder: "Дата от",
            action: setStartDate,
          },
          to: {
            value: endDate,
            placeholder: "Дата до",
            action: setEndDate,
          },
        }}
        filters={filters}
        exportCSV={{}}
      />

      <Table
        accessorKey="counterparties"
        title="Контрагенты"
        filters={{
          setQuery,
          setPage,
          setPerPage,
          query,
          page,
          perPage,
        }}
        data={data}
        optionsDots={optionsDots}
        isLoading={isFetching || isLoading}
      />
    </PageLayout>
  );
};

export default CounterpartiesTable;
