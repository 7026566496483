import { useFormikContext } from "formik";
import MDContainer from "components/MDContainer";
import Block from "layouts/pages/widgets/components/Block";
import Input from "layouts/pages/widgets/components/Input";
import { useContext, useEffect, useState } from "react";
import { ECommerceContext } from "../context/app-context";
import WarehousesMultiselectField from "layouts/components/fields/warehouses-multiselect-field";
import SalesStrategyField from "layouts/components/fields/sales-strategy-autocomplete-field";
import BranchField from "layouts/components/fields/branch-field";

export interface IFormikValues {
  photo_id?: number | string;
  code: string;
  branch_id: number | string;
  name: string;
  is_website: boolean;
  warehouse_id: (number | string)[];
  sales_strategy_id: number | string;
  is_token_active: boolean;
}

interface IMainFieldsetProps {
  disabled?: boolean;
  photoId?: number | string;
}

const MainFieldset = ({ disabled, photoId }: IMainFieldsetProps) => {
  const [name, setNameInput] = useState<string>("");

  const { setName, isWebsite } = useContext(ECommerceContext);

  const { errors, setFieldValue, initialValues } = useFormikContext<IFormikValues>();

  useEffect(() => {
    setNameInput(initialValues.name);
  }, []);

  useEffect(() => {
    if (photoId) {
      setFieldValue("photo_id", photoId);
    }
  }, [photoId]);

  useEffect(() => {
    setFieldValue("is_website", isWebsite);
  }, [isWebsite]);

  useEffect(() => {
    setName(disabled ? "" : name);
  }, [name, disabled]);

  return (
    <Block title={"Основная информация"} wrapperStyles={{ width: "100%" }}>
      <MDContainer gap={3}>
        <Input
          label={`Название ${isWebsite ? "сайта" : "приложения"}`}
          input={{
            noPointerEvents: disabled,
            name: "name",
            placeholder: "Введите название",
            value: name,
            onChange: (e: any) => setNameInput(e.target.value),
            blur: () => setFieldValue("name", name),
            error: errors.name,
          }}
        />
      </MDContainer>

      <MDContainer gap={3}>
        <BranchField
          disabled={disabled}
          error={errors.branch_id}
          initialValueId={initialValues.branch_id}
          onSelect={(value) => setFieldValue("branch_id", value?.id)}
        />
      </MDContainer>

      <MDContainer gap={3}>
        <WarehousesMultiselectField
          disabled={disabled}
          error={errors.warehouse_id?.toString()}
          initialValueIds={initialValues.warehouse_id}
          onSelect={(value) =>
            setFieldValue(
              "warehouse_id",
              value.map((v) => v.id)
            )
          }
        />
      </MDContainer>

      <MDContainer gap={3}>
        <SalesStrategyField
          disabled={disabled}
          error={errors.sales_strategy_id}
          initialValueId={initialValues.sales_strategy_id}
          onSelect={(value) => setFieldValue("sales_strategy_id", value?.id)}
        />
      </MDContainer>
    </Block>
  );
};

export default MainFieldset;
