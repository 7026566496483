import { useContext } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { ECommerceContext } from "../context/app-context";
import AccountIntegrationSimple from "layouts/components/account-integration/account-integration-simple";

interface IAccountIntegrationProps {
  isApiActive: boolean;
  onActiveChange: (value: boolean) => void;
  message?: string;
}

const AccountIntegration = ({
  isApiActive,
  onActiveChange,
  message = "",
}: IAccountIntegrationProps) => {
  const { token } = useContext(ECommerceContext);

  return (
    <Card id="integration" style={{ overflow: "hidden", width: "100%" }} sx={{ p: 2 }}>
      <MDBox p={2} lineHeight={1} mb={1}>
        <MDTypography variant="h5">Интеграция</MDTypography>
      </MDBox>
      <AccountIntegrationSimple
        label="API"
        isApiActive={isApiActive}
        onActiveChange={onActiveChange}
        message={message}
        token={token}
      />
    </Card>
  );
};

export default AccountIntegration;
