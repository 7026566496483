import { Autocomplete, CircularProgress, Popper, TextField } from "@mui/material";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { debounce } from "@mui/material/utils";
import pxToRem from "assets/theme/functions/pxToRem";

interface ISearchProps<T> {
  onSelect: (item: T) => void;
  searchMutation: any;
  searchQuery?: any;
  searchMutationGetter: string;
  fieldGetter: string;
  renderOption: (props: React.HTMLAttributes<HTMLLIElement>, option: any) => ReactNode;
}

export const Search = <T,>(props: ISearchProps<T>) => {
  const { onSelect, searchMutation, searchMutationGetter, fieldGetter, renderOption, searchQuery } =
    props;

  const [search, result] = searchMutation();
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<any[]>([]);
  const [open, setOpen] = useState(false);

  const fetch = useMemo(() => debounce((query: string) => search(query), 400), []);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    if (searchQuery) {
      search(searchQuery);
      return;
    }
  }, []);

  useEffect(() => {
    if (inputValue.length > 2) {
      if (searchQuery) {
        return;
      }

      fetch(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    if (result.data?.[searchMutationGetter]?.length) {
      setOptions(result?.data?.[searchMutationGetter]);
    }
  }, [result]);

  return (
    <Autocomplete
      sx={{
        width: "100%",
        "& svg": { display: "none" },
        "& label": { lineHeight: pxToRem(28) },
        "& .MuiAutocomplete-popper": { maxWidth: "10vw !important" },
      }}
      open={!!options?.length}
      size="small"
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      onChange={(event, value) => {
        onSelect(value);
        setOptions([]);
      }}
      loadingText={"Загрузка..."}
      noOptionsText=""
      getOptionLabel={(option) => {
        return fieldGetter.split(".").reduce((acc, key) => acc?.[key], option) || "";
      }}
      options={options}
      loading={result.isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={"Поиск"}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {result.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={renderOption}
    />
  );
};
