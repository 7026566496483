import Input from "layouts/pages/widgets/components/Input";
import { useEffect, useMemo, useState } from "react";
import { Autocomplete } from "@mui/material";
import { ITag, useGetTagsQuery } from "../../../Redux/api/categories.api";

interface ITagsMultiselectFieldProps {
  disabled?: boolean;
  error?: string | string[];
  initialValueIds?: (number | string)[];
  onSelect?: (value: ITag[]) => void;
}

const TagsMultiselectField = ({
  disabled,
  error,
  initialValueIds,
  onSelect,
}: ITagsMultiselectFieldProps) => {
  const { data: tags } = useGetTagsQuery(null, { refetchOnMountOrArgChange: true });
  const [selectedOptions, setSelectedOptions] = useState<ITag[]>([]);

  const tagOptions = tags?.tags || [];

  useEffect(() => {
    const options = tagOptions.filter((item) => initialValueIds?.some((id) => id == item.id));
    if (options) {
      setSelectedOptions(options);
    }
  }, [tagOptions]);

  const onSelectItem = (e: any, value: ITag[]) => {
    setSelectedOptions(value);
    onSelect(value);
  };

  return (
    <Autocomplete
      style={{ width: "100%", pointerEvents: disabled ? "none" : "auto" }}
      multiple
      filterOptions={(x) => x}
      getOptionLabel={(v) => v.name}
      isOptionEqualToValue={(op, val) => op.id === val.id}
      options={tagOptions}
      onChange={onSelectItem}
      popupIcon={disabled ? null : undefined}
      value={selectedOptions}
      renderInput={(params: any) => (
        <Input
          label="Теги (необязательно)"
          input={{
            noPointerEvents: disabled,
            placeholder: "",
            error,
            ...params,
          }}
        />
      )}
    />
  );
};

export default TagsMultiselectField;
