import { IUserResponse } from "Redux/api/users.api";

export const getInitialValues = (user: IUserResponse) => {
  if (user?.user) {
    return {
      id: user.user.id,
      first_name: user.user.first_name,
      last_name: user.user.last_name,
      middle_name: user.user.middle_name,
      birthday: user.user.birthday,
      branch_id: user.user.branch?.id,
      department_id: user.user.department?.id,
      login: user.user.login,
      role_id: user.user.role?.id,
      avatar_id: user.user.avatar?.id,
      employment_contract_id: user.user.employment_contract?.id,
      employment_date: user.user.employment_date,
      dismissal_date: user.user.dismissal_date,
      permissions: user.user.permissions.map((per: any) => `${per.name}`),
      phone: user.user.phone,
      is_sick: user.user?.is_sick,
      is_banned: user.user.is_banned,
    };
  }
};
