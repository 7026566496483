import { INewWarehouse } from "../../../../Redux/api/warehouses.api";

export const initialValues: INewWarehouse = {
  avatar_id: "",
  name: "",
  owned_by_us: false,
  address: "",
  storage_capacity: "",
  storage_unit_id: "",
  city_id: "",
  counterparty_id: "",
  code: "",
};
