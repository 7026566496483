import { ITableOption } from "examples/Tables/DataTable";
import useFilterParams from "hooks/useFilterParams";
import AddUpdateAlerts from "layouts/components/add-update-alerts";
import Loader from "layouts/components/loader";
import { Table } from "layouts/pages/widgets/components/Table";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  useDownWarehusePriorityMutation,
  useGetAutomationsTableQuery,
  useUpWarehusePriorityMutation,
} from "../../../../Redux/api/applications.api";

const AutomationsTable = () => {
  const { id } = useParams();
  const { queryParams, setQuery, setPage, setPerPage, query, page, perPage, startDate, endDate } =
    useFilterParams();
  const { data, refetch, isLoading } = useGetAutomationsTableQuery({
    id,
    query: queryParams(),
  });
  const [upPriority, upResult] = useUpWarehusePriorityMutation();
  const [downPriority, downResult] = useDownWarehusePriorityMutation();

  const optionsDots: ITableOption[] = useMemo(
    () => [
      {
        id: 1,
        text: "Повысить приоритет",
        url: "",
        isId: true,
        action: (warehouseId) => upPriority({ appId: id, warehouseId }),
      },
      {
        id: 2,
        text: "Понизить приоритет",
        url: "",
        isId: true,
        action: (warehouseId) => downPriority({ appId: id, warehouseId }),
      },
    ],
    []
  );

  useEffect(() => {
    refetch();
  }, [page, perPage, query, startDate, endDate]);

  if (isLoading || !data?.automations) {
    return <Loader />;
  }
  return (
    <>
      <Table
        title="Автоматизация торговли"
        accessorKey="automations"
        data={data}
        filters={{
          setQuery,
          setPage,
          setPerPage,
          query,
          page,
          perPage,
        }}
        optionsDots={optionsDots}
        isLoading={isLoading}
      />
      <AddUpdateAlerts isAddSuccess={downResult.isSuccess} />
      <AddUpdateAlerts isAddSuccess={upResult.isSuccess} />
    </>
  );
};

export default AutomationsTable;
