import * as Yup from "yup";

export const mainValidationScheme = Yup.object().shape({
  counterparty_id: Yup.number().required("Выберите клиента"),
  delivery_type_id: Yup.number().required("Выберите способ доставки"),
  address: Yup.string().required("Выберите адрес"),
  shop_id: Yup.number().when("delivery_type_id", {
    is: 1,
    then: (schema) => schema.required("Выберите магазин"),
    otherwise: (schema) => schema.notRequired(),
  }),
  branch_id: Yup.number().required("Выберите направление бизнеса"),
  application_id: Yup.number().required("Выберите источник"),
  manager_id: Yup.number().required("Выберите менеджера"),
  sales_channel_id: Yup.number().required("Выберите Канал продажи"),
});
