import { createContext } from "react";

interface IProductsContext {
  setName: (name: string) => void;
  file?: any;
  photo?: any;
  uploadPhotoLocalFile?: any;
  [key: string]: any;
}

export const ProductsContext = createContext<IProductsContext | null>(null);
