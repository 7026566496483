import routesMap from "./routes-map";
import { getRoutes } from "./utils";
import SignInCover from "layouts/authentication/sign-in/cover";
import { Navigate, Route } from "react-router-dom";

export const authRoutes = (
  <>
    <Route path={"/sign-in"} element={<SignInCover />} />
    <Route path="*" element={<Navigate to="/sign-in" />} />
  </>
);

export const appRoutes = getRoutes(routesMap);
