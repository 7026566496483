import MDAlert from "components/MDAlert";
import PageLayout from "examples/CardPage";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import useFilterParams from "hooks/useFilterParams";
import { Table } from "layouts/pages/widgets/components/Table";
import TopPanel from "layouts/pages/widgets/components/TopPanel";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "../../../../constants";
import { useGetInventoriesTableQuery } from "../../../../Redux/api/inventories.api";
import { useInventoriesFilters } from "../hooks/useInventoriesFilters";
import { useEffect } from "react";
import { ITableField } from "interfaces";

const InventoriesTable = () => {
  const navigate = useNavigate();
  const { warehouseId } = useParams<string>();

  const {
    queryParams,
    setQuery,
    setPage,
    setPerPage,
    setStartDate,
    setEndDate,
    query,
    page,
    perPage,
    startDate,
    endDate,
  } = useFilterParams(warehouseId ? `&warehouse_id[]=${warehouseId}` : "");
  const { data, isError, error, refetch, isLoading, isFetching } = useGetInventoriesTableQuery(
    queryParams()
  );

  const filters = useInventoriesFilters();

  useEffect(() => {
    refetch();
  }, [page, perPage, query, startDate, endDate]);

  const handlerAdd = () => {
    if (warehouseId) {
      navigate(routes.CREATE_WAREHOUSE_INVENTORY.replace(":warehouseId", String(warehouseId)));
    } else {
      navigate(routes.CREATE_INVENTORY);
    }
  };

  const handlerView = (id: string) => {
    if (warehouseId) {
      navigate(
        routes.VIEW_WAREHOUSE_INVENTORY.replace(":warehouseId", String(warehouseId)).replace(
          ":id",
          String(id)
        )
      );
    } else {
      navigate(routes.VIEW_INVENTORY.replace(":id", String(id)));
    }
  };

  const optionsDots = [
    {
      id: 1,
      text: "Просмотр/редакт",
      url: warehouseId ? routes.WAREHOUSE_INVENTORIES : routes.INVENTORIES,
      isId: false,
      action: (item: ITableField) => handlerView(item.value),
    },
  ];

  return (
    <PageLayout header={<DashboardNavbar />}>
      {isError && (
        <MDAlert color="error" dismissible mb={0}>
          {JSON.stringify(error)}
        </MDAlert>
      )}

      <TopPanel
        main={{
          title: "НОВЫЙ",
          action: () => handlerAdd(),
        }}
        dates={{
          from: {
            value: startDate,
            placeholder: "Дата от",
            action: setStartDate,
          },
          to: {
            value: endDate,
            placeholder: "Дата до",
            action: setEndDate,
          },
        }}
        filters={filters}
        exportCSV={{}}
      />

      <Table
        accessorKey="inventories"
        title="Остатки"
        filters={{
          setQuery,
          setPage,
          setPerPage,
          query,
          page,
          perPage,
        }}
        data={data}
        optionsDots={optionsDots}
        isLoading={isFetching || isLoading}
      />
    </PageLayout>
  );
};

export default InventoriesTable;
