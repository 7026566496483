import { postHandler } from "api/handlers";

export const Login = async (login: string, password: string) => {
  const { data } = await postHandler("/login", {
    login,
    password,
  });

  return data;
};
