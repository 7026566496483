import { Avatar, Card, Theme } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { Fragment } from "react";
import { useGetFileTypeByIdQuery } from "../../../../../Redux/api/files.api";
import { avatarFileTypeId, getAvatarPath } from "./consts";
import { IProps } from "./interfaces";

const ContentHeader = (props: IProps) => {
  const { info, action, noPointerEvents = false, styles, error } = props;

  const { data: fileTypeData } = useGetFileTypeByIdQuery(avatarFileTypeId);

  return (
    <Fragment>
      <Card
        sx={(theme: Theme) => ({
          padding: {
            xs: theme.spacing(2),
            sm: theme.spacing(2, 3),
          },
          borderRadius: 3,
          maxWidth: "100%",
          ...(styles && styles),
        })}
      >
        <MDBox
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
            gap: {
              xs: 1,
              sm: 0,
            },
          }}
        >
          <MDBox
            sx={{
              maxWidth: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: {
                xs: "column",
                sm: "row",
              },
              gap: {
                xs: 1,
                sm: 3,
              },
            }}
          >
            <label
              style={{
                cursor: "pointer",
              }}
            >
              <Avatar
                src={getAvatarPath(info.img, fileTypeData)}
                sx={(theme) => ({
                  width: 74,
                  height: 74,
                  border: `1px solid ${error ? theme.palette.error.main : "transparent"}`,
                  pointerEvents: noPointerEvents ? "none" : "auto",
                })}
                imgProps={{
                  sx: {
                    height: "100%",
                  },
                }}
              />

              <input
                type="file"
                id="avatar"
                style={{ display: "none" }}
                onChange={info.uploadLocalFile}
              />
            </label>

            <MDBox
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 0.5,
                textAlign: {
                  xs: "center",
                  sm: "left",
                },
                alignItems: {
                  xs: "center",
                  sm: "flex-start",
                },
                maxWidth: "100%",
              }}
            >
              <MDTypography
                color="dark"
                fontSize={20}
                fontWeight="bold"
                lineHeight="140%"
                style={{ maxWidth: "100%" }}
              >
                {info.title}
              </MDTypography>

              <MDTypography
                color="dark"
                fontSize={14}
                fontWeight="regular"
                lineHeight="140%"
                style={{ maxWidth: "100%" }}
              >
                {info.description}
              </MDTypography>
            </MDBox>
          </MDBox>

          {action}
        </MDBox>
      </Card>
    </Fragment>
  );
};

export default ContentHeader;
