import { IColumn } from "interfaces/IColumn";
import { api } from ".";
import { ICountry } from "./country.api";
import { IBranch } from "./interfaces";
import { IAvatar, IViewCategory } from "./interfaces/ICategory";
import { IHistory } from "./interfaces/IHistory";
import { IPagination } from "./interfaces/IPagination";
import { IUnit } from "./storage-units.api";

export interface INomenclatureTableResponse {
  columns: IColumn[];
  nomenclatures: INomenclatureTableItem[];
  pagination: IPagination;
}

interface IName {
  icon: string;
  value: string;
}

export interface INomenclatureTableItem {
  id: number;
  code: string;
  name: IName;
  type: IName;
  kind: IName;
  brand: IName;
  unit: string;
  is_import: string;
  country: string;
}

export interface INomenclatureResponse {
  nomenclature: INomenclature;
}

interface IType {
  id: number;
  name: string;
  avatar: string;
  created_at: string;
}

export interface INomenclature {
  id: number;
  code: string;
  name: string;
  avatar: IAvatar;
  preview: IAvatar;
  description: string;
  category: IViewCategory;
  specification: any;
  type: IType;
  kind: IType;
  branch: IBranch;
  brand: IType;
  tags: any[];
  photos: IAvatar[];
  created_at: string;
  updated_at: string;
  unit: IUnit;
  country: ICountry;
}

export interface IAddNomenclature {
  id?: number | string;
  avatar_id: number | string;
  code: number | string;
  name: string;
  description: string;
  category_id: number | string;
  specification: string;
  type_id: number | string;
  kind_id: number | string;
  branch_id: number;
  tags: (number | string)[];
  country_id: number | string;
  preview_id: number | string;
  photos: (number | string)[];
  storage_unit_id: number | string;
  brand_id: number | string;
}

export interface INomenclatureHistoryResponse {
  histories: IHistory[];
  historiesCount: number;
}

export const nomenclaturesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getNomenclaturesTable: builder.query<INomenclatureTableResponse, string>({
      query: (query) => `tables/nomenclatures?${query}`,
      transformErrorResponse: (response: any) => response?.data?.message || "",
      providesTags: ["nomenclatures"],
    }),
    getNomenclatureById: builder.query<INomenclatureResponse, string>({
      query: (id) => `nomenclatures/${id}`,
      transformErrorResponse: (response: any) => response?.data?.message || "",
      providesTags: ["nomenclatures"],
    }),
    getNomenclatureHistoryById: builder.query<INomenclatureHistoryResponse, string>({
      query: (id) => `nomenclatures/${id}/history`,
      transformErrorResponse: (response: any) => response?.data?.message || "",
      providesTags: ["nomenclatures"],
    }),
    addNomenclature: builder.mutation<INomenclatureResponse, IAddNomenclature>({
      query: (body) => ({
        url: `nomenclatures`,
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: any) => response?.data?.message || "",
      invalidatesTags: ["nomenclatures"],
    }),
    updateNomenclature: builder.mutation<INomenclatureResponse, Partial<IAddNomenclature>>({
      query: ({ id, ...body }) => {
        if (body?.code === null) {
          delete body.code;
        }

        return {
          url: `nomenclatures/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["nomenclatures"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
  }),
});

export const {
  useGetNomenclaturesTableQuery,
  useGetNomenclatureByIdQuery,
  useAddNomenclatureMutation,
  useUpdateNomenclatureMutation,
  useGetNomenclatureHistoryByIdQuery,
} = nomenclaturesApi;
