import { useEffect, useMemo, useState } from "react";
import Select from "layouts/pages/widgets/components/Select";
import { IItem } from "layouts/pages/widgets/components/Select/interfaces";
import { IVat, useGetVatsByIdQuery, useGetVatsQuery } from "../../../../../Redux/api/vats.api";

interface IVatFieldProps {
  disabled?: boolean;
  error?: string;
  initialValue?: number | string;
  onSelect?: (value: IItem) => void;
}

const formatToSelectItem = (items?: IVat[]): IItem[] => {
  if (!items) return [];
  return items.map((item) => ({
    id: item.id,
    label: item.name,
    value: item.id,
    icon: item.avatar,
  }));
};

const VatField = ({ disabled, onSelect, error, initialValue }: IVatFieldProps) => {
  const { data } = useGetVatsQuery(null, { refetchOnMountOrArgChange: true });
  const { data: initVat, refetch: refetchInitVat } = useGetVatsByIdQuery(initialValue);
  const options = useMemo(() => formatToSelectItem(data?.vats), [data]);

  const [selectedOption, setSelectedOption] = useState<IItem | null>(null);

  useEffect(() => {
    if (initialValue) refetchInitVat();
  }, [initialValue]);

  useEffect(() => {
    if (initVat?.vat) {
      setSelectedOption({
        id: initVat?.vat.id,
        label: initVat?.vat.name,
        value: initVat?.vat.id,
        icon: initVat?.vat.avatar,
      });
    }
  }, [initVat]);

  const onSelectItem = (value: IItem) => {
    setSelectedOption(value);
    onSelect(value);
  };

  return (
    <Select
      label="НДС"
      placeholder="Выберите из списка"
      noPointerEvents={disabled}
      error={error}
      popupIcon={disabled ? null : undefined}
      select={{
        value: selectedOption,
        items: options,
        onSelect: onSelectItem,
      }}
    />
  );
};

export default VatField;
