import { ConfiguratorState } from ".";

const initialState: ConfiguratorState = {
  sidenavActiveColor: "info",
  sidenavActiveType: "dark",
  navbarFixed: true,
  sidenavMini: false,
  darkTheme: false,
  contrastTheme: false,
};

function loadStateFromLocalStorage() {
  const state = localStorage.getItem("persist:configurator");

  if (state !== null) {
    return JSON.parse(state) as ConfiguratorState;
  } else {
    return initialState;
  }
}

function saveStateToLocalStorage(state: ConfiguratorState) {
  const newData = JSON.stringify(state);
  localStorage.setItem("persist:configurator", newData);
}

export { loadStateFromLocalStorage, saveStateToLocalStorage };
