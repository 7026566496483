import * as Yup from "yup";

export const mainPhysicalValidationScheme = Yup.object().shape({
  first_name: Yup.string().required("Заполните поле Имя"),
  last_name: Yup.string().required("Заполните поле Фамилия"),
  middle_name: Yup.string().required("Заполните поле Отчество"),
  birthday: Yup.date().required("Заполните поле Дата рождения"),
  phone_1: Yup.string().required("Заполните поле Номер телефона"),
  email: Yup.string().email("Введите корректный email").required("Заполните поле Почта"),
  address: Yup.string().required("Заполните поле Адрес"),
  counterparty_type_id: Yup.number().required("Выберите Тип"),
});
