import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import BottomPanel from "./bottom-panel";
import { useNavigate } from "react-router-dom";

interface IFormControlsProps {
  submitText?: string;
  cancelText?: string;
  cancelAction?: () => void;
  submitAction?: () => void;
  cancelRoute?: string;
}

const FormControls = ({
  cancelAction,
  submitAction,
  submitText = "ГОТОВО",
  cancelText = "ОТМЕНА",
  cancelRoute,
}: IFormControlsProps) => {
  const navigate = useNavigate();

  const handlerCancel = () => {
    if (cancelRoute) {
      navigate(cancelRoute);
      return;
    }

    cancelAction();
  };

  return (
    <BottomPanel>
      <MDBox display="flex" alignItems="center" gap={2} style={{ padding: "12px 24px" }}>
        <MDButton color="dark" variant="gradient" onClick={handlerCancel}>
          {cancelText}
        </MDButton>
        <MDButton
          color="primary"
          variant="gradient"
          type={submitAction ? "button" : "submit"}
          onClick={submitAction}
        >
          {submitText}
        </MDButton>
      </MDBox>
    </BottomPanel>
  );
};

export default FormControls;
