import { useParams } from "react-router-dom";
import TimelineList from "examples/Timeline/TimelineList";
import TimelineItem from "examples/Timeline/TimelineItem";
import MDAlert from "components/MDAlert";
import Loader from "layouts/components/loader";

const History = (props: any) => {
  const { request } = props;

  const { id } = useParams();
  const { data: history, isFetching, isError } = request(id, { skip: !id });

  if (isFetching) {
    return <Loader />;
  }

  if (isError) {
    return (
      <MDAlert dismissible color="error">
        Не удалось загрузить историю
      </MDAlert>
    );
  }

  if (history?.histories) {
    return (
      <TimelineList title="История изменений">
        {history?.histories.map(
          ({ color, icon, title, datetime, description }: any, index: number) => (
            <TimelineItem
              key={title + color}
              color={color}
              icon={icon}
              title={title}
              dateTime={datetime}
              description={description}
              lastItem={index + 1 === history?.histories.length}
            />
          )
        )}
      </TimelineList>
    );
  }
};

export default History;
