import { INomenclature } from "Redux/api/nomenclatures.api";

export const getInitialValues = (nomenclature: INomenclature) => {
  return {
    id: nomenclature?.id,
    avatar_id: nomenclature.avatar?.id,
    code: nomenclature?.code,
    name: nomenclature?.name,
    description: nomenclature?.description,
    category_id: nomenclature?.category?.id,
    specification: nomenclature?.specification,
    type_id: nomenclature?.type?.id,
    kind_id: nomenclature?.kind?.id,
    branch_id: nomenclature?.branch?.id,
    tags: nomenclature?.tags?.map((tag) => tag.id) || [],
    country_id: nomenclature?.country?.id || "",
    storage_unit_id: nomenclature.unit?.id || "",
    brand_id: nomenclature.brand?.id || "",
    preview_id: nomenclature?.preview?.id || "",
    photos: nomenclature.photos?.map((p) => p.id),
  };
};
