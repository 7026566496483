import React from "react";
import { useMaterialUIController } from "context";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useFormikContext } from "formik";

interface INewIntegrationFieldsetProps {
  label?: string;
  name1: string;
  name2: string;
  placeholder1: string;
  placeholder2: string;
  submitText?: string;
}

const NewIntegrationFieldset = ({
  name1,
  name2,
  placeholder1,
  placeholder2,
  label = "Новые данные",
  submitText = "Отправить",
}: INewIntegrationFieldsetProps) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const { setFieldValue, values, errors } = useFormikContext<Record<string, string>>();

  return (
    <>
      <MDBox
        bgColor={darkMode ? "grey-900" : "grey-100"}
        borderRadius="lg"
        display="flex"
        alignItems="center"
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        gap={{ xs: 1, lg: 0 }}
        mt={{ xs: 1, lg: 3 }}
        ml={{ xs: 1, lg: 5 }}
        paddingBlock={1}
        pl={{ xs: 0, md: 1, lg: 2 }}
        pr={1}
      >
        <MDTypography variant="button" fontWeight="medium" color="text">
          {label}
        </MDTypography>
        <MDInput
          size="small"
          placeholder={placeholder1}
          onChange={(e: any) => setFieldValue(name1, e.target.value)}
          value={values[name1]}
          name={name1}
          error={errors[name1]}
        />
        <MDInput
          size="small"
          placeholder={placeholder2}
          onChange={(e: any) => setFieldValue(name2, e.target.value)}
          value={values[name2]}
          name={name2}
          error={errors[name2]}
        />

        <MDButton type="submit" variant="gradient" color="primary" size="small">
          {submitText}
        </MDButton>
      </MDBox>
    </>
  );
};

export default NewIntegrationFieldset;
