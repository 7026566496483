import * as Yup from "yup";

export const fieldsValidationScheme = Yup.object().shape({
  photo_id: Yup.number().notRequired(),
  name: Yup.string().required("Заполните поле Название склада"),
  owned_by_us: Yup.boolean(),
  address: Yup.string().required("Заполните поле Адрес склада"),
  storage_capacity: Yup.number().notRequired(),
  storage_unit_id: Yup.number().required("Выберите Ед. изм."),
  city_id: Yup.number().required("Выберите Город"),
  counterparty_id: Yup.number().notRequired(),
  code: Yup.string().notRequired(),
});
