import { api } from ".";

export interface ICity {
  id: number;
  name: string;
  avatar: string;
  created_at: string;
}

export interface ICityResponse {
  cities: ICity[];
  citiesCount: number;
}

export interface ICityByIdResponse {
  city: ICity;
}

export const searchCityApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCities: builder.query<ICityResponse, void>({
      query: () => ({
        url: `cities`,
      }),
      transformErrorResponse: (response: any) => response.data.message,
    }),
    getCityById: builder.query<ICityByIdResponse, number>({
      query: (cityId) => ({
        url: `cities/${cityId}`,
      }),
      transformErrorResponse: (response: any) => response.data.message,
    }),
  }),
});

export const { useGetCitiesQuery, useGetCityByIdQuery } = searchCityApi;
