import React, { PropsWithChildren } from "react";
import { useState } from "react";
import Icon from "@mui/material/Icon";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

interface IIntegrationAccordionProps extends PropsWithChildren {
  action: React.ReactElement;
  title: string;
  avatar: string;
}

const IntegrationAccordion = ({ children, action, title, avatar }: IIntegrationAccordionProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <Accordion
      expanded={expanded}
      sx={{ "&:before": { content: "none" }, boxShadow: "none" }}
      style={{ marginBlock: "0", width: "100%", backgroundColor: "transparent" }}
    >
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <MDBox display="flex" alignItems="center">
          <MDAvatar src={avatar} alt={`${title} logo`} variant="rounded" />
          <MDBox ml={2}>
            <MDTypography variant="h5" fontWeight="medium">
              {title}
            </MDTypography>
            <MDBox
              display="flex"
              alignItems="flex-end"
              onClick={() => setExpanded((v) => !v)}
              style={{ cursor: "pointer" }}
            >
              <MDTypography variant="button" color="text">
                {expanded ? "Свернуть" : "Открыть"}
              </MDTypography>
              <MDTypography variant="button" color="text" sx={{ lineHeight: 0 }}>
                <Icon fontSize="small">{expanded ? "expand_less" : "expand_more"}</Icon>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          width={{ xs: "100%", sm: "auto" }}
          mt={{ xs: 1, sm: 0 }}
        >
          {action}
          {/* <MDBox lineHeight={0} mx={2}>
            <MDTypography variant="button" color="text">
              {isActive ? "Активно" : "Не активно"}
            </MDTypography>
          </MDBox>
          <MDBox mr={1}>
            <Switch checked={isActive} onChange={handleIsActive} />
          </MDBox> */}
        </MDBox>
      </MDBox>
      <AccordionDetails style={{ paddingInline: "0", paddingBottom: "0" }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default IntegrationAccordion;
