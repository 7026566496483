import { api } from ".";

export interface IOrderStatusResponse {
  orderStatuses: IOrderStatus[];
  orderStatusesCount: number;
}

export interface IOrderStatus {
  id: number;
  name: string;
  created_at: Date;
}

export const orderStatusApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrderStatus: builder.query<IOrderStatusResponse, void>({
      query: () => `order_statuses`,
      transformErrorResponse: (response: any) => response.data.message,
    }),
  }),
});

export const { useGetOrderStatusQuery } = orderStatusApi;
