import PageLayout from "examples/CardPage";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useState } from "react";
import ContentHeader from "layouts/pages/widgets/components/ContentHeader";
import Tabs from "layouts/pages/widgets/components/Tabs";
import Main from "../components/main";
import { ShopNameContext } from "../context/shop-name-context";
import { Stack, Switch } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import MDTypography from "components/MDTypography";
import { initialValues } from "../consts";
import { useLoadFile } from "hooks/userLoadFile";
import { AVATAR } from "constants/file-types";

const NewShop = () => {
  const {
    file,
    uploadLocalFile,
    loadFile,
    loadError,
    setLoadError,
    cancelUploadLocalFile,
    filePath,
  } = useLoadFile(AVATAR);

  const [isShop, setIsShop] = useState(false);
  const [name, setName] = useState("");

  return (
    <PageLayout header={<DashboardNavbar />}>
      <ShopNameContext.Provider value={{ setName, file }}>
        <ContentHeader
          info={{
            img: filePath,
            uploadLocalFile,
            title: name || "Новый",
          }}
          action={
            <Stack direction="row" spacing={0.5} alignItems="center">
              <MDTypography color="text" style={{ fontSize: pxToRem(14) }}>
                {isShop ? "Магазин" : "Пункт выдачи"}
              </MDTypography>
              <Switch checked={!isShop} onChange={() => setIsShop((v) => !v)} />
            </Stack>
          }
        />

        <Tabs
          defaultTab={1}
          items={{
            headers: ["Основные"],
            components: [
              <Main
                key="main"
                type="add"
                isShop={isShop}
                initialValues={initialValues}
                loadFile={loadFile}
                cancelUploadLocalFile={cancelUploadLocalFile}
                setLoadError={setLoadError}
              />,
            ],
          }}
        />
      </ShopNameContext.Provider>
    </PageLayout>
  );
};

export default NewShop;
