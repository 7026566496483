import { api } from ".";

export interface IMenusItemResponse {
  menuItems: IMenusItem[];
  menuItemsCount: number;
}

export interface IMenusItem {
  id: number;
  menu_id: number;
  parent_id: number;
  avatar: string;
  name: string;
  url?: string;
  children: IMenusItem[];
}

export const menusApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMenu: builder.query<any, void>({
      query: () => `menus`,
      transformErrorResponse: (response: any) => response.data.message,
    }),
    getMenuItemsById: builder.query<IMenusItemResponse, number | string>({
      query: (id) => `menus/${id}/items`,
      transformErrorResponse: (response: any) => response.data.message,
    }),
  }),
});

export const { useGetMenuQuery, useGetMenuItemsByIdQuery } = menusApi;
