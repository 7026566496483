import { INewCategory } from "../../../../Redux/api/interfaces/ICategory";

export const initialValues: INewCategory = {
  code: "",
  name: "",
  description: "",
  parent_id: "",
  avatar_id: null,
  photo_id: 0,
  tag_id: [],
};
