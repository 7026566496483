import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { fieldsValidationScheme } from "../schemas/fields";
import EditFields from "./blocks/fields";
import FormControls from "../../../components/form-controls";
import AddUpdateAlerts from "layouts/components/add-update-alerts";
import EditContainer from "layouts/components/edit-container";
import { routes } from "../../../../constants";
import {
  INewWarehouse,
  useAddWarehouseMutation,
  useEditWarehouseMutation,
} from "../../../../Redux/api/warehouses.api";
import { IMain } from "./interfaces";
import { WarehouseNameContext } from "../context/warehouse-name-context";
import { delay } from "utils/delay";

const Main = (props: IMain) => {
  const {
    initialValues,
    edit = false,
    type,
    loadFile,
    cancelUploadLocalFile,
    setLoadError,
    isOwned,
  } = props;

  const { file } = useContext(WarehouseNameContext);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [createWarehouse, createResult] = useAddWarehouseMutation();
  const [editWarehouse, editResult] = useEditWarehouseMutation();

  const navigate = useNavigate();

  const createResultHandler = async () => {
    if (createResult.isSuccess && createResult?.data?.warehouse.id) {
      await delay(1000);
      navigate(routes.WAREHOUSES + "/" + createResult?.data?.warehouse.id);
    }
  };

  useEffect(() => {
    createResultHandler();
  }, [createResult]);

  useEffect(() => {
    if (isOwned !== initialValues.owned_by_us) {
      setIsEdit(true);
    }
  }, [isOwned]);

  const editHandler = (values: INewWarehouse) => {
    editWarehouse(values);
    setIsEdit(false);
  };

  const handleSubmit = async (values: INewWarehouse) => {
    let loadedFile = null;
    if (file) loadedFile = await loadFile();
    if (loadedFile) values.avatar_id = loadedFile.id;
    cancelUploadLocalFile();
    let newValues = { ...values, owned_by_us: isOwned };
    type === "add" ? createWarehouse(newValues) : editHandler(newValues);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={fieldsValidationScheme}
        onSubmit={handleSubmit}
        validateOnMount={false}
        validate={() => {
          if (!file && !edit) {
            setLoadError?.("Загрузите avatar");
          }
        }}
      >
        {() => (
          <>
            <Form style={{ width: "100%" }}>
              <EditContainer
                showButton={edit}
                buttonColor={isEdit ? "secondary" : "error"}
                buttonAction={() => setIsEdit((prev) => !prev)}
              >
                <EditFields disabled={edit && !isEdit} setIsEdit={setIsEdit} />
                {(isEdit || !edit || file) && <FormControls cancelRoute={routes.WAREHOUSES} />}
              </EditContainer>
            </Form>
          </>
        )}
      </Formik>
      <AddUpdateAlerts
        isEditError={editResult.isError}
        isEditSuccess={editResult.isSuccess}
        isAddError={createResult.isError}
        isAddSuccess={createResult.isSuccess}
        editErrorMessage={editResult.error as string}
        addErrorMessage={createResult.error as string}
      />
    </>
  );
};

export default Main;
