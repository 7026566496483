import { api } from "./index";
import { IProfile } from "./interfaces/IProfile";
import { IUser } from "interfaces";
import { IUserColumn } from "./interfaces";
import { IPagination } from "./interfaces/IPagination";
import { IIntegration, IIntegrationsResponse } from "./interfaces/IIntagration";

export interface IUsersTableResponse {
  columns: IUserColumn[];
  users: IUser[];
  message: string;
  pagination: IPagination;
}

export interface IAddUser {
  id?: string | number;
  first_name: string;
  last_name: string;
  middle_name: string;
  birthday: string;
  branch_id?: number | string;
  department_id: number | string;
  role_id?: number | string;
  avatar_id?: number | string;
  login: string;
  phone: string;
  is_banned?: boolean;
  employment_contract_id?: number | string;
  employment_date?: string;
  dismissal_date?: string;
  permissions?: string[];
  is_sick?: boolean;
}

export type IUpdateUser = Partial<IAddUser>;

export interface IUsersResponse {
  users_count: number;
  users: IUser[];
}

export interface IUserResponse {
  user: IProfile;
}

interface IIntegrationParametrsReq {
  userId: string | number;
  integrationId: string | number;
  paramId?: string | number;
}

interface IIntegrationStatus extends IIntegrationParametrsReq {
  is_active: 1 | 0;
}

interface IAddParameterReq extends IIntegrationParametrsReq {
  integration_parameter_id: string | number;
  value_1: string;
  value_2: string;
}

export interface IParametersResponse {
  parameters: IParameter[];
  parametersCount: number;
}

export interface IParameterResponse {
  parameter: IParameter;
}

export interface IParameter {
  id: number;
  key_1: string;
  description_1: string;
  key_2: string;
  description_2: string;
  value_1: string;
  value_2: string;
}

export interface IIntegrationResponse {
  integration: IIntegration;
}

export const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query<IUserResponse, void>({
      query: () => `user`,
      providesTags: ["user"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    getUsers: builder.query<IUsersTableResponse, string>({
      query: (queryParams) => `users?${queryParams}`,
      providesTags: ["user"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    getUsersTable: builder.query<IUsersTableResponse, string>({
      query: (queryParams) => `tables/users?${queryParams}`,
      providesTags: ["user"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    getUserById: builder.query<IUserResponse, string>({
      query: (idUser) => `users/${idUser}`,
      keepUnusedDataFor: 0,
      providesTags: ["user"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    addNewUser: builder.mutation<IUserResponse, IAddUser>({
      query: (body) => ({
        url: `users`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["user"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    editUser: builder.mutation<IUserResponse, IUpdateUser>({
      query: (body) => {
        return {
          url: `users/${body.id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["user"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    deleteUser: builder.mutation<void, string>({
      query: (id) => ({
        url: `users/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["user"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),

    //integrations
    getUserIntegrations: builder.query<any, string>({
      query: (id) => `users/${id}/integrations`,
      providesTags: ["user-integrations"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    editUserIntegration: builder.mutation<any, any>({
      query: ({ userId, integrationId, is_active }) => ({
        url: `users/${userId}/integrations/${integrationId}`,
        method: "PUT",
        body: { is_active },
      }),
      transformErrorResponse: (response: any) => response?.data?.message || "",
      invalidatesTags: ["user-integrations"],
    }),
    addUserIntegrationParameters: builder.mutation<any, any>({
      query: ({ userId, integrationId, ...body }) => ({
        url: `users/${userId}/integrations/${integrationId}/parameters`,
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: any) => response?.data?.message || "",
      invalidatesTags: ["user-integrations"],
    }),
    getUserIntegrationById: builder.query<any, any>({
      query: (integrationId) => `integrations/${integrationId}`,
      providesTags: ["user-integrations"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    getUserIntegrationParameters: builder.query<any, any>({
      query: ({ userId, integrationId }) =>
        `users/${userId}/integrations/${integrationId}/parameters`,
      providesTags: ["user-integrations"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    deleteUserIntegrationParameter: builder.mutation<any, any>({
      query: ({ userId, integrationId, paramId }) => ({
        url: `users/${userId}/integrations/${integrationId}/parameters/${paramId}`,
        method: "DELETE",
      }),
      transformErrorResponse: (response: any) => response?.data?.message || "",
      invalidatesTags: ["user-integrations"],
    }),
  }),
});

export const {
  useGetUsersTableQuery,
  useGetUsersQuery,
  useGetProfileQuery,
  useGetUserByIdQuery,
  useAddNewUserMutation,
  useEditUserMutation,
  useDeleteUserMutation,
  useGetUserIntegrationsQuery,
  useEditUserIntegrationMutation,
  useAddUserIntegrationParametersMutation,
  useGetUserIntegrationByIdQuery,
  useGetUserIntegrationParametersQuery,
  useDeleteUserIntegrationParameterMutation,
} = usersApi;
