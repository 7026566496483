import { useEffect, useState } from "react";
import { IFilter } from "layouts/pages/widgets/components/TopPanel/interfaces";
import { useGetOrderStatusQuery, IOrderStatus } from "../../../../Redux/api/order-status.api";
import { Application, useGetApplicationsQuery } from "../../../../Redux/api/applications.api";
import { IDepartment } from "Redux/api/interfaces";
import { useGetDepartmentsQuery } from "../../../../Redux/api/departments.api";

const addFilers = (status: IOrderStatus[], apps: Application[], departments: IDepartment[]) => {
  const hierarchyFilter: IFilter = {
    id: 1,
    title: "Импорт",
    tag: "hierarchy",
    url: "&hierarchy=",
    isInput: false,
    list: [
      {
        id: 10,
        isInput: false,
        tag: "hierarchy",
        name: "Мои",
        url: "my",
      },
      {
        id: 11,
        isInput: false,
        tag: "hierarchy",
        name: "Все",
        url: "all",
      },
    ],
    exclusive: true,
  };

  const statusFilter: IFilter = {
    id: 1,
    title: "Статус",
    tag: "status",
    url: "&order_status_id[]=",
    isInput: false,
    list: status.map((st, index) => ({
      id: st.id,
      isInput: false,
      tag: "status",
      name: st.name,
      url: `${st.id}`,
    })),
    exclusive: false,
  };

  const appFilter: IFilter = {
    id: 3,
    title: "Источник",
    tag: "application",
    url: "&application_id[]=",
    isInput: false,
    list: apps.map((st, index) => ({
      id: st.id,
      isInput: false,
      tag: "application",
      name: st.name,
      url: `${st.id}`,
    })),
    exclusive: false,
  };

  const departmentsFilter: IFilter = {
    id: 5,
    title: "Отдел",
    tag: "department",
    url: "&department_id[]=",
    isInput: false,
    list: departments.map((st, index) => ({
      id: st.id,
      isInput: false,
      tag: "department",
      name: st.name,
      url: `${st.id}`,
    })),
    exclusive: false,
  };

  return [hierarchyFilter, statusFilter, appFilter, departmentsFilter];
};

export const useOrdersFilters = () => {
  const [filters, setFilters] = useState<IFilter[]>([]);
  const { data: status, isFetching: isStatusFetching } = useGetOrderStatusQuery();
  const { data: app, isFetching: isAppFetching } = useGetApplicationsQuery();
  const { data: department, isFetching: isDepartmentFetching } = useGetDepartmentsQuery();

  useEffect(() => {
    if (status && app && department) {
      setFilters(addFilers(status.orderStatuses, app.applications, department.departments));
    }
  }, [status, app, department]);

  if (isStatusFetching || isAppFetching || isDepartmentFetching) return null;

  return filters;
};
