import { INewShop, IShopById } from "Redux/api/shops.api";

export const getInitialValues = (shop: { shop: IShopById }) => {
  if (shop?.shop) {
    return {
      id: shop.shop.id || "",
      avatar_id: shop?.shop.avatar?.id,
      is_shop: shop.shop.is_shop,
      code: shop.shop.code,
      name: shop.shop.name,
      address: shop.shop.address,
      branches: shop.shop.branches.map((branch) => branch?.id),
      warehouses: shop.shop.warehouses.map((warehouse) => warehouse?.id),
      phone: shop.shop.phone,
    } as INewShop;
  }
};
