import { useEffect, useState } from "react";
import { Stack, Switch } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import MDTypography from "components/MDTypography";
import PageLayout from "examples/CardPage";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ContentHeader from "layouts/pages/widgets/components/ContentHeader";
import Tabs from "layouts/pages/widgets/components/Tabs";
import { ECommerceContext } from "../context/app-context";
import Main from "../components/main";
import { initialValues } from "../consts";
import { useLoadFile } from "hooks/userLoadFile";
import { AVATAR } from "constants/file-types";

const NewECommerce = () => {
  const { file, uploadLocalFile, loadFile, setLoadError, cancelUploadLocalFile, filePath } =
    useLoadFile(AVATAR);

  const [isWebsite, setIsWebsite] = useState(false);
  const [name, setName] = useState("");

  return (
    <PageLayout header={<DashboardNavbar />}>
      <ECommerceContext.Provider value={{ setName, file, isWebsite }}>
        <ContentHeader
          info={{
            img: filePath,
            uploadLocalFile,
            title: name || (isWebsite ? "Новый сайт" : "Новое приложение"),
          }}
          action={
            <Stack direction="row" spacing={0.5} alignItems="center">
              <MDTypography color="text" style={{ fontSize: pxToRem(14) }}>
                {isWebsite ? "Сайт" : "Приложение"}
              </MDTypography>

              <Switch checked={isWebsite} onChange={() => setIsWebsite((v) => !v)} />
            </Stack>
          }
          error={false}
        />

        <Tabs
          defaultTab={1}
          items={{
            headers: ["Основные"],
            components: [
              <Main
                key="main"
                type="add"
                initialValues={{ ...initialValues, is_website: isWebsite }}
                loadFile={loadFile}
                cancelUploadLocalFile={cancelUploadLocalFile}
                cancelPhotoUploadLocalFile={cancelUploadLocalFile}
                setLoadError={setLoadError}
              />,
            ],
          }}
        />
      </ECommerceContext.Provider>
    </PageLayout>
  );
};

export default NewECommerce;
