import { IItem } from "layouts/pages/widgets/components/Select/interfaces";
import { ICounterpartyType } from "../../../../Redux/api/counterparty-types.api";
import { ICounterpartyResponse } from "Redux/api/counterperties.api";

export const formatTypeToSelectItem = (types?: ICounterpartyType[]): IItem[] => {
  if (!types) return [];
  return types.map((type) => ({
    id: type.id,
    label: type.name,
    value: type.id,
  }));
};

export const getCounterpartyTitle = (counterparty?: ICounterpartyResponse) => {
  if (!counterparty) return "";
  else if ("name" in counterparty.counterparty) {
    return counterparty.counterparty.name;
  } else {
    return `${counterparty.counterparty.last_name} ${counterparty.counterparty.first_name} ${counterparty.counterparty.middle_name}`;
  }
};

export const isCounterpartyCompany = (counterparty?: ICounterpartyResponse) => {
  if (!counterparty) return false;
  return "name" in counterparty?.counterparty;
};
