import { IAddApplication, IApplication } from "Redux/api/applications.api";

export const getInitialValues = (application: IApplication) => {
  return {
    id: application.id,
    photo_id: application.photo?.id || null,
    code: application.code || "",
    branch_id: application.branch.id || "",
    name: application.name,
    is_website: application.is_website,
    warehouse_id: application.warehouses.map((w) => w.id),
    sales_strategy_id: application.sales_strategy.id,
    is_token_active: application.is_token_active,
  } as IAddApplication;
};
