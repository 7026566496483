import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDEditor from "components/MDEditor";
import MDTypography from "components/MDTypography";

interface IEditorFieldProps {
  value: string;
  onChange: (v: string) => void;
  label?: string;
  disabled?: boolean;
}

const EditorField = ({
  value,
  onChange,
  label = "Описание",
  disabled = false,
}: IEditorFieldProps) => {
  return (
    <Grid item xs={12} sm={12}>
      <MDBox mb={1} width="100%">
        <MDTypography component="label" variant="button" fontWeight="regular" color="text">
          {label}
        </MDTypography>
      </MDBox>
      <MDEditor
        key={"k" + disabled}
        onChange={onChange}
        defaultValue={value}
        noPointerEvents={disabled}
        toolbarOptions={disabled ? [] : ["fontFamily", "inline", "blockType", "fontSize", "list"]}
      />
    </Grid>
  );
};

export default EditorField;
