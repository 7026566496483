/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// draft-js
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertFromHTML, convertToHTML } from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// Custom styles for the MDEditor
import MDEditorRoot from "components/MDEditor/MDEditorRoot";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

function MDEditor(props: any) {
  const { defaultValue, onChange, toolbarOptions, placeholder, noPointerEvents, isError } = props;

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [editorState, setEditorState] = useState(() =>
    defaultValue
      ? //@ts-ignore
        EditorState.createWithContent(convertFromHTML(defaultValue))
      : EditorState.createEmpty()
  );

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    onChange(html);
  }, [editorState]);

  //options: https://jpuri.github.io/react-draft-wysiwyg/#/docs
  return (
    <MDEditorRoot ownerState={{ darkMode, isError: isError }} disabled={noPointerEvents}>
      <Editor
        toolbar={{
          options: toolbarOptions,
        }}
        placeholder={placeholder}
        editorState={editorState}
        onEditorStateChange={setEditorState}
      />
    </MDEditorRoot>
  );
}

// Setting default values for the props of MDEditor
MDEditor.defaultProps = {
  onChange: () => {},
  value: () => {},
  toolbarOptions: [
    "inline",
    "blockType",
    "fontSize",
    "fontFamily",
    "list",
    "textAlign",
    "colorPicker",
    "link",
    "embedded",
    "emoji",
    "image",
    "remove",
    "history",
  ],
  placeholder: "",
  noPointerEvents: false,
};

// Typechecking props for the MDEditor
MDEditor.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  value: PropTypes.func,
  toolbarOptions: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
  noPointerEvents: PropTypes.bool,
  isError: PropTypes.bool,
};

export default MDEditor;
