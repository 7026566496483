import { api } from ".";
import { IPagination } from "./interfaces/IPagination";
import { IIntegration } from "./interfaces/IIntagration";
import { IIntegrationByIdResponse, IIntegrationParametersResponse } from "./branches.api";
import { IAvatar } from "./interfaces/ICategory";
import { IColumn } from "interfaces/IColumn";

export interface IWarehouseTableResponse {
  columns: IColumn[];
  warehouses: IWarehouse[];
  pagination: IPagination;
}

export interface IWarehouseResponse {
  warehouses: IWarehouse[];
  warehousesCount: number;
}

export interface IWarehouse {
  id: number;
  photo: any;
  owned_by_us: boolean;
  address: string;
  storage_capacity: number;
  city: ICity;
  code: string;
  name?: string;
  avatar?: IAvatar;
}

export interface INewWarehouse {
  avatar_id: number | string;
  code: number | string;
  name: string;
  owned_by_us: boolean;
  address: string;
  storage_capacity: number | string;
  storage_unit_id: number | string;
  city_id: number | string;
  counterparty_id: number | string;
}

export interface ICurrentWarehouse {
  id: number;
  avatar: IWarehousePhoto;
  code: number;
  name: string;
  owned_by_us: boolean;
  address: string;
  storage_capacity: number;
  storage_unit: IStorageUnit;
  city: ICity;
  counterparty: ICounterparty;
}

export interface IWarehousePhoto {
  id: number;
}

export interface ICounterpartyType {
  id: number;
  name: string;
  avatar: string;
  created_at: string;
}

export interface ICounterpartyManager {
  id: number;
  first_name: string;
  last_name: string;
  middle_name: string;
  name: string;
}

export interface ICounterparty {
  id: number;
  type: ICounterpartyType;
  manager: ICounterpartyManager;
  avatar: null;
  name: string;
  registered_at: string;
  inn: string;
  kpp: null | string;
  head: string;
  legal_address: string;
  phone_1: string;
  phone_2: string;
  email: string;
  created_at: string;
  updated_at: string;
}

export interface IStorageUnit {
  id: number;
  name: string;
  abbreviation: string;
}

export interface ICity {
  id: number;
  name: string;
  avatar: string;
  created_at: string;
}

export interface IName {
  icon: string;
  value: string;
}

export interface ICreator {
  icon: string;
  value: string;
}

export interface IWarehouseHistoryResponse {
  histories: IHistoryDate[];
}

export interface IHistoryDate {
  id: number;
  icon: string;
  color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light";
  title: string;
  description: string;
  datetime: string;
}

export interface IIntegrationsResponse {
  integrations: IIntegration[];
  integrationsCount: number;
}

export interface IIntegrationParameters {
  warehouseId: string | number;
  integrationId: string | number;
}

export interface IIntegrationParameterResponse {
  integration_parameter_id: number;
  value_1: string;
  value_2: string;
}

export interface IIntegrationParameter {
  warehouseId: string | number;
  integrationId: string | number;
  integration_parameter_id: string | number;
  value_1: string;
  value_2: string;
}

export const warehousesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getWarehouses: builder.query<IWarehouseResponse, string>({
      query: (queryParams) => `warehouses?${queryParams}`,
      transformErrorResponse: (response: any) => response.data.message,
    }),
    getWarehousesTable: builder.query<IWarehouseTableResponse, string>({
      query: (queryParams) => `tables/warehouses?${queryParams}`,
      transformErrorResponse: (response: any) => response.data.message,
    }),
    getWarehouseByid: builder.query({
      query: (id) => `warehouses/${id}`,
      providesTags: ["warehouse"],
      transformErrorResponse: (response: any) => response.data.message,
    }),
    addWarehouse: builder.mutation({
      query: (body) => ({
        url: `warehouses`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["warehouse"],
    }),
    editWarehouse: builder.mutation({
      query: (body) => ({
        url: `warehouses/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["warehouse"],
    }),
    getWarehousesIntegrations: builder.query<IIntegrationsResponse, string | number>({
      query: (id) => `warehouses/${id}/integrations`,
      transformErrorResponse: (response: any) => response.data.message,
      providesTags: ["warehouse_integrations"],
    }),
    getIntegrationById: builder.query<IIntegrationByIdResponse, string | number>({
      query: (integrationId) => `integrations/${integrationId}`,
    }),
    editWarehousesIntegration: builder.mutation({
      query: ({ warehouseId, integrationId, ...body }) => ({
        url: `warehouses/${warehouseId}/integrations/${integrationId}`,
        method: "PUT",
        body,
      }),
    }),
    getWarehousesIntegrationParams: builder.query<
      IIntegrationParametersResponse,
      IIntegrationParameters
    >({
      query: ({ warehouseId, integrationId }) =>
        `warehouses/${warehouseId}/integrations/${integrationId}/parameters`,
      providesTags: ["warehouse_integrations"],
      transformErrorResponse: (response: any) => response.data.message,
    }),
    addWarehousesIntegrationParam: builder.mutation<
      IIntegrationParameterResponse,
      IIntegrationParameter
    >({
      query: ({ warehouseId, integrationId, ...body }) => ({
        url: `warehouses/${warehouseId}/integrations/${integrationId}/parameters`,
        method: "POST",
        body: { ...body },
      }),
      invalidatesTags: ["warehouse_integrations"],
    }),
    deleteWarehouseIntegrationParam: builder.mutation({
      query: ({ warehouseId, integrationId, parameterId }) => ({
        url: `warehouses/${warehouseId}/integrations/${integrationId}/parameters/${parameterId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["warehouse_integrations"],
    }),
    getWarehouseHistory: builder.query<IWarehouseHistoryResponse, string | number>({
      query: (warehouseId) => `warehouses/${warehouseId}/history`,
      transformErrorResponse: (response: any) => response.data.message,
    }),
  }),
});

export const {
  useGetWarehousesQuery,
  useGetWarehousesTableQuery,
  useGetWarehouseByidQuery,
  useAddWarehouseMutation,
  useEditWarehouseMutation,
  useGetWarehousesIntegrationsQuery,
  useGetIntegrationByIdQuery,
  useEditWarehousesIntegrationMutation,
  useGetWarehousesIntegrationParamsQuery,
  useAddWarehousesIntegrationParamMutation,
  useDeleteWarehouseIntegrationParamMutation,
  useGetWarehouseHistoryQuery,
} = warehousesApi;
