/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Icon from "@mui/material/Icon";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import Input from "layouts/pages/widgets/components/Input";
import SidePanelRoot from "./SidePanelRoot";
import ContentHeader from "layouts/pages/widgets/components/ContentHeader";
import MDButton from "components/MDButton";
import MDContainer from "components/MDContainer";
import { useLoadFile } from "hooks/userLoadFile";
import { AVATAR } from "constants/file-types";
import { useContext, useEffect, useState } from "react";
import {
  useGetBrandByIdQuery,
  useCreateBrandMutation,
  useEditBrandMutation,
} from "../../Redux/api/brands.api";
import { UserContext } from "context/user";
import BranchField from "layouts/components/fields/branch-field";

function SidePanel(props: any): JSX.Element {
  const { isOpen, setIsOpen } = props;

  const { user } = useContext(UserContext);

  const { data } = useGetBrandByIdQuery(isOpen, { skip: !isOpen || isOpen === true });
  const [createBrand, createResult] = useCreateBrandMutation();
  const [editBrand, editResult] = useEditBrandMutation();

  const [name, setName] = useState("");
  const [branch, setBranch] = useState(user?.branch?.id);
  const [isReady, setIsReady] = useState(false);

  const { file, filePath, uploadLocalFile, loadFile, cancelUploadLocalFile } = useLoadFile(AVATAR);
  const [controller] = useMaterialUIController();

  const { darkMode } = controller;

  useEffect(() => {
    if (!isOpen) {
      setName("");
      setIsReady(false);
      cancelUploadLocalFile();
    } else if (data && isOpen !== true) {
      setName(data?.brand?.name);
    }
  }, [isOpen, data]);

  useEffect(() => {
    if (
      (createResult.isSuccess && createResult?.data?.brand.id) ||
      (editResult.isSuccess && editResult?.data?.brand.id)
    ) {
      setIsReady(true);
    }
  }, [createResult, editResult]);

  const handleSubmit = async () => {
    let loadedFile = null,
      values: any = { name, id: isOpen };
    if (file) loadedFile = await loadFile();
    if (loadedFile) values.avatar_id = loadedFile.id;
    if (branch) values.branch_id = branch;
    if (isOpen) isOpen === true ? createBrand(values) : editBrand(values);
  };

  return (
    <SidePanelRoot variant="permanent" ownerState={{ openConfigurator: isOpen }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="baseline" pt={4}>
        <MDBox>
          {isOpen ? (
            <MDTypography sx={{ mb: 5 }} variant="h6">
              {!isReady
                ? `${isOpen === true ? "Добавление" : "Редактирование"} ${props.title}`
                : `Данные успешно ${isOpen === true ? "добавлены" : "обновлены"}!`}
            </MDTypography>
          ) : (
            <MDTypography sx={{ mb: 5 }} variant="h6">
              &#8203;
            </MDTypography>
          )}
        </MDBox>
        {isReady && (
          <ThumbUpIcon
            sx={({ typography: { size } }) => ({
              fontSize: `${size.lg} !important`,
              color: "#E93B77",
              transform: "translateX(-2px) translateY(2px)",
            })}
          />
        )}
        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateX(-2px) translateY(2px)",
          })}
          onClick={() => setIsOpen(false)}
        >
          close
        </Icon>
      </MDBox>
      <ContentHeader
        info={{
          img: (file && filePath) || (isOpen && isOpen !== true && data?.brand?.avatar),
          uploadLocalFile,
          title: name || "Новая",
        }}
      />
      <MDContainer gap={3} mt={3}>
        <Input
          label="Название"
          input={{
            noPointerEvents: isReady,
            placeholder: "Введите название",
            value: name,
            onChange: (e: any) => setName(e.target.value),
            name: "name",
          }}
        />
      </MDContainer>
      <MDContainer gap={3} mt={3}>
        <BranchField
          disabled={isReady}
          initialValueId={branch}
          onSelect={(value: any) => setBranch(value?.id)}
        />
      </MDContainer>
      {!isReady && (
        <MDBox display="flex" alignItems="center" gap={2} style={{ padding: "32px 0px" }}>
          <MDButton fullWidth color="dark" variant="gradient" onClick={() => setIsOpen(false)}>
            Отмена
          </MDButton>
          <MDButton
            fullWidth
            color="primary"
            variant="gradient"
            type={"button"}
            onClick={() => handleSubmit()}
          >
            Готово
          </MDButton>
        </MDBox>
      )}
    </SidePanelRoot>
  );
}

export default SidePanel;
