import { useEffect, useMemo, useState } from "react";
import Select from "layouts/pages/widgets/components/Select";
import { IItem } from "layouts/pages/widgets/components/Select/interfaces";
import { useGetShopsQuery, IShopsResponseItem } from "../../../../Redux/api/shops.api";

interface ICitiesFieldProps {
  disabled?: boolean;
  error?: string;
  initialValue?: string | number;
  onSelect?: (value: IItem) => void;
}

const formatToSelectItem = (units?: IShopsResponseItem[]): IItem[] => {
  if (!units) return [];
  return units.map((unit: IShopsResponseItem) => ({
    id: unit.id,
    label: `${unit.name} (${unit.address})`,
    value: unit.address,
    icon: unit.photo?.path,
  }));
};

const ShopsField = ({ disabled, onSelect, error, initialValue }: ICitiesFieldProps) => {
  const { data } = useGetShopsQuery("", { refetchOnMountOrArgChange: true });
  const [selectedOption, setSelectedOption] = useState<IItem | null>(null);
  const options = useMemo(() => formatToSelectItem(data?.shops), [data]);

  useEffect(() => {
    const option = options.find((item) => item.id == initialValue);
    if (option) {
      setSelectedOption(option);
    }
  }, [options]);

  const onSelectItem = (value: IItem) => {
    setSelectedOption(value);
    onSelect(value);
  };

  return (
    <Select
      label="Магазины"
      placeholder="Начните ввод"
      noPointerEvents={disabled}
      error={error}
      popupIcon={disabled ? null : undefined}
      select={{
        value: selectedOption,
        items: options,
        onSelect: onSelectItem,
      }}
    />
  );
};

export default ShopsField;
