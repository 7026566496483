import { Stack, Switch } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import MDTypography from "components/MDTypography";
import PageLayout from "examples/CardPage";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Loader from "layouts/components/loader";
import ContentHeader from "layouts/pages/widgets/components/ContentHeader";
import Tabs from "layouts/pages/widgets/components/Tabs";
import { useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetUserByIdQuery } from "../../../../Redux/api/users.api";
import Main from "../components/main";
import { NewUserContext } from "../context/new-user-context";
import { useLoadFile } from "hooks/userLoadFile";
import { AVATAR } from "constants/file-types";
import { getInitialValues } from "../consts";

const ViewUser = () => {
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [switchValue, setSwitchValue] = useState(false);

  const { id } = useParams<string>();
  const { data: userData } = useGetUserByIdQuery(id);
  const { file, filePath, uploadLocalFile, loadFile, cancelUploadLocalFile } = useLoadFile(AVATAR);

  useLayoutEffect(() => {
    setSwitchValue(userData?.user?.is_banned || false);
  }, [userData]);

  if (!userData?.user) {
    return <Loader />;
  }

  return (
    <PageLayout header={<DashboardNavbar />}>
      <NewUserContext.Provider
        value={{
          setName,
          setRole,
          file,
          contractFile: userData?.user?.employment_contract,
        }}
      >
        <ContentHeader
          info={{
            img: filePath || userData?.user?.avatar?.path || "",
            uploadLocalFile,
            title:
              name ||
              `${userData.user.first_name} ${userData.user.middle_name} ${userData.user.last_name}`,
            description: role || userData?.user?.role?.description || "Роль",
          }}
          action={
            <Stack direction="row" spacing={0.5} alignItems="center">
              <MDTypography color="text" style={{ fontSize: pxToRem(14) }}>
                {userData?.user.is_banned ? "Разблокировать" : "Заблокировать"}
              </MDTypography>
              <Switch checked={switchValue} onChange={(e) => setSwitchValue(e.target.checked)} />
            </Stack>
          }
          error={!(file || userData?.user?.avatar?.path)}
        />

        <Tabs
          defaultTab={1}
          items={{
            headers: ["Основные"],
            components: [
              <Main
                key="main"
                edit={true}
                isBanned={switchValue}
                initialValues={getInitialValues(userData)}
                type="edit"
                loadFile={loadFile}
                cancelUploadLocalFile={cancelUploadLocalFile}
              />,
            ],
          }}
        />
      </NewUserContext.Provider>
    </PageLayout>
  );
};

export default ViewUser;
