import { useEffect, useState } from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid/Grid";
import AppBar from "@mui/material/AppBar/AppBar";

import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS Base Styles
import breakpoints from "assets/theme/base/breakpoints";

import { IProps } from "./interfaces";

const TabsLayout = (props: IProps) => {
  const { items, defaultTab } = props;

  const [tabsOrientation, setTabsOrientation] = useState<"horizontal" | "vertical">("horizontal");
  const [tabValue, setTabValue] = useState<number>(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event: any, newValue: number) => setTabValue(newValue);

  const changeTab = (tabIndex: number) => {
    return () => setTabValue(tabIndex);
  };

  const { headers, components } = items;

  useEffect(() => {
    if (!defaultTab) {
      setTabValue(defaultTab || 0);
      return;
    }

    setTabValue(defaultTab - 1);
  }, [defaultTab]);

  return (
    <MDBox
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        maxWidth: "100%",
      }}
    >
      <Grid container>
        <Grid item style={{ width: tabsOrientation === "horizontal" ? "auto" : "100%" }}>
          <AppBar position="static">
            <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
              {headers?.map((header: string, index: number) => (
                <Tab
                  label={header}
                  key={index}
                  style={{
                    whiteSpace: tabsOrientation === "horizontal" ? "nowrap" : "wrap",
                    padding: "0 10px",
                  }}
                />
              ))}
            </Tabs>
          </AppBar>
        </Grid>
      </Grid>
      {components[tabValue]}
    </MDBox>
  );
};

export default TabsLayout;
