import { useEffect, useState } from "react";
import { IFilter } from "layouts/pages/widgets/components/TopPanel/interfaces";
import {
  ICounterpartyType,
  useGetCounterpartyTypesQuery,
} from "../../../../Redux/api/counterparty-types.api";
import { IManager, useGetManagersQuery } from "../../../../Redux/api/managers.api";

const statusFilter: IFilter = {
  id: 5,
  title: "Статусы",
  tag: "pay_status",
  url: "",
  isInput: true,
  list: [
    {
      id: 21,
      isInput: true,
      tag: "pay_status",
      name: "Задолж-сть <..",
      url: "&credit_gt=",
    },
    {
      id: 22,
      isInput: true,
      tag: "pay_status",
      name: "Задолж-сть >...",
      url: "&credit_lt=",
    },
    {
      id: 23,
      isInput: false,
      tag: "pay_status",
      name: "Отсутств",
      url: "&credit=0",
    },
  ],
  exclusive: true,
};

const addFilers = (types: ICounterpartyType[], managers: IManager[]) => {
  const typesFilter: IFilter = {
    id: 1,
    title: "Тип",
    tag: "counterparty_type_id",
    url: "&counterparty_type_id[]=",
    isInput: false,
    list: types.map((type) => ({
      id: type.id,
      isInput: false,
      tag: "counterparty_type_id",
      name: type.name,
      url: `${type.id}`,
    })),
    exclusive: false,
  };

  const managersFilter: IFilter = {
    id: 1,
    title: "Тип",
    tag: "manager_id",
    url: "&manager_id[]=",
    isInput: false,
    list: managers.map((manager) => ({
      id: manager.id,
      isInput: false,
      tag: "manager_id",
      name: manager.name,
      url: `${manager.id}`,
    })),
    exclusive: false,
  };
  return [typesFilter, managersFilter, statusFilter];
};

export const useCounterpartyFilters = () => {
  const [filters, setFilters] = useState<IFilter[]>([]);
  const { data: types, isFetching: isTypesFetching } = useGetCounterpartyTypesQuery();
  const { data: managers, isFetching: isManagerssFetching } = useGetManagersQuery();

  useEffect(() => {
    if (types && managers) {
      setFilters(addFilers(types.counterpartyTypes, managers.managers));
    }
  }, [types, managers]);

  if (isTypesFetching || isManagerssFetching) return null;

  return filters;
};
