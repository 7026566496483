import { Card, useTheme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import MDTypography from "components/MDTypography";
import dayjs, { Dayjs } from "dayjs";
import { useFormikContext } from "formik";
import Input from "layouts/pages/widgets/components/Input";
import { useStyles } from "layouts/pages/widgets/components/TopPanel/styles";
import { IAddOrder } from "Redux/api/orders.api";
import CalendarIcon from "assets/images/calendar-range.svg";
import pxToRem from "assets/theme/functions/pxToRem";

interface IOrderAsideProps {
  onChange: () => void;
}

const IconCustom = () => <img src={CalendarIcon} />;

const OrderAside = ({ onChange }: IOrderAsideProps) => {
  const { values, errors, setFieldValue } = useFormikContext<IAddOrder>();
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <Card sx={{ px: 4, py: 4, display: "flex", gap: pxToRem(24) }}>
      <Input
        label="Номер счёта (необязательное)"
        input={{
          type: "number",
          name: "code",
          placeholder: "Введите номер счёта",
          value: values?.invoice_number,
          onChange: (e: any) => setFieldValue("invoice_number", e.target.value),
          error: errors?.invoice_number,
        }}
      />

      <MDTypography fontSize={14} fontWeight="regular" lineHeight="140%" color="text">
        Дата счёта
      </MDTypography>

      <DatePicker
        sx={{ ...styles.date, maxWidth: "100%" }}
        label="Дата от"
        value={values.invoice_start_date ? dayjs(values.invoice_start_date) : undefined}
        onChange={(date: Dayjs) => {
          setFieldValue("invoice_start_date", dayjs(date).format("YYYY-MM-DD"));
        }}
        slots={{ openPickerIcon: IconCustom }}
      />
      <DatePicker
        sx={{ ...styles.date, maxWidth: "100%" }}
        label="Дата до"
        value={values.invoice_end_date ? dayjs(values.invoice_end_date) : undefined}
        onChange={(date: Dayjs) => {
          setFieldValue("invoice_end_date", dayjs(date).format("YYYY-MM-DD"));
        }}
        slots={{ openPickerIcon: IconCustom }}
      />
    </Card>
  );
};

export default OrderAside;
