import { IColumn } from "interfaces/IColumn";
import { api } from ".";
import { IBranch } from "./interfaces";
import { IAvatar, IViewCategory } from "./interfaces/ICategory";
import { IHistory } from "./interfaces/IHistory";
import { IPagination } from "./interfaces/IPagination";
import { INomenclature } from "./nomenclatures.api";
import { ICity } from "./search-city.api";
import { IVat } from "./vats.api";
import { IStorageUnit } from "./warehouses.api";

interface IInventoriesTableResponse {
  columns: IColumn[];
  inventories: IWarehouseNomenclatureTable[];
  pagination: IPagination;
}

export interface IWarehouseNomenclatureTable {
  id: number;
  code: string;
  name: IName;
  warehouse: IName;
  city: string;
  brand: IName;
  vat: string;
  unit: string;
  quantity: number;
  price: string;
  country: string;
}

interface IName {
  icon: string;
  value: string;
}

export interface IInventory {
  id: number;
  code: string;
  avatar: IAvatar;
  warehouse: IWarehouse;
  nomenclature: INomenclature;
  quantity: number | string;
  price: string;
  discount: null | string | number;
  vat: IVat;
  markup: number;
  created_at: string;
  updated_at: string;
}

export interface IWarehouse {
  id: number;
  code: string;
  name: string;
  photo: IAvatar;
  owned_by_us: boolean;
  address: string;
  storage_capacity: number;
  storage_unit: IStorageUnit;
  city: ICity;
  counterparty: null;
}

interface IInventoriesResponse {
  inventories: IInventory[];
  inventoriesCount: number;
}

export interface INewInventory {
  id?: string | number;
  code: string | number;
  warehouse_id?: string | number;
  nomenclature_id: string | number;
  vat_id: string | number;
  quantity: string | number;
  price: string | number;
  discount: string | number;
  markup: string | number;
  avatar_id: string | number;
}

interface IInventoriesHistoryResponse {
  histories: IHistory[];
  historiesCount: number;
}

interface INewInventoryResponse {
  inventory: IInventory;
}

interface INomenclaturesResponse {
  nomenclatures: IInventoryNomenclature[];
  nomenclaturesCount: number;
}

export interface IInventoryNomenclature {
  id: number;
  code: string;
  name: string;
  avatar: IAvatar;
  preview: any;
  description: string;
  category: IViewCategory;
  specification: any;
  type: IType;
  kind: IType;
  branch: IBranch;
  tags: any[];
  photos: IAvatar[];
  created_at: string;
  updated_at: string;
}

interface IType {
  id: number;
  name: string;
  avatar: string;
  created_at: string;
}

export const inventoriesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getInventoriesTable: builder.query<IInventoriesTableResponse, string>({
      query: (queryParams) => `tables/inventories?${queryParams}`,
      transformErrorResponse: (response: any) => response.data.message,
    }),
    getInventories: builder.query<IInventoriesResponse, string>({
      query: (queryParams) => `inventories?${queryParams}`,
      transformErrorResponse: (response: any) => response.data.message,
    }),
    searchInventories: builder.mutation<IInventoriesResponse, string>({
      query: (query) => ({
        url: `inventories?query=${query}`,
        method: "GET",
      }),
      transformErrorResponse: (response: any) => response.data.message,
    }),
    getInventoriesById: builder.query<INewInventoryResponse, string | number>({
      query: (id) => `inventories/${id}`,
      providesTags: ["inventories"],
      transformErrorResponse: (response: any) => response.data.message,
    }),
    addInventories: builder.mutation<INewInventoryResponse, INewInventory>({
      query: ({ ...body }) => ({
        url: `inventories`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["inventories"],
      transformErrorResponse: (response: any) => response.data.message,
    }),
    editInventories: builder.mutation<INewInventoryResponse, INewInventory>({
      query: ({ id, ...body }) => ({
        url: `inventories/${id}`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["inventories"],
      transformErrorResponse: (response: any) => response.data.message,
    }),
    getInventoriesHistory: builder.query<IInventoriesHistoryResponse, string | number>({
      query: (id) => `inventories/${id}/history`,
      transformErrorResponse: (response: any) => response.data.message,
    }),
    getNomenclatures: builder.query<INomenclaturesResponse, string>({
      query: (queryParams) => `nomenclatures?${queryParams}`,
      transformErrorResponse: (response: any) => response.data.message,
    }),
  }),
});

export const {
  useGetInventoriesTableQuery,
  useGetInventoriesQuery,
  useSearchInventoriesMutation,
  useGetInventoriesByIdQuery,
  useAddInventoriesMutation,
  useEditInventoriesMutation,
  useGetInventoriesHistoryQuery,
  useGetNomenclaturesQuery,
} = inventoriesApi;
