import { useEffect, useContext, ChangeEvent } from "react";
import { useFormikContext } from "formik";
import MDContainer from "components/MDContainer";
import Block from "layouts/pages/widgets/components/Block";
import Input from "layouts/pages/widgets/components/Input";
import { WarehouseNameContext } from "../../context/warehouse-name-context";
import Card from "@mui/material/Card";
import AddressAutocompleteField from "layouts/components/fields/address-autocomplete-field";
import ContentWrapper from "layouts/components/content-wrapper";
import StorageUnitsField from "./storage-units-field";
import DeliverersField from "./deliverers-field";
import CitiesField from "./cities-field";
import Integrations from "../integrations";

interface IEditFieldsProps {
  disabled?: boolean;
  setIsEdit: any;
}

export interface IFormikValues {
  id?: string | number;
  photo_id: number;
  name: string;
  owned_by_us: boolean;
  address: string;
  storage_capacity: number;
  storage_unit_id: number;
  city_id: number;
  counterparty_id: number;
  code: string;
}

const EditFields = ({ disabled = false, setIsEdit }: IEditFieldsProps) => {
  const { setName } = useContext(WarehouseNameContext);
  const { values, errors, setFieldValue } = useFormikContext<IFormikValues>();

  const handlerChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;

    setFieldValue(fieldName, fieldValue);

    if (fieldName === "name") {
      setName(fieldValue);
    }
  };

  useEffect(() => {
    if (values.name?.length > 0) {
      setName(values.name);
    }
  }, [values.name]);

  return (
    <MDContainer gap={3}>
      <ContentWrapper
        aside={
          <Card sx={{ p: 3 }}>
            <Input
              label="Код (необязательно)"
              input={{
                noPointerEvents: false,
                placeholder: "Введите код",
                value: values.code,
                name: "code",
                onChange: handlerChange,
                focus: () => setIsEdit(true),
                error: errors.code,
              }}
            />
          </Card>
        }
        childMb={1}
      >
        <Block title="Основная информация" wrapperStyles={{ width: "100%" }}>
          <MDContainer gap={3}>
            <Input
              label="Название склада"
              input={{
                noPointerEvents: disabled,
                placeholder: "Введите название склада",
                value: values.name,
                name: "name",
                onChange: handlerChange,
                error: errors.name,
              }}
            />
          </MDContainer>

          <MDContainer gap={3}>
            <DeliverersField
              disabled={disabled}
              initialValueId={values.counterparty_id}
              error={errors.counterparty_id}
              onSelect={(value) => setFieldValue("counterparty_id", value?.id)}
            />
          </MDContainer>

          <MDContainer gap={3}>
            <CitiesField
              disabled={disabled}
              initialValueId={values.city_id}
              error={errors.city_id}
              onSelect={(value) => setFieldValue("city_id", value?.id)}
            />
            <AddressAutocompleteField
              disabled={disabled}
              initialValue={values.address}
              error={errors.address}
              onSelect={(value) => setFieldValue("address", value?.value)}
              label="Адрес склада"
              placeholder="Начните вводить адрес"
            />
          </MDContainer>

          <MDContainer gap={3}>
            <Input
              label="Объем склада"
              input={{
                noPointerEvents: disabled,
                placeholder: "Введите объем склада",
                value: values?.storage_capacity?.toString(),
                name: "storage_capacity",
                onChange: handlerChange,
                error: errors.storage_capacity,
              }}
            />
            <StorageUnitsField
              disabled={disabled}
              initialValueId={values.storage_unit_id}
              error={errors.storage_unit_id}
              onSelect={(value) => setFieldValue("storage_unit_id", value?.id)}
            />
          </MDContainer>
        </Block>
        {values.id && <Integrations warehouseId={values.id} key="integrations" />}
      </ContentWrapper>
    </MDContainer>
  );
};

export default EditFields;
