import Select from "layouts/pages/widgets/components/Select";
import { IItem } from "layouts/pages/widgets/components/Select/interfaces";
import { useEffect, useState } from "react";
import { Application, useGetApplicationsQuery } from "../../../Redux/api/applications.api";

interface IApplicationFieldProps {
  disabled?: boolean;
  error?: string;
  initialId?: number | null;
  onSelect?: (value: IItem) => void;
  label?: string;
  placeholder?: string;
}

const formatTypeToSelectItem = (types?: Application[]): IItem[] => {
  if (!types) return [];
  return types.map((type) => ({
    id: type.id,
    label: type.name,
    value: type.id,
    icon: type.photo?.path,
  }));
};

const ApplicationField = ({
  disabled,
  error,
  initialId,
  onSelect,
  label = "Источник",
  placeholder = "Начните ввод названия источника",
}: IApplicationFieldProps) => {
  const { data } = useGetApplicationsQuery(null, { refetchOnMountOrArgChange: true });
  const [selectedTypeOption, setSelectedTypeOption] = useState<IItem | null>(null);

  const typeOptions = formatTypeToSelectItem(data?.applications);

  useEffect(() => {
    const typeOption = typeOptions.find((item) => item.id == initialId);
    if (typeOption) {
      setSelectedTypeOption(typeOption);
    }
  }, [typeOptions, initialId, disabled]);

  const onSelectItem = (value: IItem) => {
    setSelectedTypeOption(value);
    onSelect(value);
  };

  return (
    <Select
      label={label}
      placeholder={placeholder}
      noPointerEvents={disabled}
      error={error}
      popupIcon={disabled ? null : undefined}
      select={{
        value: selectedTypeOption,
        items: typeOptions,
        onSelect: onSelectItem,
      }}
    />
  );
};

export default ApplicationField;
