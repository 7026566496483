import MDAlert from "components/MDAlert";
import {
  IAddBankRequisite,
  useAddCounterpartyBankRequisiteByIdMutation,
  useEditCounterpartyBankRequisiteByIdMutation,
  useGetCounterpartyBankRequisiteByIdQuery,
} from "../../../../Redux/api/counterperties.api";
import MDContainer from "components/MDContainer";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import BankFields from "../../../components/fieldsets/bank-fieldset";
import MDButton from "components/MDButton";
import { Card } from "@mui/material";
import { Add } from "@mui/icons-material";
import pxToRem from "assets/theme/functions/pxToRem";
import { IBankRequisite } from "Redux/api/interfaces";
import { companyBanksValidationScheme } from "../schemas";
import EditContainer from "../../../components/edit-container";
import FormControls from "../../../components/form-controls";
import AddUpdateAlerts from "layouts/components/add-update-alerts";
import ContentWrapper from "layouts/components/content-wrapper";

const Bank = () => {
  const [enableAdd, setEnableAdd] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(null);

  const { id } = useParams();
  const { data: bankData, isError, refetch } = useGetCounterpartyBankRequisiteByIdQuery(id);

  const [addBank, addResult] = useAddCounterpartyBankRequisiteByIdMutation();
  const [editBank, editResult] = useEditCounterpartyBankRequisiteByIdMutation();

  const banks = useMemo(() => {
    const data: IBankRequisite[] = [];
    if (bankData && bankData.bankRequisites.length) {
      data.push(...bankData.bankRequisites);
    } else {
      setEnableAdd(true);
    }
    if (enableAdd) {
      data.push({
        name: "",
        bik: "",
        kpp: "",
        payment_account: "",
        address: "",
      });
    }
    return data;
  }, [bankData, enableAdd]);

  useEffect(() => {
    setEnableAdd(false);
    setSelectedIndex(null);
  }, [bankData]);

  const addBankHandler = () => {
    setEnableAdd(true);
  };

  const clearDataHandler = () => {
    refetch();
    setEnableAdd(false);
    setSelectedIndex(null);
  };

  const onEditHandler = (index: number) => {
    setSelectedIndex((i) => (i === index ? null : index));
    setEnableAdd(false);
  };

  if (isError) {
    return (
      <MDAlert dismissible color="error">
        Не удалось загрузить данные
      </MDAlert>
    );
  }

  return (
    <>
      <MDContainer gap={1} variant="column" xs={{ mb: 8 }}>
        {banks?.map((bank, index) => (
          <ContentWrapper
            key={bank.payment_account}
            aside={
              <Card sx={{ p: 3 }}>
                <MDButton
                  color="primary"
                  size="small"
                  style={{ padding: 0, width: "100%" }}
                  variant="gradient"
                >
                  {`Р/с ${index + 1}`}
                </MDButton>
              </Card>
            }
          >
            <MDContainer
              style={{ marginBottom: "1rem" }}
              horizontalAligment="center"
              variant="column"
              gap={3}
            >
              <Formik
                key={bank.payment_account + (selectedIndex === index ? "selected" : "")}
                initialValues={
                  {
                    ...bank,
                    counterpartyId: id,
                  } as unknown as IAddBankRequisite
                }
                validationSchema={companyBanksValidationScheme}
                onSubmit={selectedIndex === index && bank.payment_account ? editBank : addBank}
              >
                {() => (
                  <Form style={{ width: "100%" }}>
                    <EditContainer
                      showButton={!!bank.payment_account}
                      buttonColor={selectedIndex === index ? "secondary" : "error"}
                      buttonAction={() => onEditHandler(index)}
                    >
                      <BankFields
                        disabled={selectedIndex !== index && bank.payment_account !== ""}
                      />
                    </EditContainer>

                    {(selectedIndex === index || bank.payment_account === "") && (
                      <FormControls cancelAction={clearDataHandler} />
                    )}
                  </Form>
                )}
              </Formik>
            </MDContainer>
          </ContentWrapper>
        ))}

        {!enableAdd && (
          <ContentWrapper>
            <MDContainer horizontalAligment="center" verticalAligment="center" gap={3}>
              <MDButton
                color="primary"
                size="small"
                style={{ padding: 0, minWidth: pxToRem(32) }}
                onClick={addBankHandler}
                variant="gradient"
              >
                <Add />
              </MDButton>
            </MDContainer>
          </ContentWrapper>
        )}
      </MDContainer>
      <AddUpdateAlerts
        isEditError={editResult.isError}
        isEditSuccess={editResult.isSuccess}
        isAddError={addResult.isError}
        isAddSuccess={addResult.isSuccess}
        editErrorMessage={editResult.error as string}
        addErrorMessage={addResult.error as string}
      />
    </>
  );
};

export default Bank;
