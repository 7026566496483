import { Form, Formik } from "formik";
import { routes } from "constants/route";
import AddUpdateAlerts from "layouts/components/add-update-alerts";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContentWrapper from "layouts/components/content-wrapper";
import EditContainer from "layouts/components/edit-container";
import FormControls from "layouts/components/form-controls";
import {
  IAddOrder,
  useCreateOrderMutation,
  useEditOrderMutation,
} from "../../../../Redux/api/orders.api";
import MainFieldset from "./main-fieldset";
import OrderAside from "./order-aside";
import DocumentsFieldset from "./documents-fieldset";
import { mainValidationScheme } from "../schemas/mainValidationScheme";
import { OrderContext } from "../context/order-context";
import { IMain } from "./interfaces";
import { delay } from "utils/delay";

type BlockType = "main" | "contract" | null;

interface IFormikValues extends IAddOrder {}

const Main = (props: IMain) => {
  const { initialValues, edit = false, type, loadFile, cancelUploadLocalFile } = props;

  const { file } = useContext(OrderContext);

  const [editBlock, setEditBlock] = useState<BlockType>(null);
  const [isAsideChanged, setIsAsideChanged] = useState(false);

  const [createOrder, createResult] = useCreateOrderMutation();
  const [editOrder, editResult] = useEditOrderMutation();

  const navigate = useNavigate();

  const createResultHandler = async () => {
    if (createResult.isSuccess && createResult?.data?.order.id) {
      await delay(1000);
      navigate(routes.ORDER_LIST + "/" + createResult?.data?.order.id);
    }
  };

  useEffect(() => {
    createResultHandler();
  }, [createResult]);

  const editHandler = (values: IFormikValues) => {
    editOrder(values);
    setEditBlock(null);
    setIsAsideChanged(false);
  };

  const handleSubmit = async (values: IAddOrder) => {
    let loadedFile = null;
    if (file) loadedFile = await loadFile();
    if (loadedFile) values.avatar_id = loadedFile.id;
    cancelUploadLocalFile();
    type === "add" ? createOrder(values) : editHandler(values);
  };

  return (
    <>
      <Formik<IFormikValues>
        initialValues={initialValues}
        validationSchema={mainValidationScheme}
        onSubmit={handleSubmit}
        validateOnMount={false}
      >
        {() => (
          <ContentWrapper aside={<OrderAside onChange={() => setIsAsideChanged(true)} />}>
            <Form style={{ width: "100%", display: "flex", flexDirection: "column", gap: 24 }}>
              <EditContainer
                showButton={edit}
                buttonColor={editBlock === "main" ? "secondary" : "error"}
                buttonAction={() => setEditBlock((prev) => (prev === "main" ? null : "main"))}
              >
                <MainFieldset disabled={edit && editBlock !== "main"} />
              </EditContainer>

              <EditContainer
                showButton={edit}
                buttonColor={editBlock === "contract" ? "secondary" : "error"}
                buttonAction={() =>
                  setEditBlock((prev) => (prev === "contract" ? null : "contract"))
                }
              >
                <DocumentsFieldset disabled={edit && editBlock !== "contract"} />
              </EditContainer>
              {(!edit || (edit && (editBlock || isAsideChanged)) || file) && (
                <FormControls cancelRoute={routes.ORDER_LIST} />
              )}
            </Form>
          </ContentWrapper>
        )}
      </Formik>
      <AddUpdateAlerts
        isEditError={editResult.isError}
        isEditSuccess={editResult.isSuccess}
        isAddError={createResult.isError}
        isAddSuccess={createResult.isSuccess}
        editErrorMessage={editResult.error as string}
        addErrorMessage={createResult.error as string}
      />
    </>
  );
};

export default Main;
