import { IUserRole } from "../../../Redux/api/interfaces";
import { useGetRolesQuery } from "../../../Redux/api/user-roles.api";
import Select from "layouts/pages/widgets/components/Select";
import { IItem } from "layouts/pages/widgets/components/Select/interfaces";
import { useEffect, useMemo, useState } from "react";

interface IUserRoleFieldProps {
  disabled?: boolean;
  error?: string;
  initialValueId?: number | string;
  onSelect?: (value: IItem) => void;
  label?: string;
}

const formatToSelectItem = (roles?: IUserRole[]): IItem[] => {
  if (!roles) return [];
  return roles.map((role) => ({
    id: role.id,
    label: role.description,
    value: role.id,
  }));
};

const UserRoleField = ({
  disabled,
  error,
  initialValueId,
  onSelect,
  label,
}: IUserRoleFieldProps) => {
  const { data } = useGetRolesQuery(null, { refetchOnMountOrArgChange: true });
  const [selectedOption, setSelectedOption] = useState<IItem | null>(null);
  const options = useMemo(() => formatToSelectItem(data?.roles), [data]);

  useEffect(() => {
    const initialOption = options.find((item) => item.id == initialValueId);
    if (initialOption) {
      setSelectedOption(initialOption);
    }
  }, [options, initialValueId, disabled]);

  const onSelectItem = (value: IItem) => {
    setSelectedOption(value);
    onSelect(value);
  };

  return (
    <Select
      label={label || "Роль"}
      placeholder="Выберите из списка"
      noPointerEvents={disabled}
      error={error}
      popupIcon={disabled ? null : undefined}
      select={{
        value: selectedOption,
        items: options,
        onSelect: onSelectItem,
      }}
    />
  );
};

export default UserRoleField;
