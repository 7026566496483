import { Autocomplete } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import DebouncedInput from "layouts/pages/widgets/components/DebouncedInput";
import { IResponsibleUsers } from "Redux/api/interfaces";
import { useGetResponsibleUserMutation } from "../../../Redux/api/branches.api";

interface IResponsibleAutocompleteFieldProps {
  disabled?: boolean;
  onBlur?: () => void;
  onSelect?: (value: IResponsibleUsers) => void;
  error?: string;
  value?: string;
  initialValue?: string;
  label?: string;
  placeholder?: string;
}

const ResponsibleAutocompleteField = ({
  disabled,
  onBlur,
  onSelect,
  error,
  initialValue,
  label = "Ответственный (необязательно)",
  placeholder = "Поиск ответственного",
}: IResponsibleAutocompleteFieldProps) => {
  const [searchResponsible, resultResponsible] = useGetResponsibleUserMutation();

  const [responsibleUser, setResponsibleUser] = useState<string>("");
  const [selectedResponsibleUser, setSelectedResponsibleUser] = useState<any>(null);

  const responsibleOptions = resultResponsible?.data?.users || [];

  const onResponsibleSelect = (e: any, newValue: IResponsibleUsers | null) => {
    setSelectedResponsibleUser(newValue);

    if (newValue && onSelect) {
      onSelect(newValue);
    }
  };

  const responsibleRef = useRef<string>("");
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (initialValue) {
      setSelectedResponsibleUser({
        value: initialValue,
        name: initialValue,
      });
    }
  }, [initialValue]);

  useEffect(() => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    if (responsibleUser.length > 2 && responsibleUser !== responsibleRef.current) {
      responsibleRef.current = responsibleUser;

      timeoutIdRef.current = setTimeout(() => {
        searchResponsible(responsibleUser);
      }, 500);
    }
  }, [responsibleUser, searchResponsible]);

  return (
    <Autocomplete
      style={{ width: "100%", pointerEvents: disabled ? "none" : "auto" }}
      isOptionEqualToValue={(op, val) => op.last_name === val.last_name}
      getOptionLabel={(option: any) =>
        option?.first_name ? `${option.first_name} ${option.last_name}` : option.value
      }
      options={responsibleOptions}
      filterOptions={(x) => x}
      popupIcon={disabled ? null : undefined}
      id="responsible_user"
      noOptionsText="Нет результатов"
      onBlur={onBlur}
      onChange={onResponsibleSelect}
      value={selectedResponsibleUser}
      renderInput={(params) => (
        <DebouncedInput
          label={label}
          debounceTimeout={500}
          handleDebounce={setResponsibleUser}
          isLoading={resultResponsible.isLoading}
          input={{
            ...params,
            noPointerEvents: disabled,
            placeholder: placeholder,
            value: responsibleUser,
            error: error,
          }}
        />
      )}
    />
  );
};

export default ResponsibleAutocompleteField;
