import Select from "layouts/pages/widgets/components/Select";
import { IItem } from "layouts/pages/widgets/components/Select/interfaces";
import { useEffect, useMemo, useState } from "react";
import { IObject, useGetObjectsQuery } from "../../../Redux/api/objects.api";

interface IObjectIdFieldProps {
  disabled?: boolean;
  error?: string;
  initialObjectId?: number | null;
  onSelect?: (value: IItem) => void;
}

const formatTypeToSelectItem = (types?: IObject[]): IItem[] => {
  if (!types) return [];
  return types.map((type) => ({
    id: type.id,
    label: type.name,
    value: type.id,
    icon: type.avatar,
  }));
};

const ObjectField = ({ disabled, error, initialObjectId, onSelect }: IObjectIdFieldProps) => {
  const { data } = useGetObjectsQuery(null, { refetchOnMountOrArgChange: true });
  const [selectedTypeOption, setSelectedTypeOption] = useState<IItem | null>(null);

  const typeOptions = formatTypeToSelectItem(data?.objects);

  useEffect(() => {
    const typeOption = typeOptions.find((item) => item.id == initialObjectId);
    if (typeOption) {
      setSelectedTypeOption(typeOption);
    }
  }, [typeOptions, initialObjectId, disabled]);

  const onSelectItem = (value: IItem) => {
    setSelectedTypeOption(value);
    onSelect(value);
  };

  return (
    <Select
      label="Проект/Объект (необязательно)"
      placeholder="Выберите из списка"
      noPointerEvents={disabled}
      error={error}
      popupIcon={disabled ? null : undefined}
      select={{
        value: selectedTypeOption,
        items: typeOptions,
        onSelect: onSelectItem,
      }}
    />
  );
};

export default ObjectField;
