import { IAddress } from "../../../../../Redux/api/search-address.api";
import { useFormikContext } from "formik";
import MDContainer from "components/MDContainer";
import DatePicker from "layouts/pages/widgets/components/DatePicker";
import Block from "layouts/pages/widgets/components/Block";
import Input from "layouts/pages/widgets/components/Input";
import { useContext, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { IItem } from "layouts/pages/widgets/components/Select/interfaces";
import AddressAutocompleteField from "layouts/components/fields/address-autocomplete-field";
import CounterpartyTypeField from "layouts/components/fields/counterparty-type-field";
import { CounterpartyNameContext } from "../../context/counterparty-name-context";

export interface IFormikValues {
  id?: number | string;
  avatar_id?: number | string;
  first_name: string;
  last_name: string;
  middle_name: string;
  birthday: string;
  phone_1: string;
  phone_2: string;
  email: string;
  address: string;
  counterparty_type_id: number;
}

interface IMainPhysicalFieldsProps {
  disabled?: boolean;
}

const MainPhysicalFields = ({ disabled }: IMainPhysicalFieldsProps) => {
  const [firstName, setFirstName] = useState<string>("");
  const [middleName, setMiddleName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const { setName } = useContext(CounterpartyNameContext);

  const { values, errors, setFieldValue, validateForm, initialValues } =
    useFormikContext<IFormikValues>();

  const onAddressSelect = (value: IAddress) => {
    setFieldValue("address", value?.value || "");
  };

  const onCounnterpartyTypeSelect = (value: IItem) => {
    setFieldValue("counterparty_type_id", value.id);
  };

  useEffect(() => {
    setEmail(initialValues.email);
    setFirstName(initialValues.first_name);
    setMiddleName(initialValues.middle_name);
    setLastName(initialValues.last_name);
  }, []);

  useEffect(() => {
    if (disabled) {
      setName("");
    } else if (lastName || firstName || middleName) {
      setName(`${lastName ?? ""} ${firstName ?? ""} ${middleName ?? ""}`);
    }
  }, [lastName, firstName, middleName, disabled]);

  return (
    <Block title={"Основная информация"} wrapperStyles={{ width: "100%" }}>
      <MDContainer gap={3}>
        <Input
          label="Фамилия"
          input={{
            noPointerEvents: disabled,
            name: "last_name",
            placeholder: "Введите фамилию",
            value: lastName,
            onChange: (e: any) => setLastName(e.target.value),
            blur: () => setFieldValue("last_name", lastName),
            error: errors.last_name,
          }}
        />
        <Input
          label="Имя"
          input={{
            noPointerEvents: disabled,
            name: "first_name",
            placeholder: "Введите имя",
            value: firstName,
            onChange: (e: any) => setFirstName(e.target.value),
            blur: () => setFieldValue("first_name", firstName),
            error: errors.first_name,
          }}
        />
      </MDContainer>

      <MDContainer gap={3}>
        <Input
          label="Отчество"
          input={{
            noPointerEvents: disabled,
            name: "middle_name",
            placeholder: "Введите  отчество",
            value: middleName,
            onChange: (e: any) => setMiddleName(e.target.value),
            defaultValue: initialValues.middle_name,
            blur: () => setFieldValue("middle_name", middleName),
            error: errors.middle_name,
          }}
        />
        <DatePicker
          disabled={disabled}
          label="Дата рождения"
          placeholder="Введите дату рождения"
          value={values.birthday}
          name="birthday"
          error={errors.birthday}
          onChange={([date]: Dayjs[]) => {
            setFieldValue("birthday", dayjs(date).format("YYYY-MM-DD"));
          }}
        />
      </MDContainer>

      <MDContainer gap={3}>
        <Input
          label="Телефон"
          mask="+7 (999) 999 99 99"
          input={{
            noPointerEvents: disabled,
            name: "phone_1",
            placeholder: "+0 (000) 000 00 00",
            defaultValue: initialValues.phone_1,
            blur: (value) => {
              setFieldValue("phone_1", value);
            },
            error: errors.phone_1,
          }}
        />
        <Input
          label="Телефон (необязательно)"
          mask="+7 (999) 999 99 99"
          input={{
            noPointerEvents: disabled,
            name: "phone_2",
            placeholder: "+0 (000) 000 00 00",
            defaultValue: initialValues.phone_2,
            blur: (value) => {
              setFieldValue("phone_2", value);
            },
            error: errors.phone_2,
          }}
        />
      </MDContainer>

      <MDContainer gap={3}>
        <AddressAutocompleteField
          disabled={disabled}
          onBlur={() => validateForm(values)}
          onSelect={onAddressSelect}
          error={errors.address}
          initialValue={initialValues.address}
        />
      </MDContainer>

      <MDContainer gap={3}>
        <CounterpartyTypeField
          disabled={disabled}
          error={errors.counterparty_type_id}
          initialTypeId={initialValues.counterparty_type_id}
          onSelect={onCounnterpartyTypeSelect}
        />
        <Input
          label="Почта"
          input={{
            noPointerEvents: disabled,
            name: "email",
            placeholder: "Введите почту",
            value: email,
            onChange: (e: any) => setEmail(e.target.value),
            blur: () => setFieldValue("email", email),
            error: errors.email,
          }}
        />
      </MDContainer>
    </Block>
  );
};

export default MainPhysicalFields;
