import PageLayout from "examples/CardPage";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ContentHeader from "layouts/pages/widgets/components/ContentHeader";
import Tabs from "layouts/pages/widgets/components/Tabs";
import Main from "../components/main";
import avatar from "../image/order.svg";
import { initialValues } from "../consts";
import { useLoadFile } from "hooks/userLoadFile";
import { AVATAR } from "constants/file-types";
import { OrderContext } from "../context/order-context";

const NewOrder = () => {
  const {
    file,
    uploadLocalFile,
    loadFile,
    loadError,
    setLoadError,
    cancelUploadLocalFile,
    filePath,
  } = useLoadFile(AVATAR);

  return (
    <PageLayout header={<DashboardNavbar />}>
      <OrderContext.Provider value={{ file }}>
        <ContentHeader
          info={{
            img: filePath || avatar,
            uploadLocalFile,
            title: "Новый заказ",
          }}
          error={!!loadError}
        />

        <Tabs
          defaultTab={1}
          items={{
            headers: ["Основные"],
            components: [
              <Main
                key="main"
                type="add"
                initialValues={initialValues}
                loadFile={loadFile}
                cancelUploadLocalFile={cancelUploadLocalFile}
                setLoadError={setLoadError}
              />,
            ],
          }}
        />
      </OrderContext.Provider>
    </PageLayout>
  );
};

export default NewOrder;
