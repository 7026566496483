import PageLayout from "examples/CardPage";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Loader from "layouts/components/loader";
import ContentHeader from "layouts/pages/widgets/components/ContentHeader";
import Tabs from "layouts/pages/widgets/components/Tabs";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useGetOrderByIdQuery, useGetOrderHistoryQuery } from "../../../../Redux/api/orders.api";
import Main from "../components/main";
import OrderActionsButton from "../components/order-actions-button";
import OrderFiles from "../components/order-files";
import OrderNomenclatures from "../components/order-nomenclatures";
import avatar from "../image/order.svg";
import History from "layouts/pages/widgets/components/History";
import { getInitialValues } from "../consts";
import { useLoadFile } from "hooks/userLoadFile";
import { AVATAR } from "constants/file-types";
import { OrderContext } from "../context/order-context";

const ViewOrder = () => {
  const [tab, setTab] = useState(1);

  const { id } = useParams<string>();
  const { data, isFetching } = useGetOrderByIdQuery(id, { skip: !id });
  const { file, filePath, uploadLocalFile, loadFile, cancelUploadLocalFile } = useLoadFile(AVATAR);

  if (isFetching && !data?.order) {
    return <Loader />;
  }

  return (
    <PageLayout header={<DashboardNavbar />}>
      <OrderContext.Provider value={{ file }}>
        <OrderActionsButton setTab={setTab} />
        <ContentHeader
          info={{
            img: filePath || data?.order?.avatar?.path || avatar,
            uploadLocalFile,
            title: "Заказ №" + data.order.id,
          }}
        />

        <Tabs
          defaultTab={tab}
          items={{
            headers: ["Основные", "Содержание", "Коммуникации", "Задачи", "Файлы", "История"],
            components: [
              <Main
                key="main"
                edit={true}
                initialValues={getInitialValues(data?.order)}
                type="edit"
                loadFile={loadFile}
                cancelUploadLocalFile={cancelUploadLocalFile}
              />,
              <OrderNomenclatures key="Содержание" />,
              <div key="Коммуникации">Коммуникации</div>,
              <div key="Задачи">Задачи</div>,
              <OrderFiles key="Файлы" />,
              <History key="История" request={useGetOrderHistoryQuery} />,
            ],
          }}
        />
      </OrderContext.Provider>
    </PageLayout>
  );
};

export default ViewOrder;
