import { IPagination } from "./interfaces/IPagination";
import { api } from ".";
import { IBranch } from "./interfaces";
import { IWarehouse } from "./warehouses.api";
import { ISalesStrategy } from "./sales-strategies.api";
import { IFile } from "api/files/new";
import { IColumn } from "interfaces/IColumn";

export interface IApplicationTableItem {
  id: number;
  name: IName;
  type: string;
  date: string;
  creator: ICreator;
}

export interface IName {
  icon: string;
  value: string;
}

export interface ICreator {
  icon: string;
  value: string;
}

export interface Application {
  id: number;
  branch: IBranch;
  is_website: boolean;
  photo: any;
  code: string;
  name: string;
  token: string;
}

export interface Root {
  application: Application;
}

export interface IApplication {
  id: number;
  branch: IBranch;
  is_website: boolean;
  photo: IFile;
  code: string;
  name: string;
  is_token_active: boolean;
  token: string;
  warehouses: IWarehouse[];
  sales_strategy: ISalesStrategy;
}

export interface IAddApplication {
  id?: number | string;
  photo_id?: number | string | null;
  code: string;
  branch_id: number | string | null;
  name: string;
  is_website: boolean;
  warehouse_id: (number | string)[] | null;
  sales_strategy_id: number | string | null;
  is_token_active: boolean;
}

export interface IAutomationTableResponse {
  columns: IAutomationColumn[];
  automations: IAutomation[];
  pagination: IPagination;
}

export interface IAutomationsTableRequest {
  id: string | number;
  query: string;
}

export interface IAutomationColumn {
  id: number;
  table_id: number;
  order: number;
  key: string;
  has_icon: number;
  text: string;
}

export interface IAutomation {
  id: number;
  name: string;
  address: string;
  creator: Creator;
  sales_strategy: string;
  priority: number;
}

export interface Creator {
  icon: string;
  value: string;
}

export interface IPriorityRequest {
  appId: string | number;
  warehouseId: string | number;
}

export interface IApplpicationsResponse {
  applications: Application[];
  applicationsCount: number;
}

export interface IApplpicationsTableResponse {
  columns: IColumn[];
  applications: IApplicationTableItem[];
  pagination: IPagination;
}

export interface IApplpicationResponse {
  application: IApplication;
}

export interface IAppHistoryResponse {
  histories: IAppHistory[];
  historiesCount: number;
}

export interface IAppHistory {
  id: number;
  icon: string;
  color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light";
  title: string;
  description: string;
  datetime: string;
}

export const applicationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getApplicationsTable: builder.query<IApplpicationsTableResponse, string>({
      query: (queryParams) => `tables/applications?${queryParams}`,
      transformErrorResponse: (response: any) => response?.data?.message || "",
      providesTags: ["applications"],
    }),
    getApplications: builder.query<IApplpicationsResponse, void>({
      query: () => `applications`,
      transformErrorResponse: (response: any) => response?.data?.message || "",
      providesTags: ["applications"],
    }),
    getApplicationById: builder.query<IApplpicationResponse, number | string>({
      query: (id) => `applications/${id}`,
      transformErrorResponse: (response: any) => response?.data?.message || "",
      providesTags: ["applications"],
    }),
    createApplication: builder.mutation<IApplpicationResponse, IAddApplication>({
      query: (body) => ({
        url: "applications",
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: any) => response?.data?.message || "",
      invalidatesTags: ["applications"],
    }),
    editApplication: builder.mutation<IApplpicationResponse, IAddApplication>({
      query: (body) => {
        const data = { ...body };
        if (!data.photo_id) {
          delete data.photo_id;
        }
        return {
          url: `applications/${body.id}`,
          method: "PUT",
          body: data,
        };
      },
      transformErrorResponse: (response: any) => response?.data?.message || "",
      invalidatesTags: ["applications"],
    }),
    getAutomationsTable: builder.query<IAutomationTableResponse, IAutomationsTableRequest>({
      query: ({ id, query }) => `tables/applications/${id}/automations?${query}`,
      transformErrorResponse: (response: any) => response?.data?.message || "",
      providesTags: ["applications"],
    }),
    upWarehusePriority: builder.mutation<{ message: string }, IPriorityRequest>({
      query: ({ appId, warehouseId }) => ({
        url: `applications/${appId}/warehouses/${warehouseId}/up`,
        method: "POST",
      }),
      invalidatesTags: ["applications"],
    }),
    downWarehusePriority: builder.mutation<{ message: string }, IPriorityRequest>({
      query: ({ appId, warehouseId }) => ({
        url: `applications/${appId}/warehouses/${warehouseId}/down`,
        method: "POST",
      }),
      invalidatesTags: ["applications"],
    }),
    getApplicationsHistory: builder.query<IAppHistoryResponse, string | number>({
      query: (id) => `applications/${id}/history`,
      providesTags: ["applications"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    getApplicationsByQuery: builder.mutation<IApplpicationsResponse, string>({
      query: (query) => ({
        url: `applications?query=${query}`,
        method: "GET",
      }),
      transformErrorResponse: (response: any) => response.data.message,
    }),
    addCategory: builder.mutation<
      any,
      {
        application_id: number;
        category_id: number;
      }
    >({
      query: ({ application_id, category_id }) => ({
        url: `applications/${application_id}/categories`,
        method: "POST",
        body: {
          category_id,
        },
      }),
      invalidatesTags: ["category"],
    }),
  }),
});

export const {
  useGetApplicationsTableQuery,
  useGetApplicationByIdQuery,
  useGetApplicationsQuery,
  useCreateApplicationMutation,
  useEditApplicationMutation,
  useGetAutomationsTableQuery,
  useUpWarehusePriorityMutation,
  useDownWarehusePriorityMutation,
  useGetApplicationsHistoryQuery,
  useGetApplicationsByQueryMutation,
  useAddCategoryMutation,
} = applicationsApi;
