import Select from "layouts/pages/widgets/components/Select";
import { IItem } from "layouts/pages/widgets/components/Select/interfaces";
import { useEffect, useState } from "react";
import { IManager, useGetManagersQuery } from "../../../Redux/api/managers.api";

interface IManagerFieldProps {
  disabled?: boolean;
  error?: string;
  initialValueId?: number | null;
  onSelect?: (value: IItem) => void;
}

const formatTypeToSelectItem = (types?: IManager[]): IItem[] => {
  if (!types) return [];
  return types.map((type) => ({
    id: type.id,
    label: type.name,
    value: type.id,
    // icon: type.avatar, ??
  }));
};

const ManagerField = ({ disabled, error, initialValueId, onSelect }: IManagerFieldProps) => {
  const { data } = useGetManagersQuery(null, { refetchOnMountOrArgChange: true });
  const [selectedTypeOption, setSelectedTypeOption] = useState<IItem | null>(null);

  const typeOptions = formatTypeToSelectItem(data?.managers);

  useEffect(() => {
    const typeOption = typeOptions.find((item) => item.id == initialValueId);
    if (typeOption) {
      setSelectedTypeOption(typeOption);
    }
  }, [typeOptions, initialValueId, disabled]);

  const onSelectItem = (value: IItem) => {
    setSelectedTypeOption(value);
    onSelect(value);
  };

  return (
    <Select
      label="Менеджер"
      placeholder="Выберите из списка"
      noPointerEvents={disabled}
      error={error}
      popupIcon={disabled ? null : undefined}
      select={{
        value: selectedTypeOption,
        items: typeOptions,
        onSelect: onSelectItem,
      }}
    />
  );
};

export default ManagerField;
