import {
  ISalesStrategy,
  useGetSalesStrategiesQuery,
} from "../../../Redux/api/sales-strategies.api";
import Select from "layouts/pages/widgets/components/Select";
import { IItem } from "layouts/pages/widgets/components/Select/interfaces";
import { useEffect, useMemo, useState } from "react";

interface ISalesStrategyFieldProps {
  disabled?: boolean;
  error?: string;
  initialValueId?: number | string;
  onSelect?: (value: IItem) => void;
}

const formatToSelectItem = (items?: ISalesStrategy[]): IItem[] => {
  if (!items) return [];
  return items.map((type) => ({
    id: type.id,
    label: type.name,
    value: type.id,
  }));
};

const SalesStrategyField = ({
  disabled,
  error,
  initialValueId,
  onSelect,
}: ISalesStrategyFieldProps) => {
  const { data } = useGetSalesStrategiesQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const [selectedTypeOption, setSelectedTypeOption] = useState<IItem | null>(null);
  const typeOptions = useMemo(() => formatToSelectItem(data?.salesStrategies), [data]);

  useEffect(() => {
    const typeOption = typeOptions.find((item) => item.id == initialValueId);
    if (typeOption) {
      setSelectedTypeOption(typeOption);
    }
  }, [typeOptions]);

  const onSelectItem = (value: IItem) => {
    setSelectedTypeOption(value);
    onSelect(value);
  };

  return (
    <Select
      label="Стратегия"
      placeholder="Выберите из списка"
      noPointerEvents={disabled}
      error={error}
      popupIcon={disabled ? null : undefined}
      select={{
        value: selectedTypeOption,
        items: typeOptions,
        onSelect: onSelectItem,
      }}
    />
  );
};

export default SalesStrategyField;
