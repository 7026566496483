import { useGetRolesQuery } from "../../../../Redux/api/user-roles.api";
import { useGetDepartmentsQuery } from "../../../../Redux/api/departments.api";
import { useGetBranchesQuery } from "../../../../Redux/api/branches.api";
import { IUserRole, IBranch, IDepartment } from "../../../../Redux/api/interfaces";

import { useEffect, useState } from "react";
import { IFilter } from "layouts/pages/widgets/components/TopPanel/interfaces";

const hierarchyFilter: IFilter = {
  id: 0,
  title: "Иерархия",
  tag: "hierarchy",
  url: "&hierarchy=",
  isInput: false,
  list: [
    {
      id: 11,
      isInput: false,
      tag: "hierarchy",
      name: "Сотрудник",
      url: "employee",
    },
    {
      id: 12,
      isInput: false,
      tag: "hierarchy",
      name: "Руководитель",
      url: "manager",
    },
  ],
  exclusive: true,
};

const statusFilter: IFilter = {
  id: 5,
  title: "Статусы",
  tag: "status",
  url: "",
  isInput: true,
  list: [
    {
      id: 21,
      isInput: true,
      tag: "status",
      name: "В системе <..",
      url: "&hours_in_system_gt=",
    },
    {
      id: 22,
      isInput: true,
      tag: "status",
      name: "В системе >...",
      url: "&hours_in_system_lt=",
    },
    {
      id: 23,
      isInput: true,
      tag: "status",
      name: "Отсутств >...",
      url: "&hours_absent_system_gt=",
    },
  ],
  exclusive: true,
};

const addFilers = (
  filters: IFilter[],
  roles: IUserRole[],
  branches: IBranch[],
  departments: IDepartment[]
) => {
  const businesFilters: IFilter = {
    id: 1,
    title: "Бизнес",
    tag: "branch",
    url: "&branch_id[]=",
    isInput: false,
    list: branches.map((branch) => ({
      id: branch.id,
      isInput: false,
      tag: "branch",
      name: branch.title,
      url: `${branch.id}`,
    })),
    exclusive: false,
  };
  const roleFilters: IFilter = {
    id: 2,
    title: "Роль",
    tag: "role",
    url: "&role_id[]=",
    isInput: false,
    list: roles.map((role) => ({
      id: role.id,
      isInput: false,
      tag: "role",
      name: role.description,
      url: `${role.id}`,
    })),
    exclusive: false,
  };
  const departmentFilters: IFilter = {
    id: 3,
    title: "Отделы",
    tag: "department",
    url: "&department_id[]=",
    isInput: false,
    list: departments.map((department) => ({
      id: department.id,
      isInput: false,
      tag: "department",
      name: department.name,
      url: `${department.id}`,
    })),
    exclusive: false,
  };

  return [...filters, businesFilters, roleFilters, departmentFilters, statusFilter];
};

export const useUserFilters = () => {
  const [filters, setFilters] = useState<IFilter[]>([hierarchyFilter]);
  const { data: roles, isFetching: isRolesFetching } = useGetRolesQuery();
  const { data: departments, isFetching: isDepartmentsFetching } = useGetDepartmentsQuery();
  const { data: branches, isFetching: isBranchesFetching } = useGetBranchesQuery();

  useEffect(() => {
    if (roles && departments && branches) {
      setFilters((old) => {
        return addFilers(old, roles.roles, branches.branches, departments.departments);
      });
    }
  }, [roles, departments, branches]);

  if (isRolesFetching || isDepartmentsFetching || isBranchesFetching) return null;

  return filters;
};
