import { InternalAxiosRequestConfig } from "axios";

export const requestSuccessInterceptor = (
  value: InternalAxiosRequestConfig<any>
): InternalAxiosRequestConfig<any> | Promise<InternalAxiosRequestConfig<any>> => {
  const token = localStorage.getItem("token");
  value.headers.Authorization = `Bearer ${token}`;

  return value;
};
