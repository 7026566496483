import { api } from ".";

export interface IAddress {
  name: string;
  value: string;
}

export interface ISearhAddressRequest {
  query: string;
}

export interface IAddressResponse {
  addresses: IAddress[];
  addressesCount: number;
}

export const searchAddressApi = api.injectEndpoints({
  endpoints: (builder) => ({
    searchAddressByValue: builder.mutation<IAddressResponse, ISearhAddressRequest>({
      query: (body) => ({
        url: `search_address`,
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: any) => response.data.message,
    }),
  }),
});

export const { useSearchAddressByValueMutation } = searchAddressApi;
