import { IViewCategory } from "../../../Redux/api/interfaces/ICategory";
import { useGetCategoriesQuery } from "../../../Redux/api/categories.api";
import Select from "layouts/pages/widgets/components/Select";
import { IItem } from "layouts/pages/widgets/components/Select/interfaces";
import { useEffect, useState, useMemo } from "react";

interface ICategoryFieldProps {
  disabled?: boolean;
  error?: string;
  initialValueId?: number | string;
  onSelect?: (value: IItem) => void;
  label?: string;
}

const formatToSelectItem = (items?: IViewCategory[]): IItem[] => {
  if (!items) return [];
  return items.map((item) => ({
    id: item.id,
    label: item.name,
    value: item.id,
    icon: item.avatar?.path,
  }));
};

const CategoryField = ({
  disabled,
  error,
  initialValueId,
  onSelect,
  label = "Категория",
}: ICategoryFieldProps) => {
  const { data } = useGetCategoriesQuery(null, { refetchOnMountOrArgChange: true });
  const [selectedOption, setSelectedOption] = useState<IItem | null>(null);
  const options = useMemo(() => formatToSelectItem(data?.categories), [data]);

  useEffect(() => {
    const typeOption = options.find((item) => item.id == initialValueId);
    if (typeOption) {
      setSelectedOption(typeOption);
    }
  }, [options]);

  const onSelectItem = (value: IItem) => {
    setSelectedOption(value);
    onSelect(value);
  };

  return (
    <Select
      label={label}
      placeholder="Поиск по категории"
      noPointerEvents={disabled}
      error={error}
      popupIcon={disabled ? null : undefined}
      select={{
        value: selectedOption,
        items: options,
        onSelect: onSelectItem,
      }}
    />
  );
};

export default CategoryField;
