import { Box } from "@mui/material";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import Popup from "layouts/components/popup";
import OrderNomenclatureField from "./order-nomenclature-field";
import SearchInventoriesField from "./search-inventories-field";
import { useEffect, useState } from "react";
import { IInventory, useGetInventoriesByIdQuery } from "../../../../Redux/api/inventories.api";
import { useGetVatsQuery } from "../../../../Redux/api/vats.api";

interface INomenclaturesPopupProps {
  isOpen: boolean;
  onClose: () => void;
  editId?: string | number;
  inventoryId?: number | string;
  vat?: string;
  price?: string;
  quantity?: number;
}

const NomenclaturesPopup = ({
  isOpen,
  onClose,
  editId,
  inventoryId,
  vat,
  price,
  quantity,
}: INomenclaturesPopupProps) => {
  const [item, setItem] = useState<IInventory | null>(null);

  const { data, isSuccess } = useGetInventoriesByIdQuery(inventoryId, { skip: !inventoryId });
  const { data: vatsData, isSuccess: isVatSuccess } = useGetVatsQuery();

  useEffect(() => {
    if (data?.inventory && isSuccess && vatsData && isVatSuccess) {
      const vatValue = vatsData?.vats?.find((v) => v.name.toLowerCase() === vat.toLowerCase());

      setItem({
        ...data.inventory,
        vat: vat ? vatValue : data.inventory.vat,
        price: price ? price : data.inventory.price,
        quantity: quantity ? quantity : data.inventory.quantity,
      });
    }
  }, [data, isSuccess]);

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      title="Добавление новой позиции"
      titleIcon={
        <ContentPasteOutlinedIcon
          sx={{ mr: 1, width: 16, height: 16, transform: "translateY(3px)" }}
        />
      }
      width={1150}
      header={<SearchInventoriesField onSelect={(item) => setItem(item)} />}
    >
      <Box sx={{ display: "flex", gap: 1, flexDirection: "column", padding: "8px 0" }}>
        {item ? (
          <OrderNomenclatureField
            item={item}
            onDelete={() => setItem(null)}
            onClose={onClose}
            editId={editId}
          />
        ) : null}
      </Box>
    </Popup>
  );
};

export default NomenclaturesPopup;
