import { Card } from "@mui/material";
import { DeleteFile } from "api/files/new";
import MDAlert from "components/MDAlert";
import { ITableOption } from "examples/Tables/DataTable";
import useFilterParams from "hooks/useFilterParams";
import Loader from "layouts/components/loader";
import { Table } from "layouts/pages/widgets/components/Table";
import { useParams } from "react-router-dom";
import { useGetOrderFilesTableQuery } from "../../../../Redux/api/orders.api";

const OrderFiles = () => {
  const { id } = useParams();
  const { data, isFetching, isError, error, refetch, isLoading } = useGetOrderFilesTableQuery(id, {
    skip: !id,
  });
  const { setQuery, setPage, setPerPage, query, page, perPage } = useFilterParams();

  const optionsDots: ITableOption[] = [
    {
      id: 1,
      text: "Удалить этот файл",
      isId: true,
      action: (id: string | number) => {
        DeleteFile(id).then(() => refetch());
      },
    },
  ];

  if (isFetching) {
    return <Loader />;
  }

  return (
    <Card>
      {isError && (
        <MDAlert color="error" dismissible>
          {JSON.stringify(error)}
        </MDAlert>
      )}
      <Table
        title="Файлы заказа"
        accessorKey="files"
        data={data}
        filters={{
          setQuery,
          setPage,
          setPerPage,
          query,
          page,
          perPage,
        }}
        optionsDots={optionsDots}
        isLoading={isLoading || isFetching}
      />
    </Card>
  );
};

export default OrderFiles;
