import { api } from ".";

export interface IObjectResponse {
  objects: IObject[];
  objectsCount: number;
}

export interface IObject {
  id: number;
  name: string;
  avatar: string;
  created_at: string;
}

export const objectsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getObjects: builder.query<IObjectResponse, void>({
      query: () => `objects`,
      transformErrorResponse: (response: any) => response.data.message,
    }),
  }),
});

export const { useGetObjectsQuery } = objectsApi;
