import { useGetCounterpartiesQuery } from "../../../../../Redux/api/counterperties.api";
import {
  ICompanyCounterparty,
  IPhysicalCounterparty,
} from "../../../../../Redux/api/interfaces/ICounterparty";
import Select from "layouts/pages/widgets/components/Select";
import { IItem } from "layouts/pages/widgets/components/Select/interfaces";
import { useEffect, useMemo, useState } from "react";

interface IDeliverersFieldProps {
  disabled?: boolean;
  error?: string;
  initialValueId?: number | string;
  onSelect?: (value: IItem) => void;
}

const formatToSelectItem = (
  counterparties?: (ICompanyCounterparty | IPhysicalCounterparty)[]
): IItem[] => {
  if (!counterparties) return [];
  return counterparties.map((counterparty: ICompanyCounterparty | IPhysicalCounterparty) => ({
    id: counterparty.id,
    label:
      "name" in counterparty
        ? counterparty.name
        : `${counterparty.first_name} ${counterparty.last_name}`,
    value: counterparty.id,
  }));
};

const DeliverersField = ({ disabled, error, initialValueId, onSelect }: IDeliverersFieldProps) => {
  const { data } = useGetCounterpartiesQuery("", { refetchOnMountOrArgChange: true });

  const [selectedOption, setSelectedOption] = useState<IItem | null>(null);

  const options = useMemo(
    () =>
      formatToSelectItem(
        data?.counterparties?.filter((counterparty) => counterparty.type.id === 2)
      ),
    [data]
  );

  useEffect(() => {
    const option = options.find((item) => item.id == initialValueId);
    if (option) {
      setSelectedOption(option);
    }
  }, [options]);

  const onSelectItem = (value: IItem) => {
    setSelectedOption(value);
    onSelect(value);
  };

  return (
    <Select
      label="Поставщик (необязательно)"
      placeholder="Начните вводить название"
      noPointerEvents={disabled}
      error={error}
      popupIcon={disabled ? null : undefined}
      select={{
        value: selectedOption,
        items: options,
        onSelect: onSelectItem,
      }}
    />
  );
};

export default DeliverersField;
