import { api } from ".";

export interface IStorageUnit {
  id: number;
  name: string;
  abbreviation: string;
}

export interface IStorageUnitsResponse {
  units: IStorageUnit[];
  unitsCount: number;
}

export const storageUnitsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getStorageUnits: builder.query<IStorageUnitsResponse, void>({
      query: () => ({
        url: `storage_units`,
      }),
      transformErrorResponse: (response: any) => response.data.message,
    }),
  }),
});

export const { useGetStorageUnitsQuery } = storageUnitsApi;
