import { IStorageUnit, useGetStorageUnitsQuery } from "../../../../../Redux/api/storage_units.api";
import Select from "layouts/pages/widgets/components/Select";
import { IItem } from "layouts/pages/widgets/components/Select/interfaces";
import { useEffect, useMemo, useState } from "react";

interface IStorageUnitsFieldProps {
  disabled?: boolean;
  error?: string;
  initialValueId?: number | string;
  onSelect?: (value: IItem) => void;
}

const formatToSelectItem = (units?: IStorageUnit[]): IItem[] => {
  if (!units) return [];
  return units.map((unit: IStorageUnit) => ({
    id: unit.id,
    label: unit.name,
    value: unit.id,
  }));
};

const StorageUnitsField = ({
  disabled,
  error,
  initialValueId,
  onSelect,
}: IStorageUnitsFieldProps) => {
  const { data } = useGetStorageUnitsQuery(null, { refetchOnMountOrArgChange: true });
  const [selectedOption, setSelectedOption] = useState<IItem | null>(null);
  const options = useMemo(() => formatToSelectItem(data?.units), [data]);

  useEffect(() => {
    const option = options.find((item) => item.id == initialValueId);
    if (option) {
      setSelectedOption(option);
    }
  }, [options]);

  const onSelectItem = (value: IItem) => {
    setSelectedOption(value);
    onSelect(value);
  };

  return (
    <Select
      label="Ед. изм"
      placeholder="Выберите из списка"
      noPointerEvents={disabled}
      error={error}
      popupIcon={disabled ? null : undefined}
      select={{
        value: selectedOption,
        items: options,
        onSelect: onSelectItem,
      }}
    />
  );
};

export default StorageUnitsField;
