import { api } from ".";
import { IHistoryDate } from "./branches.api";
import { IAvatar } from "./interfaces/ICategory";
import { IPagination } from "./interfaces/IPagination";
import { IWarehouse } from "./inventories.api";
import { IStorageUnit } from "./storage_units.api";
import { IColumn } from "./interfaces/IColumn";

interface IShopsTableResponse {
  columns: IColumn[];
  shops: ITableShop[];
  pagination: IPagination;
}

interface IShopsResponse {
  shops: IShopsResponseItem[];
  shopsCount: number;
}

export interface IShopsResponseItem {
  id: number;
  photo: IAvatar;
  name: string;
  address: string;
  phone: string;
  schedule: string;
}

interface ITableShop {
  id: number;
  name: ITableShopItem;
  branch: ITableShopItem;
  city: string;
  address: string;
  creator: ITableShopItem;
}

interface ITableShopItem {
  icon: string;
  value: string;
}

interface IShopByIdResponse {
  shop: IShopById;
}

export interface IShopById {
  id: number;
  is_shop: boolean;
  avatar: IAvatar;
  code: string;
  name: string;
  branches: IShopByIdBranch[];
  warehouses: IWarehouse[];
  address: string;
  phone: string;
}

interface IShopByIdBranch {
  id: number;
  code: string;
  name: string;
  photo: IAvatar;
  owned_by_us: boolean;
  address: string;
  storage_capacity: string | number;
  storage_unit: IStorageUnit;
  city: any;
  counterparty: any;
}

export interface INewShop {
  id?: number | string;
  avatar_id: number | string;
  is_shop: boolean;
  code: string;
  name: string;
  address: string;
  branches: number[];
  warehouses: number[];
  phone: string;
}

interface IMessage {
  message: string;
}

interface IShopHistoryResponse {
  histories: IHistoryDate[];
  historiesCount: number;
}

interface IHoursResponse {
  hours: IHour[];
  hoursCount: number;
}

interface IHourResponse {
  hour: IHour[];
}

interface IHour {
  shopId?: string | number;
  id?: number | string;
  day_of_week: string;
  is_working_day: boolean;
  work_start_time: string;
  work_end_time: string;
  break_start_time: string | null;
  break_end_time: string | null;
}

export interface IDefaultRequest {
  shopId: string | number;
  id: number | string;
}

interface IHolidaysResponse {
  holidays: IHoliday;
}

interface IHoliday {
  work_start_time: string;
  work_end_time: string;
  break_start_time: string;
  break_end_time: string;
}

export interface IHolidaysRequest extends IHoliday {
  shopId: string | number;
}

interface IGetExceptionsResponse {
  exceptions: IExceptions[];
  exceptionsCount: number;
}

interface IExceptionsResponse {
  exception: IExceptions;
}

interface IExceptions {
  id: number;
  exception_start_date: string;
  exception_end_date: string;
}

export interface INewException {
  shopId?: string | number;
  id?: string | number;
  exception_start_date: string;
  exception_end_date: string;
}

export const shopsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getShopsTable: builder.query<IShopsTableResponse, string>({
      query: (queryParams) => `tables/shops?${queryParams}`,
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    getShops: builder.query<IShopsResponse, string>({
      query: (queryParams) => `shops?${queryParams}`,
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    getShopById: builder.query<IShopByIdResponse, string | number>({
      query: (id) => `shops/${id}`,
      providesTags: ["shops"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    addShop: builder.mutation<IShopByIdResponse, INewShop>({
      query: (body) => ({
        url: "shops",
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: any) => response?.data?.message || "",
      invalidatesTags: ["shops"],
    }),
    editShop: builder.mutation<IShopByIdResponse, INewShop>({
      query: ({ id, ...body }) => ({
        url: `shops/${id}`,
        method: "PUT",
        body,
      }),
      transformErrorResponse: (response: any) => response?.data?.message || "",
      invalidatesTags: ["shops"],
    }),
    deleteShop: builder.mutation<IMessage, string | number>({
      query: (id) => ({
        url: `shops/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["shops"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    getShopHistory: builder.query<IShopHistoryResponse, string | number>({
      query: (id) => `shops/${id}/history`,
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    getShopHours: builder.query<IHoursResponse, string | number>({
      query: (shopId) => `shops/${shopId}/hours`,
      providesTags: ["shopHours"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    addShopHours: builder.mutation<IHourResponse, IHour>({
      query: ({ shopId, ...body }) => ({
        url: `shops/${shopId}/hours`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["shopHours"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    editShopHours: builder.mutation<IHourResponse, IHour>({
      query: ({ shopId, id, ...body }) => ({
        url: `shops/${shopId}/hours/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["shopHours"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    deleteShopHours: builder.mutation<IMessage, IDefaultRequest>({
      query: ({ shopId, id }) => ({
        url: `shops/${shopId}/hours/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["shopHours"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    getShopHolidays: builder.query<IHolidaysResponse, string | number>({
      query: (shopId) => `shops/${shopId}/holidays`,
      providesTags: ["shopHolidays"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    editShopHolidays: builder.mutation<IHolidaysResponse, IHolidaysRequest>({
      query: ({ shopId, ...body }) => ({
        url: `shops/${shopId}/holidays`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["shopHolidays"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    getShopExceptions: builder.query<IGetExceptionsResponse, string | number>({
      query: (shopId) => `shops/${shopId}/exceptions`,
      providesTags: ["shopExceptions"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    addShopExceptions: builder.mutation<IExceptionsResponse, INewException>({
      query: ({ shopId, ...body }) => ({
        url: `shops/${shopId}/exceptions`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["shopExceptions"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    editShopExceptions: builder.mutation<IExceptionsResponse, INewException>({
      query: ({ shopId, id, ...body }) => ({
        url: `shops/${shopId}/exceptions/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["shopExceptions"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    deleteShopExceptions: builder.mutation<IMessage, IDefaultRequest>({
      query: ({ shopId, id }) => ({
        url: `shops/${shopId}/exceptions/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["shopExceptions"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
  }),
});

export const {
  useGetShopsTableQuery,
  useGetShopsQuery,
  useGetShopByIdQuery,
  useAddShopMutation,
  useEditShopMutation,
  useDeleteShopMutation,
  useGetShopHistoryQuery,
  useGetShopHoursQuery,
  useAddShopHoursMutation,
  useEditShopHoursMutation,
  useDeleteShopHoursMutation,
  useGetShopHolidaysQuery,
  useEditShopHolidaysMutation,
  useGetShopExceptionsQuery,
  useAddShopExceptionsMutation,
  useEditShopExceptionsMutation,
  useDeleteShopExceptionsMutation,
} = shopsApi;
