import { useEffect, useState } from "react";
import { IFilter } from "layouts/pages/widgets/components/TopPanel/interfaces";
import { useGetCategoriesFilterResponsibleUsersQuery } from "../../../../Redux/api/categories.api";
import { IResponsibleUsers } from "Redux/api/interfaces";

const addFilers = (responsibleUsers: IResponsibleUsers[]) => {
  const creatorsFilters: IFilter = {
    id: 1,
    title: "Создал",
    tag: "creator_id",
    url: "&creator_id[]=",
    isInput: false,
    list: responsibleUsers.map(({ id, name }) => ({
      id: id,
      isInput: false,
      tag: "branch",
      name: name,
      url: `${id}`,
    })),
    exclusive: false,
  };

  return [creatorsFilters];
};

export const useCategoriesFilters = () => {
  const [filters, setFilters] = useState<IFilter[]>([]);
  const { data: responsibleUsers } = useGetCategoriesFilterResponsibleUsersQuery();
  useEffect(() => {
    if (responsibleUsers) setFilters(addFilers(responsibleUsers.users));
  }, [responsibleUsers]);

  return filters;
};
