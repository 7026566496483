import { useFormikContext } from "formik";
import MDContainer from "components/MDContainer";
import Block from "layouts/pages/widgets/components/Block";
import { useEffect, useState } from "react";
import BranchField from "layouts/components/fields/branch-field";

import CounterpartyAutocompleteField from "layouts/components/fields/counterparty-field";
import { IAddOrder } from "Redux/api/orders.api";
import DeliveryTypeField from "layouts/components/fields/delivery-types-field";
import AddressAutocompleteField from "layouts/components/fields/address-autocomplete-field";
import ObjectField from "layouts/components/fields/object-field";
import SalesChanelsField from "layouts/components/fields/sales-chanel-field";
import ManagerField from "layouts/components/fields/manager-field";
import PromotionField from "layouts/components/fields/promotion-field";
import ApplicationField from "layouts/components/fields/application-field";
import { IItem } from "layouts/pages/widgets/components/Select/interfaces";
import ShopsField from "./shops-field";

interface IMainFieldsetProps {
  disabled?: boolean;
  avatarId?: number | string;
}

const MainFieldset = ({ disabled, avatarId }: IMainFieldsetProps) => {
  const [isSelfDelivery, setIsSelfDelivery] = useState(false);
  const { values, errors, setFieldValue, initialValues } = useFormikContext<IAddOrder>();

  useEffect(() => {
    if (avatarId) {
      setFieldValue("avatar_id", avatarId);
    }
  }, [avatarId]);

  const updateDeliveryType = (v: IItem) => {
    if (v?.label === "Самовывоз") {
      setIsSelfDelivery(true);
    } else {
      setIsSelfDelivery(false);
    }
    setFieldValue("delivery_type_id", v?.id);
  };

  useEffect(() => {
    if (initialValues.delivery_type_id === 1) {
      setIsSelfDelivery(true);
    } else {
      setIsSelfDelivery(false);
    }
  }, [initialValues]);

  return (
    <>
      <Block title={"Основная информация"} wrapperStyles={{ width: "100%" }}>
        <MDContainer gap={3}>
          <CounterpartyAutocompleteField
            disabled={disabled}
            onSelect={(value) => setFieldValue("counterparty_id", value?.value || null)}
            error={errors.counterparty_id}
            initialId={initialValues.counterparty_id}
          />
        </MDContainer>
        <MDContainer gap={3}>
          <DeliveryTypeField
            disabled={disabled}
            error={errors.delivery_type_id}
            initialTypeId={initialValues.delivery_type_id}
            onSelect={updateDeliveryType}
          />
          {isSelfDelivery ? (
            <ShopsField
              disabled={disabled}
              error={errors.shop_id}
              onSelect={(v) => {
                setFieldValue("address", v.value);
                setFieldValue("shop_id", v.id);
              }}
              initialValue={initialValues.shop_id}
            />
          ) : (
            <AddressAutocompleteField
              initialValue={initialValues.address}
              disabled={disabled}
              onSelect={(value) => setFieldValue("address", value?.value || null)}
              error={errors.address}
              label="Адрес доставки/монтажа"
            />
          )}
        </MDContainer>
        <MDContainer gap={3}>
          <BranchField
            disabled={disabled}
            error={errors.branch_id}
            initialValueId={initialValues.branch_id}
            onSelect={(value) => setFieldValue("branch_id", value?.id)}
          />
          <ObjectField
            disabled={disabled}
            error={errors.object_id}
            initialObjectId={initialValues.object_id}
            onSelect={(value) => setFieldValue("object_id", value?.id)}
          />
        </MDContainer>
        <MDContainer gap={3}>
          <ApplicationField
            initialId={values.application_id}
            disabled={disabled}
            onSelect={(value) => setFieldValue("application_id", value?.value || null)}
            error={errors.application_id}
          />
          <SalesChanelsField
            disabled={disabled}
            error={errors.sales_channel_id}
            initialValueId={initialValues.sales_channel_id}
            onSelect={(value) => setFieldValue("sales_channel_id", value?.id)}
          />
        </MDContainer>
        <MDContainer gap={3}>
          <ManagerField
            disabled={disabled}
            error={errors.manager_id}
            initialValueId={initialValues.manager_id}
            onSelect={(value) => setFieldValue("manager_id", value?.id)}
          />
          <PromotionField
            disabled={disabled}
            error={errors.promotion_id}
            initialValueId={initialValues.promotion_id}
            onSelect={(value) => setFieldValue("promotion_id", value?.id)}
          />
        </MDContainer>
      </Block>
    </>
  );
};

export default MainFieldset;
