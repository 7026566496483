import { Form, Formik } from "formik";
import AddUpdateAlerts from "layouts/components/add-update-alerts";
import EditContainer from "layouts/components/edit-container";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { INewCategory } from "Redux/api/interfaces/ICategory";
import { routes } from "../../../../constants";
import {
  useCreateCategoryMutation,
  useEditCategoryMutation,
} from "../../../../Redux/api/categories.api";
import FormControls from "../../../components/form-controls";
import { CategoryNameContext } from "../context/category-name-context";
import { fieldsValidationScheme } from "../schemas";
import AddFields from "./blocks/addFields";
import EditFields from "./blocks/editsFields";
import { IMain } from "./interfaces";
import { delay } from "utils/delay";

const Main = (props: IMain) => {
  const {
    initialValues,
    edit = false,
    type,
    loadFile,
    cancelUploadLocalFile,
    loadPhotoFile,
    cancelPhotoUploadLocalFile,
    setLoadError,
  } = props;

  const { file, photo } = useContext(CategoryNameContext);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [createCategory, createResult] = useCreateCategoryMutation();
  const [editCategory, editResult] = useEditCategoryMutation();

  const navigate = useNavigate();

  const createResultHandler = async () => {
    if (createResult.isSuccess && createResult?.data?.category.id) {
      await delay(1000);
      navigate(routes.CATEGORIES + "/" + createResult?.data?.category.id);
    }
  };

  useEffect(() => {
    createResultHandler();
  }, [createResult]);

  const editHandler = (values: INewCategory) => {
    editCategory(values);
    setIsEdit(false);
  };

  const getFieldsContent = () => {
    if (type == "add") {
      return <AddFields setIsEdit={setIsEdit} />;
    } else {
      return <EditFields disabled={edit && !isEdit} setIsEdit={setIsEdit} />;
    }
  };

  const handleSubmit = async (values: INewCategory) => {
    let loadedFile = null;
    let loadedPhotoFile = null;
    if (file) loadedFile = await loadFile();
    if (photo) loadedPhotoFile = await loadPhotoFile();
    if (loadedFile) values.avatar_id = loadedFile.id;
    if (loadedPhotoFile) values.photo_id = loadedPhotoFile.id;
    cancelUploadLocalFile();
    cancelPhotoUploadLocalFile();
    type === "add" ? createCategory(values) : editHandler(values);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={fieldsValidationScheme}
        onSubmit={handleSubmit}
        validateOnMount={false}
        validate={() => {
          if (!file && !edit) {
            setLoadError?.("Загрузите avatar");
          }
        }}
      >
        {() => (
          <Form style={{ width: "100%" }}>
            <EditContainer
              showButton={edit}
              buttonColor={isEdit ? "secondary" : "error"}
              buttonAction={() => setIsEdit((prev) => !prev)}
            >
              {getFieldsContent()}
              {(isEdit || !edit || file) && <FormControls cancelRoute={routes.CATEGORIES} />}
            </EditContainer>
          </Form>
        )}
      </Formik>
      <AddUpdateAlerts
        isEditError={editResult.isError}
        isEditSuccess={editResult.isSuccess}
        isAddError={createResult.isError}
        isAddSuccess={createResult.isSuccess}
        editErrorMessage={editResult.error as string}
        addErrorMessage={createResult.error as string}
      />
    </>
  );
};

export default Main;
