/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ru from "dayjs/locale/ru";

import App from "App";

// Material Dashboard 2 PRO React TS Context Provider
import { MaterialUIControllerProvider } from "context";
import AuthGuard from "AuthGuard";

// Redux
import { Provider } from "react-redux";
import { store, persistor } from "./Redux/store";
import { PersistGate } from "redux-persist/integration/react";
import ErrorBoundary from "hoc/ErrorBoundary";
import { Toaster } from "react-hot-toast";

const root = createRoot(document.getElementById("root") as Element);

root.render(
  <ErrorBoundary>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ru.name}>
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <AuthGuard>
                <Toaster position="bottom-right" reverseOrder={false} />
                <App />
              </AuthGuard>
            </PersistGate>
          </Provider>
        </LocalizationProvider>
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </ErrorBoundary>
);
