/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router components
import { Link, useLocation } from "react-router-dom";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";

// Material Dashboard 2 PRO React TS components
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Images
import logo from "assets/images/logo.svg";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarDesktopMenu,
  navbarIconButton,
  navbarMobileMenu,
  navbarRow,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import { Theme, useMediaQuery, useTheme } from "@mui/material";
import MDTypography from "components/MDTypography";
import { setMiniSidenav, setOpenConfigurator, useMaterialUIController } from "context";

// Declaring prop types for DashboardNavbar
interface Props {
  light?: boolean;
  isMini?: boolean;
  button?: React.ReactElement;
}

type TNavbar = "fixed" | "absolute" | "relative" | "static" | "sticky";

function DashboardNavbar(props: Props): JSX.Element {
  const { light, isMini, button } = props;

  // const [navbarType, setNavbarType] = useState<TNavbar>("static");
  const [openMenu, setOpenMenu] = useState<any>(false);

  const [controller, dispatch] = useMaterialUIController();

  const { miniSidenav, transparentNavbar, openConfigurator, darkMode } = controller;

  const route = useLocation().pathname.split("/").slice(1);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event: any) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  const iconsStyle = (params: Theme) => {
    const {
      palette: { dark, white, text },
      functions: { rgba },
    }: {
      palette: any;
      functions: any;
    } = params;

    return {
      color: () => {
        let colorValue = light || darkMode ? white.main : dark.main;

        if (transparentNavbar && !light) {
          colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
        }

        return colorValue;
      },
    };
  };

  return (
    <AppBar
      // position={absolute ? "absolute" : navbarType}
      position="static"
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, light, darkMode })}
      style={button ? { top: 20 } : undefined}
    >
      <Toolbar sx={navbarContainer}>
        <MDBox color="inherit" sx={(theme) => navbarRow(theme, { isMobile })}>
          {isMobile ? (
            <MDBox display="flex" alignItems="center" gap={1.25}>
              {<MDBox component="img" src={logo} alt="Brand" width="2rem" />}
              <MDBox>
                <MDTypography component="h6" variant="button" fontWeight="medium">
                  Gigma
                </MDTypography>
              </MDBox>
            </MDBox>
          ) : (
            <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
          )}
          <IconButton sx={navbarDesktopMenu} onClick={handleMiniSidenav} size="small" disableRipple>
            <Icon fontSize="medium" sx={iconsStyle}>
              {miniSidenav ? "menu_open" : "menu"}
            </Icon>
          </IconButton>
        </MDBox>

        {!isMini && (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            {/* <MDBox pr={1}>
              <MDInput placeholder="Search here" />
            </MDBox> */}
            <MDBox color={light ? "white" : "inherit"}>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>

              <Link to="/authentication/sign-in/basic">
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Link>

              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton>

              <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                onClick={handleOpenMenu}
              >
                <MDBadge badgeContent={9} color="primary" size="xs" circular>
                  <Icon sx={iconsStyle}>notifications</Icon>
                </MDBadge>
              </IconButton>

              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
      {button}
    </AppBar>
  );
}

// Declaring default props for DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

export default DashboardNavbar;
