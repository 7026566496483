import { ILink } from "../interfaces";

export const links: ILink[] = [
  {
    title: "Gigma",
    href: "",
  },
  {
    title: "О нас",
    href: "",
  },
  {
    title: "Блог",
    href: "",
  },
  {
    title: "Лицензия",
    href: "",
  },
];
