/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { FC, forwardRef } from "react";

import { OutlinedTextFieldProps, StandardTextFieldProps } from "@mui/material";

import MDInputRoot from "components/MDInput/MDInputRoot";

interface Props extends Omit<OutlinedTextFieldProps | StandardTextFieldProps, "variant"> {
  variant?: "standard" | "outlined";
  error?: boolean;
  success?: boolean;
  disabled?: boolean;
}

const MDInput: FC<Props | any> = forwardRef((props, ref) => {
  const { error, success, disabled, errorMessage, ...rest } = props;

  return (
    <MDInputRoot
      {...rest}
      ref={ref}
      ownerState={{
        error,
        success,
        disabled,
      }}
      error={!!error}
      helperText={error || (error && errorMessage)}
    />
  );
});

MDInput.defaultProps = {
  error: false,
  success: false,
  disabled: false,
};

export default MDInput;
