import { postHandler } from "api/handlers";
import { deleteHandler } from "api/handlers/delete";
import { INewFile } from "api/interfaces";

export interface IFileResponse {
  file: IFile;
}

export interface IFile {
  id: number;
  name: string;
  type: IType;
  path: string;
  created_at: string;
  updated_at: string;
}

interface IType {
  id: number;
  name: string;
  avatar: string;
  created_at: string;
}

export const NewFile = async (params: INewFile) => {
  const formdata = new FormData();
  formdata.append("file", params.file);
  formdata.append("file_type_id", `${params.fileTypeId}`);

  const { data } = await postHandler("/files", formdata, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return data as unknown as IFileResponse;
};

export const NewOrderFile = async (orderId: string, file: File) => {
  const formdata = new FormData();
  formdata.append("file", file);

  const { data } = await postHandler(`/orders/${orderId}/files`, formdata, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return data as unknown as IFileResponse;
};

export const DeleteFile = async (fileId: string | number) => {
  const { data } = await deleteHandler(`/files/${fileId}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return data as unknown as IFileResponse;
};
