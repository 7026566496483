import dayjs, { Dayjs } from "dayjs";
import { useFormikContext } from "formik";

import { NewFile } from "api/files/new";

import MDContainer from "components/MDContainer";

import Block from "layouts/pages/widgets/components/Block";
import DatePicker from "layouts/pages/widgets/components/DatePicker";
import LoadInput from "layouts/pages/widgets/components/LoadInput";
import { IAddUser } from "../../../../Redux/api/users.api";
import { useContext, useEffect, useState } from "react";
import { NewUserContext } from "../context/new-user-context";

const EMPLOYMENT_CONTRACT_ID = 1;

interface IJobPlacesmentFieldsProps {
  disabled?: boolean;
}

const JobPlacesmentFields = ({ disabled }: IJobPlacesmentFieldsProps) => {
  const { values, setFieldValue, setFieldError, errors } = useFormikContext<IAddUser>();
  const [fileName, setFileName] = useState("");
  const { contractFile } = useContext(NewUserContext);

  const loadFile = async (loadedFile: File) => {
    try {
      const { file } = await NewFile({
        file: loadedFile,
        fileTypeId: EMPLOYMENT_CONTRACT_ID,
      });

      if (file) {
        setFileName(file.name);
        setFieldValue("employment_contract_id", file.id);
      }
    } catch (e) {
      setFieldError("employment_contract_id", "Ошибка при загрузке файла");
    }
  };

  useEffect(() => {
    if (contractFile?.id) {
      setFileName(contractFile.name);
      setFieldValue("employment_contract_id", contractFile.id);
    }
  }, [contractFile]);

  return (
    <Block title="Трудоустройство" id="user-card" wrapperStyles={{ width: "100%" }}>
      <MDContainer variant="column" gap={3}>
        <MDContainer gap={3}>
          <LoadInput
            label="Трудовой договор (необязательно)"
            noPointerIvents={disabled}
            input={{
              value: fileName,
              placeholder: "Добавить договор",
              name: "employment_contract_id",
              error: errors["employment_contract_id"] as string,
            }}
            handler={loadFile}
          />

          <DatePicker
            disabled={disabled}
            label="Дата приема на работу (необязательно)"
            placeholder="Выберите дату приема"
            value={values.employment_date || ""}
            name="employment_date"
            error={errors["employment_date"]}
            onChange={([date]: Dayjs[]) => {
              setFieldValue("employment_date", dayjs(date).format("YYYY-MM-DD"));
            }}
          />
        </MDContainer>

        <DatePicker
          disabled={disabled}
          label="Дата увольнения (необязательно)"
          placeholder="Выберите дату увольнения"
          name="dismissal_date"
          value={values.dismissal_date || ""}
          onChange={([date]: Dayjs[]) => {
            setFieldValue("dismissal_date", dayjs(date).format("YYYY-MM-DD"));
          }}
        />
      </MDContainer>
    </Block>
  );
};

export default JobPlacesmentFields;
