import { Stack, Switch } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import MDTypography from "components/MDTypography";
import PageLayout from "examples/CardPage";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
  useGetCounterpartyByIdQuery,
  useGetCounterpartyHistoryQuery,
} from "../../../../Redux/api/counterperties.api";
import ContentHeader from "layouts/pages/widgets/components/ContentHeader";
import Tabs from "layouts/pages/widgets/components/Tabs";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../../components/loader";
import History from "../../../pages/widgets/components/History";
import Bank from "../components/bank";
import Main from "../components/main";
import Orders from "../components/orders";
import { CounterpartyNameContext } from "../context/counterparty-name-context";
import { getCounterpartyTitle, isCounterpartyCompany } from "../utils";
import { getMainCompanyInitialValues, getMainPhysicalInitialValues } from "../consts";
import { useLoadFile } from "hooks/userLoadFile";
import { AVATAR } from "constants/file-types";

const ViewCounterparty = () => {
  const [name, setName] = useState("");

  const { id } = useParams<string>();
  const { data: counterparty, isFetching } = useGetCounterpartyByIdQuery(id);
  const { file, filePath, uploadLocalFile, loadFile, cancelUploadLocalFile } = useLoadFile(AVATAR);

  const isCompany = isCounterpartyCompany(counterparty);

  if (isFetching && !counterparty?.counterparty) {
    return <Loader />;
  }

  return (
    <PageLayout header={<DashboardNavbar />}>
      <CounterpartyNameContext.Provider value={{ setName, isCompany, file }}>
        <ContentHeader
          info={{
            img: filePath || counterparty?.counterparty?.avatar?.path,
            uploadLocalFile,
            title: name || getCounterpartyTitle(counterparty) || "",
          }}
          action={
            <Stack direction="row" spacing={0.5} alignItems="center">
              <MDTypography color="text" style={{ fontSize: pxToRem(14) }}>
                {isCompany ? "Юридическое лицо" : "Физическое лицо"}
              </MDTypography>

              <Switch checked={isCompany} />
            </Stack>
          }
          error={!(file || counterparty?.counterparty?.avatar?.path)}
        />

        <Tabs
          defaultTab={1}
          items={{
            headers: ["Основные", "Реквизиты", "Заказы", "Коммуникации", "История"],
            components: [
              <Main
                key="main"
                edit={true}
                initialValues={
                  isCompany
                    ? getMainCompanyInitialValues(counterparty)
                    : getMainPhysicalInitialValues(counterparty)
                }
                type="edit"
                loadFile={loadFile}
                cancelUploadLocalFile={cancelUploadLocalFile}
              />,
              <Bank key="bank" />,
              <Orders key="orders" />,
              <div key="communication">Коммуникации</div>,
              <History key="history" request={useGetCounterpartyHistoryQuery} />,
            ],
          }}
        />
      </CounterpartyNameContext.Provider>
    </PageLayout>
  );
};

export default ViewCounterparty;
