import { api } from "./index";
import { IUserRole } from "./interfaces";

//types
interface IRolesResponse {
  roles: IUserRole[];
  rolesCount: number;
}

export const rolesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query<IRolesResponse, void>({
      query: () => `roles`,
      keepUnusedDataFor: 0,
    }),
    //   getRolesById: builder.query<IRole, any>({
    //     query: (idRoles) => `roles/${idRoles}`,
    //   }),
    //   createRoles: builder.mutation({
    //     query: (initialPost) => ({
    //       url: `roles`,
    //       method: "POST",
    //       body: initialPost,
    //     }),
    //   }),
    //   editRoles: builder.mutation({
    //     query: (initialPost) => ({
    //       url: `roles/${initialPost.idRoles}`,
    //       method: "PUT",
    //       body: initialPost,
    //     }),
    //   }),
    //   deleteRoles: builder.mutation({
    //     query: (idRoles) => ({
    //       url: `roles/${idRoles}`,
    //       method: "DELETE",
    //     }),
    //   }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetRolesQuery,
  // useGetRolesByIdQuery,
  // useCreateRolesMutation,
  // useEditRolesMutation,
  // useDeleteRolesMutation,
} = rolesApi;
