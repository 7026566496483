import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useMaterialUIController } from "context";

interface IIntegrationFieldsetProps {
  value1: string;
  value2: string;
  onDelete: () => void;
  label?: string;
  buttonLabel?: string;
}

const IntegrationFieldset = ({
  value1,
  value2,
  onDelete,
  label = "Данные",
  buttonLabel = "Удалить",
}: IIntegrationFieldsetProps) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <MDBox
      bgColor={darkMode ? "grey-900" : "grey-100"}
      borderRadius="lg"
      display="flex"
      alignItems="center"
      flexDirection={{ xs: "column", md: "row" }}
      justifyContent="space-between"
      paddingBlock={1}
      gap={{ xs: 1, lg: 0 }}
      mt={{ xs: 1, lg: 3 }}
      ml={{ xs: 1, lg: 5 }}
      pl={{ xs: 0, md: 1, lg: 2 }}
      pr={1}
    >
      <MDTypography variant="button" fontWeight="medium" color="text">
        {label}
      </MDTypography>
      <MDInput size="small" value={value1} />
      <MDInput size="small" value={value2} />

      <MDButton onClick={onDelete} variant="gradient" color="dark" size="small">
        {buttonLabel}
      </MDButton>
    </MDBox>
  );
};

export default IntegrationFieldset;
