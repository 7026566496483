import { api } from ".";

export interface IUnitResponse {
  units: IUnit[];
  unitsCount: number;
}

export interface IUnit {
  id: number;
  name: string;
  abbreviation: string;
}

export const storageUnitsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getStorageUnits: builder.query<IUnitResponse, void>({
      query: () => `storage_units`,
      transformErrorResponse: (response: any) => response.data.message,
    }),
  }),
});

export const { useGetStorageUnitsQuery } = storageUnitsApi;
