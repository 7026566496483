import { INewShop } from "../../../../Redux/api/shops.api";

export const initialValues: INewShop = {
  avatar_id: null,
  is_shop: false,
  code: "",
  name: "",
  address: "",
  branches: [],
  warehouses: [],
  phone: "",
};
