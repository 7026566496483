import { Stack, Switch } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import MDTypography from "components/MDTypography";
import PageLayout from "examples/CardPage";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Loader from "layouts/components/loader";
import ContentHeader from "layouts/pages/widgets/components/ContentHeader";
import Tabs from "layouts/pages/widgets/components/Tabs";
import { useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetShopByIdQuery, useGetShopHistoryQuery } from "../../../../Redux/api/shops.api";
import History from "../../../pages/widgets/components/History";
import Main from "../components/main";
import { ShopNameContext } from "../context/shop-name-context";
import { getInitialValues } from "../consts";
import { useLoadFile } from "hooks/userLoadFile";
import { AVATAR } from "constants/file-types";

const ViewShop = () => {
  const [name, setName] = useState("");

  const { id } = useParams<string>();
  const { data: shop, isFetching } = useGetShopByIdQuery(id);
  const { file, filePath, uploadLocalFile, loadFile, cancelUploadLocalFile } = useLoadFile(AVATAR);
  const [isShop, setIsShop] = useState(false);

  useLayoutEffect(() => {
    setIsShop(shop?.shop?.is_shop || false);
  }, [shop]);

  if (isFetching && !shop?.shop) {
    return <Loader />;
  }

  return (
    <PageLayout header={<DashboardNavbar />}>
      <ShopNameContext.Provider value={{ setName, file }}>
        <ContentHeader
          info={{
            img: filePath || shop?.shop?.avatar?.path,
            uploadLocalFile,
            title: name || shop?.shop?.name,
          }}
          action={
            <Stack direction="row" spacing={0.5} alignItems="center">
              <MDTypography color="text" style={{ fontSize: pxToRem(14) }}>
                Магазин/Пункт выдачи
              </MDTypography>
              <Switch checked={!isShop} onChange={() => setIsShop((v) => !v)} />
            </Stack>
          }
          error={!(file || shop?.shop?.avatar?.path)}
        />

        <Tabs
          defaultTab={1}
          items={{
            headers: ["Основные", "История"],
            components: [
              <Main
                key="main"
                edit={true}
                isShop={isShop}
                initialValues={getInitialValues(shop)}
                type="edit"
                loadFile={loadFile}
                cancelUploadLocalFile={cancelUploadLocalFile}
              />,
              <History key="history" request={useGetShopHistoryQuery} />,
            ],
          }}
        />
      </ShopNameContext.Provider>
    </PageLayout>
  );
};

export default ViewShop;
