import {
  useGetNomenclatureKindsQuery,
  INomenclatureKind,
} from "../../../Redux/api/nomenclature-kinds.api";
import Select from "layouts/pages/widgets/components/Select";
import { IItem } from "layouts/pages/widgets/components/Select/interfaces";
import { useEffect, useMemo, useState } from "react";

interface INomenclatureKindFieldProps {
  disabled?: boolean;
  error?: string;
  initialValueId?: number | string;
  onSelect?: (value: IItem) => void;
  label?: string;
}

const formatToSelectItem = (items?: INomenclatureKind[]): IItem[] => {
  if (!items) return [];
  return items.map((item) => ({
    id: item.id,
    label: item.name,
    value: item.id,
  }));
};

const NomenclatureKindField = ({
  disabled,
  error,
  initialValueId,
  onSelect,
  label = "Вид",
}: INomenclatureKindFieldProps) => {
  const { data } = useGetNomenclatureKindsQuery(null, { refetchOnMountOrArgChange: true });
  const [selectedOption, setSelectedOption] = useState<IItem | null>(null);
  const options = useMemo(() => formatToSelectItem(data?.nomenclatureKinds), [data]);

  useEffect(() => {
    const kindOption = options.find((item) => item.id == initialValueId);
    if (kindOption) {
      setSelectedOption(kindOption);
    }
  }, [options]);

  const onSelectItem = (value: IItem) => {
    setSelectedOption(value);
    onSelect(value);
  };

  return (
    <Select
      label={label}
      placeholder="Выберите из списка"
      noPointerEvents={disabled}
      error={error}
      popupIcon={disabled ? null : undefined}
      select={{
        value: selectedOption,
        items: options,
        onSelect: onSelectItem,
      }}
    />
  );
};

export default NomenclatureKindField;
