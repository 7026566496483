import { useState } from "react";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Divider } from "@mui/material";
import AddUpdateAlerts from "layouts/components/add-update-alerts";
import { IntegrationParametersValidationScheme } from "../../schemas/integration-parameters";
import { Formik, Form } from "formik";
import { IIntegration } from "../../../../../Redux/api/interfaces/IIntagration";
import IntegrationAccordion from "layouts/components/account-integration/integration-accordion";
import NewIntegrationFieldset from "layouts/components/account-integration/fieldsets/new-integration-fieldset";
import IntegrationFieldset from "layouts/components/account-integration/fieldsets/integration-fieldset";
import {
  useEditUserIntegrationMutation,
  useAddUserIntegrationParametersMutation,
  useGetUserIntegrationByIdQuery,
  useDeleteUserIntegrationParameterMutation,
  useGetUserIntegrationParametersQuery,
} from "../../../../../Redux/api/users.api";

interface IIntegrationItemProps {
  data: IIntegration;
  userId: string | number;
}

interface IFormik {
  [key: string]: string;
}

const IntegrationItem = ({ data, userId }: IIntegrationItemProps) => {
  const [isActive, setIsActive] = useState(Boolean(data.is_active));
  const [editIntegration, editResult] = useEditUserIntegrationMutation();
  const [addParameter, addResult] = useAddUserIntegrationParametersMutation();
  const { data: currentIntegration } = useGetUserIntegrationByIdQuery(data.integration_id);
  const [deleteParameter, deleteResult] = useDeleteUserIntegrationParameterMutation();
  const { data: integrationParameters } = useGetUserIntegrationParametersQuery({
    userId,
    integrationId: data.id,
  });

  const addNewIntegration = (form: IFormik) => {
    addParameter({
      userId,
      integrationId: data.id,
      integration_parameter_id: currentIntegration.integration.params[0].id,
      value_1: form[currentIntegration?.integration.params[0].key_1],
      value_2: form[currentIntegration?.integration.params[0].key_2],
    });
  };

  const handleIsActive = () => {
    setIsActive((prev) => !prev);
    editIntegration({ userId, integrationId: data.id, is_active: Number(!isActive) });
  };

  const removeParameter = (id: string | number) => {
    deleteParameter({
      userId,
      integrationId: data.id,
      paramId: id,
    });
  };

  return (
    <>
      <IntegrationAccordion
        action={
          <>
            <MDBox lineHeight={0} mx={2}>
              <MDTypography variant="button" color="text">
                {isActive ? "Активно" : "Не активно"}
              </MDTypography>
            </MDBox>
            <MDBox mr={1}>
              <Switch checked={isActive} onChange={handleIsActive} />
            </MDBox>
          </>
        }
        title={data.name}
        avatar={data.avatar}
      >
        {currentIntegration && (
          <Formik
            initialValues={
              {
                [currentIntegration?.integration.params[0].key_1]: "",
                [currentIntegration?.integration.params[0].key_2]: "",
              } as IFormik
            }
            validationSchema={IntegrationParametersValidationScheme}
            onSubmit={(values, { resetForm }) => {
              addNewIntegration(values);
              resetForm();
            }}
          >
            {() => (
              <Form style={{ width: "100%" }}>
                <NewIntegrationFieldset
                  name1={currentIntegration?.integration.params[0].key_1}
                  name2={currentIntegration?.integration.params[0].key_2}
                  placeholder1={currentIntegration?.integration.params[0].description_1}
                  placeholder2={currentIntegration?.integration.params[0].description_2}
                />
              </Form>
            )}
          </Formik>
        )}

        {integrationParameters && integrationParameters.parameters?.length
          ? integrationParameters.parameters.map((param: any) => (
              <IntegrationFieldset
                key={param.id}
                value1={param.value_1}
                value2={param.value_2}
                onDelete={() => removeParameter(param.id)}
              />
            ))
          : null}
      </IntegrationAccordion>

      <Divider style={{ marginBlock: "16px" }} />

      <AddUpdateAlerts
        isEditError={editResult.isError || deleteResult.isError}
        isEditSuccess={editResult.isSuccess || deleteResult.isSuccess}
        isAddError={addResult.isError}
        isAddSuccess={addResult.isSuccess}
        editErrorMessage={editResult.error as string}
        addErrorMessage={addResult.error as string}
      />
    </>
  );
};

export default IntegrationItem;
