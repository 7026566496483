import Input from "layouts/pages/widgets/components/Input";
import { useEffect, useState } from "react";
import { Autocomplete, Box } from "@mui/material";
import { useGetBranchesQuery } from "../../../../../Redux/api/branches.api";
import { IBranch } from "../../../../../Redux/api/interfaces";

interface IBranchesMultiselectFieldProps {
  disabled?: boolean;
  error?: string | string[];
  initialValueIds?: (number | string)[];
  onSelect?: (value: IBranch[]) => void;
}

const BranchesMultiselectField = ({
  disabled,
  error,
  initialValueIds,
  onSelect,
}: IBranchesMultiselectFieldProps) => {
  const { data: branches } = useGetBranchesQuery(null, { refetchOnMountOrArgChange: true });
  const [selectedOptions, setSelectedOptions] = useState<IBranch[]>([]);

  const branchOptions = branches?.branches || [];

  useEffect(() => {
    const options = branchOptions.filter((item) => initialValueIds?.some((id) => id == item.id));
    if (options) {
      setSelectedOptions(options);
    }
  }, [branchOptions, initialValueIds, disabled]);

  const onSelectItem = (e: any, value: IBranch[]) => {
    setSelectedOptions(value);
    onSelect(value);
  };

  return (
    <Autocomplete
      style={{ width: "100%", pointerEvents: disabled ? "none" : "auto" }}
      multiple
      filterOptions={(x) => x}
      getOptionLabel={(opt) => `${opt.title}, ${opt.name}`}
      isOptionEqualToValue={(op, val) => op.id === val.id}
      options={branchOptions}
      onChange={onSelectItem}
      popupIcon={disabled ? null : undefined}
      value={selectedOptions}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {option?.avatar && (
            <img width="20" style={{ marginRight: 10 }} src={`${option.avatar?.path}`} />
          )}
          {option.title}, {option.name}
        </Box>
      )}
      renderInput={(params: any) => (
        <Input
          label="Направление бизнеса"
          input={{
            noPointerEvents: disabled,
            placeholder: disabled ? "" : "Выберите из списка",
            error,
            ...params,
          }}
        />
      )}
    />
  );
};

export default BranchesMultiselectField;
