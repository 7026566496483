import * as Yup from "yup";

export const mainCompanyValidationScheme = Yup.object().shape({
  inn: Yup.number().required("Заполните поле ИНН"),
  kpp: Yup.number().notRequired(),
  counterparty_type_id: Yup.number().required("Выберите Тип"),
  phone_1: Yup.string().required("Заполните поле Телефон"),
  email: Yup.string().email("Введите корректный email").required("Заполните поле Почта компании"),
  registered_at: Yup.string().required("Заполните поле Дата регистрации"),
  name: Yup.string().required("Заполните поле Название"),
  head: Yup.string().required("Заполните поле Директор"),
  legal_address: Yup.string().required("Заполните поле Адрес компании"),
});
