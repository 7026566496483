import { IAddUser } from "Redux/api/users.api";

export const initialValues: IAddUser = {
  first_name: "",
  last_name: "",
  middle_name: "",
  birthday: "",
  branch_id: 0,
  department_id: 0,
  login: "",
  phone: "",
  permissions: [],
};
