import { IViewCategory } from "../../../../Redux/api/interfaces/ICategory";

interface IParams {
  category: IViewCategory;
}

export const getInitialValues = (category: IParams) => {
  if (category?.category) {
    return {
      id: category.category?.id || "",
      code: category.category?.code || "",
      name: category.category?.name,
      description: category.category?.description,
      parent_id: category?.category?.parent?.id,
      photo: category.category?.photo,
      photo_id: category.category.photo?.id || null,
      avatar: category.category?.avatar,
      avatar_id: category.category?.avatar?.id,
      tag_id: category.category.tags.map((tag) => tag.id),
    };
  }
};
