import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { IInventory, useSearchInventoriesMutation } from "../../../../Redux/api/inventories.api";
import { debounce } from "@mui/material/utils";
import pxToRem from "assets/theme/functions/pxToRem";

export const getProductName = (option: IInventory) => (
  <>
    {option.nomenclature.name} / Склад {option.warehouse?.name} / {option.quantity}
    {option.nomenclature?.unit.abbreviation} / {option.price}руб
  </>
);

interface ISearchInventoriesFieldProps {
  onSelect: (inventory: IInventory) => void;
}

const SearchInventoriesField = ({ onSelect }: ISearchInventoriesFieldProps) => {
  const [search, result] = useSearchInventoriesMutation();
  const [inputValue, setInputValue] = useState("");

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<IInventory[]>([]);

  const fetch = useMemo(() => debounce((query: string) => search(query), 400), []);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    if (inputValue.length > 2) {
      fetch(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    if (result.data?.inventories?.length) {
      setOptions(result.data.inventories);
    }
  }, [result]);

  return (
    <Autocomplete
      sx={{
        width: "100%",
        "& svg": { display: "none" },
        "& label": { lineHeight: pxToRem(28) },
        "& .MuiAutocomplete-popper": { maxWidth: "10vw !important" },
      }}
      open={open}
      size="small"
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      onChange={(event, value) => onSelect(value)}
      loadingText={"Загрузка..."}
      noOptionsText=""
      getOptionLabel={(option) => option.nomenclature.name}
      options={options}
      loading={result.isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={open ? "Поиск" : "Начните ввод позиции"}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {result.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={`${option.id}`}>
          <img
            src={option.nomenclature?.avatar?.path}
            alt=""
            style={{ marginRight: 8, width: 16, height: 16 }}
          />
          {getProductName(option)}
        </li>
      )}
    />
  );
};

export default SearchInventoriesField;
