import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { IProps } from "./interfaces";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";

const PageLayout = (props: IProps) => {
  const { children, header } = props;

  return (
    <DashboardLayout>
      <MDBox
        sx={{
          display: "flex",
          maxWidth: "100%",
        }}
      >
        <Grid container direction="column" gap={2} maxWidth="100%">
          {header}
          <Grid container direction="column" gap={3} maxWidth="100%">
            {children}
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default PageLayout;
