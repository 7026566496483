import MDAlert from "components/MDAlert";
import { ITableOption } from "examples/Tables/DataTable";
import useFilterParams from "hooks/useFilterParams";
import Loader from "layouts/components/loader";
import { Table } from "layouts/pages/widgets/components/Table";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useDeleteNomenclatureFromOrderMutation,
  useGetOrderNomenclaturesTableQuery,
} from "../../../../Redux/api/orders.api";
import NomenclaturesPopup from "./nomenclatures-popup";

const OrderNomenclatures = () => {
  const { id } = useParams();
  const { data, isLoading, isFetching, isError, error } = useGetOrderNomenclaturesTableQuery(id, {
    skip: !id,
  });
  const { setQuery, setPage, setPerPage, query, page, perPage } = useFilterParams();
  const [deleteFormOrder, deleteResult] = useDeleteNomenclatureFromOrderMutation();

  const [editId, setEditId] = useState<number | null>(null);
  const [deleteId, setDeleteId] = useState<number | null>(null);

  const editRow = useMemo(() => {
    return data?.orderNomenclatures?.find((d) => d.id === editId);
  }, [editId]);

  const optionsDots: ITableOption[] = [
    {
      id: 1,
      text: "Просмотр/редакт",
      isId: true,
      action: (id) => setEditId(+id),
    },
    {
      id: 2,
      text: "Удалить",
      isId: true,
      action: (id) => setDeleteId(+id),
    },
  ];

  useEffect(() => {
    if (deleteId) {
      deleteFormOrder({
        orderId: id,
        nomenclatureId: deleteId,
      }).then(() => setDeleteId(null));
    }
  }, [deleteId]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Fragment>
      {isError && (
        <MDAlert color="error" dismissible>
          {JSON.stringify(error)}
        </MDAlert>
      )}

      <Table
        title="Содержание заказа"
        accessorKey="orderNomenclatures"
        data={data}
        filters={{
          setQuery,
          setPage,
          setPerPage,
          query,
          page,
          perPage,
        }}
        optionsDots={optionsDots}
        isLoading={isLoading || isFetching}
      />

      {!!editRow && (
        <NomenclaturesPopup
          isOpen={!!editId}
          onClose={() => setEditId(null)}
          editId={editId}
          inventoryId={editRow.inventory_id}
          price={editRow.price}
          vat={editRow.vat}
          quantity={editRow.quantity}
        />
      )}
    </Fragment>
  );
};

export default OrderNomenclatures;
