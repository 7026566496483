import { routes } from "constants/route";
import PageLayout from "examples/CardPage";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Loader from "layouts/components/loader";
import ContentHeader from "layouts/pages/widgets/components/ContentHeader";
import Tabs from "layouts/pages/widgets/components/Tabs";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetNomenclatureByIdQuery,
  useGetNomenclatureHistoryByIdQuery,
} from "../../../../Redux/api/nomenclatures.api";
import History from "../../../pages/widgets/components/History";
import Main from "../components/main";
import { ProductsContext } from "../context/products-context";
import { getInitialValues } from "../consts";
import { useLoadFile } from "hooks/userLoadFile";
import { AVATAR, EMPLOYMENT_CONTRACT_ID } from "constants/file-types";

const ViewNomenclatures = () => {
  const [name, setName] = useState("");

  const { id } = useParams();
  const { data, isError, isLoading } = useGetNomenclatureByIdQuery(id, { skip: !id });
  const navigate = useNavigate();
  const { file, filePath, uploadLocalFile, loadFile, cancelUploadLocalFile } = useLoadFile(AVATAR);
  const {
    file: photo,
    uploadLocalFile: uploadPhotoLocalFile,
    loadFile: loadPhotoFile,
  } = useLoadFile(EMPLOYMENT_CONTRACT_ID);
  const {
    files,
    uploadMultiLocalFile,
    loadFile: loadMultiPhotoFile,
  } = useLoadFile(EMPLOYMENT_CONTRACT_ID);

  useEffect(() => {
    if (isError) {
      navigate(routes.NOMENCLATURES);
    }
  }, [isError]);

  if (!data || isLoading) {
    return <Loader />;
  }

  return (
    <PageLayout header={<DashboardNavbar />}>
      <ProductsContext.Provider
        value={{
          setName,
          file,
          photo,
          uploadPhotoLocalFile,
          files,
          uploadMultiLocalFile,
          loadMultiPhotoFile,
        }}
      >
        <ContentHeader
          info={{
            img: filePath || data?.nomenclature?.avatar?.path,
            uploadLocalFile,
            title: name || data?.nomenclature?.name,
          }}
          error={!(file || data?.nomenclature?.avatar?.path)}
        />

        <Tabs
          defaultTab={1}
          items={{
            headers: ["Основные", "История"],
            components: [
              <Main
                key="main"
                edit={true}
                initialValues={getInitialValues(data.nomenclature)}
                type="edit"
                loadFile={loadFile}
                cancelUploadLocalFile={cancelUploadLocalFile}
                loadPhotoFile={loadPhotoFile}
                cancelPhotoUploadLocalFile={cancelUploadLocalFile}
              />,
              <History key="history" request={useGetNomenclatureHistoryByIdQuery} />,
            ],
          }}
        />
      </ProductsContext.Provider>
    </PageLayout>
  );
};

export default ViewNomenclatures;
