import { FieldAttributes, FormikContextType } from "formik";
import Input from "layouts/pages/widgets/components/Input";

interface IInputFormikFieldProps {
  field: FieldAttributes<any>;
  form: FormikContextType<any>;
  [key: string]: any;
}

const InputFormikField = ({ field, form, ...props }: IInputFormikFieldProps) => {
  return (
    <Input
      label={props.label}
      mask={props.mask}
      input={{
        noPointerEvents: props.disabled,
        error: form.errors[field.name],
        ...field,
        placeholder: props?.placeholder,
      }}
    />
  );
};

export default InputFormikField;
