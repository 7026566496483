import { IFile } from "api/files/new";
import { createContext } from "react";

interface INewUserContext {
  setName: (name: string) => void;
  setRole: (name: string) => void;
  file?: any;
  contractFile?: IFile;
}

export const NewUserContext = createContext<INewUserContext | null>(null);
