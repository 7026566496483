import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import { Box } from "@mui/material";
import Popup from "layouts/components/popup";
import { Search } from "layouts/pages/widgets/components/Search";
import { ReadOnlyContent } from "layouts/pages/widgets/components/Search/read-only-content";
import { useEffect, useState } from "react";
import { useAddCategoryMutation } from "../../../../Redux/api/applications.api";
import { useSearchCategoriesMutation } from "../../../../Redux/api/categories.api";

export const getProductName = (option: any) => option?.name;

interface ICategoryModalProps {
  isOpened: boolean;
  applicationId: number | null;
  closeHandler: () => void;
  refetch?: () => any;
}
export const CategoryModal = (props: ICategoryModalProps) => {
  const { isOpened, closeHandler, applicationId, refetch } = props;

  const [item, setItem] = useState<any>(null);
  const [request] = useAddCategoryMutation();

  const submitAction = async () => {
    const response = await request({
      application_id: applicationId,
      category_id: item.id,
    });

    if (!response?.data?.category) {
      return;
    }

    closeHandler();

    if (refetch) {
      refetch();
    }
  };

  useEffect(() => {
    if (!isOpened) {
      setItem(null);
      return;
    }
  }, [isOpened]);

  return (
    <Popup
      isOpen={isOpened}
      onClose={closeHandler}
      title="Добавление категории"
      titleIcon={
        <ContentPasteOutlinedIcon
          sx={{ mr: 1, width: 16, height: 16, transform: "translateY(3px)" }}
        />
      }
      width={1150}
      header={
        <Search
          onSelect={(item: any) => {
            setItem(item);
          }}
          searchMutation={useSearchCategoriesMutation}
          searchMutationGetter="categories"
          fieldGetter="name"
          searchQuery={applicationId}
          renderOption={(props, option) => (
            <ReadOnlyContent
              {...props}
              avatar={option.avatar?.path}
              title={option?.name}
              description=""
            />
          )}
        />
      }
      submitAction={item && submitAction}
    >
      <Box sx={{ display: "flex", gap: 1, flexDirection: "column", p: "16px 0" }}>
        {item && (
          <ReadOnlyContent
            avatar={item?.avatar?.path}
            title={getProductName(item)}
            description=""
          />
        )}
      </Box>
    </Popup>
  );
};
