import { CustomDropZone } from "layouts/pages/widgets/components/CustomDropZone";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { useGetNomenclatureByIdQuery } from "../../../../Redux/api/nomenclatures.api";
import { ProductsContext } from "../context/products-context";

const PhotoBlock = (props: any) => {
  const { id } = useParams();
  const { data } = useGetNomenclatureByIdQuery(id, { skip: !id });

  const { files, uploadMultiLocalFile } = useContext(ProductsContext);

  const getFilesHandler = (files: File[]) => {
    props.setIsEdit(true);

    if (files?.length) uploadMultiLocalFile(files);
  };

  return (
    <CustomDropZone
      getFilesHandler={getFilesHandler}
      loadedFiles={files || data?.nomenclature?.photos}
      isError={false}
      maxFiles={4}
    />
  );
};

export default PhotoBlock;
