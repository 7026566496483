import { useEffect, useContext, ChangeEvent } from "react";
import { useFormikContext } from "formik";

import MDContainer from "components/MDContainer";

import Block from "layouts/pages/widgets/components/Block";
import Input from "layouts/pages/widgets/components/Input";
import { ShopNameContext } from "../../context/shop-name-context";
import Card from "@mui/material/Card";
import ContentWrapper from "layouts/components/content-wrapper";
import { INewShop } from "../../../../../Redux/api/shops.api";
import WarehousesMultiselectField from "./warehouses-multiselect-field";
import BranchesMultiselectField from "./branches-multiselect-field";
import { useLocation } from "react-router-dom";
import AddressAutocompleteField from "layouts/components/fields/address-autocomplete-field";

interface IEditFieldsProps {
  disabled?: boolean;
  avatar_id?: number | string;
  editCode: (code: string) => void;
}

const EditFields = ({ disabled = false, avatar_id, editCode }: IEditFieldsProps) => {
  const { setName } = useContext(ShopNameContext);
  const currentLocation = useLocation();
  const { values, errors, setFieldValue, initialValues } = useFormikContext<INewShop>();

  const handlerChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(e.target.name, e.target.value);
  };

  useEffect(() => {
    if (values.name.length > 0) {
      setName(values.name);
    }
  }, [values.name]);

  useEffect(() => {
    if (avatar_id) {
      setFieldValue("avatar_id", avatar_id);
    }
  }, [avatar_id]);

  return (
    <ContentWrapper
      childMb={currentLocation.pathname.includes("new") ? 6 : 0}
      aside={
        <Card sx={{ p: 3 }}>
          <Input
            label="Код (необязательно)"
            input={{
              noPointerEvents: false,
              placeholder: "Введите код",
              value: values.code,
              name: "code",
              onBlur: (e: React.ChangeEvent<HTMLInputElement>) => {
                values?.id && editCode(e.target.value as string);
              },
              onChange: handlerChange,
              error: errors.code,
            }}
          />
        </Card>
      }
    >
      <Block title="Основная информация" wrapperStyles={{ width: "100%" }}>
        <MDContainer gap={3}>
          <Input
            label="Название"
            input={{
              noPointerEvents: disabled,
              placeholder: "Введите название",
              value: values.name,
              name: "name",
              onChange: handlerChange,
              error: errors.name,
            }}
          />
        </MDContainer>

        <MDContainer gap={3}>
          <BranchesMultiselectField
            disabled={disabled}
            error={errors.branches}
            initialValueIds={values.branches}
            onSelect={(value) =>
              setFieldValue(
                "branches",
                value.map((v) => v.id)
              )
            }
          />
        </MDContainer>

        <MDContainer gap={3}>
          <WarehousesMultiselectField
            disabled={disabled}
            error={errors?.warehouses}
            initialValueIds={values.warehouses}
            onSelect={(value) =>
              setFieldValue(
                "warehouses",
                value.map((v) => v.id)
              )
            }
          />
        </MDContainer>

        <MDContainer gap={3}>
          <AddressAutocompleteField
            label="Адрес "
            placeholder="Начните ввод адреса"
            disabled={disabled}
            onSelect={(value) => setFieldValue("address", value.value)}
            error={errors.address}
            initialValue={values.address}
          />
          <Input
            label="Телефон"
            mask="+7 (999) 999 99 99"
            input={{
              noPointerEvents: disabled,
              name: "phone",
              placeholder: "+0 (000) 000 00 00",
              defaultValue: initialValues.phone,
              blur: (value) => {
                setFieldValue("phone", value);
              },
              error: errors.phone,
            }}
          />
        </MDContainer>
      </Block>
    </ContentWrapper>
  );
};

export default EditFields;
