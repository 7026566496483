import { Theme } from "@fullcalendar/core/internal";
import { Accordion, AccordionDetails, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useState, useEffect } from "react";
import LoopIcon from "@mui/icons-material/Loop";
import { TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { IHolidaysRequest, useGetShopHolidaysQuery } from "../../../../../Redux/api/shops.api";
import { useParams } from "react-router-dom";

interface IProps {
  disabled?: boolean;
  onSubmit: (body: IHolidaysRequest) => void;
}

const HolidaysAccordion = ({ disabled, onSubmit }: IProps) => {
  const { id } = useParams();
  const { data: holidays } = useGetShopHolidaysQuery(id, { refetchOnMountOrArgChange: true });
  const [expanded, setExpanded] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [deleteButton, setDeleteButton] = useState(false);
  const [form, setForm] = useState({
    work_start_time: "08:00",
    work_end_time: "18:00",
    break_start_time: "12:00",
    break_end_time: "13:00",
  });

  useEffect(() => {
    if (holidays?.holidays) {
      const { work_start_time, work_end_time, break_start_time, break_end_time } =
        holidays?.holidays;
      if (
        work_start_time === null ||
        work_end_time === null ||
        break_start_time === null ||
        break_end_time === null
      ) {
        setShowForm(false);
        setDeleteButton(false);
      } else {
        setShowForm(true);
        setForm(holidays?.holidays);
        setDeleteButton(true);
      }
    }
  }, [holidays]);

  const handleAdd = () => {
    onSubmit(
      showForm
        ? {
            shopId: id,
            work_start_time: dayjs(form.work_start_time, "HH:mm").format("HH:mm"),
            work_end_time: dayjs(form.work_end_time, "HH:mm").format("HH:mm"),
            break_start_time: dayjs(form.break_start_time, "HH:mm").format("HH:mm"),
            break_end_time: dayjs(form.break_end_time, "HH:mm").format("HH:mm"),
          }
        : {
            shopId: id,
            work_start_time: null,
            work_end_time: null,
            break_start_time: null,
            break_end_time: null,
          }
    );
  };

  const handleDelete = () => {
    onSubmit({
      shopId: id,
      work_start_time: null,
      work_end_time: null,
      break_start_time: null,
      break_end_time: null,
    });
  };

  return (
    <Accordion
      expanded={expanded}
      sx={{ "&:before": { content: "none" }, boxShadow: "none" }}
      style={{ marginBlock: "0", width: "100%", backgroundColor: "transparent" }}
    >
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <MDBox display="flex" alignItems="center">
          <MDBox
            display="flex"
            alignItems="center"
            onClick={() => setExpanded((v) => !v)}
            style={{ cursor: "pointer" }}
          >
            <MDTypography fontSize={14} fontWeight="regular" lineHeight="140%" color="text">
              Праздничные дни
            </MDTypography>
            <MDTypography variant="button" color="text" sx={{ lineHeight: 0 }}>
              <Icon fontSize="small">{expanded ? "expand_less" : "expand_more"}</Icon>
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <AccordionDetails
        style={{
          paddingInline: "0",
          paddingBottom: "0",
          pointerEvents: disabled ? "none" : "initial",
        }}
      >
        <MDTypography
          ml={{ xs: 0, md: 5 }}
          fontSize={{ xs: 12, md: 14 }}
          color="text"
          variant="button"
        >
          Выберите выходной день или Время работы
        </MDTypography>
        <MDBox
          bgColor={"grey-100"}
          borderRadius="lg"
          display="flex"
          alignItems={{ xs: "flex-start", xxl: "center" }}
          flexDirection={{ xs: "column", xxl: "row" }}
          justifyContent="space-between"
          height={{ xxl: "58px" }}
          gap={{ xs: 2, xxl: 1 }}
          mt={{ xs: 1, xxl: 3 }}
          ml={{ xs: 0, md: 5 }}
          paddingBlock={1}
          pl={{ xs: 1, md: 1, xxl: 2 }}
          pr={1}
          py={1.5}
        >
          <MDBox
            display="flex"
            alignItems={{ xs: "flex-start", xxl: "center" }}
            gap={{ xs: 2, md: 3 }}
            flexDirection={{ xs: "column", xxl: "row" }}
          >
            <MDBox
              style={{ cursor: "pointer" }}
              display="flex"
              alignItems="center"
              gap={1}
              onClick={() => setShowForm(!showForm)}
            >
              <LoopIcon style={{ transform: "scale(1, -1)" }} fontSize="small" />
              <MDTypography variant="button" fontWeight="bold">
                {showForm ? "Время работы " : "Выходной"}
              </MDTypography>
            </MDBox>
            {showForm && (
              <>
                <MDBox display="flex" alignItems="center" gap={0.2}>
                  <TimePicker
                    sx={({ palette: { text } }: Theme | any) => ({
                      borderColor: text.main,
                      borderRadius: 2,
                      maxWidth: "100px",
                      svg: {
                        width: "15px",
                        height: "15px",
                        fill: "#344767",
                      },
                    })}
                    value={dayjs(`2022-04-17${form.work_start_time}`)}
                    onChange={(v: any) => setForm({ ...form, work_start_time: v })}
                  />
                  -
                  <TimePicker
                    sx={({ palette: { text } }: Theme | any) => ({
                      borderColor: text.main,
                      borderRadius: 2,
                      maxWidth: "100px",
                      svg: {
                        width: "15px",
                        height: "15px",
                        fill: "#344767",
                      },
                    })}
                    value={dayjs(`2022-04-17${form.work_end_time}`)}
                    onChange={(v: any) => setForm({ ...form, work_end_time: v })}
                  />
                </MDBox>
                <MDTypography color="dark" variant="button">
                  Обед
                </MDTypography>
                <MDBox display="flex" alignItems="center" gap={0.2}>
                  <TimePicker
                    sx={({ palette: { text } }: Theme | any) => ({
                      borderColor: text.main,
                      borderRadius: 2,
                      maxWidth: "100px",
                      svg: {
                        width: "15px",
                        height: "15px",
                        fill: "#344767",
                      },
                    })}
                    value={dayjs(`2022-04-17${form.break_start_time}`)}
                    onChange={(v: any) => setForm({ ...form, break_start_time: v })}
                  />
                  -
                  <TimePicker
                    sx={({ palette: { text } }: Theme | any) => ({
                      borderColor: text.main,
                      borderRadius: 2,
                      maxWidth: "100px",
                      svg: {
                        width: "15px",
                        height: "15px",
                        fill: "#344767",
                      },
                    })}
                    value={dayjs(`2022-04-17${form.break_end_time}`)}
                    onChange={(v: any) => setForm({ ...form, break_end_time: v })}
                  />
                </MDBox>
              </>
            )}
          </MDBox>
          {deleteButton ? (
            <MDButton onClick={handleDelete} variant="gradient" color="dark" size="small">
              DELETE
            </MDButton>
          ) : (
            showForm && (
              <MDButton onClick={handleAdd} variant="gradient" color="primary" size="small">
                Отправить
              </MDButton>
            )
          )}
        </MDBox>
      </AccordionDetails>
    </Accordion>
  );
};

export default HolidaysAccordion;
