import { styled, Theme } from "@mui/material/styles";
import { List } from "@mui/material";

interface IProps {
  theme?: Theme;
}

export default styled(List)(({ theme }: IProps) => {
  const { boxShadows, functions } = theme;
  const { pxToRem } = functions;

  return {
    // boxShadow: boxShadows.md,
    width: pxToRem(180),
    marginTop: pxToRem(8),
    borderRadius: pxToRem(6),
  };
});
