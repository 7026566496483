import MDButton from "components/MDButton";
import { IBrand, useGetBrandsQuery } from "../../../Redux/api/brands.api";
import Select from "layouts/pages/widgets/components/Select";
import { IItem } from "layouts/pages/widgets/components/Select/interfaces";
import { useEffect, useMemo, useState } from "react";
import { Add } from "@mui/icons-material";
import pxToRem from "assets/theme/functions/pxToRem";
import MDBox from "components/MDBox";

interface IBrandFieldProps {
  disabled?: boolean;
  error?: string;
  initialValueId?: number | string;
  onSelect?: (value: IItem) => void;
  onOpen?: (value: number | boolean) => void;
  label?: string;
}

const formatToSelectItem = (items?: IBrand[]): IItem[] => {
  if (!items) return [];
  return items.map((item) => ({
    id: item.id,
    label: item.name,
    value: item.id,
    icon: item.avatar,
    edit: true,
  }));
};

const BrandField = ({
  disabled,
  error,
  initialValueId,
  onSelect,
  onOpen,
  label = "Торговая марка",
}: IBrandFieldProps) => {
  const { data } = useGetBrandsQuery(null, { refetchOnMountOrArgChange: true });
  const [selectedOption, setSelectedOption] = useState<IItem | null>(null);
  const options = useMemo(() => formatToSelectItem(data?.brands), [data]);

  useEffect(() => {
    const typeOption = options.find((item) => item.id == initialValueId);
    if (typeOption) {
      setSelectedOption(typeOption);
    }
  }, [options]);

  const onSelectItem = (value: IItem) => {
    setSelectedOption(value);
    onSelect(value);
  };

  const onOpenItem = (value: IItem) => {
    onOpen(value?.id);
  };

  return (
    <MDBox display="flex" flexDirection="row" alignItems="flex-end" width="100%" columnGap="10px">
      <Select
        label={label}
        placeholder="Выберите из списка"
        noPointerEvents={disabled}
        error={error}
        popupIcon={disabled ? null : undefined}
        select={{
          value: selectedOption,
          items: options,
          onSelect: onSelectItem,
          onOpen: onOpenItem,
        }}
      />
      {!disabled && (
        <MDButton
          color="primary"
          size="small"
          style={{ padding: 0, minWidth: pxToRem(24), minHeight: pxToRem(24), marginBottom: 3 }}
          onClick={() => onOpen(true)}
          variant="gradient"
        >
          <Add />
        </MDButton>
      )}
    </MDBox>
  );
};

export default BrandField;
