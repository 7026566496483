import { api } from ".";

export interface IPromotionResponse {
  promotions: IPromotion[];
  promotionsCount: number;
}

export interface IPromotion {
  id: number;
  name: string;
  avatar: string;
  created_at: string;
}

export const promotionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPromotions: builder.query<IPromotionResponse, void>({
      query: () => `promotions`,
      transformErrorResponse: (response: any) => response.data.message,
    }),
  }),
});

export const { useGetPromotionsQuery } = promotionsApi;
