import PageLayout from "examples/CardPage";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ContentHeader from "layouts/pages/widgets/components/ContentHeader";
import Tabs from "layouts/pages/widgets/components/Tabs";
import { useState } from "react";
import Main from "../components/main";
import { ProductsContext } from "../context/products-context";
import { useLoadFile } from "hooks/userLoadFile";
import { AVATAR, EMPLOYMENT_CONTRACT_ID } from "constants/file-types";
import { initialValues } from "../consts";

const NewNomenclature = () => {
  const {
    file,
    uploadLocalFile,
    loadFile,
    loadError,
    setLoadError,
    cancelUploadLocalFile,
    filePath,
  } = useLoadFile(AVATAR);
  const {
    file: photo,
    uploadLocalFile: uploadPhotoLocalFile,
    loadFile: loadPhotoFile,
  } = useLoadFile(EMPLOYMENT_CONTRACT_ID);
  const {
    files,
    uploadMultiLocalFile,
    loadFile: loadMultiPhotoFile,
  } = useLoadFile(EMPLOYMENT_CONTRACT_ID);

  const [name, setName] = useState("");

  return (
    <PageLayout header={<DashboardNavbar />}>
      <ProductsContext.Provider
        value={{
          setName,
          file,
          photo,
          uploadPhotoLocalFile,
          files,
          uploadMultiLocalFile,
          loadMultiPhotoFile,
        }}
      >
        <ContentHeader
          info={{
            img: filePath,
            uploadLocalFile,
            title: name || "Новая позиция",
          }}
          error={!!loadError}
        />

        <Tabs
          defaultTab={1}
          items={{
            headers: ["Основные"],
            components: [
              <Main
                key="main"
                type="add"
                initialValues={initialValues}
                loadFile={loadFile}
                cancelUploadLocalFile={cancelUploadLocalFile}
                loadPhotoFile={loadPhotoFile}
                cancelPhotoUploadLocalFile={cancelUploadLocalFile}
                setLoadError={setLoadError}
              />,
            ],
          }}
        />
      </ProductsContext.Provider>
    </PageLayout>
  );
};

export default NewNomenclature;
