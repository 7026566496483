import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "Redux/store";

export interface ISelectedFilter {
  filterId: number | string;
  filterItemId: number | string;
  filterUrl: string;
  listUrl: string;
  value?: string;
  exclucive: boolean;
  isInput: boolean;
  name: string;
  tag: string;
}

interface IAddItemToFiltersAction {
  payload: ISelectedFilter;
}

interface IFiltersState {
  filters: ISelectedFilter[];
}

const initialState = {
  filters: [],
} satisfies IFiltersState as IFiltersState;

export const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    addItemToFilters(state, action: IAddItemToFiltersAction) {
      const { filterId, filterItemId, exclucive, isInput, value } = action.payload;

      if (isInput) {
        const newState = state.filters.filter((f) => f.filterId !== filterId);
        if (value) newState.push(action.payload);
        state.filters = newState;
        return;
      }

      const existingFilter = state.filters.find(
        (f) => f.filterId === filterId && f.filterItemId === filterItemId
      );

      if (exclucive) {
        const newState = state.filters.filter((f) => f.filterId !== filterId);
        if (!existingFilter) newState.push(action.payload);
        state.filters = newState;
        return;
      }

      if (!existingFilter) {
        state.filters = [...state.filters, action.payload];
        return;
      }
      if (existingFilter) {
        state.filters = [
          ...state.filters.filter((f) => {
            return f.filterId !== filterId || f.filterItemId !== filterItemId;
          }),
        ];
      }
    },

    clearFilters(state) {
      state.filters = initialState.filters;
    },
  },
});

export const { addItemToFilters, clearFilters } = filtersSlice.actions;

export const filtersSelector = (state: RootState) => state.filters.filters;

export const filterSelectorById =
  (filterId: string | number, filterItemId: string | number) => (state: RootState) => {
    const { filters } = state.filters;

    if (!filters) return null;
    return filters.find(
      (filter) => filter.filterId == filterId && filter.filterItemId === filterItemId
    );
  };

export const filterParamsSelector = (state: RootState) => {
  const { filters } = state.filters;
  if (!filters.length) return "";
  return filters
    .map((filter) => {
      if (filter.isInput && !filter.value) return "";
      let query = filter.filterUrl + filter.listUrl + filter.value;
      return query;
    })
    .join("");
};

export default filtersSlice.reducer;
