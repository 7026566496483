import PageLayout from "examples/CardPage";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ContentHeader from "layouts/pages/widgets/components/ContentHeader";
import Tabs from "layouts/pages/widgets/components/Tabs";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetBranchesByIdQuery,
  useGetBranchHistoryQuery,
} from "../../../../Redux/api/branches.api";
import Loader from "../../../components/loader";
import History from "../../../pages/widgets/components/History";
import Bank from "../components/bank";
import Main from "../components/main";
import { BranchNameContext } from "../context/branch-name-context";
import { useLoadFile } from "hooks/userLoadFile";
import { AVATAR } from "constants/file-types";
import { getInitialValues } from "../consts";

const ViewBranch = () => {
  const [name, setName] = useState("");

  const { id } = useParams<string>();
  const { data: branch, isFetching } = useGetBranchesByIdQuery(id);
  const { file, filePath, uploadLocalFile, loadFile, cancelUploadLocalFile } = useLoadFile(AVATAR);

  if (isFetching && !branch?.branch) {
    return <Loader />;
  }

  return (
    <PageLayout header={<DashboardNavbar />}>
      <BranchNameContext.Provider value={{ setName, file }}>
        <ContentHeader
          info={{
            img: filePath || branch?.branch?.avatar?.path,
            uploadLocalFile,
            title: name || branch?.branch?.name,
          }}
          error={false}
        />

        <Tabs
          defaultTab={1}
          items={{
            headers: ["Основные", "Реквизиты", "История"],
            components: [
              <Main
                key="main"
                edit={true}
                initialValues={getInitialValues(branch)}
                type="edit"
                loadFile={loadFile}
                cancelUploadLocalFile={cancelUploadLocalFile}
              />,
              <Bank key="bank" />,
              <History key="history" request={useGetBranchHistoryQuery} />,
            ],
          }}
        />
      </BranchNameContext.Provider>
    </PageLayout>
  );
};

export default ViewBranch;
