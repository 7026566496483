import { Card } from "@mui/material";
import Input from "layouts/pages/widgets/components/Input";
import { useEffect, useRef, useState } from "react";

interface IAsideCodeBlockProps {
  onChange: (code: string) => void;
  onFocus?: () => void;
  initialValue: string;
  label?: string;
  placeholder?: string;
}

const AsideCodeBlock = ({
  onChange,
  onFocus,
  initialValue,
  label = "Код (необязательно)",
  placeholder = "Введите код",
}: IAsideCodeBlockProps) => {
  const [code, setCode] = useState(initialValue);
  const [error, setError] = useState("");

  const timer = useRef(null);

  useEffect(() => {
    if (!timer.current && code?.length > 1 && code !== initialValue) {
      timer.current = setTimeout(() => {
        onChange(code);
      }, 500);
    }
    // if (code.length < 1) {
    //   setError("Введите не менее 1 символа");
    //   clearTimeout(timer.current);
    //   timer.current = null;
    // } else {
    //   setError("");
    // }
    return () => {
      clearTimeout(timer.current);
      timer.current = null;
    };
  }, [code]);

  return (
    <Card sx={{ px: 4, py: 4 }}>
      <Input
        label={label}
        input={{
          type: "number",
          name: "code",
          placeholder,
          value: code,
          focus: onFocus,
          onChange: (e: any) => setCode(e.target.value),
          error: error,
        }}
      />
    </Card>
  );
};

export default AsideCodeBlock;
