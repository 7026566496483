import MDAvatar from "components/MDAvatar";
import { IRoute } from "interfaces";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Settings from "layouts/pages/account/settings";
import SignInCover from "layouts/authentication/sign-in/cover";

// Images
import profilePicture from "assets/images/team-3.jpg";
import { routes } from "constants/route";

export const profileItems: IRoute = {
  type: "collapse",
  name: "user",
  key: "user",
  icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
  collapse: [
    {
      name: "Профиль",
      key: "profile",
      route: routes.PROFILE,
      component: <ProfileOverview />,
    },
    {
      name: "Settings",
      key: "settings",
      route: routes.SETTINGS,
      component: <Settings />,
    },
    {
      name: "Выход",
      key: "logout",
      route: routes.LOGIN,
      component: <SignInCover />,
    },
  ],
};
