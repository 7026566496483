import { useEffect, useMemo, useState } from "react";
import { useFormikContext } from "formik";

import MDContainer from "components/MDContainer";

import Block from "layouts/pages/widgets/components/Block";
import Input from "layouts/pages/widgets/components/Input";
import { Autocomplete } from "@mui/material";
import DebouncedInput from "layouts/pages/widgets/components/DebouncedInput";

import { useSearchBankByValueMutation } from "../../../Redux/api/search-bank.api";
import { IBankRequisite } from "../../../Redux/api/interfaces";

export interface IFormikValues {
  id?: string | number;
  counterpartyId: string | number;
  name: string;
  bik: string;
  kpp: string;
  payment_account: string;
  address: string;
  inn: string;
}

interface IBankFieldsProps {
  disabled?: boolean;
}

const BankFields = ({ disabled }: IBankFieldsProps) => {
  const [searchBank, resultBanks] = useSearchBankByValueMutation();
  const [selectedBikOption, setSelectedBikOption] = useState<IBankRequisite | null>(null);

  const [bik, setBik] = useState<string>("");
  const [paymentAccount, setPaymentAccount] = useState<string>("");

  const bankOptions = useMemo(() => resultBanks?.data?.banks || [], [resultBanks]);

  const { values, errors, setFieldValue, validateForm, initialValues } =
    useFormikContext<IFormikValues>();

  const clearValues = () => {
    setSelectedBikOption(null);
    setFieldValue("bik", "");
    setFieldValue("kpp", "");
    setFieldValue("address", "");
    setFieldValue("name", "");
    setFieldValue("payment_account", "");
  };

  const onBikSelect = (e: any, newValue: IBankRequisite) => {
    if (newValue) {
      setSelectedBikOption(newValue);
      setFieldValue("bik", newValue.bik);
      setFieldValue("kpp", newValue.kpp);
      setFieldValue("address", newValue.address);
      setFieldValue("name", newValue.name);
      setFieldValue("payment_account", newValue.payment_account);
    } else {
      clearValues();
    }
  };

  useEffect(() => {
    if (bik.length > 2) {
      searchBank({ query: bik });
    }
  }, [bik]);

  useEffect(() => {
    setBik(initialValues.bik as string);
    setPaymentAccount(initialValues.payment_account);
    setFieldValue("payment_account", initialValues.payment_account);
    setSelectedBikOption({
      name: initialValues.name,
      bik: initialValues.bik,
      payment_account: initialValues.payment_account,
      kpp: initialValues.kpp,
      address: initialValues.address,
    });
  }, []);

  return (
    <Block title="Банковские реквизиты" wrapperStyles={{ width: "100%" }}>
      <MDContainer gap={3}>
        <Autocomplete
          style={{ width: "100%", pointerEvents: disabled ? "none" : "auto" }}
          getOptionLabel={(option: IBankRequisite) =>
            option && selectedBikOption && option?.bik === selectedBikOption?.bik
              ? option?.bik
              : option?.bik + " - " + option?.name
          }
          onOpen={clearValues}
          isOptionEqualToValue={(op, val) => op.bik === val.bik}
          options={bankOptions}
          popupIcon={disabled ? null : undefined}
          id="bik"
          noOptionsText="Нет результатов"
          onBlur={() => validateForm(values)}
          onChange={onBikSelect}
          value={selectedBikOption}
          renderInput={(params) => (
            <DebouncedInput
              label="Бик"
              debounceTimeout={500}
              handleDebounce={setBik}
              isLoading={resultBanks.isLoading}
              input={{
                noPointerEvents: disabled,
                ...params,
                placeholder: "Поиск по Бик",
                value: bik,
                error: errors.bik,
              }}
            />
          )}
        />
      </MDContainer>

      <MDContainer gap={3}>
        <Input
          label="Р/с"
          input={{
            noPointerEvents: disabled,
            name: "payment_account",
            value: paymentAccount,
            defaultValue: initialValues.payment_account,
            onChange: (e: any) => setPaymentAccount(e.target.value),
            blur: () => {
              setFieldValue("payment_account", paymentAccount);
            },
            error: errors.payment_account,
          }}
        />
        <Input
          label="Название банка"
          input={{
            noPointerEvents: true,
            placeholder: "Заполняется автоматически",
            value: values.name,
            name: "name",
            error: errors.name,
          }}
        />
      </MDContainer>
      <MDContainer gap={3}>
        <Input
          label="КПП"
          input={{
            noPointerEvents: true,
            placeholder: "Заполняется автоматически",
            value: values.kpp,
            name: "kpp",
            error: errors.kpp,
          }}
        />
        <Input
          label="Адрес компании"
          input={{
            noPointerEvents: true,
            placeholder: "Заполняется автоматически",
            value: values.address,
            name: "legal_address",
            error: errors.address,
          }}
        />
      </MDContainer>
    </Block>
  );
};

export default BankFields;
