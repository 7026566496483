import { Grid } from "@mui/material";
import { IFile } from "api/files/new";
import MDBox from "components/MDBox";
import MDDropzoneRoot from "components/MDDropzone/MDDropzoneRoot";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import "dropzone/dist/dropzone.css";
import { ChangeEvent, DragEvent, useEffect, useState } from "react";
import Block from "../Block";
import { ICustomDropZone } from "./interfaces";

export const CustomDropZone = (props: ICustomDropZone) => {
  const { title, maxFiles = 4, getFilesHandler, isError, loadedFiles } = props;

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [files, setFiles] = useState<File[]>([]);
  const [objectUrls, setObjectUrls] = useState<string[]>([]);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const getObjectUrl = (file: File | IFile) => {
    const uploadedFile = file as IFile;
    const loadedFile = file as File;

    if (uploadedFile?.id) {
      return uploadedFile.path;
    }

    const blob = new Blob([loadedFile]);
    const url = URL.createObjectURL(blob);

    return url;
  };

  const getFiles = (event: DragEvent<HTMLLabelElement> | ChangeEvent<HTMLInputElement>) => {
    const files =
      (event as DragEvent<HTMLLabelElement>)?.dataTransfer?.files ||
      (event as ChangeEvent<HTMLInputElement>)?.target?.files;

    const converteArrayFiles = Array.from(files).slice(0, maxFiles);

    const objectUrls = converteArrayFiles.map((converteArrayFile: File) =>
      getObjectUrl(converteArrayFile)
    );

    return {
      files: converteArrayFiles,
      objectUrls,
    };
  };

  const onDragEnter = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const onDragLeave = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  const onDragOver = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onDropHandler = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);

    const { files, objectUrls } = getFiles(event);
    if (!files.length) {
      return;
    }

    setFiles(files);
    setObjectUrls(objectUrls);
    getFilesHandler(files);
  };

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const { files, objectUrls } = getFiles(event);
    if (!files.length) {
      return;
    }

    setFiles(files);
    setObjectUrls(objectUrls);
    getFilesHandler(files);
  };

  const getContent = () => {
    if (!objectUrls.length) {
      return (
        <MDTypography fontSize={14} fontWeight={400} lineHeight="22px">
          {isDragging ? "Отпустите файл здесь" : "Перетащите файл или кликните сюда"}
        </MDTypography>
      );
    }

    return objectUrls.map((objectUrl) => (
      <MDBox
        key={objectUrl}
        sx={{
          display: "block",
          width: "100%",
          maxWidth: 120,
          aspectRatio: "1/1",
          borderRadius: "4px",
          overflow: "hidden",
          cursor: "pointer",
          position: "relative",
          "&>img": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
          },
        }}
      >
        <img src={objectUrl} alt="img" />
      </MDBox>
    ));
  };

  useEffect(() => {
    if (!loadedFiles?.filter(Boolean)?.length) {
      return;
    }

    setObjectUrls(loadedFiles.map((loadedFile) => getObjectUrl(loadedFile)));
  }, [loadedFiles]);

  return (
    <Block title={title} wrapperStyles={{ width: "100%", pointerEvents: "auto", height: 265 }}>
      <label
        style={{
          width: "100%",
          maxWidth: "100%",
          cursor: "pointer",
          flex: 1,
          display: "flex",
        }}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDrop={onDropHandler}
      >
        <MDDropzoneRoot className="form-control dropzone" ownerState={{ darkMode, isError }}>
          <Grid container alignItems="center" justifyContent="center" gap={2}>
            {getContent()}
          </Grid>

          <input
            style={{
              display: "none",
            }}
            name="file"
            type="file"
            max={maxFiles}
            onChange={onChangeHandler}
          />
        </MDDropzoneRoot>
      </label>
    </Block>
  );
};
