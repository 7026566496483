import { Stack, Switch } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import MDTypography from "components/MDTypography";
import PageLayout from "examples/CardPage";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ContentHeader from "layouts/pages/widgets/components/ContentHeader";
import Tabs from "layouts/pages/widgets/components/Tabs";
import { useEffect, useState } from "react";
import Main from "../components/main";
import { CounterpartyNameContext } from "../context/counterparty-name-context";
import { initialCompanyValues, initialPhysicalValues } from "../consts";
import { useLoadFile } from "hooks/userLoadFile";
import { AVATAR } from "constants/file-types";

const NewCounterparty = () => {
  const {
    file,
    uploadLocalFile,
    loadFile,
    loadError,
    setLoadError,
    cancelUploadLocalFile,
    filePath,
  } = useLoadFile(AVATAR);

  const [isCompany, setIsCompany] = useState(false);
  const [name, setName] = useState("");

  useEffect(() => {
    setName("");
  }, [isCompany]);

  return (
    <PageLayout header={<DashboardNavbar />}>
      <CounterpartyNameContext.Provider value={{ setName, isCompany, file }}>
        <ContentHeader
          info={{
            img: filePath,
            uploadLocalFile,
            title: name || "Новый контрагент",
          }}
          action={
            <Stack direction="row" spacing={0.5} alignItems="center">
              <MDTypography color="text" style={{ fontSize: pxToRem(14) }}>
                {isCompany ? "Юридическое лицо" : "Физическое лицо"}
              </MDTypography>
              <Switch checked={isCompany} onChange={() => setIsCompany((v) => !v)} />
            </Stack>
          }
          error={!!loadError}
        />

        <Tabs
          defaultTab={1}
          items={{
            headers: ["Основные"],
            components: [
              <Main
                key="main"
                type="add"
                initialValues={isCompany ? initialCompanyValues : initialPhysicalValues}
                loadFile={loadFile}
                cancelUploadLocalFile={cancelUploadLocalFile}
                setLoadError={setLoadError}
              />,
            ],
          }}
        />
      </CounterpartyNameContext.Provider>
    </PageLayout>
  );
};

export default NewCounterparty;
