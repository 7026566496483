/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import List from "./MDFilterList";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

// NewUser page components
import Input from "./MDFiltersInput";
import useOnClickOutside from "hooks/useOnClickOutside";
import MDTypography from "../MDTypography";
import {
  ISelectedFilter,
  addItemToFilters,
  clearFilters,
  filterSelectorById,
} from "../../Redux/filters/slice";
import { IFilter, IFilterListItem } from "layouts/pages/widgets/components/TopPanel/interfaces";
import pxToRem from "assets/theme/functions/pxToRem";
import { Button, Card, useMediaQuery, useTheme } from "@mui/material";
import { item } from "examples/Sidenav/styles/sidenavItem";

//types
type SelectItemType = (listItem: IFilterListItem, filter: IFilter, inputValue?: string) => void;

interface Props {
  filters: IFilter[];
  onClose: () => void;
}

function Filters({ filters, onClose }: Props) {
  const theme = useTheme();
  const matchesLG = useMediaQuery(theme.breakpoints.down("lg"));
  const ref = useRef();
  const dispatch = useDispatch();
  const selectedItem: SelectItemType = (listItem, filter, inputValue) => {
    const selectedFilter: ISelectedFilter = {
      filterId: filter.id,
      filterItemId: listItem.id,
      filterUrl: filter.url,
      listUrl: listItem.url,
      value: inputValue,
      exclucive: filter.exclusive,
      isInput: listItem.isInput,
      name: listItem.name,
      tag: filter.tag,
    };
    dispatch(addItemToFilters(selectedFilter));
  };

  useOnClickOutside(ref, () => {
    onClose();
  });

  return (
    <Grid
      container
      justifyContent="space-between"
      spacing={2}
      p={1}
      id="testidfilter"
      maxHeight="50vh"
      bgcolor={"transparent"}
      columns={{ xs: 2, lg: (filters?.length + 1) * 2 }}
    >
      {filters?.map((filter, index) => (
        <Grid
          item
          key={filter.id}
          xs={2}
          style={{
            display: "flex",
            flexDirection: "column",
            minWidth: pxToRem(170),
          }}
        >
          <MDTypography
            variant="button"
            color="info"
            fontWeight="regular"
            style={{
              textAlign: matchesLG ? "center" : "left",
              fontSize: pxToRem(12),
            }}
          >
            {filter.title}
          </MDTypography>
          <List style={{ width: "100%" }}>
            <Card sx={{ p: 1, boxShadow: "0 4px 15px rgba(40, 64, 105, 0.55)" }}>
              {filter?.list.map((list) => (
                <Fragment key={`${filter.id}${list.id}`}>
                  <FilterListItem listItem={list} selectItem={selectedItem} filter={filter} />
                </Fragment>
              ))}
            </Card>
          </List>
        </Grid>
      ))}
      <Grid
        item
        xs={2}
        style={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: pxToRem(24),
        }}
      >
        <Card
          sx={{ p: 1 }}
          style={{ marginTop: pxToRem(26), boxShadow: "0 4px 15px rgba(40, 64, 105, 0.55)" }}
        >
          <ListItem>
            <ListItemButton
              sx={{
                background: theme.palette.filters.main.background,
                color: theme.palette.error.main,
                borderRadius: 2,
              }}
            >
              <ListItemText
                onClick={() => dispatch(clearFilters())}
                primary="Сбросить фильтр"
                primaryTypographyProps={{
                  fontSize: pxToRem(13),
                  letterSpacing: 0.13,
                  textAlign: matchesLG ? "center" : "left",
                }}
                style={{ textAlign: matchesLG ? "center" : "left" }}
              />
            </ListItemButton>
          </ListItem>
        </Card>
      </Grid>
    </Grid>
  );
}

interface IFilterListItemProps {
  selectItem: SelectItemType;
  listItem: IFilterListItem;
  filter: IFilter;
}

function FilterListItem({ listItem, selectItem, filter }: IFilterListItemProps) {
  const theme = useTheme();
  const matchesLG = useMediaQuery(theme.breakpoints.down("lg"));
  const { palette } = theme;
  const currentFilter = useSelector(filterSelectorById(filter.id, listItem.id));
  const [value, setValue] = useState((currentFilter?.isInput && currentFilter?.value) || "");
  const active =
    currentFilter?.filterId === filter.id && currentFilter?.filterItemId === listItem.id;
  const handlerChange = (e: any) => {
    setValue(e.target.value);
    selectItem(listItem, filter, e.target.value);
  };

  useEffect(() => {
    if (!currentFilter) {
      setValue("");
    }
  }, [currentFilter]);

  const inputRef = useRef<HTMLInputElement>(null);

  const onItemTextClick = () => {
    if (!listItem.isInput) selectItem(listItem, filter, value);
    if (listItem.isInput && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ListItem>
      <ListItemButton
        sx={{
          background: active
            ? palette.filters.selected.background
            : palette.filters.main.background,
          color: active ? palette.filters.selected.text : palette.filters.main.text,
          borderRadius: 2,
          display: listItem.isInput ? "flex" : "block",
          justifyContent: matchesLG ? "center" : "space-between",
        }}
      >
        <ListItemText
          onClick={onItemTextClick}
          primary={listItem.name}
          primaryTypographyProps={{
            fontSize: pxToRem(13),
            letterSpacing: 0.13,
            textAlign: isMobile ? "center" : "left",
          }}
          style={{ textAlign: matchesLG ? "center" : "left" }}
        />

        {listItem.isInput && (
          <Input
            inputRef={inputRef}
            type="text"
            value={value}
            inputProps={{ type: "number", min: 0 }}
            onChange={handlerChange}
            style={{ marginTop: 0, width: "25%" }}
          />
        )}
      </ListItemButton>
    </ListItem>
  );
}

function FilterClearItem() {
  const theme = useTheme();
  const { palette } = theme;
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ListItem>
      <ListItemButton
        sx={{
          background: palette.filters.main.background,
          color: palette.filters.main.text,
          borderRadius: 2,
          justifyContent: isMobile ? "center" : "space-between",
        }}
      >
        <ListItemText
          onClick={() => dispatch(clearFilters())}
          primary={"Сбросить фильтр"}
          primaryTypographyProps={{
            fontSize: pxToRem(13),
            letterSpacing: 0.13,
            textAlign: isMobile ? "center" : "left",
            fontWeight: 500,
            color: "error",
          }}
          style={{ textAlign: isMobile ? "center" : "left" }}
        />
      </ListItemButton>
    </ListItem>
  );
}

export default Filters;
