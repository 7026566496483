import * as Yup from "yup";

export const fieldsValidationScheme = Yup.object().shape({
  avatar_id: Yup.string().notRequired(),
  is_shop: Yup.boolean().notRequired(),
  code: Yup.string().notRequired(),
  name: Yup.string().required("Заполните поле Название"),
  address: Yup.string().required("Заполните поле Адрес"),
  branches: Yup.array()
    .of(
      Yup.mixed().test(
        "is-number-or-string",
        "Tag must be a number or a string",
        (value) => typeof value === "number" || typeof value === "string"
      )
    )
    .min(1, "Выберите минимум 1 Направление бизнеса")
    .required("Выберите минимум 1 Направление бизнеса"),
  warehouses: Yup.array()
    .of(
      Yup.mixed().test(
        "is-number-or-string",
        "Tag must be a number or a string",
        (value) => typeof value === "number" || typeof value === "string"
      )
    )
    .min(1, "Выберите минимум 1 Склад")
    .required("Выберите минимум 1 Склад"),
  phone: Yup.string().required("Заполните поле Телефон"),
});
