import styled from "@emotion/styled";
import MuiInput from "@mui/material/Input";

export default styled(MuiInput)(({ theme }) => ({
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
  "& input": { padding: 0 },
}));
