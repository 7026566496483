import { api } from ".";
import { IBankRequisite } from "./interfaces";
import { ICompanyCounterparty, IPhysicalCounterparty } from "./interfaces/ICounterparty";
import { ICounterpartiesTableResponse } from "./interfaces/ICounterpartyTable";

export interface IAddPhysicalCounterparty {
  id?: number | string;
  avatar_id?: number | string;
  is_company: boolean;
  counterparty_type_id: number | null;
  first_name: string;
  last_name: string;
  middle_name: string;
  birthday: string;
  phone_1: string;
  phone_2: string;
  email: string;
  address: string;
}

export interface IPhysicalCounterpartyResponse {
  counterparty: IPhysicalCounterparty;
}

export interface IAddCompanyCounterparty {
  id?: number | string;
  avatar_id?: number | string;
  is_company: boolean;
  counterparty_type_id: number;
  name: string;
  inn: number | string;
  kpp: string;
  head: string;
  registered_at: string;
  phone_1: string;
  phone_2: string;
  email: string;
  legal_address: string;
}

export type ICounterparty = IPhysicalCounterparty | ICompanyCounterparty;

export interface ICompanyCounterpartyResponse {
  counterparty: ICompanyCounterparty;
}

export interface ICompanyCounterpartyResponse {
  counterparty: ICompanyCounterparty;
}

export interface ICounterpartyResponse {
  counterparty: IPhysicalCounterparty | ICompanyCounterparty;
}

export interface IBankRequisiteResponse {
  bankRequisites: IBankRequisite[];
  bankRequisitesCount: number;
}

export interface ICounterpartiesResponse {
  counterparties: (IPhysicalCounterparty | ICompanyCounterparty)[];
}

export interface IAddBankRequisite {
  id?: string | number;
  counterpartyId: string | number;
  name: string;
  bik: string;
  kpp: string;
  payment_account: string;
  address: string;
}

export interface IContact {
  id?: number | string;
  first_name: string;
  last_name: string;
  middle_name: string;
  birthday: string;
  phone_1: string;
  phone_2: string;
  email: string;
  address: string;
}

export interface IContectsResponse {
  contacts: IContact[];
  contactsCount: number;
}

export interface IAddContacts {
  contact: IContact;
  counterpartyId: number | string;
}

export interface ICounterpartyHistory {
  current_page: number;
  data: IHistoryDate[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: IHistoryLink[];
  next_page_url: any;
  path: string;
  per_page: number;
  prev_page_url: any;
  to: number;
  total: number;
}

export interface ICounterpartyHistoryResponse {
  counterparties: ICounterpartyHistory;
}

export interface IHistoryDate {
  id: number;
  icon: string;
  color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light";
  title: string;
  description: string;
  dateTime: string;
}

export interface IHistoryLink {
  url?: string | null;
  label: string;
  active: boolean;
}

export interface IDeliveryTypeResponse {
  deliveryTypes: IDeliveryType[];
  deliveryTypesCount: number;
}

export interface IDeliveryType {
  id: number;
  name: string;
  price: string;
  is_active: number;
  created_at: string;
}

export const counterpartiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCounterparties: builder.query<ICounterpartiesResponse, string>({
      query: (queryParams) => `counterparties?${queryParams}`,
      transformErrorResponse: (response: any) => response.data.message,
    }),
    getCounterpartiesTable: builder.query<ICounterpartiesTableResponse, string>({
      query: (queryParams) => `tables/counterparties?${queryParams}`,
      transformErrorResponse: (response: any) => response?.data?.message || "",
      providesTags: ["counterparty"],
    }),
    getCounterpartyById: builder.query<ICounterpartyResponse, string | number>({
      query: (counterpartyId) => `counterparties/${counterpartyId}`,
      providesTags: ["counterparty"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    getCounterpartyBankRequisiteById: builder.query<IBankRequisiteResponse, string | number>({
      query: (counterpartyId) => `counterparties/${counterpartyId}/bank_requisites`,
      providesTags: ["counterparty_bank_requisites"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    addCounterpartyBankRequisiteById: builder.mutation<IBankRequisiteResponse, IAddBankRequisite>({
      query: ({ counterpartyId, ...body }) => ({
        url: `counterparties/${counterpartyId}/bank_requisites`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["counterparty_bank_requisites"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    editCounterpartyBankRequisiteById: builder.mutation<IBankRequisiteResponse, IAddBankRequisite>({
      query: ({ counterpartyId, id, ...body }) => ({
        url: `counterparties/${counterpartyId}/bank_requisites/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["counterparty_bank_requisites"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    addCounterparty: builder.mutation<
      ICounterpartyResponse,
      IAddCompanyCounterparty | IAddPhysicalCounterparty
    >({
      query: (body) => ({
        url: "counterparties",
        method: "POST",
        body,
      }),
      invalidatesTags: ["counterparty"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),

    editCounterparty: builder.mutation<
      ICounterpartyResponse,
      IAddCompanyCounterparty | IAddPhysicalCounterparty
    >({
      query: (body) => ({
        url: `counterparties/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["counterparty"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),

    getAllContactsByCounterpartyId: builder.query<IContectsResponse, string | number>({
      query: (counterpartyId) => `counterparties/${counterpartyId}/contacts`,
      providesTags: ["counterparty_contacts"],
    }),
    addCounterpartyContactsById: builder.mutation<IContectsResponse, IAddContacts>({
      query: ({ counterpartyId, contact }) => ({
        url: `counterparties/${counterpartyId}/contacts`,
        method: "POST",
        body: contact,
      }),
      invalidatesTags: ["counterparty_contacts"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    editCounterpartyContactsById: builder.mutation<IContectsResponse, IAddContacts>({
      query: ({ counterpartyId, contact }) => ({
        url: `counterparties/${counterpartyId}/contacts/${contact.id}`,
        method: "PUT",
        body: contact,
      }),
      invalidatesTags: ["counterparty_contacts"],
    }),
    getCounterpartyHistory: builder.query<ICounterpartyHistoryResponse, string>({
      query: (id) => `counterparties/${id}/history`,
      providesTags: ["counterparty", "counterparty_contacts"],
    }),
    getDeliveryTypes: builder.query<IDeliveryTypeResponse, void>({
      query: () => "delivery_types",
    }),
    getCounterpartiesByQuery: builder.mutation<ICounterpartiesResponse, string>({
      query: (queryParams) => ({
        url: `counterparties?query=${queryParams}`,
        method: "GET",
      }),
      transformErrorResponse: (response: any) => response.data.message,
    }),
  }),
});

export const {
  useGetCounterpartiesQuery,
  useGetCounterpartiesTableQuery,
  useGetCounterpartyByIdQuery,
  useAddCounterpartyMutation,
  useEditCounterpartyMutation,
  useGetCounterpartyBankRequisiteByIdQuery,
  useAddCounterpartyBankRequisiteByIdMutation,
  useEditCounterpartyBankRequisiteByIdMutation,
  useAddCounterpartyContactsByIdMutation,
  useEditCounterpartyContactsByIdMutation,
  useGetAllContactsByCounterpartyIdQuery,
  useGetCounterpartyHistoryQuery,
  useGetDeliveryTypesQuery,
  useGetCounterpartiesByQueryMutation,
} = counterpartiesApi;
