import { CircularProgress } from "@mui/material";
import MDContainer from "components/MDContainer";

const Loader = () => {
  return (
    <MDContainer
      style={{ minHeight: "50vh", position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}
      verticalAligment="center"
      horizontalAligment="center"
    >
      <CircularProgress />
    </MDContainer>
  );
};

export default Loader;
