import { Box, CircularProgress } from "@mui/material";
import * as React from "react";
import Input from "../Input";
import { IInput } from "../Input/interfaces";

type DebounceProps = {
  handleDebounce: (value: string) => void;
  debounceTimeout: number;
  isLoading?: boolean;
};

function DebouncedInput(props: IInput & DebounceProps) {
  const { handleDebounce, debounceTimeout, isLoading, label, mask, outerRef, input, ...rest } =
    props;

  const timerRef = React.useRef<ReturnType<typeof setTimeout>>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      handleDebounce(event.target.value);
    }, debounceTimeout);
  };

  return (
    <Box position={"relative"}>
      <Input
        mask={mask}
        label={label}
        outerRef={outerRef}
        input={{ ...input, ...rest, onChange: handleChange }}
      />
      {isLoading && (
        <CircularProgress size={20} style={{ position: "absolute", right: 30, top: "50%" }} />
      )}
    </Box>
  );
}

export default DebouncedInput;
