import * as Yup from "yup";

export const fieldsValidationScheme = Yup.object().shape({
  code: Yup.string().notRequired(),
  warehouse_id: Yup.number().required("Выберите Склад"),
  nomenclature_id: Yup.number().required("Выберите в справочнике Название товара"),
  vat_id: Yup.number().required("Выберите НДС"),
  quantity: Yup.number()
    .typeError("Значение должно быть числом")
    .required("Заполните поле Количество"),
  price: Yup.number().typeError("Значение должно быть числом").required("Заполните поле Стоимость"),
  discount: Yup.number()
    .typeError("Значение должно быть числом")
    .required("Заполните поле Скидка")
    .min(0)
    .max(100, "Поле Скидка должно быть от 0 до 100"),
  markup: Yup.number().typeError("Значение должно быть числом").required("Заполните поле Наценка"),
});
