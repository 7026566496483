import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import { IAvatar } from "Redux/api/interfaces/ICategory";
import MDBox from "components/MDBox";
import MDContainer from "components/MDContainer";
import MDEditor from "components/MDEditor";
import MDTypography from "components/MDTypography";
import { useFormikContext } from "formik";
import ContentWrapper from "layouts/components/content-wrapper";
import CategoryParentField from "layouts/components/fields/parent-category-field";
import TagsMultiselectField from "layouts/components/fields/tags-multiselect-field";
import Block from "layouts/pages/widgets/components/Block";
import { CustomDropZone } from "layouts/pages/widgets/components/CustomDropZone";
import Input from "layouts/pages/widgets/components/Input";
import { ChangeEvent, useContext } from "react";
import { CategoryNameContext } from "../../context/category-name-context";

interface IEditFieldsProps {
  disabled?: boolean;
  setIsEdit: any;
}

interface IFormikValues {
  id: number;
  code?: string;
  name: string;
  description: string;
  parent_id?: number;
  photo: IAvatar;
  photo_id: number;
  avatar: IAvatar;
  tag_id?: number[];
}

const EditFields = (props: IEditFieldsProps) => {
  const { disabled, setIsEdit } = props;

  const { setName, uploadPhotoLocalFile } = useContext(CategoryNameContext);
  const { values, errors, setFieldValue } = useFormikContext<IFormikValues>();

  const handlerChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;

    setFieldValue(fieldName, fieldValue);

    if (fieldName === "name") {
      setName(fieldValue);
    }
  };

  const getFilesHandler = (files: File[]) => {
    const [file] = files;

    setIsEdit(true);

    setFieldValue("photo", file);
    setFieldValue("photo_id", 1);

    uploadPhotoLocalFile(file);
  };

  const handleChangeDesc = (value: any) => {
    setFieldValue("description", value);
  };

  const getDescription = () => {
    if (disabled) {
      return (
        <Input
          label="Описание"
          input={{
            noPointerEvents: true,
            value: values.description?.replace(/<[^>]*>/g, ""),
            name: "description",
          }}
        />
      );
    }

    return (
      <Grid item xs={12} sm={12}>
        <MDBox mb={1} width="100%">
          <MDTypography component="label" variant="button" fontWeight="regular" color="text">
            Описание
          </MDTypography>
        </MDBox>

        <MDEditor
          onChange={(value) => handleChangeDesc(value)}
          defaultValue={values.description}
          noPointerEvents={disabled}
          toolbarOptions={["fontFamily", "inline", "blockType", "fontSize", "list"]}
          isError={!!errors.description}
        />
      </Grid>
    );
  };

  return (
    <MDContainer gap={3}>
      <ContentWrapper
        aside={
          <Card sx={{ p: 3 }}>
            <Input
              label="Код (необязательно)"
              input={{
                noPointerEvents: false,
                placeholder: "Введите код",
                value: values.code,
                name: "code",
                onChange: handlerChange,
                focus: () => setIsEdit(true),
                error: errors.code,
              }}
            />
          </Card>
        }
      >
        <Block title="Основная информация" wrapperStyles={{ width: "100%" }}>
          <MDContainer gap={3}>
            <Input
              label="Название"
              input={{
                noPointerEvents: disabled,
                placeholder: "Введите название категории",
                value: values.name,
                name: "name",
                onChange: handlerChange,
                error: errors.name,
              }}
            />
          </MDContainer>

          <MDContainer gap={3}>{getDescription()}</MDContainer>

          <MDContainer gap={3}>
            <CategoryParentField
              disabled={disabled}
              error={errors.parent_id}
              initialValueId={values.parent_id}
              onSelect={(value) => setFieldValue("parent_id", value?.id)}
            />
          </MDContainer>

          <MDContainer gap={3}>
            <TagsMultiselectField
              disabled={disabled}
              error={errors?.tag_id}
              initialValueIds={values.tag_id}
              onSelect={(value) =>
                setFieldValue(
                  "tag_id",
                  value.map((v) => v.id)
                )
              }
            />
          </MDContainer>
        </Block>

        <CustomDropZone
          getFilesHandler={getFilesHandler}
          loadedFiles={[values.photo]}
          isError={!!errors.photo_id}
        />
      </ContentWrapper>
    </MDContainer>
  );
};

export default EditFields;
