import { createContext } from "react";

interface IAppContext {
  setName: (name: string) => void;
  file?: any;
  isWebsite: boolean;
  token?: string;
}

export const ECommerceContext = createContext<IAppContext | null>(null);
