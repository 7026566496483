import { Theme } from "@mui/material";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDContainer from "components/MDContainer";
import MDTypography from "components/MDTypography";

import { IProps } from "./interfaces";

const Block = (props: IProps) => {
  const { title = "", children, action, id, wrapperStyles } = props;

  return (
    <Card id={id} style={wrapperStyles}>
      <MDBox
        sx={(theme: Theme) => ({
          background: "transparent",
          borderRadius: 3,
          padding: theme.spacing(3),
          width: "100%",
          flex: "1 1 auto",
          display: "flex",
        })}
      >
        <MDContainer variant="column" gap={3}>
          {title && (
            <MDBox
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <MDTypography fontSize={20} fontWeight="bold" lineHeight="140%" color="dark">
                {title}
              </MDTypography>
              {action}
            </MDBox>
          )}
          {children}
        </MDContainer>
      </MDBox>
    </Card>
  );
};

export default Block;
