import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CustomerCell from "examples/CustomerCell";
import DefaultCell from "examples/DefaultCell";
import DataTable from "examples/Tables/DataTable";
import Loader from "layouts/components/loader";
import { Fragment } from "react";
import { ITable, ITableData } from "./interfaces";

export const Table = <T extends ITableData>(props: ITable<T>) => {
  const { title, filters, data, optionsDots, isLoading, accessorKey } = props;
  const { setQuery, setPage, setPerPage, query, page, perPage } = filters;

  const getContent = () => {
    if (isLoading) {
      return <Loader />;
    }

    return (
      <Fragment>
        {title && (
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {title}
            </MDTypography>
          </MDBox>
        )}

        {!(isLoading && data?.[accessorKey]) && (
          <DataTable
            table={{
              rows: data?.[accessorKey],
              columns: data.columns.map((column) => {
                return {
                  ...column,
                  Header: column.text,
                  accessor: column.key,
                  width: 200,
                  Cell: ({ value }: any) => {
                    if (value?.icon !== undefined) {
                      return (
                        <CustomerCell
                          image={column.has_icon === 1 && value.icon}
                          color={"dark"}
                          name={value.value}
                        />
                      );
                    }
                    return <DefaultCell value={value} />;
                  },
                };
              }),
            }}
            entriesPerPage={{ defaultValue: 10, entries: ["5", "10", "25", "50"] }}
            canSearch
            filterParams={{ page, perPage, query, setPage, setPerPage, setQuery }}
            isSorted
            noEndBorder
            showTotalEntries
            totalEntries={data.pagination.total}
            handleUpdateSource={(value) => setQuery(value)}
            optionDots={optionsDots}
            pagination={{ variant: "contained", color: "primary" }}
          />
        )}
      </Fragment>
    );
  };

  return <Card style={{ maxWidth: "100%" }}>{getContent()}</Card>;
};
