import { useContext, useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import { Field, useFormikContext } from "formik";
import MDContainer from "components/MDContainer";
import Block from "layouts/pages/widgets/components/Block";
import DatePicker from "layouts/pages/widgets/components/DatePicker";
import Input from "layouts/pages/widgets/components/Input";
import { IAddUser } from "../../../../Redux/api/users.api";
import BranchField from "layouts/components/fields/branch-field";
import DepartmentField from "layouts/components/fields/departmant-field";
import UserRoleField from "layouts/components/fields/user-role-filed";
import { NewUserContext } from "../context/new-user-context";
import InputFormikField from "layouts/components/fields/input-formik-field";

interface IMainFieldsProps {
  disabled?: boolean;
}
const MainFields = ({ disabled }: IMainFieldsProps) => {
  const { setName, setRole } = useContext(NewUserContext);
  const { initialValues, values, errors, setFieldValue } = useFormikContext<IAddUser>();

  useEffect(() => {
    if (values?.first_name || values?.middle_name || values?.last_name) {
      setName(`${values?.first_name} ${values?.middle_name || ""} ${values?.last_name}`);
    }
    return () => setName("");
  }, [values.first_name, values.middle_name, values.last_name]);

  return (
    <Block title="Основная информация" id="basic-info" wrapperStyles={{ width: "100%" }}>
      <MDContainer gap={3}>
        <Field
          name="last_name"
          label="Фамилия"
          placeholder="Введите  фамилию"
          disabled={disabled}
          component={InputFormikField}
        />
        <Field
          name="first_name"
          label="Имя"
          placeholder="Введите  имя"
          disabled={disabled}
          component={InputFormikField}
        />
      </MDContainer>

      <MDContainer gap={3}>
        <Field
          name="middle_name"
          label="Отчество (необязательно)"
          placeholder="Введите отчество"
          disabled={disabled}
          component={InputFormikField}
        />

        <DatePicker
          disabled={disabled}
          label="Дата рождения (необязательно)"
          placeholder="Выберите дату рождения"
          value={values.birthday}
          name="birthday"
          error={errors["birthday"]}
          onChange={([date]: Dayjs[]) => {
            setFieldValue("birthday", dayjs(date).format("YYYY-MM-DD"));
          }}
        />
      </MDContainer>

      <MDContainer gap={3}>
        <BranchField
          disabled={disabled}
          initialValueId={initialValues?.branch_id}
          error={errors?.branch_id}
          onSelect={(value) => setFieldValue("branch_id", value.id)}
        />

        <DepartmentField
          disabled={disabled}
          initialValueId={initialValues.department_id}
          error={errors.department_id}
          onSelect={(value) => setFieldValue("department_id", value.id)}
        />
      </MDContainer>

      <MDContainer gap={3}>
        <UserRoleField
          disabled={disabled}
          initialValueId={initialValues.role_id}
          error={errors.role_id}
          onSelect={(value) => {
            setRole(value.label);
            setFieldValue("role_id", value.id);
          }}
        />

        <Field
          name="login"
          label="Логин/почта"
          placeholder="Введите логин/почту"
          disabled={disabled}
          component={InputFormikField}
        />
      </MDContainer>

      <MDContainer gap={3}>
        <Input
          label="Телефон"
          mask="+7 (999) 999 99 99"
          input={{
            noPointerEvents: disabled,
            defaultValue: initialValues.phone,
            name: "phone",
            placeholder: "+0 (000) 000 00 00",
            blur: (value) => {
              setFieldValue("phone", value);
            },
            error: errors.phone,
          }}
        />
      </MDContainer>
    </Block>
  );
};

export default MainFields;
