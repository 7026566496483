import { api } from ".";

export interface INomenclatureTypeResponse {
  nomenclatureTypes: INomenclatureType[];
  nomenclatureTypesCount: number;
}

export interface INomenclatureType {
  id: number;
  name: string;
  avatar: string;
  created_at: string;
}

export const nomenclatureTypesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getNomenclatureTypes: builder.query<INomenclatureTypeResponse, void>({
      query: () => `nomenclature_types`,
      transformErrorResponse: (response: any) => response.data.message,
    }),
  }),
});

export const { useGetNomenclatureTypesQuery } = nomenclatureTypesApi;
