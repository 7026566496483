/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { memo, useEffect, useRef, useState } from "react";

// Dropzone components
import Dropzone from "dropzone";

// Dropzone styles
import "dropzone/dist/dropzone.css";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Custom styles for the MDDropzone
import MDDropzoneRoot from "components/MDDropzone/MDDropzoneRoot";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import { IFile, IFileResponse, NewFile, DeleteFile } from "api/files/new";
import { toast } from "react-hot-toast";
import { EMPLOYMENT_CONTRACT_ID } from "constants/file-types";

export interface IInfo {
  img?: string;
  setImgFile?: (files: IFile[]) => void;
  description?: string;
}

interface Props {
  options: {
    [key: string | number]: any;
    defaultFiles?: IFile[];
  };
  info?: IInfo;
  error?: string;
  uploadFunction?: (file: File) => Promise<IFileResponse>;
  removeFunction?: (fileId: string | number) => Promise<IFileResponse>;
}

interface IOptions {
  dictDefaultMessage: string;
  dictRemoveFile: string;
  addRemoveLinks: boolean;
  maxFiles?: number;
  init?: any;
  simpleMessage?: boolean;
}

const defaultOptions: IOptions = {
  dictDefaultMessage: "Перетащите файлы сюда для загрузки",
  dictRemoveFile: "Удалить файл",
  addRemoveLinks: true,
};

const MDDropzone = memo(function UploadPhoto(props: Props): JSX.Element {
  const {
    info,
    error,
    options,
    uploadFunction = (file: File) => NewFile({ file, fileTypeId: EMPLOYMENT_CONTRACT_ID }),
    removeFunction = (fileId: string | number) => DeleteFile(fileId),
  } = props;

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { setImgFile } = info;

  const [files, setFiles] = useState<IFile[]>(options.defaultFiles);

  const dropzoneRef = useRef<HTMLDivElement | null>(null);

  const onChangePhoto = async (file: File) => {
    if (file) {
      const response = await uploadFunction(file);
      if (setImgFile && response?.file?.id) {
        setFiles((f) => [...f, response.file]);

        toast.success(`Файл ${file.name} успешно добавлен!`, {
          duration: 5000,
        });
      }
    }
  };

  useEffect(() => {
    if (files !== options.defaultFiles) setImgFile(files);
  }, [files]);

  useEffect(() => {
    Dropzone.autoDiscover = false;

    if (options.singleFile) {
      defaultOptions.maxFiles = 1;
    }

    defaultOptions.init = function () {
      if (options.singleFile) {
        //@ts-ignore
        this.on("maxfilesexceeded", function (file: any) {
          //@ts-ignore
          this.removeFile(file);
          toast.error(`Достигнут максимум кол-ва загружаемых файлов: ${defaultOptions.maxFiles}`, {
            duration: 5000,
          });
        });
      }

      if (files && files.length > 0) {
        files.forEach((file) => {
          const mockFile = { name: file.name };
          //@ts-ignore
          this.emit("addedfile", mockFile);
          //@ts-ignore
          this.emit("thumbnail", mockFile, file.path);
          //@ts-ignore
          this.emit("complete", mockFile);
          //@ts-ignore
          this.files.push(mockFile);
          //@ts-ignore
          this.options.maxFiles -= 1;
        });
      }

      //@ts-ignore
      this.on("removedfile", function (file: any) {
        setFiles((f) => f.filter((fl) => fl.name !== file.name));
        const fileToRemove = files.find((f) => f.name === file.name);
        if (fileToRemove?.id) {
          removeFunction(fileToRemove.id);
        }
      });

      //@ts-ignore
      this.on("addedfile", function (file) {
        onChangePhoto(file);
      });
    };

    function createDropzone() {
      return new Dropzone(dropzoneRef.current, {
        ...defaultOptions,
        ...options,
        url: "#",
        autoProcessQueue: false,
        uploadMultiple: false,
      });
    }

    createDropzone();
  }, []);

  return (
    <MDDropzoneRoot
      ref={dropzoneRef}
      className={`form-control dropzone`}
      ownerState={{ darkMode, isError: !files?.length && !!error }}
    >
      <MDBox className="fallback" bgColor="transparent">
        <input name="file" type="file" max={1} />
      </MDBox>
    </MDDropzoneRoot>
  );
});

export default MDDropzone;
