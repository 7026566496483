import { ISalesChannel, useGetSalesChanelsQuery } from "../../../Redux/api/sales-channels.api";
import Select from "layouts/pages/widgets/components/Select";
import { IItem } from "layouts/pages/widgets/components/Select/interfaces";
import { useEffect, useMemo, useState } from "react";

interface ISalesChanelsFieldProps {
  disabled?: boolean;
  error?: string;
  initialValueId?: number | string;
  onSelect?: (value: IItem) => void;
}

const formatToSelectItem = (chanels?: ISalesChannel[]): IItem[] => {
  if (!chanels) return [];
  return chanels.map((ch) => ({
    id: ch.id,
    label: ch.name,
    value: ch.id,
    icon: ch.avatar,
  }));
};

const SalesChanelsField = ({
  disabled,
  error,
  initialValueId,
  onSelect,
}: ISalesChanelsFieldProps) => {
  const { data } = useGetSalesChanelsQuery(null, { refetchOnMountOrArgChange: true });
  const [selectedOption, setSelectedOption] = useState<IItem | null>(null);
  const options = useMemo(() => formatToSelectItem(data?.salesChannels), [data]);

  useEffect(() => {
    const typeOption = options.find((item) => item.id == initialValueId);
    if (typeOption) {
      setSelectedOption(typeOption);
    }
  }, [options, initialValueId, disabled]);

  const onSelectItem = (value: IItem) => {
    setSelectedOption(value);
    onSelect(value);
  };

  return (
    <Select
      label="Каналы продаж"
      placeholder="Выберите из списка"
      noPointerEvents={disabled}
      error={error}
      popupIcon={disabled ? null : undefined}
      select={{
        value: selectedOption,
        items: options,
        onSelect: onSelectItem,
      }}
    />
  );
};

export default SalesChanelsField;
