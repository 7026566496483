/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { PropsWithChildren, ReactNode } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { routes } from "constants/route";

// Declaring props types for the Breadcrumbs
interface Props {
  icon: ReactNode;
  title: string;
  route: string | string[];
  light?: boolean;
  [key: string]: any;
}

const breadcrumbsDictionary: Record<string, string> = {
  profile: "Профиль",
  settings: "Настройки",
  "sign-in": "Войти",
  calls: "Звонки",
  connect: "Подключение",
  orders: "Заказы",
  "order-list": "Список заказов",
  "order-details": "Информация",
  list: "Список",
  new: "Новый",
  tasks: "Задачи",
  users: "Пользователи",
  "list-users": "Список пользователей",
  "new-user": "Новый пользователь",
  counterparty: "Контрагенты",
  "list-counterparty": "Список контрагентов",
  "new-counterparty": "Новый контрагент",
  branches: "Бизнесы",
  "list-branches": "Список бизнесов",
  "new-branch": "Новый бизнес",
  ecommerce: "Е-Торговля",
  "list-ecommerce": "Список сайтов/приложений",
  "new-ecommerce": "Новый сайт/приложение",
  categories: "Категории",
  "list-categories": "Список категорий",
  "new-category": "Новая категория",
  pages: "Страницы",
  "pricing-page": "Цены",
  widgets: "Виджеты",
  charts: "Графики",
  notifications: "Уведомления",
  applications: "Приложения",
  kanban: "Канбан",
  "data-tables": "Таблицы",
  products: "Продукты",
  "new-product": "Новый продукт",
  "edit-product": "Редактировать продукт",
  "product-page": "Страница продукта",
  nomenclatures: "Товары/Услуги",
  "list-nomenclatures": "Список товаров и услуг",
  "new-nomenclature": "Новый товар/услуга",
  dashboards: "Информационные панели",
  analytics: "Аналитика",
  sales: "Продажи",
  "new-order": "Новый заказ",
  "new-shop": "Новый магазин",
  "new-warehouse": "Новый склад",
  shops: "Магазины",
  "list-warehouses": "Список складов",
  "list-inventories": "Складские остатки",
  "list-shops": "Список магазинов",
  warehouses: "Склады",
  inventories: "Остатки",
  "new-inventory": "Добавить остатки",
};

interface IwrapLinkIfRouteIsDefinedProps extends PropsWithChildren {
  route: string;
}

const WrapLinkIfRouteIsDefined = ({ route, children }: IwrapLinkIfRouteIsDefinedProps) => {
  if (Object.values(routes).find((v) => v === route)) {
    return (
      <Link to={route} key={route}>
        {children}
      </Link>
    );
  }
  return <>{children}</>;
};

function Breadcrumbs({ icon, title, route, light = false }: Props): JSX.Element {
  const routes: string[] | any = route.slice(0, -1);

  return (
    <MDBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: ({ palette: { white, grey } }) => (light ? white.main : grey[600]),
          },
        }}
      >
        <Link to="/">
          <MDTypography
            component="span"
            variant="body2"
            color={light ? "white" : "dark"}
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0 }}
          >
            <Icon>{icon}</Icon>
          </MDTypography>
        </Link>
        {routes.map((el: string, index: number) => (
          <WrapLinkIfRouteIsDefined route={`/${routes.slice(0, index + 1).join("/")}`} key={el}>
            <MDTypography
              component="span"
              variant="button"
              fontWeight="regular"
              color={light ? "white" : "dark"}
              opacity={light ? 0.8 : 0.5}
              sx={{ lineHeight: 0 }}
            >
              {breadcrumbsDictionary[el] || el}
            </MDTypography>
          </WrapLinkIfRouteIsDefined>
        ))}
        <MDTypography
          variant="button"
          fontWeight="regular"
          color={light ? "white" : "dark"}
          sx={{ lineHeight: 0 }}
        >
          {breadcrumbsDictionary[title] || title.replace("-", " ")}
        </MDTypography>
      </MuiBreadcrumbs>
      <MDTypography fontWeight="bold" variant="h6" color={light ? "white" : "dark"} noWrap>
        {breadcrumbsDictionary[title] || title.replace("-", " ")}
      </MDTypography>
    </MDBox>
  );
}

export default Breadcrumbs;
