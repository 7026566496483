import { ICurrentWarehouse } from "Redux/api/warehouses.api";

export const getInitialValues = (warehouse: { warehouse: ICurrentWarehouse }) => {
  if (warehouse?.warehouse) {
    return {
      id: warehouse.warehouse.id,
      avatar_id: warehouse.warehouse.avatar?.id || null,
      code: warehouse.warehouse.code,
      name: warehouse.warehouse.name,
      owned_by_us: warehouse.warehouse.owned_by_us,
      address: warehouse.warehouse.address,
      storage_capacity: warehouse.warehouse.storage_capacity,
      storage_unit_id: warehouse.warehouse.storage_unit?.id,
      city_id: warehouse.warehouse.city?.id,
      counterparty_id: warehouse.warehouse.counterparty?.id,
    };
  }
};
