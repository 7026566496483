import * as Yup from "yup";

export const fieldsValidationScheme = Yup.object().shape({
  code: Yup.string().notRequired(),
  name: Yup.string().required("Заполните поле Название"),
  description: Yup.string().required("Заполните поле Описание"),
  parent_id: Yup.string(),
  photo_id: Yup.number().required("Загрузите картинку").min(1, "Загрузите картинку"),
  tag_id: Yup.array().of(Yup.number().required()).notRequired(),
});
