import { postHandler } from "api/handlers";

export const Logout = async (allDevices: boolean) => {
  const token = localStorage.getItem("token");
  const { data } = await postHandler(
    "/user/logout",
    {
      from_all_devices: allDevices,
    },
    token && {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return data;
};
