import { Box, IconButton, MenuItem, TextField } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import pxToRem from "assets/theme/functions/pxToRem";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { IInventory } from "../../../../Redux/api/inventories.api";
import { getProductName } from "./search-inventories-field";
import {
  useAddNomenclatureToOrderMutation,
  useEditNomenclatureInOrderMutation,
} from "../../../../Redux/api/orders.api";
import FormControls from "layouts/components/form-controls";
import { useParams } from "react-router-dom";
import { createPortal } from "react-dom";
import { useGetVatsQuery } from "../../../../Redux/api/vats.api";

interface IOrderNomenclatureFieldProps {
  onDelete?: () => void;
  item: IInventory;
  editId?: number | string;
  onClose: () => void;
}

const OrderNomenclatureField = ({
  item,
  onDelete,
  editId,
  onClose,
}: IOrderNomenclatureFieldProps) => {
  const { id } = useParams();

  const [count, setCount] = useState(item.quantity);
  const [price, setPrice] = useState<number>(Number(item?.price) || 0);
  const [vatId, setVatId] = useState<number>(item.vat.id);
  const { data } = useGetVatsQuery();

  const [addToOrder, addResult] = useAddNomenclatureToOrderMutation();
  const [editInOrder, editResult] = useEditNomenclatureInOrderMutation();

  const update = editId ? editInOrder : addToOrder;

  return (
    <Box
      sx={{
        display: "flex",
        gap: 3,
        width: "100%",
        alignItems: "flex-start",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "flex-start",
        }}
      >
        <img
          src={item?.nomenclature?.avatar?.path}
          alt=""
          style={{
            width: 24,
            minWidth: 24,
            height: 24,
            borderRadius: "50%",
            overflow: "hidden",
            objectFit: "contain",
            objectPosition: "center",
          }}
        />

        <MDTypography color="dark" fontSize={12} lineHeight="19px">
          {getProductName(item)}
        </MDTypography>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <TextField
          type="number"
          label="Кол-во"
          value={count}
          inputProps={{
            min: 1,
            max: item.quantity,
          }}
          sx={{ width: pxToRem(106) }}
          onChange={(e) => setCount(+e.target.value)}
        />

        <TextField
          label="Ед. изм"
          value={item?.nomenclature?.unit.name}
          sx={{ width: pxToRem(80), "& svg": { display: "block" } }}
        />

        <TextField
          sx={{
            width: pxToRem(106),
            "& input": {
              paddingRight: "28px",
            },
            "&:after": {
              content: '" руб"',
              position: "absolute",
              top: "8px",
              right: "7px",
              fontSize: "14px",
            },
          }}
          type={"number"}
          label="Цена за ед."
          value={price}
          onChange={(e) => setPrice(Number(e.target.value))}
        />

        <TextField
          select
          label="В т.ч. НДС"
          SelectProps={{
            value: item.vat.id,
            onChange: (e) => {
              setVatId(+e.target.value);
            },
          }}
          sx={{ width: pxToRem(106), "& svg": { display: "block" } }}
        >
          {data?.vats?.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>

        <TextField sx={{ width: pxToRem(106) }} label="Итого" value={+price * +count + " руб"} />

        <IconButton onClick={onDelete}>
          <CloseOutlinedIcon
            style={{
              width: 16,
              height: 16,
            }}
          />
        </IconButton>
      </Box>

      {createPortal(
        <FormControls
          cancelAction={onClose}
          submitAction={() => {
            update({
              nomenclatureId: editId,
              orderId: id,
              warehouse_nomenclature_id: item.id,
              quantity: +count,
              storage_unit_id: item?.nomenclature?.unit?.id,
              price,
              vat_id: vatId,
            }).then(() => onClose());
          }}
        />,
        document.getElementById("root")!
      )}
    </Box>
  );
};

export default OrderNomenclatureField;
