import { Autocomplete } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import DebouncedInput from "layouts/pages/widgets/components/DebouncedInput";
import {
  IInventoryNomenclature,
  useGetNomenclaturesQuery,
} from "../../../../../Redux/api/inventories.api";
import { useGetNomenclatureByIdQuery } from "../../../../../Redux/api/nomenclatures.api";

interface INomenclatureFieldProps {
  disabled?: boolean;
  onBlur?: () => void;
  onSelect?: (value: IInventoryNomenclature) => void;
  error?: string;
  initialValueId?: number;
}

const NomenclatureField = ({
  disabled,
  onBlur,
  onSelect,
  error,
  initialValueId,
}: INomenclatureFieldProps) => {
  const [value, setValue] = useState<string>("");
  const { data: nomenclatures, isLoading, refetch } = useGetNomenclaturesQuery(`query=${value}`);
  const { data: initNomenclature } = useGetNomenclatureByIdQuery(String(initialValueId));
  const [selectedOption, setSelectedOption] = useState<IInventoryNomenclature | null>(null);

  const options: IInventoryNomenclature[] = useMemo(() => {
    if (value) {
      return nomenclatures?.nomenclatures || [];
    } else {
      return [];
    }
  }, [nomenclatures]);

  const onSelectHandler = (e: any, newValue: IInventoryNomenclature) => {
    setSelectedOption(newValue);
    onSelect(newValue);
  };

  useEffect(() => {
    setSelectedOption(initNomenclature?.nomenclature);
  }, [initNomenclature]);

  useEffect(() => {
    if (value.length > 2) {
      refetch();
    }
  }, [value]);

  return (
    <Autocomplete
      style={{ width: "100%", pointerEvents: disabled ? "none" : "auto" }}
      isOptionEqualToValue={(op, val) => op?.name === val?.name}
      getOptionLabel={(option: IInventoryNomenclature) => option?.name}
      options={options}
      filterOptions={(x) => x}
      id="nomenclature"
      popupIcon={disabled ? null : undefined}
      noOptionsText="Нет результатов"
      onBlur={onBlur}
      onChange={onSelectHandler}
      value={selectedOption}
      renderInput={(params) => (
        <DebouncedInput
          label="Название"
          debounceTimeout={500}
          handleDebounce={setValue}
          isLoading={isLoading}
          input={{
            noPointerEvents: disabled,
            ...params,
            placeholder: "Найти в справочнике",
            value: value,
            error: error,
          }}
        />
      )}
    />
  );
};

export default NomenclatureField;
