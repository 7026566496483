import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import {
  useCreateApplicationMutation,
  useEditApplicationMutation,
} from "../../../../Redux/api/applications.api";
import EditContainer from "../../../components/edit-container";
import FormControls from "../../../components/form-controls";
import ContentWrapper from "../../../components/content-wrapper";
import AddUpdateAlerts from "layouts/components/add-update-alerts";
import AccountIntegration from "./account-integration";
import type { IFormikValues } from "./main-fieldset";
import MainFieldset from "./main-fieldset";
import { routes } from "constants/route";
import { mainValidationScheme } from "../schemas/mainValidationSchema";
import AsideCodeBlock from "layouts/components/aside-code-block";
import { IMain } from "./interfaces";
import { ECommerceContext } from "../context/app-context";
import { delay } from "utils/delay";

const Main = (props: IMain) => {
  const {
    initialValues,
    edit = false,
    loadFile,
    cancelUploadLocalFile,
    cancelPhotoUploadLocalFile,
    setLoadError,
  } = props;

  const { file } = useContext(ECommerceContext);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [createApp, createResult] = useCreateApplicationMutation();
  const [editApp, editResult] = useEditApplicationMutation();

  const navigate = useNavigate();

  const createResultHandler = async () => {
    if (createResult.isSuccess && createResult?.data?.application.id) {
      await delay(1000);
      navigate(routes.ECOMMERCE + "/" + createResult?.data?.application.id);
    }
  };

  const editHandler = (values: IFormikValues) => {
    editApp(values);
    setIsEdit(false);
  };

  useEffect(() => {
    createResultHandler();
  }, [createResult]);

  const handleTokenActiveChange = (value: boolean) => {
    editApp({ ...initialValues, is_token_active: value });
  };

  const handleAppCode = (setFieldValue: any) => (code: string) => {
    if (!edit || (edit && isEdit)) {
      setFieldValue("code", code);
    }
    if (edit && !isEdit) {
      editApp({ ...initialValues, code });
    }
  };

  const handleSubmit = async (values: IFormikValues) => {
    let loadedFile = null;
    if (file) loadedFile = await loadFile();
    if (loadedFile) values.photo_id = loadedFile.id;
    cancelUploadLocalFile();
    cancelPhotoUploadLocalFile();
    edit ? editHandler(values) : createApp(values);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={mainValidationScheme}
        onSubmit={handleSubmit}
        validateOnMount={false}
        validate={() => {
          if (!file && !edit) {
            setLoadError?.("Загрузите avatar");
          }
        }}
      >
        {({ setFieldValue }) => (
          <ContentWrapper
            aside={
              <AsideCodeBlock
                onChange={handleAppCode(setFieldValue)}
                onFocus={() => setIsEdit(true)}
                initialValue={initialValues.code?.toString()}
              />
            }
            contentId="basic-info"
          >
            <Form style={{ width: "100%" }}>
              <EditContainer
                showButton={edit}
                buttonColor={isEdit ? "secondary" : "error"}
                buttonAction={() => setIsEdit((prev) => !prev)}
              >
                <MainFieldset disabled={edit && !isEdit} />
              </EditContainer>

              {(isEdit || !edit || file) && <FormControls cancelRoute={routes.ECOMMERCE} />}
            </Form>

            {edit && (
              <AccountIntegration
                isApiActive={initialValues.is_token_active}
                onActiveChange={handleTokenActiveChange}
              />
            )}
          </ContentWrapper>
        )}
      </Formik>
      <AddUpdateAlerts
        isEditError={editResult.isError}
        isEditSuccess={editResult.isSuccess}
        isAddError={createResult.isError}
        isAddSuccess={createResult.isSuccess}
        editErrorMessage={editResult.error as string}
        addErrorMessage={createResult.error as string}
      />
    </>
  );
};

export default Main;
