import { Icon, Menu, MenuItem, useMediaQuery, useTheme } from "@mui/material";
import MDButton from "components/MDButton";
import { useStyles } from "layouts/pages/widgets/components/TopPanel/styles";
import ProductionQuantityLimitsOutlinedIcon from "@mui/icons-material/ProductionQuantityLimitsOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";

import React, { useState } from "react";
import NomenclaturesPopup from "./nomenclatures-popup";
import AddFilePopup from "./add-file-popup";

type PopupType = "file" | "nomenclature" | "task" | null;

interface IOrderActionsButtonProps {
  setTab: (num: number) => void;
}

const OrderActionsButton = ({ setTab }: IOrderActionsButtonProps) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openedPopup, setOpenedPopup] = useState<PopupType>(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <MDButton
        sx={styles.main}
        onClick={handleClick}
        variant="gradient"
        color="info"
        style={{
          alignSelf: isMobile ? "center" : "flex-start",
          ...(isMobile ? { width: "calc(100% - 24px)" } : {}),
        }}
      >
        <Icon>add</Icon>
        Добавить
      </MDButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "basic-button" }}
      >
        <MenuItem
          onClick={() => {
            setOpenedPopup("nomenclature");
            setTab(2);
          }}
        >
          <ProductionQuantityLimitsOutlinedIcon sx={{ mr: 1 }} /> Новая позиция
        </MenuItem>
        <MenuItem onClick={() => setTab(4)}>
          <AssignmentOutlinedIcon sx={{ mr: 1 }} /> Новая задача
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenedPopup("file");
            setTab(5);
          }}
        >
          <DriveFileMoveOutlinedIcon sx={{ mr: 1 }} /> Новый файл
        </MenuItem>
      </Menu>
      <AddFilePopup isOpen={openedPopup === "file"} onClose={() => setOpenedPopup(null)} />
      <NomenclaturesPopup
        isOpen={openedPopup === "nomenclature"}
        onClose={() => setOpenedPopup(null)}
      />
    </div>
  );
};

export default OrderActionsButton;
