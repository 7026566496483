import { createContext } from "react";

interface ICategoryNameContext {
  setName: (name: string) => void;
  file?: any;
  photo?: any;
  uploadPhotoLocalFile?: any;
}

export const CategoryNameContext = createContext<ICategoryNameContext | null>(null);
