import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { fieldsValidationScheme } from "../schemas";
import EditFields from "./blocks/fields";
import FormControls from "../../../components/form-controls";
import {
  useCreateBranchesMutation,
  useEditBranchesMutation,
} from "../../../../Redux/api/branches.api";
import AddUpdateAlerts from "layouts/components/add-update-alerts";
import { INewBranch } from "Redux/api/interfaces";
import { routes } from "../../../../constants";
import EditContainer from "layouts/components/edit-container";
import { delay } from "utils/delay";
import { IMain } from "./interfaces";
import { BranchNameContext } from "../context/branch-name-context";

const Main = (props: IMain) => {
  const { initialValues, edit = false, type, loadFile, cancelUploadLocalFile } = props;

  const { file } = useContext(BranchNameContext);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [createBranch, createResult] = useCreateBranchesMutation();
  const [editBranch, editResult] = useEditBranchesMutation();

  const navigate = useNavigate();

  const createResultHandler = async () => {
    if (createResult.isSuccess && createResult?.data?.branch.id) {
      await delay(1000);
      navigate(routes.BRANCHES + "/" + createResult?.data?.branch.id);
    }
  };

  useEffect(() => {
    createResultHandler();
  }, [createResult]);

  const editHandler = (values: INewBranch) => {
    editBranch(values);
    setIsEdit(false);
  };

  const editCodeHandler = (code: string) => {
    if (code?.length > 0) {
      editBranch({ ...initialValues, code });
    }
  };

  const handleSubmit = async (values: INewBranch) => {
    let loadedFile = null;
    if (file) loadedFile = await loadFile();
    if (loadedFile) values.avatar_id = loadedFile.id;
    cancelUploadLocalFile();
    type === "add" ? createBranch(values) : editHandler(values);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={fieldsValidationScheme}
        onSubmit={handleSubmit}
        validateOnMount={false}
      >
        {() => (
          <Form style={{ width: "100%" }}>
            <EditContainer
              showButton={edit}
              buttonColor={isEdit ? "secondary" : "error"}
              buttonAction={() => setIsEdit((prev) => !prev)}
            >
              <EditFields disabled={edit && !isEdit} editCode={editCodeHandler} />
              {(isEdit || !edit || file) && <FormControls cancelRoute={routes.BRANCHES} />}
            </EditContainer>
          </Form>
        )}
      </Formik>
      <AddUpdateAlerts
        isEditError={editResult.isError}
        isEditSuccess={editResult.isSuccess}
        isAddError={createResult.isError}
        isAddSuccess={createResult.isSuccess}
        editErrorMessage={editResult.error as string}
        addErrorMessage={createResult.error as string}
      />
    </>
  );
};

export default Main;
