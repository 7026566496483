import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Input, InputLabel } from "@mui/material";

import MDContainer from "components/MDContainer";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

import { IProps } from "./interfaces";

const LoadInput = (props: IProps) => {
  const { label, input, handler, noPointerIvents } = props;

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (!files) {
      console.log("[Error]: Empty file list");
      return;
    }

    const [file] = files;
    if (!file) {
      console.log("[Error]: Files is required");
      return;
    }

    handler(file);
  };

  return (
    <MDContainer variant="column" gap={1.4}>
      <MDTypography fontSize={14} fontWeight="regular" lineHeight="140%" color="text">
        {label}
      </MDTypography>

      <InputLabel
        sx={{
          pointerEvents: noPointerIvents ? "none" : "auto",
          cursor: "pointer",
          backgroundColor: "transparent",
          display: "block",
          width: "100%",
        }}
      >
        <Input
          type="file"
          sx={{
            display: "none",
          }}
          onChange={changeHandler}
        />

        <MDInput
          variant="standard"
          name={input.name}
          placeholder={input.placeholder}
          onChange={input.change}
          onBlur={input.blur}
          onFocus={input.focus}
          value={input.value}
          error={input.error}
          InputProps={{
            endAdornment: <AttachFileIcon fontSize="small" />,
          }}
          sx={{
            width: "100%",
            pointerEvents: "none",
          }}
        />
      </InputLabel>
    </MDContainer>
  );
};

export default LoadInput;
