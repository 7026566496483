import PageLayout from "examples/CardPage";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { AVATAR, EMPLOYMENT_CONTRACT_ID } from "constants/file-types";
import { useLoadFile } from "hooks/userLoadFile";
import ContentHeader from "layouts/pages/widgets/components/ContentHeader";
import Tabs from "layouts/pages/widgets/components/Tabs";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetCategoryByIdQuery,
  useGetCategoryHistoryQuery,
} from "../../../../Redux/api/categories.api";
import Loader from "../../../components/loader";
import History from "../../../pages/widgets/components/History";
import Main from "../components/main";
import { getInitialValues } from "../consts";
import { CategoryNameContext } from "../context/category-name-context";

const ViewCategory = () => {
  const [name, setName] = useState("");

  const { id } = useParams<string>();
  const { data: category, isFetching } = useGetCategoryByIdQuery(id);
  const { file, filePath, uploadLocalFile, loadFile, cancelUploadLocalFile } = useLoadFile(AVATAR);
  const {
    file: photo,
    uploadLocalFile: uploadPhotoLocalFile,
    loadFile: loadPhotoFile,
  } = useLoadFile(EMPLOYMENT_CONTRACT_ID);

  if (isFetching && !category?.category) {
    return <Loader />;
  }

  return (
    <PageLayout header={<DashboardNavbar />}>
      <CategoryNameContext.Provider value={{ setName, file, photo, uploadPhotoLocalFile }}>
        <ContentHeader
          info={{
            img: filePath || category?.category?.avatar?.path,
            uploadLocalFile,
            title: name || category?.category?.name,
          }}
          error={!(file || category?.category?.avatar?.path)}
        />

        <Tabs
          defaultTab={1}
          items={{
            headers: ["Основные", "История"],
            components: [
              <Main
                key="main"
                edit={true}
                initialValues={getInitialValues(category)}
                type="edit"
                loadFile={loadFile}
                cancelUploadLocalFile={cancelUploadLocalFile}
                loadPhotoFile={loadPhotoFile}
                cancelPhotoUploadLocalFile={cancelUploadLocalFile}
              />,
              <History key="history" request={useGetCategoryHistoryQuery} />,
            ],
          }}
        />
      </CategoryNameContext.Provider>
    </PageLayout>
  );
};

export default ViewCategory;
