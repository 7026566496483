import { api } from ".";
import { IScreen } from "./interfaces/IScreen";

export interface IScreensResponse {
  screens: IScreen[];
  screensCount: number;
}

export const screensApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getScreens: builder.query<IScreensResponse, void>({
      query: () => `screens`,
      transformErrorResponse: (response: any) => response.data.message,
    }),
  }),
});

export const { useGetScreensQuery } = screensApi;
