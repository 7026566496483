/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { styled, Theme } from "@mui/material/styles";

export default styled("div")(
  ({
    theme,
    ownerState,
    disabled,
  }: {
    theme?: Theme | any;
    ownerState: any;
    disabled: boolean;
  }) => {
    const { palette, borders, typography } = theme;
    const { darkMode, isError } = ownerState;

    const { borderRadius } = borders;
    const { inputBorderColor, grey, white, error } = palette;

    return {
      pointerEvents: disabled ? "none" : "auto",
      "& .rdw-editor-wrapper": {
        borderRadius: borderRadius.md,
        border: `1px solid ${isError ? error.main : inputBorderColor}`,
      },

      "& .rdw-editor-toolbar": {
        border: 0,
        borderBottom: `1px solid ${isError ? error.main : inputBorderColor}`,
        borderRadius: `${borderRadius.md} ${borderRadius.md} 0 0`,
        backgroundColor: "transparent",
        display: disabled ? "none" : "flex",
      },

      "& .rdw-option-wrapper, & .rdw-dropdown-wrapper": {
        borderColor: grey[200],
        backgroundColor: darkMode ? grey[500] : white,

        "&:hover": {
          boxShadow: "none",
          borderColor: grey[400],
        },
      },

      "& .rdw-option-active": {
        boxShadow: "none",
        borderColor: grey[600],
      },

      "& .public-DraftStyleDefault-block": {
        margin: 0,
        padding: "8px",
      },
    };
  }
);
