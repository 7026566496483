import { Box } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Popup from "layouts/components/popup";
import MDDropzone from "components/MDDropzone";
import { IFile, NewOrderFile } from "api/files/new";
import { useParams } from "react-router-dom";
import { useGetOrderFilesTableQuery } from "../../../../Redux/api/orders.api";

interface IAddFilePopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddFilePopup = ({ isOpen, onClose }: IAddFilePopupProps) => {
  const { id } = useParams();
  const { refetch } = useGetOrderFilesTableQuery(id);
  const onChange = (files?: IFile[]) => {
    refetch();
    onClose();
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={onChange}
      title="Добавление файла"
      titleIcon={<FileDownloadOutlinedIcon sx={{ mr: 1 }} />}
    >
      <Box
        sx={(theme) => ({
          p: 2,
          border: `1px solid ${theme.palette.inputBorderColor}`,
          borderRadius: 2,
        })}
      >
        <MDDropzone
          info={{
            setImgFile: () => {},
          }}
          options={{
            addRemoveLinks: true,
            singleFile: false,
            dictDefaultMessage: "Перетащите файл или кликните сюда",
            isOrderFile: true,
            defaultFiles: [],
            simpleMessage: true,
          }}
          uploadFunction={(file: File) => NewOrderFile(id, file)}
        />
      </Box>
    </Popup>
  );
};

export default AddFilePopup;
