import { PropsWithChildren } from "react";
import { useTheme } from "@mui/material";
import MDBox from "components/MDBox";

const BottomPanel = ({ children }: PropsWithChildren) => {
  const { palette } = useTheme();

  return (
    <MDBox
      bgColor={palette.formControls.background}
      display="flex"
      justifyContent="flex-end"
      position="fixed"
      left={0}
      bottom={0}
      right={0}
      zIndex={400}
    >
      {children}
    </MDBox>
  );
};

export default BottomPanel;
