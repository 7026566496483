import { api } from ".";
import { IBankRequisite } from "./interfaces";

export interface ISearhBankRequest {
  query: string;
}

export interface IBankResponse {
  banks: IBankRequisite[];
  banksCount: number;
}

export const searchBankApi = api.injectEndpoints({
  endpoints: (builder) => ({
    searchBankByValue: builder.mutation<IBankResponse, ISearhBankRequest>({
      query: (body) => ({
        url: `search_bank`,
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: any) => response.data.message,
    }),
  }),
});

export const { useSearchBankByValueMutation } = searchBankApi;
