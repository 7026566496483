import { IAddOrder } from "Redux/api/orders.api";

export const initialValues: IAddOrder = {
  avatar_id: null,
  counterparty_id: null,
  delivery_type_id: null,
  address: "",
  branch_id: null,
  shop_id: null,
  object_id: null,
  application_id: null,
  sales_channel_id: null,
  manager_id: null,
  promotion_id: null,
  contract_number: null,
  contract_id: null,
  contract_start_date: null,
  contract_end_date: null,
  invoice_number: null,
  invoice_start_date: null,
  invoice_end_date: null,
};
