import MDBox from "components/MDBox";
import { useRef, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import useOnClickOutside from "hooks/useOnClickOutside";
import PropTypes from "prop-types";
import { useMaterialUIController } from "context";
import colors from "assets/theme-dark/base/colors";
import type { ITableOption } from ".";

interface IOptionsDotsProps {
  onClose?: () => void;
  options: ITableOption[];
  id: string | number;
}
function OptionsDots({ options, id }: IOptionsDotsProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <MDBox component="div" style={{ width: 10, position: "relative" }}>
      <MDBox
        display="flex"
        flexDirection="column"
        gap={0.25}
        sx={{
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={handleClickOpen}
        cursor="pointer"
      >
        <MDBox
          display="inline-block"
          bgColor="#7B809A"
          borderRadius="50%"
          color="text"
          width={4}
          height={4}
        />
        <MDBox
          display="inline-block"
          bgColor="#7B809A"
          borderRadius="50%"
          color="text"
          width={4}
          height={4}
        />
        <MDBox
          display="inline-block"
          bgColor="#7B809A"
          borderRadius="50%"
          color="text"
          width={4}
          height={4}
        />
      </MDBox>
      {open && <SimpleDialog onClose={handleClose} options={options} id={id} />}
    </MDBox>
  );
}

function SimpleDialog({ onClose, options, id: rowId }: IOptionsDotsProps): JSX.Element {
  const ref = useRef(null);
  const navigate = useNavigate();

  const handler = ({ url, isId, action, id }: ITableOption) => {
    if (action) {
      action(rowId);
    } else {
      navigate(isId ? url + "/" + rowId : url);
    }
  };

  useOnClickOutside(ref, () => onClose());

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <List
      sx={{ pt: 1, pb: 1 }}
      ref={ref}
      style={{
        position: "absolute",
        zIndex: 9,
        right: 180,
        top: 28,
        marginBottom: -56,
        marginRight: -190,
        background: darkMode ? colors.dark.focus : "#fff",
        boxShadow: "0px 10px 15px 0px rgba(0, 0, 0, 0.1)",
        width: "max-content",
        borderRadius: 10,
      }}
    >
      {options.map((item) => (
        <ListItem key={item.id} sx={{ padding: "0rem 1rem" }}>
          <ListItemButton onClick={() => handler(item)}>
            <ListItemText
              primary={item.text}
              primaryTypographyProps={{
                fontSize: "0.875rem",
                letterSpacing: 0.13,
                color: "#7B809A",
              }}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}

// Typechecking props for the DataTable
OptionsDots.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
      text: PropTypes.string,
    })
  ).isRequired,
  id: PropTypes.number.isRequired,
};

SimpleDialog.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
      text: PropTypes.string,
    })
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};

export default OptionsDots;
