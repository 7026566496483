import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { fieldsValidationScheme } from "../schemas/fields";
import EditFields from "./blocks/fields";
import FormControls from "../../../components/form-controls";
import AddUpdateAlerts from "layouts/components/add-update-alerts";
import EditContainer from "layouts/components/edit-container";
import { routes } from "../../../../constants";
import {
  INewInventory,
  useAddInventoriesMutation,
  useEditInventoriesMutation,
} from "../../../../Redux/api/inventories.api";
import { IMain } from "./interfaces";
import { InventoryContext } from "../context/inventory-context";
import ContentWrapper from "layouts/components/content-wrapper";
import AsideCodeBlock from "layouts/components/aside-code-block";
import { delay } from "utils/delay";

const Main = (props: IMain) => {
  const {
    initialValues,
    edit = false,
    type,
    loadFile,
    cancelUploadLocalFile,
    setLoadError,
  } = props;

  const { file } = useContext(InventoryContext);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [createInventory, createResult] = useAddInventoriesMutation();
  const [editInventory, editResult] = useEditInventoriesMutation();

  const navigate = useNavigate();

  const createResultHandler = async () => {
    if (createResult.isSuccess && createResult?.data?.inventory.id) {
      await delay(1000);
      navigate(routes.VIEW_INVENTORY + "/" + createResult?.data?.inventory.id);
    }
  };

  useEffect(() => {
    createResultHandler();
  }, [createResult]);

  const handleAppCode = (setFieldValue: any) => (code: string) => {
    if (!edit || (edit && isEdit)) {
      setFieldValue("code", code);
    }
    if (edit && !isEdit) {
      editInventory({ ...initialValues, code });
    }
  };

  const editHandler = (values: IInitValues) => {
    editInventory(values);
    setIsEdit(false);
  };

  const handleSubmit = async (values: INewInventory) => {
    let loadedFile = null;
    if (file) loadedFile = await loadFile();
    if (loadedFile) values.avatar_id = loadedFile.id;
    cancelUploadLocalFile();
    type === "add" ? createInventory(values) : editHandler(values);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={fieldsValidationScheme}
        onSubmit={handleSubmit}
        validateOnMount={false}
        validate={() => {
          if (!file && !edit) {
            setLoadError?.("Загрузите avatar");
          }
        }}
      >
        {({ setFieldValue }) => (
          <ContentWrapper
            aside={
              <AsideCodeBlock
                onChange={handleAppCode(setFieldValue)}
                onFocus={() => setIsEdit(true)}
                initialValue={initialValues.code?.toString()}
              />
            }
            contentId="basic-info"
          >
            <Form style={{ width: "100%" }}>
              <EditContainer
                showButton={edit}
                buttonColor={isEdit ? "secondary" : "error"}
                buttonAction={() => setIsEdit((prev) => !prev)}
              >
                <EditFields disabled={edit && !isEdit} />
                {(isEdit || !edit || file) && <FormControls cancelRoute={routes.INVENTORIES} />}
              </EditContainer>
            </Form>
          </ContentWrapper>
        )}
      </Formik>
      <AddUpdateAlerts
        isEditError={editResult.isError}
        isEditSuccess={editResult.isSuccess}
        isAddError={createResult.isError}
        isAddSuccess={createResult.isSuccess}
        editErrorMessage={editResult.error as string}
        addErrorMessage={createResult.error as string}
      />
    </>
  );
};

export default Main;
