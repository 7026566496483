import { api } from "./index";
import { IBranch, IBranchColumn, IBankRequisite, IResponsibleUsers } from "./interfaces";
import { IIntegration } from "./interfaces/IIntagration";
import { IPagination } from "./interfaces/IPagination";

//types
export interface IBranchTableResponse {
  columns: IBranchColumn[];
  branches: IBranch[];
  message: string;
  pagination: IPagination;
}

export interface IBranchesResponse {
  branches: IBranch[];
  branchesCount: number;
}

export interface IBankRequisiteResponse {
  bankRequisites: IBankRequisite[];
  bankRequisitesCount: number;
}

export interface IAddBankRequisite {
  id?: string | number;
  counterpartyId: string | number;
  name: string;
  bik: string;
  kpp: string;
  payment_account: string;
  address: string;
}

export interface IBranchHistoryResponse {
  histories: IHistoryDate[];
  historiesCount: number;
}

export interface IFilterResponsibleUsers {
  responsibleUsers: IResponsibleUsers[];
  responsibleUsersCount: number;
}

export interface IHistoryDate {
  id: number;
  icon: string;
  color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light";
  title: string;
  description: string;
  datetime: string;
}

export interface IIntegrationsResponse {
  integrations: IIntegration[];
  integrationsCount: number;
}

export interface IIntegrationParametersResponse {
  parameters: IParameter[];
  parametersCount: number;
}

export interface IIntegrationByIdResponse {
  integration: {
    id: number;
    name: string;
    avatar: string;
    params: IParameterParam[];
  };
}

export interface IIntegrationParams {
  branchId: string | number;
  bankId: string | number;
  integrationsId?: string | number;
  is_active?: number;
}

export interface IBankRequisiteIntegrationResponse {
  integration: IIntegration;
}

export interface IIntegrationParameterResponse {
  parameter: IParameter;
}

export interface IParameter {
  id: number;
  key_1: string;
  description_1: string;
  key_2: string;
  description_2: string;
  value_1: string;
  value_2: string;
}

export interface IParameterParam {
  id: number;
  title: string;
  order: number;
  key_1: string;
  key_2: string;
  description_1: string;
  description_2: string;
}

export interface IIntegrationParameter {
  branchId: string | number;
  bankId: string | number;
  integrationsId?: string | number;
  integration_parameter_id: string | number;
  value_1: string;
  value_2: string;
}

export interface IIntegrationParameters {
  branchId: string | number;
  bankId: string | number;
  integrationId: string | number;
}

export const branchesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBranches: builder.query<IBranchesResponse, void>({
      query: () => `branches`,
      keepUnusedDataFor: 0,
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    getBranchesTable: builder.query<IBranchTableResponse, string>({
      query: (queryParams) => `tables/branches?${queryParams}`,
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    getBranchesById: builder.query({
      query: (idBranches) => `branches/${idBranches}`,
      providesTags: ["branch"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    createBranches: builder.mutation({
      query: (initialPost) => ({
        url: `branches`,
        method: "POST",
        body: initialPost,
      }),
      transformErrorResponse: (response: any) => response?.data?.message || "",
      invalidatesTags: ["branch"],
    }),
    editBranches: builder.mutation({
      query: (initialPost) => ({
        url: `branches/${initialPost.id}`,
        method: "PUT",
        body: initialPost,
      }),
      transformErrorResponse: (response: any) => response?.data?.message || "",
      invalidatesTags: ["branch"],
    }),
    deleteBranches: builder.mutation({
      query: (idBranches) => ({
        url: `branches/${idBranches}`,
        method: "DELETE",
        transformErrorResponse: (response: any) => response?.data?.message || "",
      }),
    }),
    getBranchBankRequisiteById: builder.query<IBankRequisiteResponse, string | number>({
      query: (id) => `branches/${id}/bank_requisites`,
      transformErrorResponse: (response: any) => response?.data?.message || "",
      providesTags: ["branch_bank_requisites"],
    }),
    addBranchBankRequisiteById: builder.mutation<IBankRequisiteResponse, IAddBankRequisite>({
      query: ({ counterpartyId, ...body }) => ({
        url: `branches/${counterpartyId}/bank_requisites`,
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: any) => response?.data?.message || "",
      invalidatesTags: ["branch_bank_requisites"],
    }),
    editBranchBankRequisiteById: builder.mutation<IBankRequisiteResponse, IAddBankRequisite>({
      query: ({ counterpartyId, id, ...body }) => {
        return {
          url: `branches/${counterpartyId}/bank_requisites/${id}`,
          method: "PUT",
          body,
        };
      },
      transformErrorResponse: (response: any) => response?.data?.message || "",
      invalidatesTags: ["branch_bank_requisites"],
    }),
    getBranchHistory: builder.query<IBranchHistoryResponse, string>({
      query: (id) => `branches/${id}/history`,
    }),
    getFilterResponsibleUsers: builder.query<IFilterResponsibleUsers, void>({
      query: () => `/responsible_users`,
      keepUnusedDataFor: 0,
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),

    //integrations
    getIntegrations: builder.query<IIntegrationsResponse, IIntegrationParams>({
      query: ({ branchId, bankId }) =>
        `branches/${branchId}/bank_requisites/${bankId}/integrations`,
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    editIntegration: builder.mutation<IBankRequisiteIntegrationResponse, IIntegrationParams>({
      query: ({ branchId, bankId, integrationsId, ...body }) => ({
        url: `branches/${branchId}/bank_requisites/${bankId}/integrations/${integrationsId}`,
        method: "PUT",
        body,
      }),
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    addIntegrationParameters: builder.mutation<
      IIntegrationParameterResponse,
      IIntegrationParameter
    >({
      query: ({ branchId, bankId, integrationsId, ...body }) => ({
        url: `branches/${branchId}/bank_requisites/${bankId}/integrations/${integrationsId}/parameters`,
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: any) => response?.data?.message || "",
      invalidatesTags: ["branch_integrations"],
    }),
    getIntegrationById: builder.query<IIntegrationByIdResponse, string | number>({
      query: (integrationId) => `integrations/${integrationId}`,
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    getIntegrationParameters: builder.query<IIntegrationParametersResponse, IIntegrationParameters>(
      {
        query: ({ branchId, bankId, integrationId }) =>
          `branches/${branchId}/bank_requisites/${bankId}/integrations/${integrationId}/parameters`,
        providesTags: ["branch_integrations"],
        transformErrorResponse: (response: any) => response?.data?.message || "",
      }
    ),
    deleteIntegrationParameter: builder.mutation({
      query: ({ branchId, bankId, integrationId, parameterId }) => ({
        url: `branches/${branchId}/bank_requisites/${bankId}/integrations/${integrationId}/parameters/${parameterId}`,
        method: "DELETE",
      }),
      transformErrorResponse: (response: any) => response?.data?.message || "",
      invalidatesTags: ["branch_integrations"],
    }),
    getResponsibleUser: builder.mutation<any, any>({
      query: (query) => ({
        url: `users?query=${query}`,
        method: "GET",
      }),
      transformErrorResponse: (response: any) => response.data.message,
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetBranchesQuery,
  useGetBranchesTableQuery,
  useGetBranchesByIdQuery,
  useCreateBranchesMutation,
  useEditBranchesMutation,
  useDeleteBranchesMutation,
  useGetBranchBankRequisiteByIdQuery,
  useAddBranchBankRequisiteByIdMutation,
  useEditBranchBankRequisiteByIdMutation,
  useGetBranchHistoryQuery,
  useGetFilterResponsibleUsersQuery,
  useGetIntegrationsQuery,
  useEditIntegrationMutation,
  useAddIntegrationParametersMutation,
  useGetIntegrationByIdQuery,
  useGetIntegrationParametersQuery,
  useDeleteIntegrationParameterMutation,
  useGetResponsibleUserMutation,
} = branchesApi;
