import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { mainValidationScheme } from "../schemas";
import MainBlock from "./main-fieldset";
import Sidenav from "layouts/pages/account/settings/components/Sidenav";
import AddUpdateAlerts from "layouts/components/add-update-alerts";
import ContentWrapper from "layouts/components/content-wrapper";
import EditContainer from "layouts/components/edit-container";
import FormControls from "layouts/components/form-controls";
import { routes } from "constants/route";
import {
  IAddUser,
  useAddNewUserMutation,
  useEditUserMutation,
} from "../../../../Redux/api/users.api";
import JobPlacesmentFields from "./job-placesment-fieldset";
import PermissionsFieldset from "./permissions-fieldset";
import StatusBlock from "layouts/components/status-block";
import Integrations from "./integrations";
import { IMain } from "./interfaces";
import { NewUserContext } from "../context/new-user-context";
import { delay } from "utils/delay";

const sidenavItems = [
  { icon: "person", label: "Основная информация", href: "basic-info" },
  { icon: "receipt_long", label: "Трудоустройство", href: "user-card" },
  { icon: "security", label: "Разрешения", href: "permissions" },
  { icon: "campaign", label: "Интеграции", href: "integrations" },
];

type SectionsType = "main" | "job" | "permission" | null;

const Main = (props: IMain) => {
  const {
    initialValues,
    edit = false,
    type,
    loadFile,
    cancelUploadLocalFile,
    setLoadError,
    isBanned,
  } = props;

  const { file } = useContext(NewUserContext);

  const [editSection, setEditSection] = useState<SectionsType>(null);

  const [createUser, createResult] = useAddNewUserMutation();
  const [editUser, editResult] = useEditUserMutation();

  const navigate = useNavigate();

  const createResultHandler = async () => {
    if (createResult.isSuccess && createResult.data?.user?.id) {
      await delay(1000);
      navigate(routes.USERS + "/" + createResult.data?.user?.id);
    }
  };

  useEffect(() => {
    createResultHandler();
  }, [createResult]);

  useEffect(() => {
    if (isBanned !== initialValues.is_banned) {
      setEditSection("main");
    }
  }, [isBanned]);

  const editHandler = (values: IAddUser) => {
    editUser(values);
    setEditSection(null);
  };

  const handleSubmit = async (values: IAddUser) => {
    let loadedFile = null;
    if (file) loadedFile = await loadFile();
    if (loadedFile) values.avatar_id = loadedFile.id;
    cancelUploadLocalFile();
    let newValues = { ...values, is_banned: isBanned };
    type === "add" ? createUser(newValues) : editHandler(newValues);
  };

  return (
    <>
      <ContentWrapper aside={<Sidenav sidenavItems={sidenavItems} />} contentId="basic-info">
        <Formik
          initialValues={initialValues}
          validationSchema={mainValidationScheme}
          onSubmit={handleSubmit}
          validateOnMount={false}
          validate={() => {
            if (!file && !edit) {
              setLoadError?.("Загрузите avatar");
            }
          }}
        >
          {() => (
            <Form style={{ width: "100%", display: "flex", flexDirection: "column", gap: 24 }}>
              <EditContainer
                showButton={edit}
                buttonColor={editSection === "main" ? "secondary" : "error"}
                buttonAction={() => setEditSection((prev) => (prev === "main" ? null : "main"))}
              >
                <MainBlock disabled={edit && editSection !== "main"} />
              </EditContainer>
              <EditContainer
                showButton={edit}
                buttonColor={editSection === "job" ? "secondary" : "error"}
                buttonAction={() => setEditSection((prev) => (prev === "job" ? null : "job"))}
              >
                <JobPlacesmentFields disabled={edit && editSection !== "job"} />
              </EditContainer>
              <EditContainer
                showButton={edit}
                buttonColor={editSection === "permission" ? "secondary" : "error"}
                buttonAction={() =>
                  setEditSection((prev) => (prev === "permission" ? null : "permission"))
                }
              >
                <PermissionsFieldset disabled={edit && editSection !== "permission"} />
              </EditContainer>
              {(editSection || !edit || file) && <FormControls cancelRoute={routes.USERS} />}
            </Form>
          )}
        </Formik>
        {edit && (
          <>
            <Integrations />
            <StatusBlock
              title="Больничный"
              description="Сотрудник не будет получать уведомления и задачи"
              activeButton={initialValues.is_sick ? 1 : 2}
              label1="Больничный"
              label2="На работе"
              action1={() => editUser({ id: initialValues.id, is_sick: true })}
              action2={() => editUser({ id: initialValues.id, is_sick: false })}
            />
          </>
        )}
      </ContentWrapper>
      <AddUpdateAlerts
        isEditError={editResult.isError}
        isEditSuccess={editResult.isSuccess}
        isAddError={createResult.isError}
        isAddSuccess={createResult.isSuccess}
        editErrorMessage={editResult.error as string}
        addErrorMessage={createResult.error as string}
      />
    </>
  );
};

export default Main;
