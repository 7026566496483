import { useEffect, useState } from "react";
import { IFilter } from "layouts/pages/widgets/components/TopPanel/interfaces";
import { ICity } from "../../../../Redux/api/warehouses.api";
import { useGetCitiesQuery } from "../../../../Redux/api/search-city.api";
import { useGetBranchesQuery } from "../../../../Redux/api/branches.api";
import { IBranch } from "../../../../Redux/api/interfaces";

const isShopFilters: IFilter = {
  id: 1,
  title: "Иерархия",
  tag: "is_shop",
  url: "&is_shop=",
  isInput: true,
  list: [
    {
      id: 1,
      isInput: false,
      tag: "is_shop",
      name: "Магазин",
      url: "1",
    },
    {
      id: 2,
      isInput: false,
      tag: "is_shop",
      name: "Пункт выдачи",
      url: "0",
    },
  ],
  exclusive: true,
};

const addFilers = (filters: IFilter[], cities: ICity[], branches: IBranch[]) => {
  const citiesFilters: IFilter = {
    id: 3,
    title: "Город",
    tag: "city",
    url: "&city_id=",
    isInput: true,
    list: cities?.map((city: ICity) => ({
      id: city.id,
      isInput: false,
      tag: "city",
      name: city.name,
      url: `${city.id}`,
    })),
    exclusive: true,
  };

  const branchesFilters: IFilter = {
    id: 2,
    title: "Направление бизнеса",
    tag: "branch",
    url: "&branch_id=",
    isInput: true,
    list: branches?.map((branch: IBranch) => ({
      id: branch.id,
      isInput: false,
      tag: "branch",
      name: branch.name,
      url: `${branch.id}`,
    })),
    exclusive: true,
  };

  return [...filters, isShopFilters, citiesFilters, branchesFilters];
};

export const useShopsFilters = () => {
  const [filters, setFilters] = useState<IFilter[]>([]);
  const { data: cities, isFetching: isResponsibleCitiesFetching } = useGetCitiesQuery();
  const { data: branches, isFetching: isResponsibleBranchesFetching } = useGetBranchesQuery();

  useEffect(() => {
    if (cities?.cities && branches?.branches) {
      setFilters((old) => {
        return addFilers(old, cities.cities, branches.branches);
      });
    }
  }, [cities, branches]);

  if (isResponsibleCitiesFetching || isResponsibleBranchesFetching) return null;

  return filters;
};
