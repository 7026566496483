import { IAddNomenclature } from "Redux/api/nomenclatures.api";

export const initialValues: IAddNomenclature = {
  avatar_id: "",
  code: "",
  name: "",
  description: "",
  category_id: "",
  specification: "",
  type_id: "",
  kind_id: "",
  branch_id: 0,
  tags: [],
  country_id: "",
  preview_id: "",
  photos: [],
  storage_unit_id: "",
  brand_id: "",
};
