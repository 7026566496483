import MDAlert from "components/MDAlert";
import PageLayout from "examples/CardPage";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import useFilterParams from "hooks/useFilterParams";
import { Table } from "layouts/pages/widgets/components/Table";
import TopPanel from "layouts/pages/widgets/components/TopPanel";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../constants";
import { useGetApplicationsTableQuery } from "../../../../Redux/api/applications.api";

const ECommerceTable = () => {
  const {
    queryParams,
    setQuery,
    setPage,
    setPerPage,
    setStartDate,
    setEndDate,
    query,
    page,
    perPage,
    startDate,
    endDate,
  } = useFilterParams();
  const { data, isFetching, isError, error, refetch } = useGetApplicationsTableQuery(queryParams());

  useEffect(() => {
    refetch();
  }, [page, perPage, query, startDate, endDate]);

  const navigate = useNavigate();

  const optionsDots = [
    {
      id: 1,
      text: "Просмотр/редакт",
      url: routes.ECOMMERCE,
      isId: true,
    },
  ];

  return (
    <PageLayout header={<DashboardNavbar />}>
      {isError && (
        <MDAlert color="error" dismissible mb={0}>
          {JSON.stringify(error)}
        </MDAlert>
      )}

      <TopPanel
        main={{
          title: "НОВЫЙ",
          action: () => {
            navigate(routes.NEW_ECOMMERCE);
          },
        }}
        dates={{
          from: {
            value: startDate,
            placeholder: "Дата от",
            action: setStartDate,
          },
          to: {
            value: endDate,
            placeholder: "Дата до",
            action: setEndDate,
          },
        }}
        filters={[]}
        exportCSV={{}}
      />

      <Table
        accessorKey="applications"
        title="Сайты, приложения"
        filters={{
          setQuery,
          setPage,
          setPerPage,
          query,
          page,
          perPage,
        }}
        data={data}
        optionsDots={optionsDots}
        isLoading={isFetching}
      />
    </PageLayout>
  );
};

export default ECommerceTable;
