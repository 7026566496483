import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loadStateFromLocalStorage } from "./utils";

export interface ConfiguratorState {
  sidenavActiveColor:
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "light"
    | "dark"
    | "";
  sidenavActiveType: "dark" | "transparent" | "white";
  navbarFixed: boolean;
  sidenavMini: boolean;
  darkTheme: boolean;
  contrastTheme: boolean;
}

export const configuratorSlice = createSlice({
  name: "configurator",
  initialState: loadStateFromLocalStorage(),
  reducers: {
    updateSidenavColor(
      state,
      {
        payload,
      }: PayloadAction<
        "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark" | ""
      >
    ) {
      state.sidenavActiveColor = payload;
    },
    updateSidenavType(state, { payload }: PayloadAction<"dark" | "transparent" | "white">) {
      state.sidenavActiveType = payload;
    },
    updateNavbarFixed(state, { payload }: PayloadAction<boolean>) {
      state.navbarFixed = payload;
    },
    updateSidenavMini(state, { payload }: PayloadAction<boolean>) {
      state.sidenavMini = payload;
    },
    updateDarkTheme(state, { payload }: PayloadAction<boolean>) {
      state.darkTheme = payload;
    },
    updateContrastTheme(state, { payload }: PayloadAction<boolean>) {
      state.contrastTheme = payload;
    },
  },
});

export const {
  updateSidenavColor,
  updateSidenavType,
  updateNavbarFixed,
  updateSidenavMini,
  updateDarkTheme,
  updateContrastTheme,
} = configuratorSlice.actions;

export default configuratorSlice.reducer;
