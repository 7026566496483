import * as Yup from "yup";

export const mainValidationScheme = Yup.object().shape({
  code: Yup.number().notRequired(),
  name: Yup.string().required("Заполните поле Название"),
  description: Yup.string().notRequired(),
  category_id: Yup.number().notRequired(),
  specification: Yup.string().notRequired(),
  type_id: Yup.number().notRequired(),
  kind_id: Yup.number().notRequired(),
  branch_id: Yup.number().notRequired(),
  country_id: Yup.number().notRequired(),
  storage_unit_id: Yup.number().notRequired(),
  brand_id: Yup.number().notRequired(),
});
