import * as Yup from "yup";

export const fieldsValidationScheme = Yup.object().shape({
  title: Yup.string().required("Заполните поле Название направления бизнеса"),
  inn: Yup.number().required("Заполните поле ИНН"),
  name: Yup.string().required("Заполните поле Название компании"),
  kpp: Yup.string().notRequired(),
  phone_1: Yup.string().required("Заполните поле Телефон"),
  phone_2: Yup.string().notRequired(),
  head: Yup.string().notRequired(),
  email: Yup.string().email("Введите корректный email").required("Заполните поле Почта компании"),
  address: Yup.string().required("Заполните поле Адрес компании"),
  legal_address: Yup.string().required("Заполните поле Почтовый адрес компании"),
  responsible_user_id: Yup.number().notRequired(),
  code: Yup.string().notRequired(),
});
