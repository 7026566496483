import { CustomDropZone } from "layouts/pages/widgets/components/CustomDropZone";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { useGetNomenclatureByIdQuery } from "../../../../Redux/api/nomenclatures.api";
import { ProductsContext } from "../context/products-context";

const PreviewBlock = (props: any) => {
  const { id } = useParams();
  const { data } = useGetNomenclatureByIdQuery(id, { skip: !id });

  const { photo, uploadPhotoLocalFile } = useContext(ProductsContext);

  const getFilesHandler = (files: File[]) => {
    const [file] = files;

    props.setIsEdit(true);

    if (file) uploadPhotoLocalFile(file);
  };

  return (
    <CustomDropZone
      getFilesHandler={getFilesHandler}
      loadedFiles={[photo || data?.nomenclature?.preview]}
      isError={false}
    />
  );
};

export default PreviewBlock;
