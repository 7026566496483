/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect, useContext } from "react";

// react-router components
import { Routes, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React TS examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React TS themes
import theme from "assets/theme";
// Material Dashboard 2 PRO React TS Dark Mode themes
import themeDark from "assets/theme-dark";
// Material Dashboard 2 PRO React TS Contrast Mode themes
import themeContrast from "assets/theme-contrast";

// Material Dashboard 2 PRO React TS routes

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController, setMiniSidenav } from "context";

// Images
import logo from "assets/images/logo.svg";

import { UserContext } from "context/user";
import { GlobalStyles } from "@mui/material";
import { useGetMenuItemsByIdQuery } from "./Redux/api/menus.api";
import { routesMap, appRoutes, authRoutes, getSidebarRoutes, profileItems } from "router";

export default function App() {
  const { user } = useContext(UserContext);
  const { data: menu } = useGetMenuItemsByIdQuery(1, { skip: !user });

  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, layout, sidenavColor, darkMode, contrastMode } = controller;

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getContent = () => {
    if (!user || !menu) {
      return;
    }

    return (
      <>
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={logo}
              brandName="Gigma"
              routes={getSidebarRoutes(menu?.menuItems, routesMap, [profileItems])}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
          </>
        )}

        {layout === "vr" && <Configurator />}
      </>
    );
  };

  const activeTheme = darkMode ? themeDark : contrastMode ? theme : themeContrast;

  return (
    <ThemeProvider theme={activeTheme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: {
            background: activeTheme.palette.background.default,
            minHeight: "100vh",
          },
          "li.MuiMultiSectionDigitalClockSection-item": {
            minWidth: "auto",
          },
        }}
      />
      {getContent()}
      <Routes>{user ? appRoutes : authRoutes}</Routes>
    </ThemeProvider>
  );
}
