import PageLayout from "examples/CardPage";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ContentHeader from "layouts/pages/widgets/components/ContentHeader";
import Tabs from "layouts/pages/widgets/components/Tabs";
import { useState } from "react";
import Main from "../components/main";
import { BranchNameContext } from "../context/branch-name-context";
import { initialValues } from "../consts";
import { useLoadFile } from "hooks/userLoadFile";
import { AVATAR } from "constants/file-types";

const NewBranch = () => {
  const { file, uploadLocalFile, loadFile, cancelUploadLocalFile, filePath } = useLoadFile(AVATAR);

  const [name, setName] = useState("");

  return (
    <PageLayout header={<DashboardNavbar />}>
      <BranchNameContext.Provider value={{ setName, file }}>
        <ContentHeader
          info={{
            img: filePath,
            uploadLocalFile,
            title: name || "Направление бизнеса",
          }}
          error={false}
        />

        <Tabs
          defaultTab={1}
          items={{
            headers: ["Основные"],
            components: [
              <Main
                key="main"
                type="add"
                initialValues={initialValues}
                loadFile={loadFile}
                cancelUploadLocalFile={cancelUploadLocalFile}
              />,
            ],
          }}
        />
      </BranchNameContext.Provider>
    </PageLayout>
  );
};

export default NewBranch;
