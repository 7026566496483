import { useFormikContext } from "formik";
import Block from "layouts/pages/widgets/components/Block";
import Select from "layouts/pages/widgets/components/Select";
import { useGetScreensQuery } from "../../../../Redux/api/screens.api";
import { IUpdateUser } from "../../../../Redux/api/users.api";
import { IScreen } from "../../../../Redux/api/interfaces/IScreen";
import { IPermission } from "../../../../Redux/api/interfaces/IPermission";
import { IItem } from "layouts/pages/widgets/components/Select/interfaces";
import { useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";

interface IPermissionsFieldsetProps {
  disabled?: boolean;
}

const permissionToItem = (permission?: IPermission): IItem => {
  if (!permission) return null;
  return {
    id: permission.id,
    label: permission.description,
    value: permission.name,
  };
};

const getInitialPermissions = (values: string[], screens: IScreen[]) => {
  return screens.map((screen) => {
    return permissionToItem(screen.permissions.find((p) => values.some((v) => v === p.name)));
  });
};

const PermissionsFieldset = ({ disabled }: IPermissionsFieldsetProps) => {
  const { data: screensData, isLoading } = useGetScreensQuery();
  const [selectedItems, setSelectedItems] = useState<IItem[]>([]);

  const { values, initialValues, setFieldValue, errors } = useFormikContext<IUpdateUser>();

  const initialPermissions = useMemo(() => {
    if (screensData?.screens) {
      return getInitialPermissions(initialValues.permissions, screensData?.screens);
    } else {
      return [];
    }
  }, [screensData, disabled, initialValues.permissions]);

  const onSelect = (item: IItem | undefined, selectItems: IPermission[], index: number) => {
    let nextSelectedItems = [...selectedItems];
    nextSelectedItems[index] = item;
    setSelectedItems(nextSelectedItems);
    const { permissions } = values;
    const newPermissions = permissions.filter(
      (p) => !selectItems.some((i: IPermission) => i.name === p)
    );
    if (item) newPermissions.push(item.value);
    setFieldValue("permissions", newPermissions);
  };

  useEffect(() => {
    if (initialPermissions?.length) {
      setSelectedItems(initialPermissions);
    }
  }, [initialPermissions]);

  if (isLoading) return null;

  return (
    <Block title="Разрешения" id="permissions" wrapperStyles={{ width: "100%" }}>
      <Grid container spacing={3}>
        {screensData.screens.map((item, index) => (
          <Grid item xs={12} md={6} key={item.id}>
            <Select
              label={item.name}
              placeholder="Выберите доступ"
              error={errors["permissions"] as string}
              noPointerEvents={disabled}
              popupIcon={disabled ? null : undefined}
              select={{
                value: selectedItems[index] || null,
                items: item.permissions.map((i) => permissionToItem(i)),
                onSelect: (i) => onSelect(i, item.permissions, index),
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Block>
  );
};

export default PermissionsFieldset;
