import * as Yup from "yup";

export const companyContactsValidationScheme = Yup.object().shape({
  first_name: Yup.string().required("Заполните поле Имя"),
  last_name: Yup.string().required("Заполните поле Фамилия"),
  middle_name: Yup.string().required("Заполните поле Отчество"),
  phone_1: Yup.string().required("Заполните поле Номер телефона"),
  email: Yup.string().email("Введите корре́ктный email").required("Заполните поле Почта"),
  address: Yup.string().required("Заполните поле Адрес"),
});
