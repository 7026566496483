import { Autocomplete, AutocompleteRenderInputParams, Box, IconButton } from "@mui/material";

import Input from "../Input";
import { IProps } from "./interfaces";
import pxToRem from "assets/theme/functions/pxToRem";
import { Edit } from "@mui/icons-material";

const Select = (props: IProps) => {
  const { label, placeholder, select, style, error, disabled, noPointerEvents, popupIcon } = props;

  const renderInput = (params: AutocompleteRenderInputParams) => {
    const restParams = {
      ...params,
      InputProps: {
        ...params.InputProps,
      },
    };

    return (
      <Input
        label={label}
        input={{
          noPointerEvents,
          value: "",
          placeholder,
          error,
          ...restParams,
        }}
      />
    );
  };

  return (
    <Autocomplete
      sx={{
        width: "100%",
        pointerEvents: noPointerEvents ? "none" : "auto",
        ...style,
      }}
      popupIcon={popupIcon}
      disabled={disabled}
      value={select.value}
      placeholder={placeholder}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={select.items}
      onChange={(_, value) => select.onSelect(value)}
      renderInput={renderInput}
      renderOption={(props, option: any) => (
        <Box component="li" {...{ ...props, key: props.id }}>
          {option?.icon && <img width="20" style={{ marginRight: 10 }} src={`${option.icon}`} />}
          {option.label}
          {option?.edit && (
            <IconButton
              aria-label="редактировать"
              style={{ position: "absolute", right: 0 }}
              onClick={() => select.onOpen(option)}
              color={"secondary"}
            >
              <Edit style={{ width: pxToRem(16), height: pxToRem(16) }} />
            </IconButton>
          )}
        </Box>
      )}
    />
  );
};

export default Select;
