import { Autocomplete } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import DebouncedInput from "layouts/pages/widgets/components/DebouncedInput";
import { IAddress, useSearchAddressByValueMutation } from "../../../Redux/api/search-address.api";

interface IAddressAutocompleteFieldProps {
  disabled?: boolean;
  onBlur?: () => void;
  onSelect?: (value: IAddress) => void;
  error?: string;
  value?: string;
  initialValue?: string;
  label?: string;
  placeholder?: string;
}

const AddressAutocompleteField = ({
  disabled,
  onBlur,
  onSelect,
  error,
  initialValue,
  label = "Адрес",
  placeholder = "Поиск адреса",
}: IAddressAutocompleteFieldProps) => {
  const [searchAddress, addressResult] = useSearchAddressByValueMutation();

  const [selectedAddressOption, setSelectedAddressOption] = useState<IAddress | null>(null);
  const [address, setAddress] = useState<string>("");

  const addressOptions = addressResult?.data?.addresses || [];

  const onAddressSelect = (e: any, newValue: IAddress | null) => {
    setSelectedAddressOption(newValue);

    if (newValue && onSelect) {
      onSelect(newValue);
    }
  };

  const addressRef = useRef<string>("");
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (initialValue) {
      setSelectedAddressOption({
        value: initialValue,
        name: initialValue,
      });
    }
  }, [initialValue]);

  useEffect(() => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    if (address.length > 2 && address !== addressRef.current) {
      addressRef.current = address;

      timeoutIdRef.current = setTimeout(() => {
        searchAddress({ query: address });
      }, 500);
    }
  }, [address, searchAddress]);

  return (
    <Autocomplete
      style={{ width: "100%", pointerEvents: disabled ? "none" : "auto" }}
      isOptionEqualToValue={(op, val) => op.value === val.value}
      getOptionLabel={(option: IAddress) => option.name}
      options={addressOptions}
      filterOptions={(x) => x}
      id="address"
      popupIcon={disabled ? null : undefined}
      noOptionsText="Нет результатов"
      onBlur={onBlur}
      onChange={onAddressSelect}
      value={selectedAddressOption}
      renderInput={(params) => (
        <DebouncedInput
          label={label}
          debounceTimeout={500}
          handleDebounce={setAddress}
          isLoading={addressResult.isLoading}
          input={{
            noPointerEvents: disabled,
            ...params,
            placeholder: placeholder,
            value: address,
            error: error,
          }}
        />
      )}
    />
  );
};

export default AddressAutocompleteField;
