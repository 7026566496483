import { Route } from "react-router-dom";
import { IMenusItem } from "Redux/api/menus.api";
import { ICollapse, IRoute } from "interfaces";

const getRoutes = (routes: Map<string, React.ReactElement>) => {
  return [...routes].map((item) => {
    return <Route path={item[0]} element={item[1]} key={item[0]} />;
  });
};
const getCollapseItems = (items: IMenusItem[], routesMap: Map<string, React.ReactElement>) => {
  const collapse: ICollapse[] = [];
  items.forEach((item) => {
    const collapseItem: ICollapse = {
      name: item.name,
      key: `${item.id}`,
      route: item.url || "",
      component: routesMap.get(item.url) || null,
      avatar: item.avatar,
    };
    if (item.children.length) {
      collapseItem.collapse = getCollapseItems(item.children, routesMap);
    }
    collapse.push(collapseItem);
  });
  return collapse;
};

const getSidebarRoutes = (
  items: IMenusItem[],
  routesMap: Map<string, React.ReactElement>,
  defaultItems: IRoute[]
) => {
  if (!items) return [...defaultItems];
  const routes: IRoute[] = [...defaultItems];
  items.forEach((item) => {
    if (item.children.length && !item.parent_id) {
      const route: IRoute = {
        type: "collapse",
        name: item.name,
        key: `${item.id}`,
        avatar: item.avatar,
        collapse: getCollapseItems(item.children, routesMap),
      };
      routes.push(route);
    }
  });
  return routes;
};

export { getRoutes, getSidebarRoutes };
