import { IAddApplication } from "Redux/api/applications.api";

export const initialValues: IAddApplication = {
  photo_id: null,
  code: "",
  branch_id: null,
  name: "",
  warehouse_id: [],
  sales_strategy_id: null,
  is_website: false,
  is_token_active: false,
};
