import { api } from ".";

export interface INewFile {
  file: File;
  fileTypeId: number;
}

export interface IFileType {
  id: number;
  name: string;
  avatar: string;
  created_at: string;
}

export interface IFileTypesResponse {
  fileTypes: IFileType[];
  fileTypesCount: number;
}

export interface IFileTypeResponse {
  fileType: IFileType;
}

export const filesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFileTypes: builder.query<IFileTypesResponse, void>({
      query: () => `file_types`,
      transformErrorResponse: (response: any) => response.data.message,
    }),
    getFileTypeById: builder.query<IFileTypeResponse, number | string>({
      query: (id) => `file_types/${id}`,
      transformErrorResponse: (response: any) => response.data.message,
    }),
  }),
});

export const { useGetFileTypesQuery, useGetFileTypeByIdQuery } = filesApi;
