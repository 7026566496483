import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useGetIntegrationsQuery } from "../../../../Redux/api/branches.api";
import IntegrationItem from "./blocks/integration-item";

interface IProps {
  bankId: string | number;
  branchId: string | number;
}

const Integrations = ({ bankId, branchId }: IProps) => {
  const { data: integrations } = useGetIntegrationsQuery({
    branchId,
    bankId,
  });

  return (
    <Card id="integration" style={{ overflow: "hidden", width: "100%" }} sx={{ p: 2 }}>
      <MDBox p={2} lineHeight={1} mb={integrations?.integrations?.length && 1}>
        <MDTypography variant="h5">
          {integrations?.integrations?.length > 0 ? "Интеграции" : "Нет интеграций"}
        </MDTypography>
      </MDBox>
      {integrations?.integrations?.length
        ? integrations.integrations.map((integration) => (
            <IntegrationItem
              bankId={bankId}
              branchId={branchId}
              key={integration.id}
              data={integration}
            />
          ))
        : null}
    </Card>
  );
};

export default Integrations;
