import MDAlert from "components/MDAlert";
import useFilterParams from "hooks/useFilterParams";
import { Table } from "layouts/pages/widgets/components/Table";
import { useParams } from "react-router-dom";
import { useGetOrdersTableQuery } from "../../../../Redux/api/orders.api";
import Loader from "../../../components/loader";

const optionsDots = [
  {
    id: 1,
    text: "Просмотр/редакт",
    url: "/pages/orders/order-card/",
    isId: true,
  },
  {
    id: 2,
    text: "Новая задача",
    url: "/pages/tasks/new-tasks?order=",
    isId: true,
  },
];

const Orders = () => {
  const { id } = useParams();
  const { queryParams, setQuery, setPage, setPerPage, query, page, perPage } = useFilterParams(
    `&counterparty_id=${id}`
  );

  const { data, isFetching, isError, isLoading } = useGetOrdersTableQuery(queryParams());

  if (isError) {
    return (
      <MDAlert dismissible color="error">
        Не удалось загрузить заказы
      </MDAlert>
    );
  }

  if (isFetching || !data?.columns || !data?.orders) {
    return <Loader />;
  }

  return (
    <Table
      accessorKey="orders"
      data={data}
      filters={{
        setQuery,
        setPage,
        setPerPage,
        query,
        page,
        perPage,
      }}
      optionsDots={optionsDots}
      isLoading={isLoading || isFetching}
    />
  );
};

export default Orders;
