import { AVATAR } from "constants/file-types";
import PageLayout from "examples/CardPage";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useLoadFile } from "hooks/userLoadFile";
import ContentHeader from "layouts/pages/widgets/components/ContentHeader";
import Tabs from "layouts/pages/widgets/components/Tabs";
import { useState } from "react";
import Main from "../components/main";
import { initialValues } from "../consts";
import { InventoryContext } from "../context/inventory-context";

const NewInventory = () => {
  const {
    file,
    uploadLocalFile,
    loadFile,
    loadError,
    setLoadError,
    cancelUploadLocalFile,
    filePath,
  } = useLoadFile(AVATAR);

  const [name, setName] = useState("");

  return (
    <PageLayout header={<DashboardNavbar />}>
      <InventoryContext.Provider value={{ setName, file }}>
        <ContentHeader
          noPointerEvents={true}
          info={{
            img: filePath,
            uploadLocalFile,
            title: name || "Добавить товар на склад",
          }}
          error={!!loadError}
        />

        <Tabs
          defaultTab={1}
          items={{
            headers: ["Основные"],
            components: [
              <Main
                key="main"
                type="add"
                initialValues={initialValues}
                loadFile={loadFile}
                cancelUploadLocalFile={cancelUploadLocalFile}
                setLoadError={setLoadError}
              />,
            ],
          }}
        />
      </InventoryContext.Provider>
    </PageLayout>
  );
};

export default NewInventory;
