import { Theme } from "@fullcalendar/core/internal";
import { Divider } from "@mui/material";
import {
  useAddShopExceptionsMutation,
  useAddShopHoursMutation,
  useDeleteShopExceptionsMutation,
  useDeleteShopHoursMutation,
  useEditShopHolidaysMutation,
  useEditShopHoursMutation,
  useGetShopHoursQuery,
} from "../../../../../Redux/api/shops.api";

import ContentWrapper from "layouts/components/content-wrapper";
import Block from "layouts/pages/widgets/components/Block";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HolidaysAccordion from "./holidays-accordion";
import ExceptionsAccordion from "./exceptions-accordion";
import Hours from "./hours";
import Days from "./days";
import AddUpdateAlerts from "layouts/components/add-update-alerts";
import { Form, Formik } from "formik";
import EditContainer from "layouts/components/edit-container";
import FormControls from "layouts/components/form-controls";
import dayjs from "dayjs";

interface IProps {
  disabled?: boolean;
  shopEdit?: boolean;
  setShopEdit?: (v: boolean) => void;
}

const dividerStyle =
  () =>
  ({ palette: { secondary } }: Theme | any) => ({
    margin: 0,
    backgroundColor: secondary.main,
    width: "100%",
  });

const WorkMode = ({ shopEdit, setShopEdit }: IProps) => {
  const { id } = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const [createHours, createResult] = useAddShopHoursMutation();
  const [editHours, editResult] = useEditShopHoursMutation();
  const [deleteHours, deleteResult] = useDeleteShopHoursMutation();
  const [editHolidays, editHolidaysResult] = useEditShopHolidaysMutation();
  const [deleteExceptions, deleteExceptionsResult] = useDeleteShopExceptionsMutation();
  const [createExceptions, createExceptionsResult] = useAddShopExceptionsMutation();
  const { data: hoursData } = useGetShopHoursQuery(id);
  const [initHours, setInitHours] = useState([]);
  const [activeDays, setActiveDays] = useState<string[]>([]);
  const [weekDays, setWeekDays] = useState({
    work_start_time: "08:00",
    work_end_time: "18:00",
    break_start_time: "12:00",
    break_end_time: "13:00",
  });
  const [weekends, setWeekends] = useState({
    work_start_time: "08:00",
    work_end_time: "18:00",
    break_start_time: "12:00",
    break_end_time: "13:00",
  });
  const [showHolidayHours, setShowHolidayHours] = useState(false);

  useEffect(() => {
    setInitHours(hoursData?.hours?.length ? hoursData.hours : []);
  }, [hoursData]);

  useEffect(() => {
    if (initHours.length) {
      setActiveDays(hoursData.hours.map((item) => item.day_of_week));

      let foundWorkingDay = false;
      let foundWeekend = false;

      hoursData.hours.forEach((item) => {
        const isWeekend = item.day_of_week === "СБ" || item.day_of_week === "ВС";

        if (!foundWeekend && isWeekend) {
          setWeekends({
            work_start_time: item.work_start_time,
            work_end_time: item.work_end_time,
            break_start_time: item.break_start_time,
            break_end_time: item.break_end_time,
          });
          foundWeekend = true;
        }

        if (!foundWorkingDay && !isWeekend) {
          setWeekDays({
            work_start_time: item.work_start_time,
            work_end_time: item.work_end_time,
            break_start_time: item.break_start_time,
            break_end_time: item.break_end_time,
          });
          foundWorkingDay = true;
        }

        if (foundWeekend && foundWorkingDay) {
          return;
        }
      });
    } else {
      setActiveDays([]);
    }
  }, [initHours]);

  useEffect(() => {
    if (activeDays.includes("СБ") || activeDays.includes("ВС")) {
      setShowHolidayHours(true);
    } else {
      setShowHolidayHours(false);
    }
  }, [activeDays]);

  const handleActiveDay = (value: string) => {
    setActiveDays((prevActiveDays) => {
      if (prevActiveDays.includes(value)) {
        return prevActiveDays.filter((day) => day !== value);
      } else {
        return [...prevActiveDays, value];
      }
    });
  };

  const handleSubmit = () => {
    initHours.forEach(
      ({
        day_of_week,
        id: dayId,
        work_start_time,
        work_end_time,
        break_start_time,
        break_end_time,
      }) => {
        const isWeekend = day_of_week === "СБ" || day_of_week === "ВС";
        const targetTimes = isWeekend ? weekends : weekDays;
        if (!activeDays.includes(day_of_week)) {
          deleteHours({ shopId: id, id: dayId });
        } else if (
          (work_start_time !== targetTimes.work_start_time ||
            work_end_time !== targetTimes.work_end_time ||
            break_start_time !== targetTimes.break_start_time ||
            break_end_time !== targetTimes.break_end_time,
          "HH:mm")
        ) {
          editHours({
            id: dayId,
            shopId: id,
            day_of_week,
            is_working_day: !isWeekend,
            work_start_time: dayjs(targetTimes.work_start_time, "HH:mm").format("HH:mm"),
            work_end_time: dayjs(targetTimes.work_end_time, "HH:mm").format("HH:mm"),
            break_start_time: dayjs(targetTimes.break_start_time, "HH:mm").format("HH:mm"),
            break_end_time: dayjs(targetTimes.break_end_time, "HH:mm").format("HH:mm"),
          });
        }
      }
    );

    activeDays.forEach((activeDay) => {
      const found = initHours.find((item) => item.day_of_week === activeDay);

      if (!found) {
        const isWeekend = activeDay === "СБ" || activeDay === "ВС";
        const { work_start_time, work_end_time, break_start_time, break_end_time } = isWeekend
          ? weekends
          : weekDays;
        createHours({
          shopId: id,
          day_of_week: activeDay,
          is_working_day: !isWeekend,
          work_start_time: dayjs(work_start_time, "HH:mm").format("HH:mm"),
          work_end_time: dayjs(work_end_time, "HH:mm").format("HH:mm"),
          break_start_time: dayjs(break_start_time, "HH:mm").format("HH:mm"),
          break_end_time: dayjs(break_end_time, "HH:mm").format("HH:mm"),
        });
      }
    });

    setIsEdit(false);
  };

  return (
    <>
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        {() => (
          <Form style={{ width: "100%" }}>
            <EditContainer
              showButton={true}
              buttonColor={isEdit ? "secondary" : "error"}
              buttonAction={() => setIsEdit((prev) => !prev)}
            >
              <ContentWrapper asideStyles={{ paddingTop: "0" }}>
                <Block title="Режим работы" wrapperStyles={{ width: "100%" }}>
                  <Days disabled={!isEdit} activeDays={activeDays} change={handleActiveDay} />
                  <Divider sx={dividerStyle()} />
                  <Hours
                    disabled={!isEdit}
                    time={weekDays}
                    change={setWeekDays}
                    is_working_day={false}
                  />
                  <Divider sx={dividerStyle()} />
                  {showHolidayHours && (
                    <>
                      <Hours
                        disabled={!isEdit}
                        time={weekends}
                        change={setWeekends}
                        is_working_day={true}
                      />
                      <Divider sx={dividerStyle()} />
                    </>
                  )}

                  <HolidaysAccordion disabled={!isEdit} onSubmit={editHolidays} />
                  <Divider sx={dividerStyle()} />
                  <ExceptionsAccordion
                    disabled={!isEdit}
                    onDelete={deleteExceptions}
                    onAdd={createExceptions}
                  />
                </Block>
              </ContentWrapper>
              {!shopEdit && isEdit && <FormControls cancelAction={() => setIsEdit(false)} />}
            </EditContainer>
          </Form>
        )}
      </Formik>
      <AddUpdateAlerts
        isEditError={
          editResult.isError ||
          deleteResult.isError ||
          editHolidaysResult.isError ||
          deleteExceptionsResult.isError
        }
        isEditSuccess={
          editResult.isSuccess ||
          deleteResult.isSuccess ||
          editHolidaysResult.isSuccess ||
          deleteExceptionsResult.isSuccess
        }
        isAddError={createResult.isError || createExceptionsResult.isError}
        isAddSuccess={createResult.isSuccess || createExceptionsResult.isSuccess}
        editErrorMessage={editResult.error as string}
        addErrorMessage={createResult.error as string}
      />
    </>
  );
};

export default WorkMode;
