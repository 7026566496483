import { Form, Formik } from "formik";
import {
  IAddNomenclature,
  useAddNomenclatureMutation,
  useUpdateNomenclatureMutation,
} from "../../../../Redux/api/nomenclatures.api";
import { routes } from "constants/route";
import AddUpdateAlerts from "layouts/components/add-update-alerts";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContentWrapper from "layouts/components/content-wrapper";
import EditContainer from "layouts/components/edit-container";
import FormControls from "layouts/components/form-controls";
import AsideCodeBlock from "layouts/components/aside-code-block";
import MainFieldset from "./main-fieldset";
import PhotoBlock from "./photo-block";
import PreviewBlock from "./preview-block";
import { mainValidationScheme } from "../schemas/main";
import { ProductsContext } from "../context/products-context";
import { delay } from "utils/delay";

interface IMainProps {
  initialValues: IAddNomenclature;
  edit?: boolean;
  type?: "add" | "edit";
  [key: string]: any;
}

interface IFormikValues extends IAddNomenclature {}

const Main = (props: IMainProps) => {
  const {
    initialValues,
    edit = false,
    loadFile,
    cancelUploadLocalFile,
    loadPhotoFile,
    cancelPhotoUploadLocalFile,
    setLoadError,
  } = props;

  const { file, photo, files, loadMultiPhotoFile } = useContext(ProductsContext);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [createNomenclature, createResult] = useAddNomenclatureMutation();
  const [editNomenclature, editResult] = useUpdateNomenclatureMutation();

  const navigate = useNavigate();

  const createResultHandler = async () => {
    if (createResult.isSuccess && createResult?.data?.nomenclature.id) {
      await delay(1000);
      navigate(routes.NOMENCLATURES + "/" + createResult?.data?.nomenclature.id);
    }
  };

  useEffect(() => {
    createResultHandler();
  }, [createResult]);

  const editHandler = (values: IFormikValues) => {
    editNomenclature(values);
    setIsEdit(false);
  };

  const handleSubmit = async (values: IFormikValues) => {
    let loadedFile = null;
    let loadedPhotoFile = null;
    let loadedMultiPhotoFiles = [];
    if (file) loadedFile = await loadFile();
    if (photo) loadedPhotoFile = await loadPhotoFile();

    if (files?.length) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const loadedFile = await loadMultiPhotoFile(file);
        if (!loadedFile) continue;
        loadedMultiPhotoFiles.push(loadedFile.id);
      }
    }

    if (loadedFile) values.avatar_id = loadedFile.id;
    if (loadedPhotoFile) values.preview_id = loadedPhotoFile.id;
    if (loadedMultiPhotoFiles?.length) values.photos = loadedMultiPhotoFiles;

    cancelUploadLocalFile();
    cancelPhotoUploadLocalFile();

    edit ? editHandler(values) : createNomenclature(values);
  };

  return (
    <>
      <Formik<IFormikValues>
        initialValues={initialValues}
        validationSchema={mainValidationScheme}
        onSubmit={handleSubmit}
        validateOnMount={false}
        validate={() => {
          if (!file && !edit) {
            setLoadError?.("Загрузите avatar");
          }
        }}
      >
        {({ setFieldValue }) => (
          <ContentWrapper
            aside={
              <AsideCodeBlock
                onChange={(code) => setFieldValue("code", code)}
                onFocus={() => setIsEdit(true)}
                initialValue={initialValues.code?.toString()}
              />
            }
          >
            <Form style={{ width: "100%" }}>
              <EditContainer
                showButton={edit}
                buttonColor={isEdit ? "secondary" : "error"}
                buttonAction={() => setIsEdit((prev) => !prev)}
              >
                <MainFieldset disabled={edit && !isEdit} />
              </EditContainer>

              {(isEdit || !edit || file) && <FormControls cancelRoute={routes.NOMENCLATURES} />}
            </Form>

            <PreviewBlock setIsEdit={setIsEdit} />

            <PhotoBlock setIsEdit={setIsEdit} />
          </ContentWrapper>
        )}
      </Formik>
      <AddUpdateAlerts
        isEditError={editResult.isError}
        isEditSuccess={editResult.isSuccess}
        isAddError={createResult.isError}
        isAddSuccess={createResult.isSuccess}
        editErrorMessage={editResult.error as string}
        addErrorMessage={createResult.error as string}
      />
    </>
  );
};

export default Main;
