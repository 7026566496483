import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { mainCompanyValidationScheme } from "../schemas";
import {
  IAddCompanyCounterparty,
  IAddPhysicalCounterparty,
  useAddCounterpartyMutation,
  useEditCounterpartyMutation,
} from "../../../../Redux/api/counterperties.api";
import MainCompanyFields from "./blocks/main-company-fieldset";
import CompanyContacts from "./blocks/company-contacts";
import EditContainer from "../../../components/edit-container";
import FormControls from "../../../components/form-controls";
import Sidenav from "layouts/pages/account/settings/components/Sidenav";
import MainPhysicalFields from "./blocks/main-physical-fieldset";
import { mainPhysicalValidationScheme } from "../schemas/main-physical";
import ContentWrapper from "../../../components/content-wrapper";
import AddUpdateAlerts from "layouts/components/add-update-alerts";
import { routes } from "../../../../constants";
import { IMain } from "./interfaces";
import { CounterpartyNameContext } from "../context/counterparty-name-context";
import { delay } from "utils/delay";

const sidenavItems = [
  { icon: "receipt_long", label: "Основная информация", href: "basic-info" },
  { icon: "receipt_long", label: "Контактное лицо", href: "contact-person" },
];

const Main = (props: IMain) => {
  const {
    initialValues,
    edit = false,
    type,
    loadFile,
    cancelUploadLocalFile,
    setLoadError,
  } = props;

  const { file, isCompany } = useContext(CounterpartyNameContext);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [createCounterparty, createResult] = useAddCounterpartyMutation();
  const [editCounterparty, editResult] = useEditCounterpartyMutation();

  const navigate = useNavigate();

  const createResultHandler = async () => {
    if (createResult.isSuccess && createResult?.data?.counterparty.id) {
      await delay(1000);
      navigate(routes.COUNTERPARTY + "/" + createResult?.data?.counterparty.id);
    }
  };

  useEffect(() => {
    createResultHandler();
  }, [createResult]);

  const editHandler = (values: IAddCompanyCounterparty | IAddPhysicalCounterparty) => {
    editCounterparty(values);
    setIsEdit(false);
  };

  const handleSubmit = async (values: IAddCompanyCounterparty | IAddPhysicalCounterparty) => {
    let loadedFile = null;
    if (file) loadedFile = await loadFile();
    if (loadedFile) values.avatar_id = loadedFile.id;
    cancelUploadLocalFile();
    type === "add" ? createCounterparty(values) : editHandler(values);
  };

  return (
    <>
      <ContentWrapper aside={<Sidenav sidenavItems={sidenavItems} />} contentId="basic-info">
        <Formik
          initialValues={initialValues}
          validationSchema={isCompany ? mainCompanyValidationScheme : mainPhysicalValidationScheme}
          onSubmit={handleSubmit}
          validateOnMount={false}
          enableReinitialize={true}
          validate={() => {
            if (!file && !edit) {
              setLoadError?.("Загрузите avatar");
            }
          }}
        >
          {() => (
            <Form style={{ width: "100%" }}>
              <EditContainer
                showButton={edit}
                buttonColor={isEdit ? "secondary" : "error"}
                buttonAction={() => setIsEdit((prev) => !prev)}
              >
                {isCompany ? (
                  <MainCompanyFields disabled={edit && !isEdit} />
                ) : (
                  <MainPhysicalFields disabled={edit && !isEdit} />
                )}
              </EditContainer>

              {(isEdit || !edit || file) && <FormControls cancelRoute={routes.COUNTERPARTY} />}
            </Form>
          )}
        </Formik>
        {edit && <CompanyContacts isMainEdit={isEdit} />}
      </ContentWrapper>
      <AddUpdateAlerts
        isEditError={editResult.isError}
        isEditSuccess={editResult.isSuccess}
        isAddError={createResult.isError}
        isAddSuccess={createResult.isSuccess}
        editErrorMessage={editResult.error as string}
        addErrorMessage={createResult.error as string}
      />
    </>
  );
};

export default Main;
