import PageLayout from "examples/CardPage";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useState } from "react";
import ContentHeader from "layouts/pages/widgets/components/ContentHeader";
import Tabs from "layouts/pages/widgets/components/Tabs";
import Main from "../components/main";
import { WarehouseNameContext } from "../context/warehouse-name-context";
import { Stack, Switch } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import MDTypography from "components/MDTypography";
import { useLoadFile } from "hooks/userLoadFile";
import { AVATAR } from "constants/file-types";
import { initialValues } from "../consts";

const NewWarehouse = () => {
  const {
    file,
    uploadLocalFile,
    loadFile,
    loadError,
    setLoadError,
    cancelUploadLocalFile,
    filePath,
  } = useLoadFile(AVATAR);

  const [isOwned, setIsOwned] = useState(false);
  const [name, setName] = useState("");

  return (
    <PageLayout header={<DashboardNavbar />}>
      <WarehouseNameContext.Provider value={{ setName, file }} key="add">
        <ContentHeader
          info={{
            img: filePath,
            uploadLocalFile,
            title: name || "Новый склад",
          }}
          action={
            <Stack direction="row" spacing={0.5} alignItems="center">
              <MDTypography color="text" style={{ fontSize: pxToRem(14) }}>
                Свой / Чужой
              </MDTypography>

              <Switch checked={!isOwned} onChange={() => setIsOwned((v) => !v)} />
            </Stack>
          }
          error={!!loadError}
        />

        <Tabs
          defaultTab={1}
          items={{
            headers: ["Основные"],
            components: [
              <Main
                key="main"
                type="add"
                initialValues={initialValues}
                isOwned={isOwned}
                loadFile={loadFile}
                cancelUploadLocalFile={cancelUploadLocalFile}
                setLoadError={setLoadError}
              />,
            ],
          }}
        />
      </WarehouseNameContext.Provider>
    </PageLayout>
  );
};

export default NewWarehouse;
