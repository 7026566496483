import PageLayout from "examples/CardPage";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Loader from "layouts/components/loader";
import ContentHeader from "layouts/pages/widgets/components/ContentHeader";
import Tabs from "layouts/pages/widgets/components/Tabs";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetInventoriesByIdQuery,
  useGetInventoriesHistoryQuery,
} from "../../../../Redux/api/inventories.api";
import History from "../../../pages/widgets/components/History";
import Main from "../components/main";
import { InventoryContext } from "../context/inventory-context";
import { useLoadFile } from "hooks/userLoadFile";
import { AVATAR } from "constants/file-types";
import { getInitialValues } from "../consts";

const ViewInventory = () => {
  const [name, setName] = useState("");

  const { id } = useParams<string>();
  const { data: inventory, isFetching } = useGetInventoriesByIdQuery(id);
  const { file, filePath, uploadLocalFile, loadFile, cancelUploadLocalFile } = useLoadFile(AVATAR);

  if (isFetching && !inventory?.inventory) {
    return <Loader />;
  }

  return (
    <PageLayout header={<DashboardNavbar />}>
      <InventoryContext.Provider value={{ setName, file }} key="edit">
        <ContentHeader
          info={{
            img: filePath || inventory?.inventory?.avatar?.path,
            uploadLocalFile,
            title: name || inventory?.inventory?.nomenclature?.name,
          }}
          error={!(file || inventory?.inventory?.avatar?.path)}
        />
        <Tabs
          defaultTab={1}
          items={{
            headers: ["Основные", "История"],
            components: [
              <Main
                key="inventory"
                edit={true}
                initialValues={getInitialValues(inventory)}
                type="edit"
                loadFile={loadFile}
                cancelUploadLocalFile={cancelUploadLocalFile}
              />,
              <History key="history" request={useGetInventoriesHistoryQuery} />,
            ],
          }}
        />
      </InventoryContext.Provider>
    </PageLayout>
  );
};

export default ViewInventory;
