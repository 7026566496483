import { IBranch } from "Redux/api/interfaces";
import { useGetBranchesQuery } from "../../../Redux/api/branches.api";
import Select from "layouts/pages/widgets/components/Select";
import { IItem } from "layouts/pages/widgets/components/Select/interfaces";
import { useEffect, useMemo, useState } from "react";

interface IBranchFieldProps {
  disabled?: boolean;
  error?: string;
  initialValueId?: number | string;
  onSelect?: (value: IItem) => void;
}

const formatToSelectItem = (branches?: IBranch[]): IItem[] => {
  if (!branches) return [];
  return branches.map((branch) => ({
    id: branch.id,
    label: branch.title,
    value: branch.id,
    icon: branch.avatar?.path,
  }));
};

const BranchField = ({ disabled, error, initialValueId, onSelect }: IBranchFieldProps) => {
  const { data } = useGetBranchesQuery(null, { refetchOnMountOrArgChange: true });
  const [selectedOption, setSelectedOption] = useState<IItem | null>(null);
  const options = useMemo(() => formatToSelectItem(data?.branches), [data]);

  useEffect(() => {
    const typeOption = options.find((item) => item.id == initialValueId);
    if (typeOption) {
      setSelectedOption(typeOption);
    }
  }, [options, initialValueId, disabled]);

  const onSelectItem = (value: IItem) => {
    setSelectedOption(value);
    onSelect(value);
  };

  return (
    <Select
      label="Направление бизнеса"
      placeholder="Выберите из списка"
      noPointerEvents={disabled}
      error={error}
      popupIcon={disabled ? null : undefined}
      select={{
        value: selectedOption,
        items: options,
        onSelect: onSelectItem,
      }}
    />
  );
};

export default BranchField;
