import { Theme } from "@mui/material";

export const useStyles = (theme: Theme) => {
  const styles = {
    wrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: {
        xs: 3,
        md: 1,
      },
      flexDirection: {
        xs: "column",
        md: "row",
      },
      maxWidth: "100%",
      // marginTop: 2,
      // marginBottom: 2,
    },
    inner: {
      display: "flex",
      alignItems: "center",
      gap: 1,
    },
    main: {
      display: "flex",
      alignItems: "center",
      gap: 1.3,
    },
    dates: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: 0.3,
      width: "100%",
    },
    date: {
      width: "100%",
      maxWidth: "100%",
      margin: "0",
    },
    buttons: {
      display: "flex",
      alignItems: "center",
      gap: 1,
      justifyContent: "space-between",
      width: "100%",
      flexDirection: {
        xs: "column",
        sm: "row",
      },
    },
    button: {
      display: "flex",
      alignItems: "center",
      gap: 1.3,
      paddingLeft: "1rem",
      paddingRight: "1rem",
      width: {
        xs: "100%",
        sm: 150,
      },
      flex: 1,
    },
  };

  return styles;
};
