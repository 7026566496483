import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Box, Card, Modal } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import MDTypography from "components/MDTypography";
import { Fragment, PropsWithChildren } from "react";
import { createPortal } from "react-dom";
import FormControls from "./form-controls";

interface IPopupProps extends PropsWithChildren {
  isOpen: boolean;
  onClose: () => void;
  titleIcon: React.ReactElement;
  title: string;
  width?: number;
  header?: React.ReactElement;
  submitAction?: () => void;
}

const Popup = ({
  isOpen,
  onClose,
  titleIcon,
  title,
  children,
  width,
  header,
  submitAction,
}: IPopupProps) => {
  return (
    <Fragment>
      <Modal open={isOpen} onClose={onClose}>
        <Card
          sx={{
            width: "96%",
            p: 3,
            maxWidth: pxToRem(1200),
            minHeight: pxToRem(300),
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            gap: pxToRem(24),
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <MDTypography
              color="dark"
              fontSize={16}
              fontWeight="bold"
              lineHeight="140%"
              style={{ maxWidth: "100%" }}
            >
              {titleIcon && titleIcon}
              {title && title}
            </MDTypography>
            <CloseOutlinedIcon
              sx={{
                cursor: "pointer",
                width: 24,
                height: 24,
                color: "rgba(0, 0, 0, 1)",
              }}
              onClick={onClose}
            />
          </Box>
          {header}
          <Box
            sx={{
              width: "100%",
              overflowX: "auto",
            }}
          >
            <Box
              sx={{
                width: width ? pxToRem(width) : "auto",
                overflowX: "auto",
                minHeight: pxToRem(200),
              }}
            >
              {children}
            </Box>
          </Box>
        </Card>
      </Modal>

      {submitAction &&
        createPortal(
          <FormControls cancelAction={onClose} submitAction={submitAction} />,
          document.getElementById("root")!
        )}
    </Fragment>
  );
};

export default Popup;
