import * as Yup from "yup";

export const mainValidationScheme = Yup.object().shape({
  name: Yup.string().required("Заполните поле Название"),
  branch_id: Yup.number().required("Выберите Направление бизнеса"),
  warehouse_id: Yup.array()
    .of(Yup.number().required())
    .min(1, "Выберите минимум 1 Склад")
    .required("Выберите минимум 1 Склад"),
  sales_strategy_id: Yup.number().required("Выберите Стратегию продаж"),
});
