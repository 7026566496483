import MDAlert from "components/MDAlert";
import BottomPanel from "./bottom-panel";
import { Box, Grid } from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ErrorIcon from "@mui/icons-material/Error";

interface IAddUpdateAlertsProps {
  isEditError?: boolean;
  isAddError?: boolean;
  isEditSuccess?: boolean;
  isAddSuccess?: boolean;
  editErrorMessage?: string;
  addErrorMessage?: string;
}

const DEFAULT_MESSAGES = {
  error: "Произошла ошибка",
  success: "Данные сохранены",
};

const AddUpdateAlerts = ({
  isAddError,
  isEditError,
  isAddSuccess,
  isEditSuccess,
  editErrorMessage = DEFAULT_MESSAGES.error,
  addErrorMessage = DEFAULT_MESSAGES.error,
}: IAddUpdateAlertsProps) => {
  return (
    <BottomPanel>
      <Grid container display="flex" zIndex={500}>
        {isEditError && (
          <MDAlert dismissible color="error" mb={0} fullWidth childCenter autoClose>
            <Box alignItems="center" gap={2} display="flex">
              <ErrorIcon style={{ width: 24, height: 24 }} />
              <div>{editErrorMessage || DEFAULT_MESSAGES.error}</div>
            </Box>
          </MDAlert>
        )}
        {isAddError && (
          <MDAlert dismissible color="error" mb={0} fullWidth childCenter autoClose>
            <Box alignItems="center" gap={2} display="flex">
              <ErrorIcon style={{ width: 24, height: 24 }} />
              <div>{addErrorMessage || DEFAULT_MESSAGES.error}</div>
            </Box>
          </MDAlert>
        )}
        {isEditSuccess && (
          <MDAlert dismissible color="primary" mb={0} fullWidth childCenter autoClose>
            <Box alignItems="center" gap={2} display="flex">
              <ThumbUpIcon style={{ width: 24, height: 24 }} />
              <div>{DEFAULT_MESSAGES.success}</div>
            </Box>
          </MDAlert>
        )}
        {isAddSuccess && (
          <MDAlert dismissible color="primary" mb={0} fullWidth childCenter autoClose>
            <Box alignItems="center" gap={2} display="flex">
              <ThumbUpIcon style={{ width: 24, height: 24 }} />
              <div>{DEFAULT_MESSAGES.success}</div>
            </Box>
          </MDAlert>
        )}
      </Grid>
    </BottomPanel>
  );
};

export default AddUpdateAlerts;
