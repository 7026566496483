import { api } from ".";

export interface ISearhCompanyRequest {
  field: "name" | "inn" | "ogrn";
  query: string;
}

export interface ICompanyResponse {
  companies: ICompany[];
  companiesCount: number;
}

export interface ICompany {
  name: string;
  inn: string;
  orgn?: string;
  legal_address: string;
  kpp: string;
  head: string;
  registration_date?: string;
}

export const searchCompanyApi = api.injectEndpoints({
  endpoints: (builder) => ({
    searchCompanyByValue: builder.mutation<ICompanyResponse, ISearhCompanyRequest>({
      query: (body) => ({
        url: `search_company`,
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: any) => response.data.message,
    }),
  }),
});

export const { useSearchCompanyByValueMutation } = searchCompanyApi;
