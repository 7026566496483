import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import IntegrationItem from "./blocks/integration-item";
import { useGetWarehousesIntegrationsQuery } from "../../../../Redux/api/warehouses.api";

interface IProps {
  warehouseId: string | number;
}

const Integrations = ({ warehouseId }: IProps) => {
  const { data: integrations } = useGetWarehousesIntegrationsQuery(warehouseId);

  return (
    <Card id="integration" style={{ overflow: "hidden", width: "100%" }} sx={{ p: 2 }}>
      <MDBox p={2} lineHeight={1} mb={integrations?.integrations?.length && 1}>
        <MDTypography variant="h5">
          {integrations?.integrations?.length > 0 ? "Интеграции" : "Нет интеграций"}
        </MDTypography>
      </MDBox>
      {integrations?.integrations?.length
        ? integrations.integrations.map((integration) => (
            <IntegrationItem warehouseId={warehouseId} key={integration.id} data={integration} />
          ))
        : null}
    </Card>
  );
};

export default Integrations;
