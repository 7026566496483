import { useEffect, useState, ReactNode, Fragment } from "react";

import { useLocation, NavLink } from "react-router-dom";

import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavList from "examples/Sidenav/SidenavList";
import SidenavItem from "examples/Sidenav/SidenavItem";

import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import { ICollapse, IRoute } from "interfaces";
import profilePicture from "assets/images/team-3.jpg";
import MDAvatar from "components/MDAvatar";
import { useTheme } from "@mui/material";

interface Props {
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark";
  brand?: string;
  brandName: string;
  routes: IRoute[];
  [key: string]: any;
}

function Sidenav({ color, brand, brandName, routes, ...rest }: Props): JSX.Element {
  const [openCollapse, setOpenCollapse] = useState<boolean | string>(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState<boolean | string>(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];
  const { palette } = useTheme();

  let textColor:
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "dark"
    | "white"
    | "inherit"
    | "text"
    | "light" = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, []);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse: ICollapse[]) => {
    const template = collapse.map(
      ({ name, route, key, component, href, icon, avatar }: ICollapse) =>
        href ? (
          <Link
            key={key}
            href={href}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem
              name={name}
              nested
              icon={icon}
              avatar={avatar}
              style={{ background: "rgba(255, 255, 255, 0.1)" }}
            />
          </Link>
        ) : (
          <NavLink
            to={route}
            key={key}
            style={{
              textDecoration: "none",
              pointerEvents: !route || !component ? "none" : "auto",
              opacity: !route || !component ? 0.7 : 1,
            }}
          >
            <SidenavItem
              name={name}
              active={route === pathname}
              nested
              icon={icon}
              avatar={avatar}
              opacity={0.1}
            />
          </NavLink>
        )
    );

    return template;
  };
  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses: ICollapse[]) => {
    return collapses.map((props: ICollapse) => {
      const { name, collapse, route, href, component, avatar, key, icon } = props;

      if (collapse) {
        return (
          <SidenavList key={key}>
            <SidenavItem
              key={key}
              color={color}
              name={name}
              opacity={0.05}
              active={key === itemParentName ? "isParent" : false}
              open={openNestedCollapse === key}
              icon={icon}
              avatar={avatar}
              disabled={!!href}
              onClick={({ currentTarget }: any) =>
                openNestedCollapse === key && currentTarget.classList.contains("MuiListItem-root")
                  ? setOpenNestedCollapse(false)
                  : setOpenNestedCollapse(key)
              }
            >
              {renderNestedCollapse(collapse)}
            </SidenavItem>
          </SidenavList>
        );
      }

      return (
        <SidenavList key={key}>
          <NavLink
            to={route}
            key={key}
            style={{
              textDecoration: "none",
              pointerEvents: !route || !component ? "none" : "auto",
              opacity: !route || !component ? 0.7 : 1,
            }}
          >
            <SidenavItem
              color={color}
              name={name}
              active={key === itemName}
              avatar={avatar}
              opacity={0.1}
            />
          </NavLink>
        </SidenavList>
      );
    });
  };

  const [profile, ...restRoutes] = routes;

  const routeClickHandler = (key?: string) => {
    return () => {
      if (openCollapse === (key || profile.key)) {
        setOpenCollapse(false);
        return;
      }

      setOpenCollapse(key || profile.key);
    };
  };

  const renderRoutes = restRoutes.map((props: IRoute, index: number) => {
    const { type, name, icon, title, collapse, noCollapse, key, href, route, avatar } = props;

    if (type === "out-side") {
      return null;
    }

    return (
      <Fragment key={key}>
        <Divider
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />

        <SidenavCollapse
          name={name}
          icon={icon}
          avatar={avatar}
          active={key === collapseName}
          open={openCollapse === key}
          onClick={routeClickHandler(key)}
          index={key}
        >
          {collapse && renderCollapse(collapse)}
        </SidenavCollapse>

        {index === restRoutes.length - 1 && (
          <Divider
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        )}
      </Fragment>
    );
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>

        <MDBox component={NavLink} to="/" display="flex" alignItems="center" gap={1.25}>
          {brand && <MDBox component="img" src={brand} alt="Brand" width="2rem" />}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme: any) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}

// Declaring default props for Sidenav
export default Sidenav;
