import { api } from ".";

export interface IManager {
  id: number;
  name: string;
}

export interface IManagerResponse {
  managers: IManager[];
  managersCount: number;
}

export const managersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getManagers: builder.query<IManagerResponse, void>({
      query: () => `managers`,
      transformErrorResponse: (response: any) => response.data.message,
    }),
  }),
});

export const { useGetManagersQuery } = managersApi;
