import { IAddCompanyCounterparty, IAddPhysicalCounterparty } from "Redux/api/counterperties.api";

export const initialCompanyValues: IAddCompanyCounterparty = {
  is_company: true,
  counterparty_type_id: null,
  name: "",
  inn: "",
  head: "",
  kpp: "",
  registered_at: "",
  phone_1: "",
  phone_2: "",
  email: "",
  legal_address: "",
};

export const initialPhysicalValues: IAddPhysicalCounterparty = {
  is_company: false,
  counterparty_type_id: null,
  first_name: "",
  last_name: "",
  middle_name: "",
  birthday: "",
  phone_1: "",
  phone_2: "",
  email: "",
  address: "",
};
