import dayjs from "dayjs";
import "flatpickr/dist/flatpickr.css";
import { Russian } from "flatpickr/dist/l10n/ru";
import Flatpickr from "react-flatpickr";
dayjs.locale("ru");

import Input from "../Input";

const DatePicker = (props: any) => {
  const { input, disabled, ...rest } = props;

  return (
    <Flatpickr
      {...rest}
      disabled={disabled}
      options={{
        dateFormat: "Y-m-d",
        locale: Russian,
        disableMobile: "true",
      }}
      render={({ defaultValue, label, placeholder, disabled, value }: any, ref) => (
        <Input
          input={{
            ...input,
            value: value && dayjs(value, "YYYY-MM-DD").format("DD MMMM YYYY"),
            placeholder,
            noPointerEvents: disabled,
            defaultValue,
            ref,
            error: rest.error,
          }}
          label={label}
        />
      )}
    />
  );
};

export default DatePicker;
