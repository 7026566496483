import { api } from ".";

export interface ICounterpartyType {
  id: number;
  name: string;
  avatar: string;
  created_at: string;
}

export interface ICounterpartyTypeResponse {
  counterpartyTypes: ICounterpartyType[];
  counterpartyTypesCount: number;
}

export const counterpartyTypesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCounterpartyTypes: builder.query<ICounterpartyTypeResponse, void>({
      query: () => `counterparty_types`,
      transformErrorResponse: (response: any) => response.data.message,
    }),
  }),
});

export const { useGetCounterpartyTypesQuery } = counterpartyTypesApi;
