import { IInventory } from "Redux/api/inventories.api";

export const getInitialValues = (inventory: { inventory: IInventory }) => {
  if (inventory?.inventory) {
    return {
      avatar_id: inventory?.inventory?.avatar?.id || null,
      id: inventory?.inventory.id,
      code: inventory?.inventory.code,
      nomenclature_id: inventory?.inventory.nomenclature.id,
      vat_id: inventory?.inventory.vat.id,
      quantity: inventory?.inventory.quantity,
      price: inventory?.inventory.price,
      discount: inventory?.inventory.discount || 0,
      markup: Number(inventory?.inventory.markup)?.toFixed(0) || 0,
      warehouse_id: inventory?.inventory?.warehouse?.id,
    };
  }
};
