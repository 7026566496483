import { Theme } from "@fullcalendar/core/internal";
import { TimePicker } from "@mui/x-date-pickers";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import dayjs from "dayjs";

interface IProps {
  disabled: boolean;
  time: ITime;
  is_working_day: boolean;
  change: (time: ITime) => void;
}

interface ITime {
  work_start_time: string;
  work_end_time: string;
  break_start_time: string;
  break_end_time: string;
}

const Hours = ({ disabled, time, is_working_day, change }: IProps) => {
  return (
    <MDBox
      display="flex"
      flexDirection="column"
      width="100%"
      gap={1.5}
      alignItems="flex-start"
      sx={{
        pointerEvents: disabled ? "none" : "initial",
      }}
    >
      <MDTypography fontSize={14} fontWeight="regular" lineHeight="140%" color="text">
        {is_working_day ? "Выходные дни" : "Будние дни"}
      </MDTypography>
      <MDBox
        display="flex"
        alignItems={{ xs: "flex-start", lg: "center" }}
        flexDirection={{ xs: "column", lg: "row" }}
        gap={3}
      >
        <MDBox
          display="flex"
          gap={{ xs: 1, md: 3 }}
          alignItems={{ xs: "flex-start", lg: "center" }}
          flexDirection={{ xs: "column", lg: "row" }}
        >
          <MDTypography color="dark" fontSize={14} fontWeight="regular" lineHeight="140%">
            Время работы
          </MDTypography>
          <MDBox display="flex" gap={0.25} alignItems="center">
            <TimePicker
              sx={({ palette: { text } }: Theme | any) => ({
                borderColor: text.main,
                borderRadius: 2,
                maxWidth: "140px",
                svg: {
                  width: "15px",
                  height: "15px",
                  fill: "#344767",
                },
              })}
              value={dayjs(`2022-04-17 ${time.work_start_time}`)}
              onChange={(v: any) => change({ ...time, work_start_time: v })}
            />
            -
            <TimePicker
              sx={({ palette: { text } }: Theme | any) => ({
                borderColor: text.main,
                borderRadius: 2,
                maxWidth: "140px",
                svg: {
                  width: "15px",
                  height: "15px",
                  fill: "#344767",
                },
              })}
              value={dayjs(`2022-04-17 ${time.work_end_time}`)}
              onChange={(v: any) => change({ ...time, work_end_time: v })}
            />
          </MDBox>
        </MDBox>
        <MDBox
          display="flex"
          gap={{ xs: 1, md: 3 }}
          alignItems={{ xs: "flex-start", lg: "center" }}
          flexDirection={{ xs: "column", lg: "row" }}
        >
          <MDTypography color="dark" fontSize={14} fontWeight="regular" lineHeight="140%">
            Обед
          </MDTypography>
          <MDBox display="flex" gap="2px" alignItems="center">
            <TimePicker
              sx={({ palette: { text } }: Theme | any) => ({
                borderColor: text.main,
                borderRadius: 2,
                maxWidth: "140px",
                svg: {
                  width: "15px",
                  height: "15px",
                  fill: "#344767",
                },
              })}
              value={dayjs(`2022-04-17 ${time.break_start_time}`)}
              onChange={(v: any) => change({ ...time, break_start_time: v })}
            />
            -
            <TimePicker
              sx={({ palette: { text } }: Theme | any) => ({
                borderColor: text.main,
                borderRadius: 2,
                maxWidth: "140px",
                svg: {
                  width: "15px",
                  height: "15px",
                  fill: "#344767",
                },
              })}
              value={dayjs(`2022-04-17 ${time.break_end_time}`)}
              onChange={(v: any) => change({ ...time, break_end_time: v })}
            />
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default Hours;
