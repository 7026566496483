import { IFile, NewFile } from "api/files/new";
import { ChangeEvent, useState } from "react";

export const useLoadFile = (fileTypeId: number) => {
  const [file, setFile] = useState<(File | IFile) | null>(null);
  const [files, setFiles] = useState<(File | IFile)[] | null>(null);

  const [filePath, setFilePath] = useState<string>("");

  const [loadError, setLoadError] = useState<string>("");

  const uploadLocalFile = (data: ChangeEvent<HTMLInputElement> | File) => {
    const file = (data as ChangeEvent<HTMLInputElement>)?.target?.files?.[0] || (data as File);

    if (!file) {
      return;
    }

    const blob = new Blob([file]);
    const path = URL.createObjectURL(blob);

    setFile(file);
    setFilePath(path);
    setLoadError("");
  };

  const uploadMultiLocalFile = (data: ChangeEvent<HTMLInputElement> | File[]) => {
    const files = (data as ChangeEvent<HTMLInputElement>)?.target?.files || (data as File[]);

    if (!files?.length) {
      return;
    }

    setFiles(Array.from(files));
    setLoadError("");
  };

  const cancelUploadLocalFile = () => {
    setFile(null);
  };

  const loadFile = async (singleFile?: File) => {
    const response = await NewFile({ file: (singleFile || file) as File, fileTypeId });
    if (!response?.file?.id) {
      setLoadError("Не удалось загрузить file");
      return;
    }

    setLoadError("");
    setFile(response.file);

    return response.file;
  };

  return {
    file,
    files,
    uploadMultiLocalFile,
    loadError,
    uploadLocalFile,
    cancelUploadLocalFile,
    loadFile,
    filePath,
    setLoadError,
  };
};
