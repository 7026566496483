import { api } from "./index";
import { ICategory, ICategoryColumn, IViewCategory } from "./interfaces/ICategory";
import { IPagination } from "./interfaces/IPagination";

//types
export interface ICategoryTableResponse {
  columns: ICategoryColumn[];
  categories: ICategory[];
  pagination: IPagination;
}

export interface ICategoriesFilterResponsibleUser {
  first_name: string;
  id: number;
  last_name: string;
  middle_name: string;
  name: string;
}

export interface ICategoriesFilterResponsibleUsers {
  users: ICategoriesFilterResponsibleUser[];
}

export interface ICategoryHistoryResponse {
  histories: IHistoryDate[];
}

export interface IHistoryDate {
  id: number;
  icon: string;
  color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light";
  title: string;
  description: string;
  datetime: string;
}

export interface ITagsResponse {
  tags: ITag[];
  tagsCount: number;
}

export interface ITag {
  id: number;
  name: string;
  avatar: string;
  created_at: string;
}

interface ICategoriesResponse {
  categories: IViewCategory[];
  categoriesCount: number;
}

interface IGetApplicationCategoriesTableParams {
  id: string;
  queryParams: string;
}

export const categoriesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCategoriesTable: builder.query<ICategoryTableResponse, string>({
      query: (queryParams) => `tables/categories?${queryParams}`,
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    getApplicationCategoriesTable: builder.query<
      ICategoryTableResponse,
      IGetApplicationCategoriesTableParams
    >({
      query: (params) => `tables/applications/${params.id}/categories?${params.queryParams}`,
      transformErrorResponse: (response: any) => {
        return response?.data?.errors?.query?.pop() || response?.data?.message || "";
      },
    }),
    createCategory: builder.mutation({
      query: (body) => ({
        url: `categories`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["category"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    upCategory: builder.mutation<
      any,
      {
        application_id: number;
        category_id: number;
      }
    >({
      query: ({ application_id, category_id }) => ({
        url: `applications/${application_id}/categories/${category_id}/up`,
        method: "POST",
      }),
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    downCategory: builder.mutation<
      any,
      {
        application_id: number;
        category_id: number;
      }
    >({
      query: ({ application_id, category_id }) => ({
        url: `applications/${application_id}/categories/${category_id}/down`,
        method: "POST",
      }),
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    getCategoryById: builder.query({
      query: (id) => `categories/${id}`,
      providesTags: ["category"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    searchCategories: builder.mutation<any, string>({
      query: (id) => ({
        url: `categories`,
        method: "GET",
      }),
      transformErrorResponse: (response: any) => response.data.message,
    }),
    editCategory: builder.mutation({
      query: (body) => ({
        url: `categories/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["category"],
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    getCategoryHistory: builder.query<ICategoryHistoryResponse, string>({
      query: (id) => `categories/${id}/history`,
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    getTags: builder.query<ITagsResponse, void>({
      query: () => `tags`,
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    getCategories: builder.query<ICategoriesResponse, void>({
      query: () => `categories`,
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    getCategoriesFilterResponsibleUsers: builder.query<ICategoriesFilterResponsibleUsers, void>({
      query: () => `categories/creators`,
      transformErrorResponse: (response: any) => response?.data?.message || "",

      keepUnusedDataFor: 0,
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetCategoriesTableQuery,
  useCreateCategoryMutation,
  useGetCategoryByIdQuery,
  useEditCategoryMutation,
  useGetCategoryHistoryQuery,
  useGetTagsQuery,
  useGetCategoriesQuery,
  useGetCategoriesFilterResponsibleUsersQuery,
  useGetApplicationCategoriesTableQuery,
  useSearchCategoriesMutation,
  useUpCategoryMutation,
  useDownCategoryMutation,
} = categoriesApi;
