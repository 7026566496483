export const initialValues: IInitValues = {
  avatar_id: null,
  code: "",
  nomenclature_id: "",
  vat_id: "",
  quantity: "",
  price: "",
  discount: "",
  markup: "",
  warehouse_id: "",
};
