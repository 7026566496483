import axios from "axios";

import { config } from "./constants";
import {
  requestRejectInterceptor,
  requestSuccessInterceptor,
  responseRejectInterceptor,
  responseSuccessInterceptor,
} from "./interceptors";

export const api = axios.create(config);

api.interceptors.request.use(requestSuccessInterceptor, requestRejectInterceptor);
api.interceptors.response.use(responseSuccessInterceptor, responseRejectInterceptor);
