import { Accordion, AccordionDetails, Icon, useTheme } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useState } from "react";
import CalendarIcon from "assets/images/calendar-range.svg";
import { useStyles } from "layouts/pages/widgets/components/TopPanel/styles";
import { useParams } from "react-router-dom";
import {
  IDefaultRequest,
  INewException,
  useGetShopExceptionsQuery,
} from "../../../../../Redux/api/shops.api";
import dayjs from "dayjs";

interface IProps {
  disabled?: boolean;
  onDelete: (body: IDefaultRequest) => void;
  onAdd: (body: INewException) => void;
}

const ExceptionsAccordion = ({ disabled, onDelete, onAdd }: IProps) => {
  const { id } = useParams();
  const IconCustom = () => <img src={CalendarIcon} />;
  const theme = useTheme();
  const styles = useStyles(theme);

  const [expanded, setExpanded] = useState<boolean>(false);
  const { data: exceptions } = useGetShopExceptionsQuery(id, { refetchOnMountOrArgChange: true });
  const [form, setForm] = useState({ start: null, end: null });

  const handleAdd = () => {
    if (form.start !== null && form.end !== null) {
      onAdd({
        shopId: id,
        exception_start_date: dayjs(form.start, "YYYY-MM-DD").format("YYYY-MM-DD"),
        exception_end_date: dayjs(form.end, "YYYY-MM-DD").format("YYYY-MM-DD"),
      });
    }
  };

  const handleDelete = (exceptionId: string | number) => {
    onDelete({ shopId: id, id: exceptionId });
  };

  return (
    <Accordion
      expanded={expanded}
      sx={{ "&:before": { content: "none" }, boxShadow: "none" }}
      style={{ marginBlock: "0", width: "100%", backgroundColor: "transparent" }}
    >
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <MDBox display="flex" alignItems="center">
          <MDBox
            display="flex"
            alignItems="center"
            onClick={() => setExpanded((v) => !v)}
            style={{ cursor: "pointer" }}
          >
            <MDTypography fontSize={14} fontWeight="regular" lineHeight="140%" color="text">
              Исключения
            </MDTypography>
            <MDTypography variant="button" color="text" sx={{ lineHeight: 0 }}>
              <Icon fontSize="small">{expanded ? "expand_less" : "expand_more"}</Icon>
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <AccordionDetails
        style={{
          paddingInline: "0",
          paddingBottom: "0",
          pointerEvents: disabled ? "none" : "initial",
        }}
      >
        <MDTypography
          ml={{ xs: 0, md: 5 }}
          fontSize={{ xs: 12, md: 14 }}
          color="text"
          variant="button"
        >
          Не рабочие дни
        </MDTypography>
        <MDBox
          bgColor={"grey-100"}
          borderRadius="lg"
          display="flex"
          alignItems={{ xs: "flex-start", lg: "center" }}
          flexDirection={{ xs: "column", lg: "row" }}
          justifyContent="space-between"
          maxHeight={{ lg: "58px" }}
          gap={{ xs: 2, lg: 1 }}
          mt={{ xs: 1, xxl: 3 }}
          ml={{ xs: 0, md: 5 }}
          paddingBlock={1}
          pl={{ xs: 1, md: 1, lg: 2 }}
          pr={1}
          py={1.5}
        >
          <MDBox
            display="flex"
            alignItems={{ xs: "flex-start", lg: "center" }}
            flexDirection={{ xs: "column", lg: "row" }}
            gap={{ xs: 1, md: 3 }}
          >
            <MDTypography
              variant="button"
              fontWeight="medium"
              color="text"
              fontSize={{ xs: 12, md: 14 }}
            >
              Добавьте даты
            </MDTypography>
            <DatePicker
              sx={styles.date}
              label="Дата от"
              format={"DD.MM.YYYY"}
              value={form.start}
              slots={{ openPickerIcon: IconCustom }}
              onChange={(v: any) => setForm({ ...form, start: v })}
            />
            <DatePicker
              sx={styles.date}
              label="Дата до"
              format={"DD.MM.YYYY"}
              value={form.end}
              slots={{ openPickerIcon: IconCustom }}
              onChange={(v: any) => setForm({ ...form, end: v })}
            />
          </MDBox>
          <MDButton onClick={handleAdd} variant="gradient" color="primary" size="small">
            Отправить
          </MDButton>
        </MDBox>
        {exceptions?.exceptions &&
          exceptions?.exceptions.map((exception) => (
            <MDBox
              key={exception.id}
              bgColor={"grey-100"}
              borderRadius="lg"
              display="flex"
              alignItems={{ xs: "flex-start", lg: "center" }}
              flexDirection={{ xs: "column", lg: "row" }}
              justifyContent="space-between"
              maxHeight={{ lg: "58px" }}
              gap={{ xs: 2, lg: 1 }}
              mt={{ xs: 1, xxl: 3 }}
              ml={{ xs: 0, md: 5 }}
              paddingBlock={1}
              pl={{ xs: 1, md: 1, lg: 2 }}
              pr={1}
              py={1.5}
            >
              <MDBox
                display="flex"
                alignItems={{ xs: "flex-start", lg: "center" }}
                flexDirection={{ xs: "column", lg: "row" }}
                gap={3}
              >
                <MDTypography
                  variant="button"
                  fontWeight="medium"
                  color="text"
                  fontSize={{ xs: 12, md: 14 }}
                >
                  Добавьте даты
                </MDTypography>
                <DatePicker
                  sx={styles.date}
                  label="Дата от"
                  format={"DD.MM.YYYY"}
                  value={dayjs(exception.exception_start_date)}
                  slots={{ openPickerIcon: IconCustom }}
                />
                <DatePicker
                  sx={styles.date}
                  label="Дата до"
                  format={"DD.MM.YYYY"}
                  value={dayjs(exception.exception_end_date)}
                  slots={{ openPickerIcon: IconCustom }}
                />
              </MDBox>
              <MDButton
                onClick={() => handleDelete(exception.id)}
                variant="gradient"
                color="dark"
                size="small"
              >
                DELETE
              </MDButton>
            </MDBox>
          ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default ExceptionsAccordion;
