import { api } from ".";

export interface IBrandResponse {
  brands: IBrand[];
  brandsCount: number;
}

export interface IBrand {
  id: number;
  name: string;
  avatar: string;
  created_at: string;
}

export const brandsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBrands: builder.query<IBrandResponse, void>({
      query: () => `brands`,
      transformErrorResponse: (response: any) => response.data.message,
    }),
    getBrandById: builder.query({
      query: (idBrand) => `brands/${idBrand}`,
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    createBrand: builder.mutation({
      query: (initialBrand) => ({
        url: `brands`,
        method: "POST",
        body: initialBrand,
      }),
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    editBrand: builder.mutation({
      query: (initialBrand) => ({
        url: `brands/${initialBrand.id}`,
        method: "PUT",
        body: initialBrand,
      }),
      transformErrorResponse: (response: any) => response?.data?.message || "",
    }),
    deleteBrand: builder.mutation({
      query: (idBrand) => ({
        url: `brands/${idBrand}`,
        method: "DELETE",
        transformErrorResponse: (response: any) => response?.data?.message || "",
      }),
    }),
  }),
});

export const {
  useGetBrandsQuery,
  useGetBrandByIdQuery,
  useCreateBrandMutation,
  useEditBrandMutation,
  useDeleteBrandMutation,
} = brandsApi;
