import { useGetCategoriesQuery } from "../../../Redux/api/categories.api";
import Select from "layouts/pages/widgets/components/Select";
import { IItem } from "layouts/pages/widgets/components/Select/interfaces";
import { useEffect, useMemo, useState } from "react";

interface ICategoryParentFieldProps {
  disabled?: boolean;
  error?: string;
  initialValueId?: number | string;
  onSelect?: (value: IItem) => void;
}

const formatToSelectItem = (parents?: any[]): IItem[] => {
  if (!parents) return [];
  return parents.map((parent) => ({
    id: parent.id,
    label: parent.name,
    value: parent.id,
  }));
};

const CategoryParentField = ({
  disabled,
  error,
  initialValueId,
  onSelect,
}: ICategoryParentFieldProps) => {
  const { data } = useGetCategoriesQuery(null, { refetchOnMountOrArgChange: true });

  const parents = data?.categories.map((category) => ({
    id: category.id,
    name: category.name,
  }));

  const [selectedOption, setSelectedOption] = useState<IItem | null>(null);
  const options = useMemo(() => formatToSelectItem(parents), [data]);

  useEffect(() => {
    const typeOption = options.find((item) => item.id == initialValueId);
    if (typeOption) {
      setSelectedOption(typeOption);
    }
  }, [options]);

  const onSelectItem = (value: IItem) => {
    setSelectedOption(value);
    onSelect(value);
  };

  return (
    <Select
      label="Родительская категория (необязательно)"
      placeholder="Выберите из списка"
      noPointerEvents={disabled}
      error={error}
      popupIcon={disabled ? null : undefined}
      select={{
        value: selectedOption,
        items: options,
        onSelect: onSelectItem,
      }}
    />
  );
};

export default CategoryParentField;
