import { Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import MDContainer from "components/MDContainer";
import { PropsWithChildren } from "react";

interface IEditContainerProps extends PropsWithChildren {
  showButton: boolean;
  buttonColor: "secondary" | "error";
  buttonAction: () => void;
}

/**
 * @param showButton - показывать ли кнопку редактирования
 * @param buttonColor - цвет кнопки
 * @param buttonAction - действие при клике
 * @param children - форма
 */

const EditContainer = ({
  showButton,
  buttonColor,
  buttonAction,
  children,
}: IEditContainerProps) => {
  return (
    <MDContainer variant="column" gap={3} style={{ position: "relative" }}>
      {showButton && (
        <IconButton
          aria-label="редактировать"
          style={{ position: "absolute", top: 22, right: 10, zIndex: 300 }}
          onClick={buttonAction}
          color={buttonColor}
        >
          <Edit style={{ width: pxToRem(16), height: pxToRem(16) }} />
        </IconButton>
      )}
      {children}
    </MDContainer>
  );
};

export default EditContainer;
