import { Grid } from "@mui/material";
import { CSSProperties, PropsWithChildren, ReactElement } from "react";

interface IContentWrapperProps extends PropsWithChildren {
  aside?: ReactElement;
  contentId?: string;
  childMb?: number;
  asideStyles?: CSSProperties;
}

const ContentWrapper = ({
  aside,
  children,
  contentId,
  childMb = 6,
  asideStyles,
}: IContentWrapperProps) => {
  return (
    <Grid container spacing={3}>
      <Grid item sm={12} md={3} style={{ width: "100%", ...asideStyles }}>
        {aside}
      </Grid>
      <Grid
        item
        container
        sm={12}
        md={9}
        mb={childMb}
        gap={3}
        id={contentId || ""}
        style={{ width: "100%" }}
      >
        {children}
      </Grid>
    </Grid>
  );
};

export default ContentWrapper;
