import { useEffect, useState } from "react";
import { IFilter } from "layouts/pages/widgets/components/TopPanel/interfaces";
import { ICity, useGetCitiesQuery } from "../../../../Redux/api/search-city.api";
import { IVat, useGetVatsQuery } from "../../../../Redux/api/vats.api";
import { IBrand, useGetBrandsQuery } from "../../../../Redux/api/brands.api";

const addFilers = (filters: IFilter[], brands: IBrand[], cities: ICity[], vats: IVat[]) => {
  const brandsFilters: IFilter = {
    id: 4,
    title: "Торговая марка",
    tag: "brand",
    url: "&brand_id[]=",
    isInput: true,
    list: brands?.map((brand: IBrand) => ({
      id: brand.id,
      isInput: false,
      tag: "brand",
      name: brand.name,
      url: `${brand.id}`,
    })),
    exclusive: true,
  };
  const citiesFilters: IFilter = {
    id: 3,
    title: "Город",
    tag: "city",
    url: "&city_id[]=",
    isInput: true,
    list: cities?.map((city: ICity) => ({
      id: city.id,
      isInput: false,
      tag: "city",
      name: city.name,
      url: `${city.id}`,
    })),
    exclusive: true,
  };
  const vatsFilters: IFilter = {
    id: 5,
    title: "НДС",
    tag: "vat",
    url: "&vat_id[]=",
    isInput: true,
    list: vats?.map((vat: IVat) => ({
      id: vat.id,
      isInput: false,
      tag: "vat",
      name: vat.name,
      url: `${vat.id}`,
    })),
    exclusive: true,
  };

  return [...filters, brandsFilters, vatsFilters, citiesFilters];
};

export const useInventoriesFilters = () => {
  const [filters, setFilters] = useState<IFilter[]>([]);
  const { data: brands, isFetching: isBrandsFetching } = useGetBrandsQuery();
  const { data: cities, isFetching: isCitiesFetching } = useGetCitiesQuery();
  const { data: vats, isFetching: isVatsFetching } = useGetVatsQuery();

  useEffect(() => {
    if (brands?.brands && cities?.cities && vats?.vats) {
      setFilters((old) => {
        return addFilers(old, brands.brands, cities.cities, vats.vats);
      });
    }
  }, [brands, cities, vats]);

  if (isBrandsFetching || isCitiesFetching || isVatsFetching) return null;

  return filters;
};
