import { IFile } from "api/files/new";
import { api } from ".";
import { IPagination } from "./interfaces/IPagination";
import { IAppHistoryResponse } from "./applications.api";
import { IHistoryDate } from "./branches.api";
import { IColumn } from "interfaces/IColumn";
import { IShopsResponseItem } from "./shops.api";

interface IIconAdnValue {
  icon: string;
  value: string;
}

export interface IOrdersColumn {
  id: number;
  table_id: number;
  order: number;
  key: string;
  has_icon: number;
  text: string;
}

export interface IOrderTable {
  id: number;
  created_at: string;
  branch: string;
  counterparty: IIconAdnValue;
  object: string;
  promo: any;
  source: IIconAdnValue;
  price: string;
  status: IIconAdnValue;
}

export interface IOrdersTableResponse {
  columns: IOrdersColumn[];
  orders: IOrderTable[];
  pagination: IPagination;
}

export interface IOrderResponse {
  order: IOrder;
}

export interface IOrder {
  id: number;
  avatar: IFile;
  application: {
    id: number;
    name: string;
    avatar: string;
    created_at: string;
  };
  status: Branch;
  invoice_number: null;
  invoice_start_date: null;
  invoice_end_date: null;
  counterparty: Counterparty;
  delivery_type: DeliveryType;
  address: string;
  branch: Branch;
  shop: IShopsResponseItem | null;
  object: Branch | null;
  source: Branch;
  sales_channel: Branch | null;
  manager: Manager | null;
  promotion: Branch | null;
  contract: IFile | null;
  contract_number: string | null;
  contract_start_date: Date | null;
  contract_end_date: Date | null;
}

interface Branch {
  id: number;
  name: string;
  avatar: string;
  created_at: Date;
}

interface Counterparty {
  id: number;
  name: string;
}

interface DeliveryType {
  id: number;
  name: string;
  price: string;
  is_active: number;
  created_at: Date;
}

interface Manager {
  id: number;
  first_name: string;
  last_name: string;
  middle_name: string;
  name: string;
}

export interface IAddOrder {
  id?: string | number | null;
  avatar_id?: number | null;
  counterparty_id: number | null;
  delivery_type_id: number | null;
  address: string | null;
  shop_id?: number | null;
  branch_id: number | null;
  object_id?: number | null;
  application_id: number | null;
  sales_channel_id?: number | null;
  manager_id: number | null;
  promotion_id?: number | null;
  contract_number?: string | null;
  contract_id?: number | null;
  contract_start_date?: Date | null;
  contract_end_date?: Date | null;
  invoice_number?: string | null;
  invoice_start_date?: Date | null;
  invoice_end_date?: Date | null;
}

export interface IOrderHistoryResponse {
  orders: {
    current_page: number;
    data: IHistoryDate[];
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    links: Link[];
    next_page_url: null;
    path: string;
    per_page: number;
    prev_page_url: null;
    to: number;
    total: number;
  };
}

interface Link {
  url: null | string;
  label: string;
  active: boolean;
}

export interface IDeleteOrderNomenclature {
  nomenclatureId: string | number;
  orderId: string | number;
}

export interface IDeleteOrderNomenclatureResponse {
  message: string;
}

export interface IOrderNomenclaturesTable {
  id: number;
  code: string;
  name: Name;
  warehouse: Name;
  city: string;
  brand: Name;
  vat: string;
  unit: string;
  inventory_id: number;
  quantity: number;
  price: string;
  amount: string;
}

export interface IAddNomenclatureToOrder {
  warehouse_nomenclature_id: number;
  quantity: number;
  storage_unit_id: number;
  price: number;
  vat_id: number;
  orderId?: number | string;
  nomenclatureId?: number | string;
}

interface Name {
  icon: string;
  value: string;
}

export interface IOrderNomenclaturesTableResponse {
  columns: IColumn[];
  orderNomenclatures: IOrderNomenclaturesTable[];
  pagination: IPagination;
}

export interface IOrderFilesResponse {
  columns: IColumn[];
  files: IOrderFiles[];
  pagination: IPagination;
}

export interface IOrderFiles {
  id: number;
  creator: Creator;
  name: string;
  path: string;
  created_at: string;
}

export interface Creator {
  icon: string;
  value: string;
}

export const ordersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrdersTable: builder.query<IOrdersTableResponse, string>({
      query: (queryParams) => `tables/orders?${queryParams}`,
      providesTags: ["orders"],
    }),
    // getOrders: builder.query({
    //   query: (queryParams) => `orders?${queryParams}`,
    // }),

    getOrderById: builder.query<IOrderResponse, number | string>({
      query: (id) => `orders/${id}`,
      keepUnusedDataFor: 0,
      providesTags: ["orders"],
    }),

    createOrder: builder.mutation<any, IAddOrder>({
      query: (body) => {
        return {
          url: `orders`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["orders"],
    }),
    editOrder: builder.mutation<any, Partial<IAddOrder>>({
      query: ({ id, ...body }) => ({
        url: `orders/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["orders"],
    }),

    getOrderNomenclaturesTable: builder.query<IOrderNomenclaturesTableResponse, string | number>({
      query: (id) => `tables/orders/${id}/nomenclatures`,
      keepUnusedDataFor: 0,
      providesTags: ["order_nomenclatures"],
    }),
    addNomenclatureToOrder: builder.mutation<any, IAddNomenclatureToOrder>({
      query: ({ orderId, ...body }) => ({
        url: `orders/${orderId}/nomenclatures`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["order_nomenclatures"],
    }),
    editNomenclatureInOrder: builder.mutation<any, IAddNomenclatureToOrder>({
      query: ({ orderId, nomenclatureId, ...body }) => ({
        url: `orders/${orderId}/nomenclatures/${nomenclatureId}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["order_nomenclatures"],
    }),
    deleteNomenclatureFromOrder: builder.mutation<
      IDeleteOrderNomenclatureResponse,
      IDeleteOrderNomenclature
    >({
      query: ({ orderId, nomenclatureId }) => ({
        url: `orders/${orderId}/nomenclatures/${nomenclatureId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["order_nomenclatures"],
    }),

    getOrderFilesTable: builder.query<IOrderFilesResponse, string | number>({
      query: (id) => `tables/orders/${id}/files`,
      keepUnusedDataFor: 0,
      providesTags: ["orders"],
    }),

    getOrderHistory: builder.query<IOrderHistoryResponse, string | number>({
      query: (id) => `orders/${id}/history`,
      providesTags: ["orders"],
    }),
  }),
});

export const {
  useGetOrdersTableQuery,
  useGetOrderHistoryQuery,
  useGetOrderByIdQuery,
  useCreateOrderMutation,
  useEditOrderMutation,
  useGetOrderNomenclaturesTableQuery,
  useAddNomenclatureToOrderMutation,
  useEditNomenclatureInOrderMutation,
  useDeleteNomenclatureFromOrderMutation,
  useGetOrderFilesTableQuery,
} = ordersApi;
