import Select from "layouts/pages/widgets/components/Select";
import { IItem } from "layouts/pages/widgets/components/Select/interfaces";
import { useEffect, useState } from "react";
import { IDeliveryType, useGetDeliveryTypesQuery } from "../../../Redux/api/counterperties.api";

interface ICounterpartyTypeFieldProps {
  disabled?: boolean;
  error?: string;
  initialTypeId?: number | null;
  onSelect?: (value: IItem) => void;
}

const formatTypeToSelectItem = (types?: IDeliveryType[]): IItem[] => {
  if (!types) return [];
  return types.map((type) => ({
    id: type.id,
    label: type.name,
    value: type.id,
    // icon: type.avatar  no icons yet
  }));
};

const DeliveryTypeField = ({
  disabled,
  error,
  initialTypeId,
  onSelect,
}: ICounterpartyTypeFieldProps) => {
  const { data: counterpartyTypes } = useGetDeliveryTypesQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const [selectedTypeOption, setSelectedTypeOption] = useState<IItem | null>(null);

  const typeOptions = formatTypeToSelectItem(counterpartyTypes?.deliveryTypes);

  useEffect(() => {
    const typeOption = typeOptions.find((item) => item.id == initialTypeId);
    if (typeOption) {
      setSelectedTypeOption(typeOption);
    }
  }, [typeOptions, initialTypeId, disabled]);

  const onSelectItem = (value: IItem) => {
    setSelectedTypeOption(value);
    onSelect(value);
  };

  return (
    <Select
      label="Способ получения"
      placeholder="Выберите из списка"
      noPointerEvents={disabled}
      error={error}
      popupIcon={disabled ? null : undefined}
      select={{
        value: selectedTypeOption,
        items: typeOptions,
        onSelect: onSelectItem,
      }}
    />
  );
};

export default DeliveryTypeField;
