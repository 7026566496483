import MDAlert from "components/MDAlert";
import useFilterParams from "hooks/useFilterParams";
import { Table } from "layouts/pages/widgets/components/Table";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useDownCategoryMutation,
  useGetApplicationCategoriesTableQuery,
  useUpCategoryMutation,
} from "../../../../Redux/api/categories.api";
import { CategoryModal } from "./category-modal";

export const Menu = (props: any) => {
  const { categoryModalOpened } = props;

  const [isCategoryModalOpened, setIsCategoryModalOpened] = useState<boolean>(false);

  const categoryModalHandler = () => {
    setIsCategoryModalOpened((prev) => !prev);
  };

  const { id } = useParams();

  const { queryParams, setQuery, setPage, setPerPage, query, page, perPage, startDate, endDate } =
    useFilterParams();

  const { data, error, refetch, isLoading } = useGetApplicationCategoriesTableQuery(
    {
      id,
      queryParams: queryParams(),
    },
    {
      skip: !id,
    }
  );

  const [upCategoryRequest] = useUpCategoryMutation();
  const [downCategoryRequest] = useDownCategoryMutation();

  const upCategoryRequestHandler = async (categoryId: number) => {
    const response = await upCategoryRequest({
      application_id: +id,
      category_id: categoryId,
    });

    if (!response.data?.message) {
      return;
    }

    refetch();
  };

  const downCategoryRequestHandler = async (categoryId: number) => {
    const resposne = await downCategoryRequest({
      application_id: +id,
      category_id: categoryId,
    });

    if (!resposne.data?.message) {
      return;
    }

    refetch();
  };

  useEffect(() => {
    refetch();
  }, [page, perPage, query, startDate, endDate]);

  if (!data) {
    return;
  }

  return (
    <Fragment>
      {error && (
        <MDAlert color="error" dismissible>
          {error as string}
        </MDAlert>
      )}

      <Table
        title="Настройка меню"
        accessorKey="categories"
        data={data}
        filters={{
          setQuery,
          setPage,
          setPerPage,
          query,
          page,
          perPage,
        }}
        optionsDots={[
          {
            id: 1,
            text: "Добавить категорию",
            action: (id: string | number) => {
              categoryModalHandler();
            },
          },
          {
            id: 2,
            text: "Повысить приоритет",
            action: (categoryId: string | number) => {
              upCategoryRequestHandler(+categoryId);
            },
          },
          {
            id: 3,
            text: "Понизить приоритет",
            action: (categoryId: string | number) => {
              downCategoryRequestHandler(+categoryId);
            },
          },
        ]}
        isLoading={isLoading}
      />

      <CategoryModal
        isOpened={categoryModalOpened || isCategoryModalOpened}
        closeHandler={categoryModalHandler}
        applicationId={+id}
        refetch={refetch}
      />
    </Fragment>
  );
};
