import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

type buttonType = 1 | 2;

interface IStatusBlockProps {
  title: string;
  description: string;
  activeButton: buttonType;
  label1: string;
  label2: string;
  action1: () => void;
  action2: () => void;
  id?: string;
}

function StatusBlock({
  title,
  description,
  label1,
  label2,
  action1,
  action2,
  activeButton,
  id,
}: IStatusBlockProps) {
  return (
    <Card id={id} sx={{ width: "100%" }}>
      <MDBox
        p={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <MDBox lineHeight={1} pb={{ xs: 2, sm: 0 }}>
          <MDBox mb={1}>
            <MDTypography variant="h5">{title}</MDTypography>
          </MDBox>
          <MDTypography variant="button" color="text">
            {description}
          </MDTypography>
        </MDBox>
        <MDBox
          display="flex"
          flexWrap={{ xs: "wrap", sm: "nowrap" }}
          alignItems={{ xs: "center" }}
          gap={1}
        >
          <MDButton
            variant={activeButton === 1 ? "gradient" : "outlined"}
            color={activeButton === 1 ? "error" : "secondary"}
            sx={{ whiteSpace: "nowrap" }}
            onClick={action1}
          >
            {label1}
          </MDButton>
          <MDButton
            variant={activeButton === 2 ? "gradient" : "outlined"}
            color={activeButton === 2 ? "error" : "secondary"}
            sx={{ whiteSpace: "nowrap" }}
            onClick={action2}
          >
            {label2}
          </MDButton>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default StatusBlock;
