import { useEffect, useContext, ChangeEvent, useState } from "react";
import { useFormikContext } from "formik";
import MDContainer from "components/MDContainer";
import Block from "layouts/pages/widgets/components/Block";
import Input from "layouts/pages/widgets/components/Input";
import { InventoryContext } from "../../context/inventory-context";
import NomenclatureField from "./nomenclature-field";
import VatField from "./vat-field";
import { IInventoryNomenclature } from "../../../../../Redux/api/inventories.api";
import WarehouseField from "./warehouse-field";
import { useParams } from "react-router-dom";

interface IEditFieldsProps {
  disabled?: boolean;
}

export interface IFormikValues {
  code: string;
  nomenclature_id: number;
  vat_id: number;
  quantity: number;
  price: number;
  discount: number;
  markup: number;
  avatar_id: number;
  warehouse_id?: number;
}

const EditFields = ({ disabled = false }: IEditFieldsProps) => {
  const { warehouseId } = useParams<string>();
  const { setName } = useContext(InventoryContext);
  const [activeNomenclature, setActiveNomenclature] = useState<IInventoryNomenclature | undefined>(
    undefined
  );
  const [activeVat, setActiveVat] = useState<any>();
  const { values, errors, setFieldValue } = useFormikContext<IFormikValues>();

  const handlerChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(e.target.name, e.target.value);
  };

  useEffect(() => {
    if (activeNomenclature) {
      setName(activeNomenclature.name);
      setFieldValue("nomenclature_id", activeNomenclature?.id);
      setFieldValue("avatar_id", activeNomenclature?.avatar?.id || 0);
    }
  }, [activeNomenclature]);

  useEffect(() => {
    if (activeVat) {
      setFieldValue("vat_id", activeVat.id);
    }
  }, [activeVat]);

  useEffect(() => {
    if (warehouseId) {
      setFieldValue("warehouse_id", warehouseId);
    }
  }, [warehouseId]);

  return (
    <Block title="Основная информация" wrapperStyles={{ width: "100%" }}>
      <MDContainer gap={3}>
        {!warehouseId && (
          <WarehouseField
            disabled={disabled}
            error={errors?.warehouse_id?.length && errors.warehouse_id}
            initialValueId={values.warehouse_id}
            onSelect={(value) => setFieldValue("warehouse_id", value?.id)}
          />
        )}
      </MDContainer>
      <MDContainer gap={3}>
        <NomenclatureField
          disabled={disabled}
          error={errors.nomenclature_id}
          initialValueId={values.nomenclature_id}
          onSelect={setActiveNomenclature}
        />
        <VatField
          disabled={disabled}
          error={errors.vat_id}
          initialValue={values.vat_id}
          onSelect={setActiveVat}
        />
      </MDContainer>
      <MDContainer gap={3}>
        <Input
          label="Количество"
          input={{
            noPointerEvents: disabled,
            placeholder: "Введите количество товара",
            value: String(values.quantity),
            name: "quantity",
            onChange: handlerChange,
            error: errors.quantity,
          }}
        />
        <Input
          label="Стоимость"
          input={{
            noPointerEvents: disabled,
            placeholder: "Введите стоимость товара",
            value: String(values.price),
            name: "price",
            onChange: handlerChange,
            error: errors.price,
          }}
        />
      </MDContainer>
      <MDContainer gap={3}>
        <Input
          label="Скидка"
          input={{
            noPointerEvents: disabled,
            placeholder: "Введите скидку товара",
            value: String(values.discount),
            name: "discount",
            onChange: handlerChange,
            error: errors.discount,
          }}
        />
        <Input
          label="Наценка"
          input={{
            noPointerEvents: disabled,
            placeholder: "Введите наценку товара",
            value: String(values.markup),
            name: "markup",
            onChange: handlerChange,
            error: errors.markup,
          }}
        />
      </MDContainer>
    </Block>
  );
};

export default EditFields;
