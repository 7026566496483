import { useContext, useEffect, useMemo, useState } from "react";
import { useFormikContext } from "formik";
import MDContainer from "components/MDContainer";
import Block from "layouts/pages/widgets/components/Block";
import Input from "layouts/pages/widgets/components/Input";
import { Autocomplete } from "@mui/material";
import { ICompany, useSearchCompanyByValueMutation } from "../../../../../Redux/api/search-company";
import DebouncedInput from "layouts/pages/widgets/components/DebouncedInput";
import { useGetCounterpartyTypesQuery } from "../../../../../Redux/api/counterparty-types.api";
import Select from "layouts/pages/widgets/components/Select";
import { IItem } from "layouts/pages/widgets/components/Select/interfaces";
import { formatTypeToSelectItem } from "../../utils";
import { CounterpartyNameContext } from "../../context/counterparty-name-context";

export interface IFormikValues {
  is_company: true;
  avatar_id?: number | string;
  counterparty_type_id: number;
  name: string;
  inn: number | string;
  head: string;
  kpp: string;
  registered_at: string;
  phone_1: string;
  phone_2: string;
  email: string;
  legal_address: string;
}

interface IMainCompanyFieldsProps {
  disabled?: boolean;
}

const MainCompanyFields = ({ disabled = false }: IMainCompanyFieldsProps) => {
  const [searchCompany, resultCompanies] = useSearchCompanyByValueMutation();
  const [selectedInnOption, setSelectedInnOption] = useState<ICompany | null>(null);
  const [selectedTypeOption, setSelectedTypeOption] = useState<IItem | null>(null);

  const { data: counterpartyTypes } = useGetCounterpartyTypesQuery();
  const [inn, setInn] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [head, setHead] = useState<string>("");
  const [kpp, setKpp] = useState<string>("");

  const { setName } = useContext(CounterpartyNameContext);

  const innOptions = useMemo(
    () => resultCompanies?.data?.companies || [],
    [resultCompanies, selectedInnOption]
  );
  const typeOptions = useMemo(
    () => formatTypeToSelectItem(counterpartyTypes?.counterpartyTypes),
    [counterpartyTypes]
  );

  const { values, errors, setFieldValue, validateForm, initialValues } =
    useFormikContext<IFormikValues>();

  const clearValues = () => {
    setSelectedInnOption(null);
    setFieldValue("inn", "");
    setFieldValue("head", "");
    setFieldValue("kpp", "");
    setFieldValue("legal_address", "");
    setFieldValue("name", "");
    setFieldValue("orgn", "");
    setFieldValue("registered_at", "");
  };

  const onInnSelect = (e: any, newValue: ICompany) => {
    if (newValue) {
      setSelectedInnOption(newValue);
      setFieldValue("inn", newValue.inn);
      setFieldValue("head", newValue.head);
      setFieldValue("kpp", newValue.kpp);
      setFieldValue("legal_address", newValue.legal_address);
      setFieldValue("name", newValue.name);
      setFieldValue("orgn", newValue.orgn);
      setFieldValue("registered_at", newValue.registration_date);
      setName(newValue.name);
    } else {
      clearValues();
    }
  };

  useEffect(() => {
    if (inn?.length > 2) {
      searchCompany({ field: "inn", query: inn });
    }
  }, [inn]);

  useEffect(() => {
    if (initialValues.inn) {
      setInn(initialValues.inn as string);
      setSelectedInnOption({
        name: initialValues.name,
        inn: initialValues.inn as string,
        legal_address: initialValues.legal_address,
        orgn: "",
        kpp: initialValues.kpp,
        head: initialValues.head,
        registration_date: initialValues.registered_at,
      });
    }
    setEmail(initialValues.email || "");
  }, []);

  useEffect(() => {
    const typeOption = typeOptions.find((item) => item.id === initialValues.counterparty_type_id);
    if (typeOption) {
      setSelectedTypeOption(typeOption);
    }
  }, [typeOptions]);

  useEffect(() => {
    if (disabled) setName("");
  }, [disabled]);

  return (
    <Block title="Основная информация" wrapperStyles={{ width: "100%" }}>
      <MDContainer gap={3}>
        <Autocomplete
          style={{ width: "100%", pointerEvents: disabled ? "none" : "auto" }}
          getOptionLabel={(option: ICompany) =>
            option && selectedInnOption && option?.inn === selectedInnOption?.inn
              ? option?.inn
              : option?.inn + " - " + option?.name
          }
          onOpen={clearValues}
          isOptionEqualToValue={(op, val) => op?.inn === val?.inn}
          options={innOptions}
          popupIcon={disabled ? null : undefined}
          id="inn"
          noOptionsText="Нет результатов"
          onBlur={() => validateForm(values)}
          onChange={onInnSelect}
          value={selectedInnOption}
          renderInput={(params) => (
            <DebouncedInput
              label="ИНН"
              debounceTimeout={500}
              handleDebounce={setInn}
              isLoading={resultCompanies.isLoading}
              input={{
                ...params,
                noPointerEvents: disabled,
                placeholder: "Поиск по ИНН",
                value: inn,
                error: errors.inn,
              }}
            />
          )}
        />
      </MDContainer>
      <MDContainer gap={3}>
        <Input
          label="Название"
          input={{
            noPointerEvents: disabled,
            placeholder: "Заполняется автоматически",
            value: values.name,
            name: "name",
            error: errors.name,
          }}
        />
        <Input
          label="КПП"
          input={{
            noPointerEvents: disabled,
            placeholder: "Заполняется автоматически",
            value: values.kpp || kpp,
            onChange: (e: any) => setKpp(e.target.value),
            defaultValue: initialValues.kpp,
            name: "kpp",
            blur: () => setFieldValue("kpp", kpp),
            error: errors.kpp,
          }}
        />
      </MDContainer>

      <MDContainer gap={3}>
        <Input
          label="Телефон"
          mask="+7 (999) 999 99 99"
          input={{
            noPointerEvents: disabled,
            name: "phone_1",
            placeholder: "+0 (000) 000 00 00",
            defaultValue: initialValues.phone_1,
            blur: (value) => setFieldValue("phone_1", value),
            error: errors.phone_1,
          }}
        />
        <Input
          label="Телефон (необязательно)"
          mask="+7 (999) 999 99 99"
          input={{
            noPointerEvents: disabled,
            name: "phone_2",
            placeholder: "+0 (000) 000 00 00",
            defaultValue: initialValues.phone_2,
            blur: (value) => setFieldValue("phone_2", value),
            error: errors.phone_2,
          }}
        />
      </MDContainer>

      <MDContainer gap={3}>
        <Input
          label="Директор"
          input={{
            noPointerEvents: disabled,
            placeholder: "Заполняется автоматически",
            value: values.head || head,
            onChange: (e: any) => setHead(e.target.value),
            defaultValue: initialValues.head,
            name: "head",
            blur: () => setFieldValue("head", head),
            error: errors.head,
          }}
        />
        <Input
          label="Дата регистрации "
          input={{
            noPointerEvents: disabled,
            placeholder: "Заполняется автоматически",
            value: values.registered_at,
            name: "registered_at",
            error: errors.registered_at,
          }}
        />
      </MDContainer>

      <MDContainer gap={3}>
        <Select
          label="Тип"
          placeholder="Выберите из списка"
          noPointerEvents={disabled}
          error={errors.counterparty_type_id}
          popupIcon={disabled ? null : undefined}
          select={{
            value: selectedTypeOption,
            items: typeOptions,
            onSelect: (value) => {
              setSelectedTypeOption(value);
              setFieldValue("counterparty_type_id", value?.id);
            },
          }}
        />

        <Input
          label="Почта компании"
          input={{
            noPointerEvents: disabled,
            name: "email",
            placeholder: "Введите почту",
            value: email,
            onChange: (e: any) => setEmail(e.target.value),
            blur: () => setFieldValue("email", email),
            error: errors.email,
          }}
        />
      </MDContainer>

      <MDContainer gap={3}>
        <Input
          label="Адрес компании"
          input={{
            noPointerEvents: disabled,
            placeholder: "Заполняется автоматически",
            value: values.legal_address,
            name: "legal_address",
            error: errors.legal_address,
          }}
        />
      </MDContainer>
    </Block>
  );
};

export default MainCompanyFields;
