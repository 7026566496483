import { useEffect, useMemo, useState, useContext, ChangeEvent } from "react";
import { useFormikContext } from "formik";
import ContentWrapper from "layouts/components/content-wrapper";
import MDContainer from "components/MDContainer";
import Block from "layouts/pages/widgets/components/Block";
import Input from "layouts/pages/widgets/components/Input";
import { Autocomplete } from "@mui/material";
import { ICompany, useSearchCompanyByValueMutation } from "../../../../../Redux/api/search-company";
import DebouncedInput from "layouts/pages/widgets/components/DebouncedInput";
import { BranchNameContext } from "../../context/branch-name-context";
import Card from "@mui/material/Card";
import ResponsibleAutocompleteField from "layouts/components/fields/responsible-autocomplete-field";
import { IResponsibleUser } from "Redux/api/interfaces";

interface IEditFieldsProps {
  disabled?: boolean;
  editCode: (code: string) => void;
}

export interface IFormikValues {
  title: string;
  inn: string;
  name: string;
  kpp?: string;
  phone_1: string;
  phone_2: string;
  email: string;
  address: string;
  legal_address: string;
  code?: string;
  head: string;
  responsible_user: IResponsibleUser | null;
}

const EditFields = ({ disabled = false, editCode }: IEditFieldsProps) => {
  const [searchCompany, resultCompanies] = useSearchCompanyByValueMutation();

  const [selectedInnOption, setSelectedInnOption] = useState<ICompany | null>(null);
  const [inn, setInn] = useState<string>("");

  const { setName } = useContext(BranchNameContext);

  const innOptions = useMemo(
    () => resultCompanies?.data?.companies || [],
    [resultCompanies, selectedInnOption]
  );

  const { values, errors, setFieldValue, validateForm, initialValues } =
    useFormikContext<IFormikValues>();

  const clearValues = () => {
    setSelectedInnOption(null);
    setFieldValue("inn", "");
    setFieldValue("name", "");
    setFieldValue("kpp", "");
    setFieldValue("address", "");
    setFieldValue("legal_address", "");
    setFieldValue("head", "");
  };

  const onInnSelect = (_: any, newValue: ICompany) => {
    if (newValue) {
      setSelectedInnOption(newValue);
      setFieldValue("inn", newValue.inn);
      setFieldValue("name", newValue.name);
      setFieldValue("kpp", newValue.kpp);
      setFieldValue("address", newValue.legal_address);
      setFieldValue("legal_address", newValue.legal_address);
      setFieldValue("head", newValue.head || newValue.name);
    } else {
      clearValues();
    }
  };

  const handlerChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(e.target.name, e.target.value);
  };

  useEffect(() => {
    if (inn.length > 2) {
      searchCompany({ field: "inn", query: inn });
    }
  }, [inn]);

  useEffect(() => {
    setInn(initialValues.inn as string);
    setSelectedInnOption({
      inn: initialValues.inn as string,
      name: initialValues.name,
      kpp: initialValues.kpp,
      legal_address: initialValues.legal_address,
      head: initialValues.head,
    });
  }, []);

  useEffect(() => {
    if (values.title.length > 0) {
      setName(values.title);
    }
  }, [values.title]);

  return (
    <ContentWrapper
      aside={
        <Card sx={{ p: 3 }}>
          <Input
            label="Код (необязательно)"
            input={{
              noPointerEvents: false,
              placeholder: "Введите код",
              value: values.code,
              name: "code",
              onBlur: (e: React.ChangeEvent<HTMLInputElement>) => {
                editCode(e.target.value as string);
              },
              onChange: handlerChange,
              error: errors.code,
            }}
          />
        </Card>
      }
    >
      <Block title="Основная информация" wrapperStyles={{ width: "100%" }}>
        <MDContainer gap={3}>
          <Input
            label="Название направления бизнеса"
            input={{
              noPointerEvents: disabled,
              placeholder: "Введите название бизнеса",
              value: values.title,
              name: "title",
              onChange: handlerChange,
              error: errors.title,
            }}
          />
        </MDContainer>

        <MDContainer gap={3}>
          <Autocomplete
            style={{ width: "100%", pointerEvents: disabled ? "none" : "auto" }}
            getOptionLabel={(option: ICompany) =>
              option && selectedInnOption && option?.inn === selectedInnOption?.inn
                ? option?.inn
                : option?.inn + " - " + option?.name
            }
            onOpen={clearValues}
            isOptionEqualToValue={(op, val) => op?.inn === val?.inn}
            options={innOptions}
            popupIcon={disabled ? null : undefined}
            id="inn"
            noOptionsText="Нет результатов"
            onBlur={() => validateForm(values)}
            onChange={onInnSelect}
            value={selectedInnOption}
            renderInput={(params) => (
              <DebouncedInput
                label="ИНН"
                debounceTimeout={500}
                handleDebounce={setInn}
                isLoading={resultCompanies.isLoading}
                input={{
                  ...params,
                  noPointerEvents: disabled,
                  placeholder: "Поиск по ИНН",
                  value: inn,
                  error: errors.inn,
                }}
              />
            )}
          />
        </MDContainer>

        <MDContainer gap={3}>
          <Input
            label="Название компании"
            input={{
              noPointerEvents: disabled,
              placeholder: "Введите название компании",
              value: values.name,
              onChange: handlerChange,
              name: "name",
              error: errors.name,
            }}
          />
          <Input
            label="КПП (необязательно)"
            input={{
              noPointerEvents: disabled,
              placeholder: "Введите КПП",
              value: values.kpp,
              onChange: handlerChange,
              name: "kpp",
              error: errors.kpp,
            }}
          />
        </MDContainer>

        <MDContainer gap={3}>
          <Input
            label="Телефон"
            mask="+7 (999) 999 99 99"
            input={{
              noPointerEvents: disabled,
              name: "phone_1",
              placeholder: "+0 (000) 000 00 00",
              defaultValue: initialValues.phone_1,
              blur: (value) => {
                setFieldValue("phone_1", value);
              },
              error: errors.phone_1,
            }}
          />
          <Input
            label="Телефон (необязательно)"
            mask="+7 (999) 999 99 99"
            input={{
              noPointerEvents: disabled,
              name: "phone_2",
              placeholder: "+0 (000) 000 00 00",
              defaultValue: initialValues.phone_2,
              blur: (value) => {
                setFieldValue("phone_2", value);
              },
              error: errors.phone_2,
            }}
          />
        </MDContainer>

        <MDContainer gap={3}>
          <Input
            label="Электронная почта компании"
            input={{
              noPointerEvents: disabled,
              placeholder: "company@mail.ru",
              value: values.email,
              name: "email",
              onChange: handlerChange,
              error: errors.email,
            }}
          />
          <Input
            label="Адрес компании"
            input={{
              noPointerEvents: disabled,
              placeholder: "Заполняется автоматически",
              value: values.address,
              name: "legal_address",
              error: errors.address,
            }}
          />
        </MDContainer>

        <MDContainer gap={3}>
          <ResponsibleAutocompleteField
            label="Ответственный (необязательно)"
            placeholder="Начните ввод сотрудника"
            disabled={disabled}
            onBlur={() => validateForm(values)}
            onSelect={(value) => setFieldValue("responsible_user_id", value.id)}
            error={errors.responsible_user?.id}
            initialValue={initialValues.responsible_user?.name}
          />
          <Input
            label="Почтовый адрес компании"
            input={{
              noPointerEvents: disabled,
              placeholder: "Заполняется автоматически",
              value: values.legal_address,
              name: "legal_address",
              error: errors.legal_address,
            }}
          />
        </MDContainer>
      </Block>
    </ContentWrapper>
  );
};

export default EditFields;
