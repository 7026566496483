import {
  Action,
  Dispatch,
  MiddlewareAPI,
  Middleware,
  isRejectedWithValue,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import { filtersSlice } from "./filters/slice";
import { api } from "./api";
import configuratorReducer from "./configurator";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { saveStateToLocalStorage } from "./configurator/utils";

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    console.warn("We got a rejected action! ", action.payload);
  }

  return next(action);
};

const persistConfig = {
  key: "configurator",
  storage,
  blacklist: ["filters", "api"],
};

const reducer = combineReducers({
  api: api.reducer,
  filters: filtersSlice.reducer,
  configurator: configuratorReducer,
});

const localStorageMiddleware: any =
  (store: MiddlewareAPI<Dispatch<Action>, RootState>) =>
  (next: Dispatch<Action>) =>
  (action: Action) => {
    const result = next(action);
    const state = store.getState();
    saveStateToLocalStorage(state.configurator);
    return result;
  };

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(api.middleware, localStorageMiddleware),
});

const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { store, persistor };
