import { api } from ".";

export interface INomenclatureKindResponse {
  nomenclatureKinds: INomenclatureKind[];
  nomenclatureKindsCount: number;
}

export interface INomenclatureKind {
  id: number;
  name: string;
  avatar: string;
  created_at: string;
}

export const nomenclatureKindsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getNomenclatureKinds: builder.query<INomenclatureKindResponse, void>({
      query: () => `nomenclature_kinds`,
      transformErrorResponse: (response: any) => response.data.message,
    }),
  }),
});

export const { useGetNomenclatureKindsQuery } = nomenclatureKindsApi;
