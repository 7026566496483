import { Stack, Switch } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import MDTypography from "components/MDTypography";
import PageLayout from "examples/CardPage";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { routes } from "constants/route";
import Loader from "layouts/components/loader";
import ContentHeader from "layouts/pages/widgets/components/ContentHeader";
import Tabs from "layouts/pages/widgets/components/Tabs";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetApplicationByIdQuery,
  useGetApplicationsHistoryQuery,
} from "../../../../Redux/api/applications.api";
import History from "../../../pages/widgets/components/History";
import AutomationsTable from "../components/automation-table";
import { CategoryModal } from "../components/category-modal";
import Main from "../components/main";
import { Menu } from "../components/menu";
import { getInitialValues } from "../consts";
import { AVATAR } from "constants/file-types";
import { useLoadFile } from "hooks/userLoadFile";
import { ECommerceContext } from "../context/app-context";

const ViewECommerce = () => {
  const [name, setName] = useState("");
  const [isCategoryModalOpened, setIsCategoryModalOpened] = useState<boolean>(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const { data: appData, isError, isLoading } = useGetApplicationByIdQuery(id, { skip: !id });
  const { file, filePath, uploadLocalFile, loadFile, cancelUploadLocalFile } = useLoadFile(AVATAR);

  useEffect(() => {
    if (isError) {
      navigate(routes.ECOMMERCE);
    }
  }, [isError]);

  if (!appData || isLoading) {
    return <Loader />;
  }

  return (
    <PageLayout header={<DashboardNavbar />}>
      <ECommerceContext.Provider
        value={{
          setName,
          isWebsite: appData?.application.is_website,
          token: appData.application.token,
          file,
        }}
      >
        <ContentHeader
          info={{
            img: filePath || appData?.application?.photo?.path || "",
            uploadLocalFile: uploadLocalFile,
            title: name || appData?.application?.name,
          }}
          action={
            <Stack direction="row" spacing={0.5} alignItems="center">
              <MDTypography color="text" style={{ fontSize: pxToRem(14) }}>
                {appData?.application.is_website ? "Сайт" : "Приложение"}
              </MDTypography>
              <Switch checked={appData?.application.is_website} />
            </Stack>
          }
          error={false}
        />

        <Tabs
          defaultTab={1}
          items={{
            headers: ["Основные", "Меню", "Автоматизация", "История"],
            components: [
              <Main
                key={"main"}
                edit
                initialValues={getInitialValues(appData.application)}
                type="edit"
                loadFile={loadFile}
                cancelUploadLocalFile={cancelUploadLocalFile}
                cancelPhotoUploadLocalFile={cancelUploadLocalFile}
              />,
              <Menu key="menu" categoryModalOpened={isCategoryModalOpened} />,
              <AutomationsTable key="automatization" />,
              <History key="history" request={useGetApplicationsHistoryQuery} />,
            ],
          }}
        />
      </ECommerceContext.Provider>

      <CategoryModal
        isOpened={isCategoryModalOpened}
        closeHandler={() => setIsCategoryModalOpened((prev) => !prev)}
        applicationId={+id}
      />
    </PageLayout>
  );
};

export default ViewECommerce;
