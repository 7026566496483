/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo } from "react";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

// Material Dashboard 2 PRO React TS examples components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import OptionsDots from "./Options";
import MDAlert from "components/MDAlert";
import FilterTagList from "components/MDFilters/FilterTagList";
import { useTheme } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

export interface ITableOption {
  id: number;
  text: string;
  url?: string;
  isId?: boolean;
  action?: (id: string | number) => any;
}

interface FilterParams {
  page: number;
  perPage: number;
  query: string;
  setPage: Function;
  setPerPage: Function;
  setQuery: Function;
}

// Declaring props types for DataTable
interface Props {
  entriesPerPage?:
    | false
    | {
        defaultValue: number;
        entries: string[];
      };
  canSearch?: boolean;
  totalEntries?: number;
  table: {
    columns: { [key: string]: any }[];
    rows: { [key: string]: any }[];
  };
  pagination?: {
    variant: "contained" | "gradient";
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light";
  };
  isSorted?: boolean;
  noEndBorder?: boolean;
  optionDots?: Array<ITableOption>;
  handleUpdateSource?: (value: string) => void;
  filterParams?: FilterParams;
  showTotalEntries?: boolean;
}

function DataTable({
  entriesPerPage,
  canSearch,
  totalEntries,
  table,
  pagination,
  isSorted,
  optionDots,
  handleUpdateSource,
  filterParams,
  showTotalEntries,
}: Props): JSX.Element {
  let entries: any[];

  const { query, page: initialPage, perPage, setPage, setPerPage } = filterParams;

  if (entriesPerPage) {
    entries = entriesPerPage.entries ? entriesPerPage.entries : [5, 10, 25, 50];
  }

  const columns = useMemo<any>(() => table.columns, [table]);
  const data = useMemo<any>(() => table.rows, [table]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: initialPage ? initialPage - 1 : 0,
        pageSize: perPage,
      },
      manualPagination: true,
      pageCount: Math.ceil(totalEntries / perPage),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  }: any = tableInstance;

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value: any) => {
    setPageSize(value);
    setPerPage(value);
  };

  const setCurrentPage = (value: any) => {
    gotoPage(value);
    setPage(value);
  };

  const renderPaginationButtons = () => {
    const items: (number | "dots")[] = [];
    const getItem = (option: number) => (
      <MDPagination
        item
        key={option}
        onClick={() => setCurrentPage(Number(option + 1))}
        active={pageIndex === option}
      >
        {option + 1}
      </MDPagination>
    );
    pageOptions.forEach((option: number) => {
      if (
        option === 0 ||
        option === pageOptions.length - 1 ||
        pageIndex === option + 1 ||
        pageIndex === option + 2 ||
        pageIndex === option - 1 ||
        pageIndex === option - 2 ||
        pageIndex === option
      ) {
        items.push(option);
      } else if (items[items.length - 1] !== "dots") {
        items.push("dots");
      }
    });
    return items.map((item, index) => {
      if (item === "dots") {
        return <MoreHorizIcon key={"dots-" + index} />;
      } else {
        return getItem(item);
      }
    });
  };
  // Search input state handle
  const onSearchChange = useAsyncDebounce((value: any) => {
    handleUpdateSource(value);
  }, 800);

  // A function that sets the sorted value for the table
  const setSortedValue = (column: any) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  let entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = Math.min(totalEntries || rows.length, pageSize);
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = totalEntries || rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  if (entriesEnd === 0) entriesStart = 0;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      {entriesPerPage || canSearch ? (
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          {canSearch && (
            <MDBox width="24rem">
              <MDInput
                placeholder="Поиск..."
                fullWidth
                defaultValue={query}
                onChange={({ currentTarget }: any) => {
                  onSearchChange(currentTarget.value);
                }}
                sx={{ mb: "1rem" }}
              />
              <FilterTagList />
            </MDBox>
          )}
          {entriesPerPage && !isMobile && (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={entries}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                }}
                size="small"
                sx={{ width: "4rem" }}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;записей на стр.
              </MDTypography>
            </MDBox>
          )}
        </MDBox>
      ) : null}
      {page.length ? (
        <Table {...getTableProps()}>
          <MDBox component="thead">
            {headerGroups.map((headerGroup: any, key: any) => (
              <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any, key: any) => (
                  <DataTableHeadCell
                    key={key}
                    {...column.getHeaderProps(
                      isSorted && column.getSortByToggleProps({ title: "Сортировать" })
                    )}
                    width={"auto"}
                    align={column.align ? column.align : "left"}
                    sorted={setSortedValue(column)}
                  >
                    {column.render("Header")}
                  </DataTableHeadCell>
                ))}
              </TableRow>
            ))}
          </MDBox>
          <TableBody {...getTableBodyProps()}>
            {page.map((row: any, key: any) => {
              prepareRow(row);
              return (
                <TableRow key={key} {...row.getRowProps()}>
                  {row.cells.map((cell: any, key: any) => (
                    <DataTableBodyCell
                      link={cell?.value?.url || cell?.value?.link}
                      key={key}
                      noBorder={false}
                      align={cell.column.align ? cell.column.align : "left"}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </DataTableBodyCell>
                  ))}
                  {optionDots && (
                    <DataTableBodyCell noBorder={false} align={"left"} width={40}>
                      <OptionsDots options={optionDots} id={row.original.id} />
                    </DataTableBodyCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <MDAlert color="secondary" dismissible ml={2} mr={2}>
          Данных нет
        </MDAlert>
      )}

      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!totalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {totalEntries && showTotalEntries ? (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography variant="button" color="secondary" fontWeight="regular">
              Показаны {entriesStart}-{entriesEnd} из {totalEntries} записей
            </MDTypography>
          </MDBox>
        ) : null}
        {pageOptions.length > 1 ? (
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {canPreviousPage && (
              <MDPagination item onClick={() => setCurrentPage(pageIndex - 1)}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPaginationButtons()}
            {canNextPage && (
              <MDPagination item onClick={() => setCurrentPage(pageIndex + 1)}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        ) : null}
      </MDBox>
    </TableContainer>
  );
}

export default DataTable;
