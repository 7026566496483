import { Theme } from "@fullcalendar/core/internal";
import { IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

interface IProps {
  disabled: boolean;
  activeDays: string[];
  change: (day: string) => void;
}

const Days = ({ disabled, activeDays, change }: IProps) => {
  const days = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];

  return (
    <>
      <MDBox display="flex" alignItems="center" gap={3}>
        <MDBox display="flex" gap={1} alignItems="center">
          <MDBox
            sx={{
              background: "linear-gradient(180.1deg, #49A3F1 0.09%, #1A73E8 50%)",
              boxShadow: "0px 2px 6px 0px #2840698C",
            }}
            variant="gradient"
            width={18}
            height={18}
            borderRadius="4px"
          />
          <MDTypography
            fontSize={{ xs: 12, md: 14 }}
            fontWeight="regular"
            lineHeight="140%"
            color="text"
          >
            Рабочие дни
          </MDTypography>
        </MDBox>
        <MDBox display="flex" gap="8px" alignItems="center">
          <MDBox
            sx={{
              background: "#7B809A80",
              boxShadow: "0px 2px 6px 0px #2840698C",
            }}
            variant="gradient"
            width={18}
            height={18}
            borderRadius="4px"
          />
          <MDTypography
            fontSize={{ xs: 12, md: 14 }}
            fontWeight="regular"
            lineHeight="140%"
            color="text"
          >
            Не рабочие дни
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox display="flex" gap="12px" flexWrap="wrap">
        {days.map((day) => (
          <IconButton
            key={day}
            sx={({ palette: { primary, white, dark, light } }: Theme | any) => ({
              display: "flex",
              fontSize: "12px",
              fontWeight: "bold",
              width: "33px",
              height: "33px",
              padding: "8px",
              borderRadius: "8px",
              background: activeDays.includes(day)
                ? "linear-gradient(180.1deg, #49A3F1 0.09%, #1A73E8 50%)"
                : "#7B809A80",
              color: activeDays.includes(day) ? white.main : dark.main,
              boxShadow: "0px 2px 6px 0px #2840698C",
              pointerEvents: disabled ? "none" : "initial",
              flexWrap: "wrap",

              "&:hover": {
                background: "linear-gradient(180.1deg, #49A3F1 0.09%, #1A73E8 50%)",
                color: white.main,
              },
            })}
            onClick={() => change(day)}
          >
            {day}
          </IconButton>
        ))}
      </MDBox>
    </>
  );
};

export default Days;
