import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import MDButton from "components/MDButton";
import MDContainer from "components/MDContainer";
import {
  IContact,
  useAddCounterpartyContactsByIdMutation,
  useEditCounterpartyContactsByIdMutation,
  useGetAllContactsByCounterpartyIdQuery,
} from "../../../../../Redux/api/counterperties.api";
import MDAlert from "components/MDAlert";
import { Add } from "@mui/icons-material";
import ContactFields from "../../../../components/fieldsets/contact-fieldset";
import pxToRem from "assets/theme/functions/pxToRem";
import { companyContactsValidationScheme } from "../../schemas";
import EditContainer from "../../../../components/edit-container";
import FormControls from "../../../../components/form-controls";

interface ICompanyContactsProps {
  isMainEdit?: boolean;
}

const CompanyContacts = ({ isMainEdit }: ICompanyContactsProps) => {
  const { id } = useParams();
  const [addContact, setAddContact] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(null);

  const { data: contactsData } = useGetAllContactsByCounterpartyIdQuery(id);
  const [createContact, createContactResult] = useAddCounterpartyContactsByIdMutation();
  const [editContact, editContactResult] = useEditCounterpartyContactsByIdMutation();

  const editContactHandler = (contact: IContact) => {
    editContact({ counterpartyId: id, contact });
    setSelectedIndex(null);
  };

  const createContactHandler = (contact: IContact) => {
    createContact({ counterpartyId: id, contact });
    setAddContact(false);
  };

  const onEditClickHandler = (index: number) => {
    setSelectedIndex((i) => (i === index ? null : index));
    setAddContact(false);
  };

  const onCancel = () => {
    setAddContact(false);
    setSelectedIndex(null);
  };

  const contacts = useMemo(() => {
    const data: IContact[] = [];
    if (contactsData && contactsData?.contacts?.length) {
      data.push(...contactsData.contacts);
    }
    if (addContact) {
      data.push({
        first_name: "",
        last_name: "",
        middle_name: "",
        birthday: "",
        phone_1: "",
        phone_2: "",
        email: "",
        address: "",
      });
    }

    return data;
  }, [contactsData, addContact]);

  useEffect(() => {
    if (isMainEdit) {
      setAddContact(false);
      setSelectedIndex(null);
    }
  }, [isMainEdit]);

  return (
    <MDContainer variant="column" gap={3} horizontalAligment="center" id="contact-person">
      {editContactResult.isError && (
        <MDAlert dismissible color="error" style={{ width: "100%" }}>
          {JSON.stringify(editContactResult.error)}
        </MDAlert>
      )}
      {createContactResult.isError && (
        <MDAlert dismissible color="error" style={{ width: "100%" }}>
          {JSON.stringify(createContactResult.error)}
        </MDAlert>
      )}
      {editContactResult.isSuccess && (
        <MDAlert dismissible color="success" style={{ width: "100%" }}>
          Данные сохранены
        </MDAlert>
      )}

      {contacts.map((contact, index) => (
        <Formik
          key={Object.values(contact).join("") + (selectedIndex === index ? "selected" : "")}
          initialValues={contact}
          validationSchema={companyContactsValidationScheme}
          onSubmit={
            selectedIndex === index && contact.first_name
              ? editContactHandler
              : createContactHandler
          }
        >
          {() => (
            <Form style={{ width: "100%" }}>
              <EditContainer
                showButton={!!contact.first_name}
                buttonColor={selectedIndex === index ? "secondary" : "error"}
                buttonAction={() => onEditClickHandler(index)}
              >
                <ContactFields
                  position={index + 1}
                  disabled={selectedIndex !== index && !addContact}
                />
              </EditContainer>
              {(selectedIndex === index || contact.first_name === "") && (
                <FormControls cancelAction={onCancel} />
              )}
            </Form>
          )}
        </Formik>
      ))}
      {!addContact && (
        <MDButton
          color="primary"
          size="small"
          style={{ padding: 0, minWidth: pxToRem(32) }}
          onClick={() => setAddContact(true)}
          variant="gradient"
        >
          <Add />
        </MDButton>
      )}
    </MDContainer>
  );
};

export default CompanyContacts;
